import { useState, useLayoutEffect } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  Icon,
  InputAdornment,
} from '@material-ui/core';

import IzinCutiTabs from './component/IzinCutiTabs';
import IzinPengajuan from './component/IzinPengajuan';
import {
  getPengajuanCuti,
  getRiwayatCuti,
} from 'app/redux/actions/IzinCutiAction';
import IzinCutiSelect from './component/IzinCutiSelect';
import { SearchKaryawan } from 'app/components';

const Cuti = () => {
  const [state, setState] = useState({
    search: '',
    pengajuan: true,
    data: [],
    status: '',
    openDitolak: false,
    selectedItem: null,
  });

  const getData = () => {
    if (state.pengajuan) {
      let params = state.search === '' ? '' : `?search=${state.search}`;
      getPengajuanCuti(params).then((res) => {
        const data = res.data?.data;
        setState((prev) => ({
          ...prev,
          data,
        }));
      });
    } else if (!state.pengajuan) {
      let params =
        state.search === ''
          ? state.status === ''
            ? ''
            : `?status=${state.status}`
          : state.status === ''
          ? `?search=${state.search}`
          : `?search=${state.search}&status=${state.status}`;
      getRiwayatCuti(params).then((res) => {
        const data = res.data?.data;
        setState((prev) => ({
          ...prev,
          data,
        }));
      });
    }
  };

  useLayoutEffect(() => {
    getData();
  }, [state.status, state.pengajuan]);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: '#0A0A0A' }}
          >
            Cuti
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="cuti/rekap">
            <Button variant="outlined" color="primary" className="px-8 py-3">
              <span className="karyawan-btn-span">Rekap Cuti Karyawan</span>
            </Button>
          </Link>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div
          className="d-flex mt-1 mb-7"
          style={{ justifyContent: 'space-between' }}
        >
          <div className="align-center">
            <IzinCutiTabs
              style=""
              state={state}
              setState={setState}
              izin={false}
            />
          </div>
          <div className="d-flex align-center">
            <SearchKaryawan
              otherClasses='mr-4 w-240'
              handleChange={(e) =>
                setState({
                  ...state,
                  search: e.target.value,
                })
              }
              value={state.search}
              handleKeyDown={submitSearch}
            />
            {!state.pengajuan && (
              <IzinCutiSelect
                dataSelect={[
                  {
                    value: '',
                    text: 'Lihat Semua',
                  },
                  {
                    value: 'Diizinkan',
                    text: 'Diizinkan',
                  },
                  {
                    value: 'Ditolak',
                    text: 'Ditolak',
                  },
                ]}
                state={state}
                setState={setState}
                label="Status"
                name="status"
              />
            )}
          </div>
        </div>
        <IzinPengajuan izin={false} state={state} setState={setState} getData={getData} />
      </SimpleCard>
    </div>
  );
};

export default Cuti;

import {
  Button,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import React, { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { RenderTable } from 'app/components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAturAbsensi, delAturAbsensi } from 'app/redux/actions/AturanAction';
import ic_searchfilter from '../../../assets/component/ic_searchfilter.svg';
import RenderTableAturAbsensi from '../../components/table/RenderTableAturAbsensi';
import AddIcon from '@material-ui/icons/Add';

const AturAbsensi = () => {
  const [state, setState] = useState({
    loading: true,
    search: '',
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: '',
    nameClick: '',
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: 'Indonesia',
        value: 'ind',
      },
      {
        label: 'English',
        value: 'eng',
      },
    ],
    bahasa: '',
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  
  const getData = useCallback(() => {
    let params = `?search=${search}`;
    dispatch(getAturAbsensi(params));
  }, [dispatch, search]);

  useEffect(() => {
    getData();
  }, []);

  const handleEditData = () => {
    setState({ editAbsensi: true });
  };

  const handleDetail = (item) => {
    setState({ nameClick: item });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Hapus',
      text: 'Apakah kamu yakin ?',
      showCancelButton: true,
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal',
      icon: 'warning',
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(delAturAbsensi(id))
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: 'Berhasil',
                text: 'Data berhasil dihapus',
                timer: 2000,
                icon: 'success',
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: 'gagal',
              text: 'Data Gagal dihapus',
              timer: 2000,
              icon: 'error',
            });
          });
      }
    });
  };

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value });
    HTMLFormControlsCollection;
  };
  const dataAturan = useSelector((state) => state.aturan.dataAturan);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: '#0A0A0A' }}
          >
            Atur Absensi
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/AturAbsensi/Tambah">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <Grid container spacing={2} justify="flex-end">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div
                className="d-flex items-center mt-4 "
                style={{ justifyContent: 'flex-end', gap: '20px' }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  className="karyawan-top-search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  onKeyDown={submitSearch}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          style={{ paddingRight: '5px' }}
                          src={ic_searchfilter}
                        ></img>
                      </InputAdornment>
                    ),
                    style: { fontSize: 13 },
                    placeholder: 'Cari Jam Absensi',
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: '1px #e6e5e5 solid',
                marginTop: '20px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={2}>Nama</TableCell>

                  <TableCell align="center" colSpan={4}>
                    Waktu Absensi
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Jam Masuk
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Jam Pulang
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTable
                  state={state}
                  data={dataAturan}
                  tableName="aturabsensi"
                  handleEditData={handleEditData}
                  handleDelete={handleDelete}
                  dataKosongSpan={17}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataAturan?.length ? dataAturan?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={'From'}
              page={state.page}
              backIconButtonProps={{ 'aria-label': 'Previous page' }}
              nextIconButtonProps={{ 'aria-label': 'Next page' }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

export default AturAbsensi;

import { API, setAuthToken } from 'app/config/api';

export const GET_DETAIL_ATUR_PASAL = 'GET_DETAIL_ATUR_PASAL';

export const getListAturPasal = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get('/user/lainnya/atur_pasal' + params);
};

export const addAturPasal = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/lainnya/atur_pasal/add`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const getDetailAturPasal = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/lainnya/atur_pasal/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_ATUR_PASAL,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_ATUR_PASAL,
        payload: [],
      });
    }
  };
};

export const editAturPasal = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/lainnya/atur_pasal/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const delAturPasal = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(
      `/user/lainnya/atur_pasal/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import { Icon, MenuItem, Select } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';

const styles = {
  badgeSelect: {
    width: '125px',
    height: '36px',
    borderRadius: '5px',
    color: '#FFFFFF !important',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    '& .MuiSelect-icon': {
      color: '#FFFFFF',
      height: '100%',
      marginTop: '-6px',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    }
  },
  menuIcon: {
    '&.Mui-selected': {
      backgroundColor: '#FFFFFF',
    },
    '&[data-color="#72BE42"]': {
        '&:hover': {
            backgroundColor: '#F3FFEC',
        },
    },
    '&[data-color="#FF5C58"]': {
        '&:hover': {
            backgroundColor: '#FFEBEA',
        },
    },
    '&[data-color="#FBC02A"]': {
        '&:hover': {
            backgroundColor: '#FFF8E7',
        },
    },
    '&[data-color="#00ACEE"]': {
        '&:hover': {
            backgroundColor: '#E0F7FF',
        },
    },
  }
};

class BadgeSelect extends React.Component {
  render() {
    const { badgeBackgroundColor, classes, iconBackgroundColor, handleChange, item, menus, renderValue, value } = this.props;

    return (
      <Select
        value={value}
        onChange={(event) => handleChange(event, item)}
        displayEmpty
        className={classes.badgeSelect}
        style={{
          background: badgeBackgroundColor,
        }}
        renderValue={renderValue}
        IconComponent={(props) => (<KeyboardArrowDown {...props} style={{
            background: iconBackgroundColor,
        }} />)}
      >
        {menus.map(menu => (
            <MenuItem
                key={menu.value}
                value={menu.value}
                className={classes.menuIcon}
                data-color={menu.color}
                style={{ color: menu.color }}
            >
                {menu.icon} {menu.label}
            </MenuItem>
        ))}
      </Select>
    );
  }
}

export default withStyles(styles)(BadgeSelect);
import { API, setAuthToken } from "app/config/api";

export const GET_DATA_PERJANJIAN_KERJA_SAMA = "GET_DATA_PERJANJIAN_KERJA_SAMA";
export const GET_DETAIL_PERJANJIAN_KERJA_SAMA =
  "GET_DETAIL_PERJANJIAN_KERJA_SAMA";
export const DEL_PERJANJIAN_KERJA_SAMA = "DEL_PERJANJIAN_KERJA_SAMA";

export const getPerjanjianKerjaSama = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/perjanjian_kerja_sama${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_PERJANJIAN_KERJA_SAMA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_PERJANJIAN_KERJA_SAMA,
          payload: [],
        });
      });
  };
};

export const getDetailPerjanjianKerjaSama = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/perjanjian_kerja_sama/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_PERJANJIAN_KERJA_SAMA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_PERJANJIAN_KERJA_SAMA,
        payload: [],
      });
    }
  };
};

export const delPerjanjianKerjaSama = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/perjanjian_kerja_sama/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_PERJANJIAN_KERJA_SAMA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addPerjanjianKerjaSama = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/perjanjian_kerja_sama/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editPerjanjianKerjaSama = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/perjanjian_kerja_sama/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import {
  GET_DATA_PENERIMAAN_MAGANG,
  GET_DETAIL_PENERIMAAN_MAGANG,
  GET_DATA_ATUR_LEMBUR,
  GET_DATA_KETERANGAN_MAGANG,
  GET_DETAIL_KETERANGAN_MAGANG,
  GET_DATA_PERNYATAAN_MAGANG,
  GET_DETAIL_PERNYATAAN_MAGANG,
  DEL_PENERIMAAN_MAGANG,
  DEL_PERNYATAAN_MAGANG,
  DEL_KETERANGAN_MAGANG,
} from "../actions/administrasi/MagangAction";

import {
GET_DATA_KOP_SURAT,
GET_DETAIL_KOP_SURAT,
DEL_KOP_SURAT
} from "../actions/administrasi/KopSuratAction";

import {
GET_DATA_HANDOVER,
GET_DETAIL_HANDOVER,
DEL_HANDOVER
} from "../actions/administrasi/HandoverAction";

import {
GET_DATA_PERJANJIAN_KERJA_SAMA,
GET_DETAIL_PERJANJIAN_KERJA_SAMA,
DEL_PERJANJIAN_KERJA_SAMA
} from "../actions/administrasi/PerjanjianKerjaSamaAction";

import {
  GET_DATA_PENERIMAAN_KERJA,
  GET_DETAIL_PENERIMAAN_KERJA,
  GET_DATA_KETERANGAN_KERJA,
  GET_DETAIL_KETERANGAN_KERJA,
  GET_DATA_KONTRAK_KERJA,
  GET_DETAIL_KONTRAK_KERJA,
  DEL_PENERIMAAN_KERJA,
  DEL_KONTRAK_KERJA,
  DEL_KETERANGAN_KERJA,
} from "../actions/administrasi/KerjaAction";

const initialState = {
  dataAdministrasi: [],
  detailAdministrasi: {},
};

const AturanReducer = function (state = initialState, action) {
  switch (action.type) {
    case DEL_PENERIMAAN_MAGANG: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DATA_PENERIMAAN_MAGANG: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case GET_DETAIL_PENERIMAAN_MAGANG: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case GET_DATA_KETERANGAN_MAGANG: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case DEL_KETERANGAN_MAGANG: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DETAIL_KETERANGAN_MAGANG: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case GET_DATA_PERNYATAAN_MAGANG: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }

    case GET_DETAIL_PERNYATAAN_MAGANG: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case DEL_PERNYATAAN_MAGANG: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
     case DEL_KOP_SURAT: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DATA_KOP_SURAT: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case GET_DETAIL_KOP_SURAT: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }

      case DEL_HANDOVER: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DATA_HANDOVER: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case GET_DETAIL_HANDOVER: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }

     case DEL_PERJANJIAN_KERJA_SAMA: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DATA_PERJANJIAN_KERJA_SAMA: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case GET_DETAIL_PERJANJIAN_KERJA_SAMA: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }

  // Penerimaan Kerja    
  case DEL_PENERIMAAN_KERJA: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DATA_PENERIMAAN_KERJA: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case GET_DETAIL_PENERIMAAN_KERJA: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case GET_DATA_KETERANGAN_KERJA: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }
    case DEL_KETERANGAN_KERJA: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }
    case GET_DETAIL_KETERANGAN_KERJA: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case GET_DATA_KONTRAK_KERJA: {
      return {
        ...state,
        dataAdministrasi: action.payload,
      };
    }

    case GET_DETAIL_KONTRAK_KERJA: {
      return {
        ...state,
        detailAdministrasi: action.payload,
      };
    }
    case DEL_KONTRAK_KERJA: {
      let penilaian = state.data;
      return {
        ...state,
        dataAdministrasi: penilaian,
      };
    }

  
    default: {
      return state;
    }
  }
};

export default AturanReducer;

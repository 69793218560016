import { useState } from "react";
import { Dialog, Grid, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ModalImg from "app/components/Modal/ModalImg";

import Swal from "sweetalert2";
import {
  getDetailCuti,
  getDetailIzin,
  updateStatusCuti,
  updateStatusIzin,
} from "app/redux/actions/IzinCutiAction";
import { formatTgl } from "app/utils/globalFunction";
import IzinCutiDatePicker from "./IzinCutiDatePicker";

export default function Modal({ item, getData, izin = true }) {
  const [state, setState] = useState({
    created_at: "",
    openDialog: false,
    openDitolak: false,
    keterangan: "",
    name: "",
    status: "",
    alasan: "",
    tanggal_akhir: "",
    tanggal_diverifikasi: "",
    tanggal_mulai: "",
    unit_kerja: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const useStyles = makeStyles({
    dialog: {
      width: "430px",
      height: "fit-content",
      scrollbarColor: "transparent",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      openDialog: true,
    }));
    if (!izin) {
      getDetailCuti(item?.cuti_code).then((res) => {
        let data = res.data?.data[0];
        if (data.created_at !== undefined) {
          data.created_at = data.created_at;
        }
        setState((prev) => ({
          ...prev,
          ...data,
        }));
      });
    } else {
      getDetailIzin(item?.izin_code).then((res) => {
        const data = res.data?.data[0];
        setState((prev) => ({
          ...prev,
          ...data,
        }));
      });
    }
  };

  const handleClose = () => {
    getData();
    setState((prev) => ({
      ...prev,
      openDialog: false,
      openDitolak: false,
    }));
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTolak = () => {
    setState((prev) => ({
      ...prev,
      openDialog: false,
      openDitolak: true,
    }));
  };

  const handleTerima = async () => {
    const tanggalMulai =
      state.tanggal_mulai === "" ? item.tanggal_mulai : state.tanggal_mulai;
    const tanggalAkhir =
      state.tanggal_akhir === "" ? item.tanggal_akhir : state.tanggal_akhir;
    try {
      if (Date.parse(tanggalMulai) <= Date.parse(tanggalAkhir)) {
        if (izin) {
          const res = await dispatch(
            updateStatusIzin({
              izin_code: item?.izin_code,
              tanggal_mulai: tanggalMulai,
              tanggal_akhir: tanggalAkhir,
              status: "Diizinkan",
            })
          );

          console.log(res);

          setTimeout(() => {
            handleClose();
            Swal.fire("Success!", "Data Izin Berhasil Disimpan", "success");
          }, 1000);
        } else {
          const res = await dispatch(
            updateStatusCuti({
              cuti_code: item?.cuti_code,
              tanggal_mulai: tanggalMulai,
              tanggal_akhir: tanggalAkhir,
              status: "Diizinkan",
            })
          );

          console.log(res);

          if (res.code == 0) {
            setTimeout(() => {
              handleClose();
              Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
            }, 1000);
          } else {
            setTimeout(() => {
              handleClose();
              Swal.fire("Success!", res.data.msg, "success");
            }, 1000);
          }
        }
      } else {
        Swal.fire(
          "Oopss!",
          "tanggal mulai harus sebelum tanggal selesai",
          "error"
        );
      }
    } catch (e) {
      handleClose();
      Swal.fire("Oopss!", `${e}`, "error");
    }
  };

  const handleKirimPenolakan = async () => {
    if (izin) {
      try {
        await dispatch(
          updateStatusIzin({
            izin_code: item?.izin_code,
            status: "Ditolak",
            alasan: state?.alasan,
          })
        );

        setTimeout(() => {
          handleClose();
          Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
        }, 1000);
      } catch (e) {
        handleClose();
        Swal.fire("Oopss!", `${e}`, "error");
      }
    } else {
      try {
        await dispatch(
          updateStatusCuti({
            cuti_code: item?.cuti_code,
            status: "Ditolak",
            alasan: state?.alasan,
          })
        );

        setTimeout(() => {
          handleClose();
          Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
        }, 1000);
      } catch (e) {
        handleClose();
        Swal.fire("Oopss!", `${e}`, "error");
      }
    }
  };

  return (
    <div className="m-0 p-0 break-word">
      <Button
        variant="contained"
        className="bg-white elevation-z0 py-3"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        onClick={handleClickOpen}
      >
        <VisibilityOutlinedIcon
          className="text-green"
          style={{ transform: "scale(1.4)" }}
        />
      </Button>

      <Dialog
        open={state?.openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <div
          className="my-8 px-8"
          style={{ overflow: "hidden !important", margin: 0 }}
        >
          <h1 className="text-18 font-semibold text-black mb-1">
            Detail {izin ? "Izin" : "Cuti"}
          </h1>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <p
                className="text-14 font-medium"
                style={{ color: "#BBBBBB", marginBottom: "-10px" }}
              >
                Nama Pegawai
              </p>
              <p className="text-16 font-medium text-black mb-1">
                {item?.name}
              </p>
            </Grid>
            {izin && (
              <Grid item xs={6}>
                <p
                  className="text-14 font-medium"
                  style={{ color: "#BBBBBB", marginBottom: "-10px" }}
                >
                  Jenis Izin
                </p>
                <p className="text-16 font-medium text-black mb-1">
                  {item?.jenis_izin}
                </p>
              </Grid>
            )}
            <Grid item xs={6}>
              <p
                className="text-14 font-medium"
                style={{ color: "#BBBBBB", marginBottom: "-10px" }}
              >
                Unit Kerja
              </p>
              <p className="text-16 font-medium text-black mb-1">
                {item?.unit_kerja}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p
                className="text-14 font-medium mb-2"
                style={{ color: "#BBBBBB", marginBottom: "-10px" }}
              >
                Status
              </p>
              <p
                className={
                  item?.status.toLowerCase() === "diproses"
                    ? "status-diproses mb-1"
                    : item?.status.toLowerCase() === "diizinkan"
                    ? "status-diizinkan mb-1"
                    : "status-ditolak mb-1"
                }
                style={{ marginRight: "100px" }}
              >
                {item?.status[0].toUpperCase() + item?.status.substring(1)}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p
                className="text-14 font-medium break-word"
                style={{ color: "#BBBBBB", marginBottom: "-10px" }}
              >
                Keterangan
              </p>
              <p className="text-16 font-medium text-black mb-1 break-word">
                {izin
                  ? item?.keterangan
                  : state?.keterangan === ""
                  ? "-"
                  : state?.keterangan}
              </p>
            </Grid>
            {!izin && (
              <Grid item xs={6}>
                <p
                  className="text-14 font-medium"
                  style={{ color: "#BBBBBB", marginBottom: "-10px" }}
                >
                  Tanggal Diverifikasi
                </p>
                <p className="text-16 font-medium text-black mb-1">
                  {state?.tanggal_diverifikasi === ""
                    ? "-"
                    : formatTgl(state?.tanggal_diverifikasi)}
                </p>
              </Grid>
            )}
            <Grid item xs={6}>
              <p
                className="text-14 font-medium"
                style={{ color: "#BBBBBB", marginBottom: "-10px" }}
              >
                Tanggal Dibuat
              </p>
              <p className="text-16 font-medium text-black mb-1">
                {izin
                  ? state?.created_at === ""
                    ? "-"
                    : formatTgl(state?.created_at)
                  : state?.created_at === ""
                  ? "-"
                  : formatTgl(state?.created_at)}
              </p>
            </Grid>
            {!izin && (
              <Grid
                item
                xs={item?.status.toLowerCase() === "diproses" ? 6 : 12}
              >
                <p
                  className="text-14 font-medium"
                  style={{
                    color: "#BBBBBB",
                    marginBottom:
                      item?.status?.toLowerCase() === "diproses"
                        ? "2px"
                        : "-10px",
                  }}
                >
                  {item?.status.toLowerCase() === "diproses"
                    ? "Tanggal Mulai Cuti"
                    : "Tanggal Cuti"}
                </p>
                {item?.status?.toLowerCase() === "diproses" ? (
                  <IzinCutiDatePicker
                    state={state}
                    setState={setState}
                    name="tanggal_mulai"
                    format="long"
                  />
                ) : (
                  <p className="text-16 font-medium text-black mb-1">
                    {formatTgl(item?.tanggal_mulai) ===
                    formatTgl(item?.tanggal_akhir)
                      ? formatTgl(item?.tanggal_akhir)
                      : formatTgl(item?.tanggal_mulai) +
                        " - " +
                        formatTgl(item?.tanggal_akhir)}
                  </p>
                )}
              </Grid>
            )}
            {!izin && item.status.toLowerCase() === "diproses" && (
              <Grid item xs={6}>
                <p
                  className="text-14 font-medium"
                  style={{
                    color: "#BBBBBB",
                    marginBottom: "2px",
                  }}
                >
                  Tanggal Selesai Cuti
                </p>
                <IzinCutiDatePicker
                  state={state}
                  setState={setState}
                  name="tanggal_akhir"
                  format="long"
                />
              </Grid>
            )}
            {izin && (
              <Grid
                item
                xs={item?.status.toLowerCase() === "diproses" ? 6 : 12}
              >
                <p
                  className="text-14 font-medium"
                  style={{
                    color: "#BBBBBB",
                    marginBottom:
                      item?.status?.toLowerCase() === "diproses"
                        ? "2px"
                        : "-10px",
                  }}
                >
                  {item?.status.toLowerCase() === "diproses"
                    ? "Tanggal Mulai Izin"
                    : "Tanggal Izin"}
                </p>
                {item?.status?.toLowerCase() === "diproses" ? (
                  <IzinCutiDatePicker
                    state={state}
                    setState={setState}
                    name="tanggal_mulai"
                    format="long"
                  />
                ) : (
                  <p className="text-16 font-medium text-black mb-1">
                    {formatTgl(item?.tanggal_mulai) ===
                    formatTgl(item?.tanggal_akhir)
                      ? formatTgl(item?.tanggal_akhir)
                      : formatTgl(item?.tanggal_mulai) +
                        " - " +
                        formatTgl(item?.tanggal_akhir)}
                  </p>
                )}
              </Grid>
            )}
            {izin && item.status.toLowerCase() === "diproses" && (
              <Grid item xs={6}>
                <p
                  className="text-14 font-medium"
                  style={{
                    color: "#BBBBBB",
                    marginBottom: "2px",
                  }}
                >
                  Tanggal Selesai Izin
                </p>
                <IzinCutiDatePicker
                  state={state}
                  setState={setState}
                  name="tanggal_akhir"
                  format="long"
                />
              </Grid>
            )}
            {item?.status.toLowerCase() === "ditolak" && (
              <Grid item xs={12}>
                <p
                  className="text-14 font-medium"
                  style={{ color: "#BBBBBB", marginBottom: "-10px" }}
                >
                  Alasan Ditolak
                </p>
                <p className="text-16 font-medium text-black mb-1">
                  {izin
                    ? item?.alasan
                    : state?.alasan === ""
                    ? "-"
                    : state?.alasan}
                </p>
              </Grid>
            )}
            {izin && (
              <Grid item xs={12}>
                <p
                  className="text-14 font-medium mb-3"
                  style={{ color: "#BBBBBB", marginBottom: "-10px" }}
                >
                  Bukti
                </p>
                {state?.bukti === "" || state?.bukti == undefined ? (
                  <Avatar
                    className="w-full"
                    style={{ height: "170px" }}
                    variant="rounded"
                  >
                    Bukti tidak ditemukan
                  </Avatar>
                ) : (
                  <ModalImg src={state?.bukti} />
                )}
              </Grid>
            )}
            {item?.status.toLowerCase() === "diproses" && (
              <Grid item xs={6} className="mt-7 pr-2">
                <Button
                  variant="outlined"
                  className="w-full text-red elevation-z0 px-4 py-3 border-red-figma"
                  style={{}}
                  onClick={handleTolak}
                >
                  Tolak
                </Button>
              </Grid>
            )}
            {item?.status.toLowerCase() === "diproses" && (
              <Grid item xs={6} className="mt-7 pl-2">
                <Button
                  variant="outlined"
                  className="w-full text-primary elevation-z0 px-4 py-3"
                  onClick={handleTerima}
                >
                  Terima
                </Button>
              </Grid>
            )}
            {item?.status.toLowerCase() !== "diproses" && (
              <Grid item xs={12} className="mt-7">
                <Button
                  variant="outlined"
                  className="w-full text-primary elevation-z0 p-4"
                  onClick={handleClose}
                >
                  Tutup
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={state?.openDitolak}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: "hidden !important", margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-semibold text-black mb-5">
            Masukkan Alasan Ditolak
          </h1>
          <Grid container>
            <Grid item xs={12}>
              <p
                className="text-16 font-medium text-black"
                style={{ marginBottom: "-3px" }}
              >
                Alasan Ditolak
              </p>
              <textarea
                required={true}
                name="alasan"
                rows="3"
                cols="50"
                className="mt-3 w-full px-6 py-4 border-radius-5"
                placeholder="Alasan"
                style={{
                  border: "2px solid #e6e9ed",
                  fontSize: "1.5em",
                  fontFamily: "inherit",
                }}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6} className="mt-4 pr-3">
              <Button
                variant="outlined"
                className="w-full text-primary elevation-z0 p-4"
                onClick={handleClose}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6} className="mt-4 pl-3">
              <Button
                variant="contained"
                className="w-full text-white bg-primary elevation-z0 p-4"
                onClick={handleKirimPenolakan}
              >
                Kirim
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

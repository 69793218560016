import BonusRoutes from "./bonus/BonusRoutes";
import DendaRoutes from "./denda/DendaRoutes";
import IzinRoutes from "./izin/IzinRoutes";
import AturPasalRoutes from "./atur_pasal/AturPasalRoutes";
import ReimburseRoutes from "./reimburse/ReimburseRoutes";
import TunjanganRoutes from "./tunjangan/TunjanganRoutes";
import UnitKerjaRoutes from "./unitKerja/UnitKerjaRoutes";

const LainnyaRoutes = [
  ...TunjanganRoutes,
  ...BonusRoutes,
  ...ReimburseRoutes,
  ...DendaRoutes,
  ...IzinRoutes,
  ...AturPasalRoutes,
  ...UnitKerjaRoutes,
];

export default LainnyaRoutes;

import React from "react";
import { Grid } from "@material-ui/core";

const DashboardCardCounter = ({ title, count, Icon }) => {
  return (
    <Grid item xs={12} sm className="rounded-lg pl-9 mx-4 bg-white h-134">
      <p className="font-semibold text-18 text-semiblack">{title}</p>
      <div className="d-flex items-center gap-16 h-42">
        <Icon
            className="w-25 h-25 ml-6 text-primary"
            style={{ transform: 'scale(1.5)' }}
        />
        <span className="font-bold text-28 text-semiblack">{count}</span>
      </div>
    </Grid>
  );
};

export default DashboardCardCounter;

import { useState, useLayoutEffect } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TableAturPasal from './component/TableAturPasal';
import { getListAturPasal } from 'app/redux/actions/lainnya/AturPasalAction';

const AturPasal = () => {
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    atur_pasal: [],
  });

  const getData = () => {
    const params = `?search=${search}`;
    getListAturPasal(params).then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        atur_pasal: data,
      }));
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="text-semiblack font-semibold text-25 my-auto">
            Atur Pasal
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/lainnya/atur_pasal/add">
            <Button variant="contained" color="primary" className="px-8 py-3">
              <AddIcon /> <span className="">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="mt-2 d-flex items-center mb-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <TextField
            size="small"
            variant="outlined"
            className="w-260"
            placeholder="Cari Judul Pasal"
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                getData();
              }
            }}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TableAturPasal data={state.atur_pasal} getData={getData} />
      </SimpleCard>
    </div>
  );
};

export default AturPasal;

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Grid } from '@material-ui/core';
import { SimpleCard } from 'matx';
import Swal from 'sweetalert2';
import {
  editJenisTunjangan,
  getDetailJenisTunjangan,
} from 'app/redux/actions/lainnya/JenisTunjanganAction';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import KaryawanSelect from 'app/views/karyawan/component/KaryawanSelect';

const EditTunjangan = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = useState({
    jenis: '',
    tipe: '',
    scaleY: '0.85',
  });

  useEffect(() => {
    getDetailJenisTunjangan(id).then((item) => {
      const data = item.data?.data;
      setState((prev) => ({
        ...prev,
        ...data,
      }));
    });
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      await dispatch(
        editJenisTunjangan({
          jenis_tunjangan_code: id,
          jenis: state.jenis,
          tipe: state.tipe,
        })
      );
      let timerInterval;
      Swal.fire({
        title: 'Sedang diproses...',
        html: 'tunggu dalam waktu <b></b> detik.',
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          setTimeout(() => {
            clearInterval(timerInterval);
            history.push('/lainnya/tunjangan');
            Swal.fire(
              'Success!',
              'Data Jenis Tunjangan berhasil disimpan',
              'success'
            );
          }, 4000);
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 1000);
        },
      });
    } catch (e) {
      Swal.fire('Oopss!', 'Data Jenis Tunjangan gagal disimpan', 'error');
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #e6e9ed',
      },
    },
    input: {
      transform: 'scaleY(0.88)',
      marginBlock: 'auto',
    },
  }));
  const classes = useStyles();
  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25">Edit Jenis Tunjangan</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="mt-9 mb-8"
            >
              <Grid
                container
                className="mt-2"
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-5 font-medium text-14">
                    Jenis
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    name="jenis"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="Jenis"
                    variant="outlined"
                    value={state.jenis}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-5 font-medium text-14">
                    Tipe
                  </h1>
                  <KaryawanSelect
                    menuItemFontSize="text-16"
                    width="100%"
                    scaleY="1.25"
                    dataSelect={[
                      {
                        value: 'harian',
                        text: 'Harian',
                      },
                      {
                        value: 'bulanan',
                        text: 'Bulanan',
                      },
                      {
                        value: 'tahunan',
                        text: 'Tahunan',
                      },
                    ]}
                    size="small"
                    label="Tipe"
                    name="tipe"
                    state={state}
                    setState={setState}
                    style="w-full"
                    styleMenu={{
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default EditTunjangan;

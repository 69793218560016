import { API, setAuthToken } from "app/config/api";

export const GET_DATA_PENERIMAAN_MAGANG = "GET_DATA_PENERIMAAN_MAGANG";
export const GET_DETAIL_PENERIMAAN_MAGANG = "GET_DETAIL_PENERIMAAN_MAGANG";
export const DEL_PENERIMAAN_MAGANG = "DEL_PENERIMAAN_MAGANG";
export const GET_DATA_LEMBUR = "GET_DATA_LEMBUR";
export const GET_DATA_KETERANGAN_MAGANG = "GET_DATA_KETERANGAN_MAGANG";
export const GET_DETAIL_KETERANGAN_MAGANG = "GET_DETAIL_KETERANGAN_MAGANG";
export const DEL_KETERANGAN_MAGANG = "DEL_KETERANGAN_MAGANG";
export const GET_DATA_PERNYATAAN_MAGANG = "GET_DATA_PERNYATAAN_MAGANG";
export const GET_DETAIL_PERNYATAAN_MAGANG = "GET_DETAIL_PERNYATAAN_MAGANG";
export const DEL_PERNYATAAN_MAGANG = "DEL_PERNYATAAN_MAGANG";
export const DEL_LEMBUR = "DEL_LEMBUR";

export const getPenerimaanMagang = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/penerimaan_magang${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_PENERIMAAN_MAGANG,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_PENERIMAAN_MAGANG,
          payload: [],
        });
      });
  };
};

export const getDetailPenerimaanMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/penerimaan_magang/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_PENERIMAAN_MAGANG,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_PENERIMAAN_MAGANG,
        payload: [],
      });
    }
  };
};

export const delPenerimaanMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/penerimaan_magang/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_PENERIMAAN_MAGANG,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addPenerimaanMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/penerimaan_magang/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editPenerimaanMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/penerimaan_magang/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getKeteranganMagang = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/keterangan_magang${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_KETERANGAN_MAGANG,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_KETERANGAN_MAGANG,
          payload: [],
        });
      });
  };
};

export const getDetailKeteranganMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/keterangan_magang/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_KETERANGAN_MAGANG,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_KETERANGAN_MAGANG,
        payload: [],
      });
    }
  };
};

export const addKeteranganMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/keterangan_magang/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editKeteranganMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/keterangan_magang/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delKeteranganMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/keterangan_magang/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_KETERANGAN_MAGANG,
        payload: code,
      });
    }
    return res.data;
  };
};

export const getPernyataanMagang = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/pernyataan_magang${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_PERNYATAAN_MAGANG,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_PERNYATAAN_MAGANG,
          payload: [],
        });
      });
  };
};

export const getDetailPernyataanMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/pernyataan_magang/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_PERNYATAAN_MAGANG,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_PERNYATAAN_MAGANG,
        payload: [],
      });
    }
  };
};

export const delPernyataanMagang = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/pernyataan_magang/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_PERNYATAAN_MAGANG,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addPernyataanMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/pernyataan_magang/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editPernyataanMagang = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/pernyataan_magang/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useState, useRef } from "react";
import { SimpleCard } from "matx";
import { connect } from "react-redux";

import { addAturHariLibur } from "app/redux/actions/AturanAction";
import Swal from "sweetalert2";
import { DatePickerComponent } from "app/components";
import { useParams, useHistory } from "react-router-dom";

const TambahAturHariLibur = ({ addAturHariLibur }) => {
  const [nama, setNama] = useState("");
  const [tanggal_mulai, setTanggalMulai] = useState(new Date());
  const [tanggal_akhir, setTanggalAkhir] = useState(new Date());
  const [submit, setSubmit] = useState(false);

  const formRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "nama") {
      setNama(value);
    } else if (name === "tanggal_mulai") {
      setTanggalMulai(new Date(value));
    } else if (name === "tanggal_akhir") {
      setTanggalAkhir(new Date(value));
    }
  };

  const handleDateMulaiChange = (date) => {
    var event = new Date(date);

    let dateFormat = JSON.stringify(event);
    dateFormat = dateFormat.slice(1, 11);
    // setTanggalMulai(dateFormat);
    setTanggalMulai(dateFormat);
  };

  const handleDateAkhirChange = (date) => {
    var event = new Date(date);

    let dateFormat = JSON.stringify(event);
    dateFormat = dateFormat.slice(1, 11);
    // setTanggalMulai(dateFormat);
    setTanggalAkhir(dateFormat);
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      await addAturHariLibur({
        nama: nama,
        tanggal_mulai: tanggal_mulai,
        tanggal_akhir: tanggal_akhir,
      });

      Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
      history.push("/AturHariLibur");
    } catch (e) {
      Swal.fire("Oopss!", "Data Hari Libur gagal disimpan", "error");
    }
  };

  return (
    <div>
      <div className="p-5 font-semibold text-12 ">
        <Grid
          container
          spacing={3}
          className="mx-auto px-2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm>
            <h4 className="mb-5 ">Tambah Hari Libur</h4>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            className="d-flex mr-4"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              className="px-15 py-3"
              onClick={handleSubmit}
            >
              <span className="">Simpan</span>
            </Button>
          </Grid>
        </Grid>

        <SimpleCard loading={false} currency="" saldo="">
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <h5>Nama Hari Libur</h5>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                name="nama"
                InputProps={{
                  disableUnderline: true,
                }}
                id="mui-theme-provider-outlined-input"
                onChange={handleChange}
                placeholder="Nama Hari Libur"
                fullWidth
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <h5>Tanggal Mulai</h5>
              <DatePickerComponent
                handleDate={handleDateMulaiChange}
                date={tanggal_mulai}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <h5>Tanggal Akhir</h5>
              <DatePickerComponent
                handleDate={handleDateAkhirChange}
                date={tanggal_akhir}
              />
            </Grid>
          </Grid>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDivisiList: state.karyawan.dataDivisiList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAturHariLibur: (params) => dispatch(addAturHariLibur(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TambahAturHariLibur);

import { useState, useLayoutEffect, useEffect } from 'react';
import { SimpleCard } from 'matx';
import { Button, TextField, Grid, Avatar, InputAdornment, IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux';
import {
  logoutUser,
  getDetailUser,
  editProfile,
} from 'app/redux/actions/UserActions';
import FotoProfileHandler from './FotoProfileHandler';

const EditProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);


  const [state, setState] = useState({
    foto_profile: '',
    name: '',
    role: '',
    email: '',
    password: '',
    showPassword: false
  });


  useLayoutEffect(() => {
    dispatch(getDetailUser());
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...user,
    }));

  }, [user]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData(e.currentTarget);

      let obj = {};

      for (let [key, value] of data.entries()) {
        obj = {
          ...obj,
          [key]: value,
        };
      }

      obj = {
        ...obj,
        foto_profile: state.foto_profile,
        users_code: user.users_code,
      };

      if (state.password == '') {
        delete obj.password;
      }

      await dispatch(editProfile(obj));
      Swal.fire(
        'Success!',
        'Profil berhasil disimpan',
        'success'
      );
    } catch (e) {
      Swal.fire(
        'Oppss!',
        'Data Profil gagal disimpan',
        'error'
      );
    }

    dispatch(getDetailUser());
  };

  const handleChangeProfile = (file, path) => {
    setState((prev) => ({
      ...prev,
      foto_profile: file,
      img_profile_preview: path,
    }));
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} className="my-6">
          <h1 className="font-semibold text-semiblack text-25">Edit Profil</h1>
        </Grid>

        <SimpleCard>
          <form onSubmit={handleSubmit}>
            <div className="px-10">
              <Grid item xs={12} className="d-flex items-center mt-10">
                <FotoProfileHandler
                  name="foto_profile"
                  uploadFoto={handleChangeProfile}
                  preview={state.foto_profile}
                  setState={setState}
                  CardStyle={{
                    width: '85px',
                    height: '85px',
                    borderRadius: '100%',
                    border: '2px solid #72BE42',
                  }}
                />
              </Grid>
              <Grid
                container
                className="mt-6"
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-3 font-medium text-16">
                    Nama
                  </h1>
                  <TextField
                    className=" w-full"
                    label="Nama"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-3 font-medium text-16">
                    Peran
                  </h1>
                  <TextField
                    className=" w-full"
                    label="Peran"
                    name="role"
                    value={state.role}
                    onChange={handleChange}
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-3 font-medium text-16">
                    Email
                  </h1>
                  <TextField
                    className=" w-full"
                    label="Email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semibold mb-3 font-medium text-16">
                    Password
                  </h1>
                  <TextField
                  type={state.showPassword ? 'text' : 'password'}
                    className=" w-full"
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                     InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setState({
                              ...state,
                              showPassword: !state.showPassword,
                            })
                          }
                          edge="end"
                        >
                          {state.showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} className="d-flex justify-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="p-5 ml-8 rounded-lg h4 font-weight-bolder"
                    style={{
                      height: '45px',
                      border: '1px solid #72be42',
                      color: '#ffffff',
                    }}
                    type="submit"
                  >
                    <span className="">Simpan</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </SimpleCard>
      </Grid>
    </div>
  );
};

export default EditProfile;

import { useState, useLayoutEffect } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TableIzin from './component/TableIzin';
import { getListIzin } from 'app/redux/actions/lainnya/JenisIzinAction';

const Izin = () => {
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    jenis_izin: [],
  });

  const getData = () => {
    const params = `?jenis_izin=${search}`;
    getListIzin(params).then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        jenis_izin: data,
      }));
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="text-semiblack font-semibold text-25 my-auto">
            Jenis Izin
          </h1>
        </Grid>
        <Grid item xs={12} sm className="d-flex justify-end mr-8">
          <Link to="/lainnya/izin/add">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="mt-3 mb-7 d-flex justify-end items-center">
          <TextField
            size="small"
            variant="outlined"
            className="w-245"
            placeholder="Cari Jenis Izin & Cuti"
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                getData();
              }
            }}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TableIzin data={state.jenis_izin} getData={getData} />
      </SimpleCard>
    </div>
  );
};

export default Izin;

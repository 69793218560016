import { useState } from 'react';
import { Card, Grid } from '@material-ui/core';

import UploadImage from 'app/components/button/UploadImage';
import { ValidatorForm } from 'react-material-ui-form-validator';

const TambahMOM = () => {
  const [params, setParams] = useState({
    img: '',
    img_preview: '',
  });

  const handleChangeFoto = (file, path) => {
    setParams((pref) => ({
      ...pref,
      img: file,
      img_preview: path,
    }));
  };

  const handleFormSubmit = () => {
    // setSubmit(true);
    // addDataManajemen(params)
    //   .then(() => {
    //     showAlert("Data berhasil disimpan", true);
    //     setSubmit(false);
    //   })
    //   .catch((err) => {
    //     let error = err?.response?.data;
    //     showAlert(
    //       Array.isArray(error?.data)
    //         ? error?.data[0]
    //         : "Gagal menyimpan data, coba beberapa saat lagi",
    //       false
    //     );
    //     setSubmit(false);
    //   });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={11} md={12}>
          <h3 className="fw-bold m-0">Tambah Data</h3>
        </Grid>
        <Grid item xs={11} md={12}>
          <Card elevation={0} className="py-5 px-6 border-radius-0 ">
            <ValidatorForm onSubmit={handleFormSubmit} className="w-full">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <UploadImage
                    uploadFoto={handleChangeFoto}
                    label="Unggah Foto"
                    preview={params?.img_preview}
                    required={true}
                    aspectRatio={488 / 509}
                    note={[
                      'Format PNG, WEBP dan JPG diperbolehkan',
                      '*Ukuran foto 488 x 509',
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UploadImage
                    uploadFoto={handleChangeFoto}
                    label="Unggah Foto"
                    preview={params?.img_preview}
                    required={true}
                    aspectRatio={488 / 509}
                    note={[
                      'Format PNG, WEBP dan JPG diperbolehkan',
                      '*Ukuran foto 488 x 509',
                    ]}
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default TambahMOM;

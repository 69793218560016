import {
  GET_DATA_ATUR_HARI_LIBUR,
  GET_DETAIL_ATUR_HARI_LIBUR,
  GET_DATA_ATUR_LEMBUR,
  GET_DATA_ATUR_ABSENSI,
  GET_DETAIL_ATUR_ABSENSI,
  GET_DATA_ATUR_TITIK_ABSENSI,
  GET_DETAIL_ATUR_TITIK_ABSENSI,
  DEL_ATUR_HARI_LIBUR,
  DEL_ATUR_TITIK_ABSENSI,
  DEL_ATUR_ABSENSI,
  
} from "../actions/AturanAction";

const initialState = {
  dataAturan: [],
  detailAturan: {},
};

const AturanReducer = function (state = initialState, action) {
  switch (action.type) {
    case DEL_ATUR_HARI_LIBUR: {
      let penilaian = state.data;
      return {
        ...state,
        dataAturan: penilaian,
      };
    }
    case GET_DATA_ATUR_HARI_LIBUR: {
      return {
        ...state,
        dataAturan: action.payload,
      };
    }
    case GET_DATA_ATUR_LEMBUR: {
      return {
        ...state,
        dataAturan: action.payload,
      };
    }
    case GET_DETAIL_ATUR_HARI_LIBUR: {
      return {
        ...state,
        detailAturan: action.payload,
      };
    }
    case GET_DATA_ATUR_ABSENSI: {
      return {
        ...state,
        dataAturan: action.payload,
      };
    }
    case DEL_ATUR_ABSENSI: {
      let penilaian = state.data;
      return {
        ...state,
        dataAturan: penilaian,
      };
    }
    case GET_DETAIL_ATUR_ABSENSI: {
      return {
        ...state,
        detailAturan: action.payload,
      };
    }
    case GET_DATA_ATUR_TITIK_ABSENSI: {
      return {
        ...state,
        dataAturan: action.payload,
      };
    }

    case GET_DETAIL_ATUR_TITIK_ABSENSI: {
      return {
        ...state,
        detailAturan: action.payload,
      };
    }
    case DEL_ATUR_TITIK_ABSENSI: {
      let penilaian = state.data;
      return {
        ...state,
        dataAturan: penilaian,
      };
    }

    // case GET_DETAIL_MOM: {
    //   return {
    //     ...state,
    //     detailMOM: action.payload,
    //   };
    // }
    // case GET_MOM_WITH: {
    //   return {
    //     ...state,
    //     MOMWith: action.payload,
    //   };
    // }
    default: {
      return state;
    }
  }
};

export default AturanReducer;

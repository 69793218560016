import { useState, useLayoutEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Icon,
  InputAdornment,
} from "@material-ui/core";
import IzinCutiTabs from "./component/IzinCutiTabs";
import IzinPengajuan from "./component/IzinPengajuan";
import IzinCutiDatePicker from "./component/IzinCutiDatePicker";
import {
  getDataPengajuanIzinKaryawan,
  getRiwayatIzinKaryawan,
} from "app/redux/actions/IzinCutiAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { SearchKaryawan } from "app/components";

const Izin = ({
  dataPengajuanIzinKaryawan,
  getDataPengajuanIzinKaryawan,
  dataRiwayatIzinKaryawan,
  getRiwayatIzinKaryawan,
}) => {
  const [state, setState] = useState({
    search: "",
    pengajuan: true,
    data: [],
    status: "",
    searchTgl: new Date(),
    openDitolak: false,
    selectedItem: null,
  });

  const getData = () => {
    if (state.pengajuan) {
      var dateFormat = JSON.stringify(state.searchTgl);
      dateFormat = dateFormat.slice(1, 8);
      dateFormat = dateFormat.split("-").reverse().join("-");
      dateFormat = `?bulan_tahun=${dateFormat}`;

      let params =
        state.search === ""
          ? dateFormat
          : `?search=${state.search}&${dateFormat.slice(1, 20)}`;

      getDataPengajuanIzinKaryawan(params);
    } else {
      var dateFormat = JSON.stringify(state.searchTgl);
      dateFormat = dateFormat.slice(1, 8);
      dateFormat = dateFormat.split("-").reverse().join("-");
      dateFormat = `?bulan_tahun=${dateFormat}`;

      let params =
        state.search === ""
          ? dateFormat
          : `?search=${state.search}&${dateFormat.slice(1, 20)}`;

      getRiwayatIzinKaryawan(params);
    }
  };

  useLayoutEffect(() => {
    getData();
  }, [state.searchTgl, state.pengajuan]);

  useEffect(() => {
    if (state.pengajuan) {
      setState((prev) => ({
        ...prev,
        data: dataPengajuanIzinKaryawan,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        data: dataRiwayatIzinKaryawan,
      }));
    }
  }, [dataPengajuanIzinKaryawan, dataRiwayatIzinKaryawan]);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Izin
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="izin/rekap">
            <Button variant="outlined" color="primary" className="px-8 py-3">
              Rekap Izin Karyawan
            </Button>
          </Link>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div
          className="d-flex mt-1 mb-7"
          style={{ justifyContent: "space-between" }}
        >
          <div className="align-center">
            <IzinCutiTabs
              style=""
              state={state}
              setState={setState}
              izin={true}
            />
          </div>
          <div className="align-center">
            <SearchKaryawan
              otherClasses='mr-4'
              handleChange={(e) =>
                setState({
                  ...state,
                  search: e.target.value,
                })
              }
              value={state.search}
              handleKeyDown={submitSearch}
            />
            <IzinCutiDatePicker
              state={state}
              setState={setState}
              name="searchTgl"
              tipe="MMMM yyyy"
              addPrevNext
            />
          </div>
        </div>
        <IzinPengajuan izin={true} state={state} setState={setState} getData={getData} />
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataPengajuanIzinKaryawan: state.izincuti.dataPengajuanIzinKaryawan,
    dataRiwayatIzinKaryawan: state.izincuti.dataRiwayatIzinKaryawan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataPengajuanIzinKaryawan: (params) =>
      dispatch(getDataPengajuanIzinKaryawan(params)),
    getRiwayatIzinKaryawan: (params) =>
      dispatch(getRiwayatIzinKaryawan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Izin);

import { TableCell, TableRow } from '@material-ui/core';
import MenuAbsensi from './MenuAbsensi';

const AbsensiTable = ({ data, state, setState, getData }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell className="color-semiblack text-14" colSpan={1}>
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell className="color-semiblack text-14" colSpan={3}>
            {item.name}
          </TableCell>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={3}
          >
            {item.unit_kerja}
          </TableCell>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={3}
          >
            {item.nama_kantor}
          </TableCell>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={3}
          >
            {item.tanggal_absensi.split('-').reverse().join('/')}
          </TableCell>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={2}
          >
            <MenuAbsensi
              item={item}
              getData={getData}
              setState={setState}
              editPath={`/lokasiabsensi/edit/${item.lokasi_absensi_code}`}
              detailPath={`/lokasiabsensi/detail/${item.lokasi_absensi_code}`}
            />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={15} align="center">
        {' '}
        Data kosong{' '}
      </TableCell>
    </TableRow>
  );
};

export default AbsensiTable;

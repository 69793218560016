import { TableCell, TableRow, Button } from '@material-ui/core';
import MenuPtkp from '../menu/MenuPtkp';

const RenderTablePajak = ({ data, page, rowsPerPage, getData }) => {
  const handleNumbering = () => {
    if (rowsPerPage === 5) {
      return page * 5;
    } else if (rowsPerPage === 10) {
      return page * 10;
    } else if (rowsPerPage === 25) {
      return page * 25;
    }
  };

  return data?.length > 0 ? (
    data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            className="pl-5 text-14"
            style={{ color: '#0A0A0A' }}
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell
            className="pl-5 text-14"
            style={{ color: '#0A0A0A' }}
            colSpan={4}
          >
            {item.rentang_1 === null
              ? ''
              : item.rentang_1 == undefined
              ? ''
              : `Rp ${item.rentang_1
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}`}
          </TableCell>
          <TableCell
            className="pl-5 text-14"
            style={{ color: '#0A0A0A' }}
            colSpan={4}
          >
            {item.rentang_2 === null
              ? '-'
              : item.rentang_2 == undefined
              ? ''
              : `Rp ${item.rentang_2
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}`}
          </TableCell>
          <TableCell
            className="pl-5 text-14"
            style={{ color: '#0A0A0A' }}
            align="center"
            colSpan={4}
          >
            {item.persentase === undefined ? '' : parseInt(item.persentase)}%
          </TableCell>
          <TableCell
            className="text-14"
            style={{ color: '#0A0A0A' }}
            align="center"
            colSpan={2}
          >
            <Button className="karyawan-aksi-dot elevation-z0">
              <MenuPtkp
                item={item}
                getData={getData}
                editPath={`/pajak/ketentuan/edit/${item.ketentuan_pajak_code}`}
                isPajak={true}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell
        className="font-medium text-12 line-height-28 text-body"
        colSpan={15}
        align="center"
      >
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTablePajak;

import { API, setAuthToken } from 'app/config/api';

export const GET_DATA_DASHBOARD = 'GET_DATA_DASHBOARD';
export const GET_PERMINTAAN_IZIN_DASHBOARD = 'GET_PERMINTAAN_IZIN_DASHBOARD';
export const GET_STATISTIK_IZIN_CUTI = 'GET_STATISTIK_IZIN_CUTI';
export const GET_STATISTIK_KETERLAMBATAN = 'GET_STATISTIK_KETERLAMBATAN';

export const getDataDashboardTop = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/dashboard/data_dashboard${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_DASHBOARD,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_DASHBOARD,
        payload: [],
      });
    }
  };
};

export const getDataDashboardPermintaanIzin = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/dashboard/permintaan_izin_terbaru${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_PERMINTAAN_IZIN_DASHBOARD,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_PERMINTAAN_IZIN_DASHBOARD,
        payload: [],
      });
    }
  };
};

export const getStatistikIzinCuti = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/dashboard/statistik_izin_cuti${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_STATISTIK_IZIN_CUTI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_STATISTIK_IZIN_CUTI,
        payload: [],
      });
    }
  };
};

export const getStatistikKeterlambatan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/dashboard/statistik_keterlambatan${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_STATISTIK_KETERLAMBATAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_STATISTIK_KETERLAMBATAN,
        payload: [],
      });
    }
  };
};

import { useState, useLayoutEffect } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import RenderTablePtkp from 'app/components/table/RenderTablePtkp';
import { getListPtkp } from 'app/redux/actions/PajakAction';

const Ptkp = () => {
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [state, setState] = useState({
    data_ptkp: [],
  });

  const getData = () => {
    getListPtkp('').then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        data_ptkp: data,
      }));
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: '#0A0A0A' }}
          >
            PTKP
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/pajak/ptkp/add">
            <Button variant="contained" color="primary" className="px-8 py-3">
              <AddIcon /> <span className="ml-2">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="">
          <div
            className="d-flex mt-5 mb-8 mr-3"
            style={{ justifyContent: 'flex-end' }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="w-270"
              placeholder="Cari Status"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  getListPtkp(`?status=${search}`).then((res) => {
                    const data = res.data?.data;
                    setState((prev) => ({
                      ...prev,
                      data_ptkp: data,
                    }));
                  });
                }
              }}
              value={search}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="w-full overflow-auto bg-white">
            <Table className="buku-kas-table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="text-semiblack font-medium text-15 pl-5"
                    colSpan={1}
                  >
                    No
                  </TableCell>
                  <TableCell
                    className="text-semiblack font-medium text-15 pl-5"
                    colSpan={4}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="text-semiblack font-medium text-15 pl-5"
                    colSpan={4}
                  >
                    PTKP
                  </TableCell>
                  <TableCell
                    className="text-semiblack font-medium text-15 "
                    colSpan={2}
                    align="center"
                  >
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTablePtkp
                  data={state.data_ptkp}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  getData={getData}
                />
              </TableBody>
            </Table>

            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={state.data_ptkp?.length ? state.data_ptkp?.length : 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={'From'}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next page',
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            />
          </div>
        </div>
      </SimpleCard>
    </div>
  );
};

export default Ptkp;

import {
  Button,
  TablePagination,
  Icon,
  InputAdornment,
  Table,
  TableCell,
  TableRow,
  TextField,
  TableBody,
  TableHead,
  Grid,
  Avatar,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SimpleCard } from 'matx';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuKaryawan from './MenuKaryawan';
import foto_profile from 'assets/page/karyawan/foto_profile.png';
import KaryawanSelect from './component/KaryawanSelect';

import { editKaryawanStatus, getAllKaryawan } from 'app/redux/actions/KaryawanActions';
import { getUnitKerjaList } from 'app/redux/actions/UnitKerjaAction';
import { SearchKaryawan, SelectText } from 'app/components';
import BadgeSelect from 'app/components/select/BadgeSelect';

class Karyawan extends Component {
  constructor() {
    super();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      unit_kerja: '',
      search: '',
      renderDataKaryawan: '',
      list_unit_kerja: [],
      status: '',
    };
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  getData = () => {
    const { getAllKaryawan, getUnitKerjaList } = this.props;
    const { search, unit_kerja, status } = this.state;
    const params = `?search=${search}&unit_kerja=${status}`;
    getAllKaryawan(params);
    getUnitKerjaList();
  };

  componentDidMount() {
    this.getData();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.dataListUnitKerja !== this.props.dataListUnitKerja) {
      return 'list_unit_kerja';
    }
    if (prevState.unit_kerja !== this.state.unit_kerja) {
      return 'unit_kerja';
    }
    if (prevState.status !== this.state.status) {
      return 'status'
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot == 'status') {
      this.getData();
      return;
    }

    const { dataListUnitKerja } = this.props;
    if (snapshot == 'list_unit_kerja') {
      if (this.state.list_unit_kerja.length <= 1) {
        var listUnitKerja = dataListUnitKerja.map((data) => {
          return {
            value: data.unit_kerja_code,
            text: data.unit_kerja,
          };
        });
        listUnitKerja.push({
          value: '',
          text: 'Lihat Semua',
        });
        this.setState({
          list_unit_kerja: listUnitKerja,
        });
      }
    } else if (snapshot == 'unit_kerja') {
      this.getData();
    }
  }

  handleSubmitSearch = (e) => {
    if (e.keyCode == 13) {
      this.getData();
    }
  };

  async handleStatusChange (event, item) {
    const selectedStatus = event.target.value;
    await this.props.editKaryawanStatus({
      users_code: item.users_code,
      status: selectedStatus,
    });
    this.getData();
  }

  renderTable = () => {
    const { page, rowsPerPage } = this.state;
    const { dataKaryawan } = this.props;
    const handleNumbering = () => {
      if (rowsPerPage === 5) {
        return page * 5;
      } else if (rowsPerPage === 10) {
        return page * 10;
      } else if (rowsPerPage === 25) {
        return page * 25;
      }
    };

    return dataKaryawan?.length > 0 ? (
      dataKaryawan
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => (
          <TableRow hover key={index}>
            <TableCell
              align="center"
              className="pl-4 text-14 text-semibold"
              colSpan={1}
            >
              {index + 1 + handleNumbering()}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className="text-semibold"
              colSpan={5}
            >
              <div
                className="pl-3 z-100 text-14 d-flex items-center"
                style={{ gap: '16px' }}
              >
                {item.img_profil === '' ? (
                  <Avatar
                    src={foto_profile}
                    variant="square"
                    alt="foto profil"
                    style={{
                      width: '50px',
                      height: '50px',
                      background: 'white',
                    }}
                  />
                ) : (
                  <Avatar
                    variant="square"
                    src={item.foto_profile}
                    width={'50px'}
                  />
                )}
                {item.name}
              </div>
            </TableCell>
            <TableCell
              className="pl-3 text-14 text-semibold"
              align="center"
              colSpan={4}
            >
              {item.unit_kerja}
            </TableCell>
            <TableCell
              className="pl-3 text-14 text-semibold"
              align="center"
              colSpan={4}
            >
              {item.tipe_karyawan === 'fulltime' ? 'Full Time' : 'Intern'}
            </TableCell>
            <TableCell
              className="pl-3 text-14 text-semibold"
              align="center"
              colSpan={4}
            >
                <BadgeSelect
                  item={item}
                  handleChange={this.handleStatusChange}
                  menus={[
                    {
                      label: 'Aktif',
                      value: 'aktif',
                      icon: <Icon>{item.status.toLowerCase() === 'aktif' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                      color: '#72BE42',
                      backgroundColor: '#F3FFEC'
                    },
                    {
                      label: 'Tidak Aktif',
                      value: 'tidak aktif',
                      icon: <Icon>{item.status.toLowerCase() === 'tidak aktif' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                      color: '#FF5C58',
                      backgroundColor: '#FFEBEA'
                    }
                  ]}
                  badgeBackgroundColor={item.status.toLowerCase() === 'aktif' ? '#72BE42' : '#FF5C58'}
                  iconBackgroundColor={item.status.toLowerCase() === 'aktif' ? '#68B03A' : '#F94A46'}
                  renderValue={(selected) => (selected === 'aktif' ? 'Aktif' : 'Tidak Aktif')}
                  value={item.status.toLowerCase()}
                />
            </TableCell>
            <TableCell className="" align="center" colSpan={1}>
              <MenuKaryawan item={item} getData={this.getData.bind(this)} />
            </TableCell>
          </TableRow>
        ))
    ) : (
      <TableRow hover>
        <TableCell colSpan={19} align="center">
          Data kosong
        </TableCell>
      </TableRow>
    );
  };

  handleStatus = (e) => {
    this.setState({
      status: e.target.value,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setPage = (page) => {
    this.setState({ page });
  };

  setRenderDataKaryawan = (renderDataKaryawan) => {
    this.setState({ renderDataKaryawan });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  renderedKaryawan = () => {
    let renderedData = this.renderTable();
    this.setRenderDataKaryawan(renderedData);
  };

  render() {
    const { search, page, rowsPerPage } = this.state;
    const { dataKaryawan } = this.props;
    return (
      <div className="m-sm-30">
        <>
          <Grid
            container
            spacing={3}
            className="mb-4 mx-auto px-2"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm>
              <h1 className="font-semibold text-25 my-auto text-semiblack">
                Karyawan
              </h1>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              className="d-flex mr-8"
              style={{ justifyContent: 'flex-end' }}
            >
              <Link to="/karyawan/add">
                <Button
                  variant="contained"
                  color="primary"
                  className="karyawan-btn-top px-8 py-3"
                >
                  <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
                </Button>
              </Link>
            </Grid>
          </Grid>
          <SimpleCard title="">
            <div className="mx-5">
              <div
               className="d-flex items-center py-4 "
            style={{ justifyContent: 'flex-end', gap: '20px' }}
              >
                <SearchKaryawan
                  otherClasses='py-8 rounded-lg'
                  style={{
                    width: '263px',
                    background: '#ffffff',
                  }}
                  handleChange={this.handleChange}
                  value={search}
                  handleKeyDown={this.handleSubmitSearch}
                />

                <Grid item xs={3} sm={2} className="d-flex align-items-end">
                  <SelectText
                    search
                    unitKerja
                    label="Unit Kerja"
                    handleChange={this.handleStatus}
                    value={this.state.status}
                  />
                </Grid>
                {/* <KaryawanSelect
                  dataSelect={this.state.list_unit_kerja}
                  state={this.state}
                  setState={this.setState.bind(this)}
                  label="Unit Kerja"
                  name="unit_kerja"
                  width="240px"
                /> */}
              </div>
              <div className="w-full bg-white">
                <Table className="buku-kas-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="font-medium text-15 pl-3"
                        colSpan={1}
                      >
                        No
                      </TableCell>
                      <TableCell
                        className="font-medium text-15 pl-3"
                        colSpan={5}
                      >
                        Nama
                      </TableCell>
                      <TableCell
                        className="font-medium text-15"
                        colSpan={4}
                        align="center"
                      >
                        Unit Kerja
                      </TableCell>
                      <TableCell
                        className="font-medium text-15"
                        colSpan={4}
                        align="center"
                      >
                        Tipe
                      </TableCell>
                      <TableCell
                        className="font-medium text-15"
                        colSpan={4}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className="font-medium text-15"
                        colSpan={1}
                        align="center"
                      >
                        Aksi
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this.renderTable()}</TableBody>
                </Table>
                <TablePagination
                  className="my-7 px-10"
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataKaryawan?.length ? dataKaryawan?.length : 0}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={'From'}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next page',
                  }}
                  backIconButtonText="Previous page"
                  nextIconButtonText="Next page"
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.setRowsPerPage}
                />
              </div>
            </div>
          </SimpleCard>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataKaryawan: state.karyawan.allData,
    dataListUnitKerja: state.unitkerja.dataListUnitKerja,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllKaryawan: (params) => dispatch(getAllKaryawan(params)),
    getUnitKerjaList: () => dispatch(getUnitKerjaList()),
    editKaryawanStatus: (body) => dispatch(editKaryawanStatus(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Karyawan);

import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { dateFormatter } from 'globalize';

function DatePicker({
  required = false,
  state,
  setState,
  name,
  tipe = 'dd/MM/yyyy',
  format = 'long',
  scaleY = '1',
}) {
  const DatePicker = withStyles({
    root: {
      borderRadius: '5px',
      background: '#fbfbfb',
      width: format === 'long' ? '100%' : format === '100%' ? '100%' : '100%',
      height: '47px',
    },
  })((props) => <KeyboardDatePicker color="default" {...props} />);

  const handleTanggal = (e) => {
    const event = new Date(e);

    let dateFormat = JSON.stringify(event);

    dateFormat = dateFormat.slice(1, 11);

    setState({
      ...state,
      [name]: dateFormat,
    });
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          required={required}
          autoOk
          variant="inline"
          inputVariant="outlined"
          format={tipe}
          control={<KeyboardDatePicker />}
          value={state[name]}
          InputAdornmentProps={{
            position: 'end',
          }}
          onChange={handleTanggal}
          size="medium"
          style={{
            transform: `scaleY(${scaleY})`,
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DatePicker;

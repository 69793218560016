import React, { useState } from "react";
import {
Table,
TableCell,
TableRow,
TableBody,
TableHead,
Button,
Avatar,
Chip,
} from "@material-ui/core";
import foto_profile from "assets/page/karyawan/foto_profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { formatRupiah } from "app/utils/globalFunction";

import Aksieye from "assets/component/Aksieye.svg";
    import SimpleMenu from "app/components/menu/Menu";

const RenderTableBonus = ({
data,
state,
tableName,
handleDelete,
handleDetail,
handleEdit,
aksiClick,
clickEdit,
clickDetail,
}) => {
const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
    return state.page * 5;
    } else if (state.rowsPerPage === 10) {
    return state.page * 10;
    } else if (state.rowsPerPage === 25) {
    return state.page * 25;
    }
};

const timeWithoutSeconds = (timeString) => {
    if (timeString) {
    return timeString.substring(0, 5);
    } else {
    return "loading...";
    }
}; // "08:00"

function formatDate(dateString) {
    const parts = dateString?.split("-");
    return `${parts[0]}/${parts[1]}/${parts[2]}`;
}

const useStyles = makeStyles((theme) => ({
    root: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "28px",
    color: "#FFFFFF",
    flex: "none",
    width: "110px",
    height: "36px",
    },
    yellow: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "28px",
    color: "#FFFFFF",
    flex: "none",
    width: "110px",
    height: "36px",
    background: "#FBC02A",
    },
    red: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "28px",
    color: "#FFFFFF",
    flex: "none",
    width: "110px",
    height: "36px",
    background: "#FF5C58",
    },
}));

const classes = useStyles();

return data?.length > 0 ? (
  data
    .slice(
      state.page * state.rowsPerPage,
      state.page * state.rowsPerPage + state.rowsPerPage
    )
    .map((item, index) => (
        <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.name}</TableCell>
            <TableCell align="center" colSpan={3}>
            {formatRupiah(item.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
            <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                item={item}
                linkDetail={`/Bonus/Detail/${item.bonus_code}`}
                linkEdit={`/Bonus/Edit/${item.bonus_code}`}
                handleDelete={() => handleDelete(item.bonus_code)}
                />
            </Button>
            </TableCell>
        </TableRow>
    ))
) : (
  <TableRow hover>
    <TableCell colSpan={10} align="center">
      Data kosong
    </TableCell>
  </TableRow>
);
};

export default RenderTableBonus;

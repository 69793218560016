import { API, setAuthToken } from 'app/config/api';

export const GET_LIST_UNIT_KERJA = 'GET_LIST_UNIT_KERJA';
export const GET_DATA_UNIT_KERJA = 'GET_DATA_UNIT_KERJA';

//use reducer
export const getUnitKerjaList = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get('user/lainnya/unit_kerja').catch((err) =>
      Promise.reject(err)
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_LIST_UNIT_KERJA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_LIST_UNIT_KERJA,
        payload: [],
      });
    }
  };
};

// export const getUnitKerja = () => {
//   const token = localStorage.getItem('jwt_token');
//   setAuthToken(token);
//   return API.get('/user/lainnya/unit_kerja').then((res) => {
//         dispatch({
//           type: GET_DATA_UNIT_KERJA,
//           payload: res.data.data || [],
//         });
//       })
//       .catch(() => {
//         dispatch({
//           type: GET_DATA_UNIT_KERJA,
//           payload: [],
//         });
//       });
// };

export const getUnitKerja = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.get(`/user/lainnya/unit_kerja`)
      .then((res) => {
        dispatch({
          type: GET_DATA_UNIT_KERJA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_UNIT_KERJA,
          payload: [],
        });
      });
  };
};

import {
  GET_DATA_PENGAJUAN_IZIN,
  GET_DATA_REKAP_CUTI,
  GET_DATA_RIWAYAT_IZIN,
  GET_DATA_REKAP_IZIN,
} from '../actions/IzinCutiAction';

const initialState = {
  dataRekapCutiKaryawan: [],
  dataPengajuanIzinKaryawan: [],
  dataRiwayatIzinKaryawan: [],
  dataRekapIzinKaryawan: [],
};

const IzinCutiReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_REKAP_CUTI: {
      return {
        ...state,
        dataRekapCutiKaryawan: action.payload,
      };
    }
    case GET_DATA_PENGAJUAN_IZIN: {
      return {
        ...state,
        dataPengajuanIzinKaryawan: action.payload,
      };
    }
    case GET_DATA_RIWAYAT_IZIN: {
      return {
        ...state,
        dataRiwayatIzinKaryawan: action.payload,
      };
    }
    case GET_DATA_REKAP_IZIN: {
      return {
        ...state,
        dataRekapIzinKaryawan: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default IzinCutiReducer;

import { useState, useLayoutEffect, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { SimpleCard } from 'matx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  deleteLokasiAbsensi,
  getDetailLokasiAbsensi,
} from 'app/redux/actions/AbsenAction';

const DetailAbsensiLocation = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataDetailLokasiAbsensi = useSelector(
    (state) => state.absen.dataDetailLokasiAbsensi
  );

  const [state, setState] = useState({
    name: '',
    lokasi_absensi_code: '',
    unit_kerja: '',
    nama_kantor: '',
    tanggal_absensi: '',
    waktu_absensi: '',
  });

  useLayoutEffect(() => {
    dispatch(getDetailLokasiAbsensi(id));
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...dataDetailLokasiAbsensi,
    }));
  }, [dataDetailLokasiAbsensi]);

  const returnWaktuAbsensi = () => {
    if (state.waktu_absensi.includes('[')) {
      let newStr = JSON.parse(state.waktu_absensi);
      newStr = newStr.join(', ');
      return newStr;
    } else {
      return state.waktu_absensi;
    }
  };

  const handleDelete = async (params) => {
    try {
      await dispatch(deleteLokasiAbsensi(params));
      history.push('/lokasiabsensi');
      Swal.fire('Success!', 'Data Lokasi Absensi berhasil dihapus', 'success');
    } catch (e) {
      Swal.fire('Oopss!', `Data Lokasi Absensi gagal disimpan ${e}`, 'error');
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 text-semiblack">
            Detail Lokasi Absensi
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
          container
          spacing={2}
        >
          <Button
            variant="outlined"
            color="danger"
            className="px-15 py-3 border-red-figma text-error mr-3"
            onClick={() => handleDelete(id)}
          >
            <DeleteOutlineIcon />
            <span className="mx-1">Hapus</span>
          </Button>
          <Link
            to={{
              pathname: `/lokasiabsensi/edit/${id}`,
            }}
          >
            <Button variant="outlined" color="primary" className="px-15 py-3">
              <CreateIcon />
              <span className="mx-1">Ubah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Nama Karyawan
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.name}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Unit Kerja
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.unit_kerja}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Titik Absensi
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.nama_kantor}
                </p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Tanggal Absensi
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.tanggal_absensi}
                </p>
              </Grid>
              <Grid item xs={12}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Waktu Absensi
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {returnWaktuAbsensi()}
                </p>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default DetailAbsensiLocation;

import Denda from './Denda';
import AddDenda from './AddDenda';
import EditDenda from './EditDenda';

const DendaRoutes = [
  {
    path: '/lainnya/denda',
    component: Denda,
    exact: true,
  },
  {
    path: '/lainnya/denda/add',
    component: AddDenda,
    exact: true,
  },
  {
    path: '/lainnya/denda/edit/:id',
    component: EditDenda,
    exact: true,
  },
];

export default DendaRoutes;

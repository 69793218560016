import EditProfile from './EditProfile';

const ProfileRoutes = [
  {
    path: '/profile/edit',
    component: EditProfile,
    exact: true,
  },
];

export default ProfileRoutes;

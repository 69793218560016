import { API, setAuthToken } from "app/config/api";

export const GET_DATA_LEMBUR = "GET_DATA_LEMBUR";
export const GET_DETAIL_LEMBUR = "GET_DETAIL_LEMBUR";
export const DEL_LEMBUR = "DEL_LEMBUR";
export const GET_DATA_RIWAYAT_LEMBUR = "GET_DATA_RIWAYAT_LEMBUR";

export const getLembur = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/lembur${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_LEMBUR,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_LEMBUR,
          payload: [],
        });
      });
  };
};

export const getRiwayatLembur = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/lembur/riwayat${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_RIWAYAT_LEMBUR,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_RIWAYAT_LEMBUR,
          payload: [],
        });
      });
  };
};

export const getDetailLembur = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/lembur/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_LEMBUR,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_LEMBUR,
        payload: [],
      });
    }
  };
};

export const delLembur = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/lembur/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_LEMBUR,
        payload: code,
      });
    }
    return res.data;
  };
};

export const DEL_GAJI_LEMBUR = "DEL_GAJI_LEMBUR";

export const delGajiLembur = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/gaji_lembur/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_GAJI_LEMBUR,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addLembur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/lembur/add", params).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const addGajiLembur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/gaji/lembur/gaji_lembur/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editLembur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/lembur/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const editGajiLembur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/gaji/lembur/gaji_lembur/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const editStatusLembur = async (params) => {

  // return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/lembur/update/status`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  // };
};

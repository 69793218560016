import { API, setAuthToken } from "app/config/api";

export const GET_DATA_HANDOVER = "GET_DATA_HANDOVER";
export const GET_DETAIL_HANDOVER = "GET_DETAIL_HANDOVER";
export const DEL_HANDOVER = "DEL_HANDOVER";

export const getHandover = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/handover${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_HANDOVER,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_HANDOVER,
          payload: [],
        });
      });
  };
};

export const getDetailHandover = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/handover/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_HANDOVER,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_HANDOVER,
        payload: [],
      });
    }
  };
};

export const delHandover = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/handover/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_HANDOVER,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addHandover = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/administrasi/handover/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editHandover = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/handover/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

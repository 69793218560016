import { useState, useLayoutEffect, useEffect } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import { Button, Grid } from '@material-ui/core';
import { SimpleCard } from 'matx';
import {
  getDetailKaryawan,
  delKaryawan,
} from 'app/redux/actions/KaryawanActions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { formatRupiah } from 'app/utils/globalFunction';

const DetailKaryawan = () => {
  const { id } = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    jadwal_kerja: [],
    jenis_kelamin: '',
    status: '',
  });

  useLayoutEffect(() => {
    getDetailKaryawan(id).then((res) => {
      let data = res.data?.data[0];
      setState((prev) => ({
        ...prev,
        ...data,
      }));
    });
  }, []);

  const handleDelete = (id) => {
    delKaryawan(id);

    let timerInterval;
    Swal.fire({
      title: 'Sedang diproses...',
      html: 'tunggu dalam waktu <b></b> detik.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('b');
        setTimeout(() => {
          clearInterval(timerInterval);
          history.push('/karyawan');
          Swal.fire('Success!', 'Data karyawan berhasil dihapus', 'success');
        }, 4000);
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 1000);
      },
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25" style={{ color: '#0A0A0A' }}>
            Detail Karyawan
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
          container
          spacing={2}
        >
          <Button
            variant="outlined"
            color="danger"
            className="px-15 py-3 border-red-figma text-error mr-4"
            onClick={() => handleDelete(id)}
          >
            <DeleteOutlineIcon />
            <span className="mx-1">Hapus</span>
          </Button>
          <Link
            to={{
              pathname: `/karyawan/edit/${id}`,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              className="px-15 py-3 ml-4"
            >
              <CreateIcon />
              <span className="mx-1">Ubah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              className=""
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Info Umum
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Foto Profile
                </h1>
                <div
                  className="w-full p-2 border-radius-5"
                  style={{ height: '275px', border: '1px dashed #72BE42' }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${state.foto_profile})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '100%',
                    }}
                    className="w-full"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Foto KTP
                </h1>
                <div
                  className="w-full p-2 border-radius-5"
                  style={{ height: '275px', border: '1px dashed #72BE42' }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${state.foto_ktp})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '100%',
                    }}
                    className="w-full"
                  />
                </div>
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                    Foto NPWP
                  </h1>
                  <div
                    className="w-full p-2 border-radius-5"
                    style={{ height: '275px', border: '1px dashed #72BE42' }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${state.foto_npwp})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '100%',
                      }}
                      className="w-full"
                    />
                  </div>
                </Grid>
              )}
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                    Foto BPJS
                  </h1>
                  <div
                    className="w-full p-2 border-radius-5"
                    style={{ height: '275px', border: '1px dashed #72BE42' }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${state.foto_bpjs})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '100%',
                      }}
                      className="w-full"
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Name
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.name}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Email
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.email}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  No. Telp
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.telepon}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Tanggal Lahir
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.tanggal_lahir}
                </p>
              </Grid>
              <Grid item xs={12}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Alamat
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.alamat}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  NIK
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.NIK}
                </p>
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    No. BPJS
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.no_bpjs}
                  </p>
                </Grid>
              )}
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    No. NPWP
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.no_npwp}
                  </p>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Jenis Kelamin
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.jenis_kelamin == ''
                    ? ''
                    : state.jenis_kelamin[0].toUpperCase() +
                      state.jenis_kelamin.substring(1)}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Unit Kerja
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.unit_kerja}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Atasan
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.pilih_atasan}
                </p>
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    Hak Cuti Tahunan (Hari)
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.hak_cuti_tahunan}
                  </p>
                </Grid>
              )}
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    Jenis Perjanjian
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.jenis_perjanjian}
                  </p>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Tipe
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.tipe_karyawan == 'fulltime' ? 'Full Time' : 'Intern'}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Status
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.status.toLowerCase() == 'aktif'
                    ? 'Aktif'
                    : 'Tidak Aktif'}
                </p>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              className=""
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Gaji & Jadwal
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Gaji Pokok
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {formatRupiah(state.gaji_pokok)}
                </p>
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    Tunjangan
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {formatRupiah(state.tunjangan)}
                  </p>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
              <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    Status PPH
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.pph_activation ? 'Aktif' : ' Tidak Aktif'}
                  </p>
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    Jenis Penghasilan
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.jenis_penghasilan}
                  </p>
                </Grid>
              )}
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                    PTKP
                  </h1>
                  <p
                    className="text-16 font-medium"
                    style={{ color: '#0A0A0A' }}
                  >
                    {state.ptkp}
                  </p>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Tanggal Gajian
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.tanggal_gajian}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Waktu Kerja
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.waktu_kerja}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Jadwal Kerja
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.jadwal_kerja.join(', ')}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={state.tipe_karyawan === 'fulltime' ? 6 : 12}
              >
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Titik Absensi
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state['nama_kantor']}
                </p>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              className=""
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Informasi Pembayaran
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Nama Bank
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.nama_bank}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  No. Rekening
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.nomer_rekening}
                </p>
              </Grid>
              <Grid item xs={12}>
                <h1 className="text-muted text-14 font-medium mb-2 font-500 h4">
                  Pemilik Akun
                </h1>
                <p className="text-16 font-medium" style={{ color: '#0A0A0A' }}>
                  {state.pemilik_akun}
                </p>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default DetailKaryawan;

import {
  Button,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import { Fragment, useRef, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import AbsensiDatePicker from './component/AbsensiDatePicker';
import { getDataLokasiAbsensi } from '../../redux/actions/AbsenAction';
import AbsensiTable from './component/AbsensiTable';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { SearchKaryawan } from 'app/components';

const AbsensiLocation = ({ dataLokasiAbsensi, getDataLokasiAbsensi }) => {
  const inputRef = useRef();

  const [state, setState] = useState({
    loading: true,
    search: '',
    searchTgl: new Date(),
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = () => {
    console.log('Deleted');
  };

  const handleEditData = () => {
    console.log('Edited');
  };

  const getData = () => {
    let dateFormat = JSON.stringify(state.searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    var splitString = dateFormat.split('-');
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join('-');

    var params;
    if (inputRef.current.container.search === '') {
      params = `?bulan_tahun=${joinArray}`;
    } else {
      params = `?bulan_tahun=${joinArray}&search=${inputRef.current.container.search}`;
    }
    console.log(params);
    getDataLokasiAbsensi(params);
  };

  useLayoutEffect(() => {
    inputRef.current = {
      container: {
        search: '',
      },
    };
  }, []);

  useEffect(() => {
    getData();
  }, [state.searchTgl]);

  const handleChange = (e) => {
    inputRef.current.container = {
      ...inputRef.current.container,
      [e.target.name]: e.target.value,
    };
  };

  const handleSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        className="my-4 d-flex items-center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 text-semiblack">
            Lokasi Absensi
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-6 items-center"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/lokasiabsensi/add">
            <Button
              variant="contained"
              color="primary"
              className="px-8 py-3 my-auto"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div
                className="flex items-center py-5"
                style={{ justifyContent: 'flex-end' }}
              >
                <SearchKaryawan
                  style={{
                    width: '250px',
                    height: '46px',
                    background: '#ffffff',
                  }}
                  otherClasses='pr-5 rounded-lg'
                  handleChange={handleChange}
                  handleKeyDown={handleSearch}
                />
                <AbsensiDatePicker
                  state={state}
                  setState={setState}
                  name="searchTgl"
                  tipe="MMMM yyyy"
                  addPrevNext
                />
              </div>
            </Grid>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: '1px #e6e5e5 solid',
                marginTop: '20px',
              }}
            >
              <TableHead>
                <TableRow hover>
                  <TableCell className="text-15 py-6" colSpan={1}>
                    No
                  </TableCell>
                  <TableCell className="text-15 py-6" colSpan={3}>
                    Nama Karyawan
                  </TableCell>
                  <TableCell
                    className="text-15 py-6"
                    align="center"
                    colSpan={3}
                  >
                    Unit Kerja
                  </TableCell>
                  <TableCell
                    className="text-15 py-6"
                    align="center"
                    colSpan={3}
                  >
                    Titik Absensi
                  </TableCell>
                  <TableCell
                    className="text-15 py-6"
                    align="center"
                    colSpan={3}
                  >
                    Tanggal Absensi
                  </TableCell>

                  <TableCell
                    className="text-15 py-6"
                    align="center"
                    colSpan={2}
                  >
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <AbsensiTable
                  getData={getData}
                  state={state}
                  setState={setState}
                  data={dataLokasiAbsensi}
                  tableName="absensilocation"
                  handleDelete={handleDelete}
                  handleEditData={handleEditData}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16 mx-5"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataLokasiAbsensi?.length ? dataLokasiAbsensi?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={'From'}
              page={state.page}
              backIconButtonProps={{
                'aria-label': 'Previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next page',
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onPageChange={handleChangePage}
              onRowsPerPageChange={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataMOM: state.mom.dataMOM,
    dataLokasiAbsensi: state.absen.dataLokasiAbsensi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataLokasiAbsensi: (params) => dispatch(getDataLokasiAbsensi(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AbsensiLocation);

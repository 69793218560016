import Izin from './Izin';
import AddIzin from './AddIzin';
import EditIzin from './EditIzin';

const IzinRoutes = [
  {
    path: '/lainnya/izin',
    component: Izin,
    exact: true,
  },
  {
    path: '/lainnya/izin/add',
    component: AddIzin,
    exact: true,
  },
  {
    path: '/lainnya/izin/edit/:id',
    component: EditIzin,
    exact: true,
  },
];

export default IzinRoutes;

import Karyawan from './Karyawan';
import AddKaryawan from './AddKaryawan';
import EditKaryawan from './EditKaryawan';
import DetailKaryawan from './DetailKaryawan';

const KaryawanRoutes = [
  {
    path: '/karyawan',
    component: Karyawan,
    exact: true,
  },
  {
    path: '/karyawan/add',
    component: AddKaryawan,
    exact: true,
  },
  {
    path: '/karyawan/edit/:id',
    component: EditKaryawan,
    exact: true,
  },
  {
    path: '/karyawan/detail/:id',
    component: DetailKaryawan,
    exact: true,
  },
];

export default KaryawanRoutes;

import { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Grid, InputAdornment } from '@material-ui/core';
import { SimpleCard } from 'matx';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  editJenisDenda,
  getDetailJenisDenda,
} from 'app/redux/actions/lainnya/JenisDendaAction';

const EditDenda = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    nama_denda: '',
    nominal: '',
    scaleY: '0.85',
  });

  useLayoutEffect(() => {
    getDetailJenisDenda(id).then((res) => {
      let data = res.data?.data;
      setState((prev) => ({
        ...prev,
        ...data,
      }));
    });
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
     await  dispatch(
        editJenisDenda({
          jenis_denda_code: id,
          nama_denda: state.nama_denda,
          nominal: state.nominal,
        })
      );
      setTimeout(() => {
        history.push('/lainnya/denda');
        Swal.fire('Success!', 'Data Denda berhasil disimpan', 'success');
      }, 2000);
    } catch (e) {
      Swal.fire('Oopss!', 'Data Denda gagal disimpan', 'error');
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #e6e9ed',
      },
    },
    input: {
      transform: 'scaleY(0.88)',
      marginBlock: 'auto',
    },
  }));
  const classes = useStyles();
  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25" style={{ color: '#0A0A0A' }}>
            Edit Jenis Denda
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="mt-9 mb-8"
            >
              <Grid
                container
                className="mt-2"
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: '#0a0a0a' }}
                  >
                    Nama Denda
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    value={state.nama_denda}
                    name="nama_denda"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="Nama Denda"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: '#0a0a0a' }}
                  >
                    Nominal
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    value={state.nominal}
                    name="nominal"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="0"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="font-medium">Rp</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default EditDenda;

import {
  GET_KRITERIA_PENILAIAN,
  GET_PENILAIAN,
} from "../actions/PenilaianAction";

import { GET_DATA_GRAFIK } from "../actions/GrafikAction";

const initialState = {
  dataKriteriaPenilaian: [],
  dataPenilaian: [],
  dataGrafik: [],
};

const PenilaianReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_KRITERIA_PENILAIAN: {
      return {
        ...state,
        dataKriteriaPenilaian: action.payload,
      };
    }
    case GET_PENILAIAN: {
      return {
        ...state,
        dataPenilaian: action.payload,
      };
    }
    case GET_DATA_GRAFIK: {
      return {
        ...state,
        dataGrafik: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default PenilaianReducer;

import Absensi from './Absensi';
import AbsensiLocation from './AbsensiLocation';
import AddAbsensiLocation from './AddAbsensiLocation';
import EditAbsensiLocation from './EditAbsensiLocation';
import DetailAbsensiLocation from './DetailAbsensiLocation';
import DetailAbsensiKaryawanTable from './DetailAbsensiKaryawanTable';
import DetailAbsensiKaryawan from './DetailAbsensiKaryawan';

const AbsensiRoutes = [
  {
    path: '/absensi',
    exact: true,
    component: Absensi,
  },
  {
    path: '/absensi/:id',
    exact: true,
    component: DetailAbsensiKaryawanTable,
  },
  {
    path: '/absensi/detail/:id',
    exact: true,
    component: DetailAbsensiKaryawan,
  },
  {
    path: '/lokasiabsensi',
    exact: true,
    component: AbsensiLocation,
  },
  {
    path: '/lokasiabsensi/add',
    exact: true,
    component: AddAbsensiLocation,
  },
  {
    path: '/lokasiabsensi/edit/:id',
    exact: true,
    component: EditAbsensiLocation,
  },
  {
    path: '/lokasiabsensi/detail/:id',
    exact: true,
    component: DetailAbsensiLocation,
  },
];

export default AbsensiRoutes;

import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

import {
  editRiwayatGaji,
  getDetailRiwayatGaji,
  cetakRiwayatGaji,
} from "app/redux/actions/gaji/RiwayatGajiAction";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import { formatRupiah } from "app/utils/globalFunction";
import { RenderTable, Modal } from "app/components";
import { delTunjangan } from "app/redux/actions/gaji/TunjanganAction";
import { delReimburse } from "app/redux/actions/gaji/ReimburseAction";
import { delBonus } from "app/redux/actions/gaji/BonusAction";
import { delDenda } from "app/redux/actions/gaji/DendaAction";
import { delLembur } from "app/redux/actions/gaji/LemburAction";

const DetailRiwayatGaji = ({
  getDetailRiwayatGaji,
  detailRiwayatGaji,
  delTunjangan,
  delReimburse,
  delBonus,
  delDenda,
  delLembur,
  cetakRiwayatGaji,
}) => {
  //page function

  const { id } = useParams();
  const [foundData, setFoundData] = useState();
  const getData = () => {
    getDetailRiwayatGaji(id);
  };

  function formatDate(dateString) {
    const parts = dateString?.split("-") ?? [];
    return `${parts[0]}/${parts[1]}/${parts[2]}`;
  }

  function isMinus(value) {
    if (Number(value) < 0) {
      return "#FF5C58";
    } else {
      return "#72BE42";
    }
  }

  //bonus function
  const [dataBonus, setDataBonus] = useState();
  const [detailBonus, setDetailBonus] = useState();

  const [bonusTable, setBonusTable] = useState({
    loading: true,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [openModalBonusAdd, setOpenModalBonusAdd] = useState(false);
  const [openModalBonusEdit, setOpenModalBonusEdit] = useState(false);

  const handleAddBonus = () => {
    setOpenModalBonusAdd(true);
  };

  // const handleCetak = () => {
  //   cetakRiwayatGaji(id)
  //     .then(() => {
  //       Swal.fire("Success!", "Data Handover berhasil disimpan", "success");
  //       history.push("/administrasi/Handover");
  //     })
  //     .catch((err) => {
  //       let error = err?.response?.data;
  //       Swal.fire(
  //         Array.isArray(error?.data)
  //           ? error?.data[0]
  //           : "Gagal menyimpan data, coba beberapa saat lagi",
  //         false
  //       );
  //     });
  // };

  const handleDeleteBonus = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delBonus(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEditBonus = (code) => {
    const DetailData = dataBonus.find((item) => item.bonus_code === code);
    setDetailBonus(DetailData);

    setOpenModalBonusEdit(true);
  };

  //denda function
  //denda function
  const [dataDenda, setDataDenda] = useState();
  const [detailDenda, setDetailDenda] = useState();

  const [dendaTable, setDendaTable] = useState({
    loading: true,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [openModalDendaAdd, setOpenModalDendaAdd] = useState(false);
  const [openModalDendaEdit, setOpenModalDendaEdit] = useState(false);

  const handleAddDenda = () => {
    setOpenModalDendaAdd(true);
  };

  const handleDeleteDenda = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delDenda(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEditDenda = (code) => {
    const DetailData = dataDenda.find((item) => item.denda_code === code);
    setDetailDenda(DetailData);

    setOpenModalDendaEdit(true);
  };

  //tunjanganfunction
  const [dataTunjangan, setDataTunjangan] = useState();
  const [detailTunjangan, setDetailTunjangan] = useState();
  console.log(detailTunjangan);

  const [tunjanganTable, setTunjanganTable] = useState({
    loading: true,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [openModalTujanganAdd, setOpenModalTujanganAdd] = useState(false);
  const [openModalTujanganEdit, setOpenModalTujanganEdit] = useState(false);

  const handleAddTunjangan = () => {
    setOpenModalTujanganAdd(true);
  };

  const handleDeleteTunjangan = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delTunjangan(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEditTunjangan = (code) => {
    const DetailData = dataTunjangan.find(
      (item) => item.tunjangan_code === code
    );
    setDetailTunjangan(DetailData);

    setOpenModalTujanganEdit(true);
  };

  //reimburse function
  const [dataReimburse, setDataReimburse] = useState();
  const [detailReimburse, setDetailReimburse] = useState();

  const [reimburseTable, setReimburseTable] = useState({
    loading: true,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [openModalReimburseAdd, setOpenModalReimburseAdd] = useState(false);
  const [openModalReimburseEdit, setOpenModalReimburseEdit] = useState(false);

  const handleAddReimburse = () => {
    setOpenModalReimburseAdd(true);
  };

  const handleDeleteReimburse = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delReimburse(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEditReimburse = (code) => {
    const DetailData = dataReimburse.find(
      (item) => item.reimburse_code === code
    );
    setDetailReimburse(DetailData);

    setOpenModalReimburseEdit(true);
  };

  //lembur function
  const [dataLembur, setDataLembur] = useState();
  const [detailLembur, setDetailLembur] = useState();

  const [lemburTable, setLemburTable] = useState({
    loading: true,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [openModalLemburAdd, setOpenModalLemburAdd] = useState(false);
  const [openModalLemburEdit, setOpenModalLemburEdit] = useState(false);

  const handleAddLembur = () => {
    setOpenModalLemburAdd(true);
  };

  const handleDeleteLembur = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delLembur(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEditLembur = (code) => {
    const DetailData = dataLembur.find((item) => item.lembur_code === code);
    setDetailLembur(DetailData);

    setOpenModalLemburEdit(true);
  };

  const setPage = (table, page) => {
    table === "bonus"
      ? setBonusTable({ ...bonusTable, page })
      : table === "denda"
      ? setDendaTable({ ...dendaTable, page })
      : table === "tunjangan"
      ? setTunjanganTable({ ...tunjanganTable, page })
      : table === "reimburse"
      ? setReimburseTable({ ...reimburseTable, page })
      : setLemburTable({ ...lemburTable, page });
  };

  const setRowsPerPage = (table, event) => {
    table === "bonus"
      ? setBonusTable({
          ...bonusTable,
          rowsPerPage: event.target.value,
          page: 0,
        })
      : table === "denda"
      ? setDendaTable({
          ...dendaTable,
          rowsPerPage: event.target.value,
          page: 0,
        })
      : table === "tunjangan"
      ? setTunjanganTable({
          ...tunjanganTable,
          rowsPerPage: event.target.value,
          page: 0,
        })
      : table === "reimburse"
      ? setReimburseTable({
          ...reimburseTable,
          rowsPerPage: event.target.value,
          page: 0,
        })
      : setLemburTable({
          ...lemburTable,
          rowsPerPage: event.target.value,
          page: 0,
        });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFoundData(detailRiwayatGaji);
    setDataBonus(detailRiwayatGaji?.bonus_list);
    setDataDenda(detailRiwayatGaji?.denda_list);
    setDataTunjangan(detailRiwayatGaji?.tunjangan_list);
    setDataReimburse(detailRiwayatGaji?.reimburse_list);
    setDataLembur(detailRiwayatGaji?.lembur_list);
  }, [detailRiwayatGaji]);

  return foundData ? (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">
              Detail Riwayat Gaji
            </strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <Button
              variant="outlined"
              color="primary"
              className="batal-lembur-button  px-6 py-3 "
            >
              <AddIcon color="primary" />
              Download
            </Button>
            <Button
              variant="contained"
              color="primary"
              className=" px-4 py-3 no-shadow  "
            >
              <span>Bayar Sekarang</span>
            </Button>
          </div>
        </Grid>
      </Grid>
      <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="">
          <div className="simple-card-content">
            <h1 className="mb-20px">Informasi Umum</h1>

            <Fragment>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama</h5>
                  <div>{foundData?.name}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Total Gaji</h5>
                  <div style={{ color: isMinus(foundData?.total_gaji) }}>
                    {formatRupiah(foundData?.total_penerimaan_bersih)}
                  </div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Gaji Pokok</h5>
                  <div style={{ color: isMinus(foundData?.gaji_pokok) }}>
                    {formatRupiah(foundData?.gaji_pokok)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Status</h5>
                  <div>{foundData?.status}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>BPJS 4%</h5>
                  <div style={{ color: "#FF5C58" }}>
                    {formatRupiah(foundData?.bpjs_4)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>BPJS 1%</h5>
                  <div style={{ color: "#FF5C58" }}>
                    {formatRupiah(foundData?.bpjs_1)}
                  </div>
                </Grid>

                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Tunjangan Tetap</h5>
                  <div style={{ color: isMinus(foundData?.tunjangan_tetap) }}>
                    {formatRupiah(foundData?.tunjangan_tetap)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Tunjangan Tidak Tetap</h5>
                  <div
                    style={{ color: isMinus(foundData?.tunjangan_tidak_tetap) }}
                  >
                    {formatRupiah(foundData?.tunjangan_tidak_tetap)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>PPh21</h5>
                  <div style={{ color: "#FF5C58" }}>
                    {formatRupiah(foundData?.pph_21)}
                  </div>
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <h5>Bonus</h5>
                  <div style={{ color: isMinus(foundData?.bonus) }}>
                    {formatRupiah(foundData?.bonus)}
                  </div>
                </Grid> */}
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Lembur</h5>
                  <div style={{ color: isMinus(foundData?.lembur) }}>
                    {formatRupiah(foundData?.lembur)}
                  </div>
                </Grid>

                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Reimburse</h5>
                  <div style={{ color: isMinus(foundData?.reimburse) }}>
                    {formatRupiah(foundData?.reimburse)}
                  </div>
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <h5>Denda</h5>
                  <div style={{ color: isMinus(foundData?.denda) }}>
                    {formatRupiah(foundData?.denda)}
                  </div>
                </Grid> */}
                <Grid item sm={6} xs={12}>
                  <h5>Potongan</h5>
                  <div style={{ color: "#FF5C58" }}>
                    {formatRupiah(foundData?.potongan)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Tambahan</h5>
                  <div style={{ color: isMinus(foundData?.tambahan) }}>
                    {formatRupiah(foundData?.tambahan)}
                  </div>
                </Grid>

                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Pengurangan</h5>
                  <div style={{ color: "#FF5C58" }}>
                    {formatRupiah(foundData?.pengurangan)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Periode</h5>
                  <div>
                    {formatDate(foundData.periode_awal)}-
                    {formatDate(foundData.periode_akhir)}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Hadir</h5>
                  <div>{foundData?.hadir} Hari</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Status</h5>
                  <div>{foundData?.status}</div>
                </Grid>
              </Grid>
            </Fragment>
          </div>
        </SimpleCard>
      </div>

      {/* tunjangan table */}
      <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <div className="w-full overflow-auto bg-white px-8 py-8">
              <Grid container justifyContent="space-between" className="mb-8 ">
                <h1
                  className="font-semibold text-22 my-auto"
                  style={{ color: "#0A0A0A" }}
                >
                  Tunjangan
                </h1>

                <Button
                  variant="contained"
                  color="primary"
                  className="px-8 py-3"
                  onClick={handleAddTunjangan}
                >
                  <AddIcon /> Tambah
                </Button>
              </Grid>

              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>No</TableCell>
                    <TableCell colSpan={4}>Jenis</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Tipe
                    </TableCell>

                    <TableCell align="center" colSpan={3}>
                      Nominal
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Tanggal
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Keterangan
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTable
                    state={tunjanganTable}
                    data={dataTunjangan}
                    tableName="riwayatTunjangan"
                    handleDelete={handleDeleteTunjangan}
                    handleEdit={handleEditTunjangan}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableBody>
              </Table>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataTunjangan?.length ? dataTunjangan?.length : 0}
                rowsPerPage={tunjanganTable.rowsPerPage}
                labelRowsPerPage={"From"}
                page={tunjanganTable.page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={(event, newPage) => setPage("tunjangan", newPage)}
                onChangeRowsPerPage={(event) =>
                  setRowsPerPage("tunjangan", event)
                }
              />
            </div>
          </Fragment>
        </SimpleCard>
      </div>

      {/* bonus table */}
      {/* <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <div className="w-full overflow-auto bg-white px-8 py-8">
              <Grid container justifyContent="space-between" className="mb-8 ">
                <h1
                  className="font-semibold text-22 my-auto"
                  style={{ color: "#0A0A0A" }}
                >
                  Bonus
                </h1>

                <Button
                  variant="contained"
                  color="primary"
                  className="px-8 py-3"
                  onClick={handleAddBonus}
                >
                  <AddIcon /> Tambah
                </Button>
              </Grid>
              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>No</TableCell>
                    <TableCell colSpan={5}>Jenis</TableCell>

                    <TableCell align="center" colSpan={3}>
                      Nominal
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Tanggal
                    </TableCell>
                    <TableCell colSpan={5}>Keterangan</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTable
                    state={bonusTable}
                    data={dataBonus}
                    tableName="riwayatBonus"
                    handleDelete={handleDeleteBonus}
                    handleEdit={handleEditBonus}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableBody>
              </Table>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataBonus?.length ? dataBonus?.length : 0}
                rowsPerPage={bonusTable.rowsPerPage}
                labelRowsPerPage={"From"}
                page={bonusTable.page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={(event, newPage) => setPage("bonus", newPage)}
                onChangeRowsPerPage={(event) => setRowsPerPage("bonus", event)}
              />
            </div>
          </Fragment>
        </SimpleCard>
      </div> */}

      {/* reimburse table */}
      {/* <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <div className="w-full overflow-auto bg-white px-8 py-8">
              <Grid container justifyContent="space-between" className="mb-8 ">
                <h1
                  className="font-semibold text-22 my-auto"
                  style={{ color: "#0A0A0A" }}
                >
                  Reimburse
                </h1>

                <Button
                  variant="contained"
                  color="primary"
                  className="px-8 py-3"
                  onClick={handleAddReimburse}
                >
                  <AddIcon /> Tambah
                </Button>
              </Grid>
              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>No</TableCell>
                    <TableCell colSpan={5}>Jenis</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Nominal
                    </TableCell>

                    <TableCell align="center" colSpan={3}>
                      Tanggal
                    </TableCell>
                    <TableCell align="center" colSpan={5}>
                      Keterangan
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTable
                    state={reimburseTable}
                    data={dataReimburse}
                    tableName="riwayatReimburse"
                    handleDelete={handleDeleteReimburse}
                    handleEdit={handleEditReimburse}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableBody>
              </Table>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataReimburse?.length ? dataReimburse?.length : 0}
                rowsPerPage={reimburseTable.rowsPerPage}
                labelRowsPerPage={"From"}
                page={reimburseTable.page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={(event, newPage) => setPage("reimburse", newPage)}
                onChangeRowsPerPage={(event) =>
                  setRowsPerPage("reimburse", event)
                }
              />
            </div>
          </Fragment>
        </SimpleCard>
      </div> */}

      {/* lembur table */}
      <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <div className="w-full overflow-auto bg-white px-8 py-8">
              <Grid container justifyContent="space-between" className="mb-8 ">
                <h1
                  className="font-semibold text-22 my-auto"
                  style={{ color: "#0A0A0A" }}
                >
                  Lembur
                </h1>

                <Button
                  variant="contained"
                  color="primary"
                  className="px-8 py-3"
                  onClick={handleAddLembur}
                >
                  <AddIcon /> Tambah
                </Button>
              </Grid>
              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1}>No</TableCell>
                    <TableCell colSpan={4}>Upah Lembur</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Total Jam
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Tanggal
                    </TableCell>
                    <TableCell colSpan={6} align="center">
                      Catatan
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTable
                    state={lemburTable}
                    data={dataLembur}
                    tableName="riwayatLemburGaji"
                    handleDelete={handleDeleteLembur}
                    handleEdit={handleEditLembur}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    noDetail
                  />
                </TableBody>
              </Table>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataLembur?.length ? dataLembur?.length : 0}
                rowsPerPage={lemburTable.rowsPerPage}
                labelRowsPerPage={"From"}
                page={lemburTable.page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={(event, newPage) => setPage("lembur", newPage)}
                onChangeRowsPerPage={(event) => setRowsPerPage("lembur", event)}
              />
            </div>
          </Fragment>
        </SimpleCard>
      </div>

      {/* denda table */}
      {/* <div style={{ marginBottom: "25px" }}>
        <SimpleCard loading={false} currency="" saldo="" className=" my-4">
          <Fragment>
            <div className="w-full overflow-auto bg-white px-8 py-8">
              <Grid container justifyContent="space-between" className="mb-8 ">
                <h1
                  className="font-semibold text-22 my-auto"
                  style={{ color: "#0A0A0A" }}
                >
                  Denda
                </h1>

                <Button
                  variant="contained"
                  color="primary"
                  className="px-8 py-3"
                  onClick={handleAddDenda}
                >
                  <AddIcon /> Tambah
                </Button>
              </Grid>
              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>No</TableCell>
                    <TableCell colSpan={5}>Jenis</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Nominal
                    </TableCell>

                    <TableCell align="center" colSpan={5}>
                      Tanggal
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Keterangan
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTable
                    state={dendaTable}
                    data={dataDenda}
                    tableName="riwayatDenda"
                    handleDelete={handleDeleteDenda}
                    handleEdit={handleEditDenda}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableBody>
              </Table>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataDenda?.length ? dataDenda?.length : 0}
                rowsPerPage={dendaTable.rowsPerPage}
                labelRowsPerPage={"From"}
                page={dendaTable.page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={(event, newPage) => setPage("denda", newPage)}
                onChangeRowsPerPage={(event) => setRowsPerPage("denda", event)}
              />
            </div>
          </Fragment>
        </SimpleCard>
      </div> */}

      <Modal
        open={openModalTujanganAdd}
        modalName="tambahtunjangan"
        data={detailRiwayatGaji}
        setClose={setOpenModalTujanganAdd}
        gajiCode={detailRiwayatGaji?.gaji_code}
        getData={getData}
      />
      <Modal
        open={openModalTujanganEdit}
        modalName="tambahtunjangan"
        data={detailTunjangan}
        setClose={setOpenModalTujanganEdit}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
        usersCode={detailRiwayatGaji?.users_code}
        edit
      />

      {/* modallembur */}
      <Modal
        open={openModalLemburAdd}
        modalName="tambahlemburriwayat"
        data={detailRiwayatGaji}
        setClose={setOpenModalLemburAdd}
        gajiCode={detailRiwayatGaji?.gaji_code}
        getData={getData}
      />
      <Modal
        open={openModalLemburEdit}
        modalName="tambahlemburriwayat"
        data={detailLembur}
        setClose={setOpenModalLemburEdit}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
        usersCode={detailRiwayatGaji?.users_code}
        edit
      />

      {/* {/* modalreimburse */}
      <Modal
        open={openModalReimburseAdd}
        modalName="tambahreimburse"
        data={detailRiwayatGaji}
        setClose={setOpenModalReimburseAdd}
        gajiCode={detailRiwayatGaji?.gaji_code}
        getData={getData}
      />
      <Modal
        open={openModalReimburseEdit}
        modalName="tambahreimburse"
        data={detailReimburse}
        setClose={setOpenModalReimburseEdit}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
        usersCode={detailRiwayatGaji?.users_code}
        edit
      />
      {/* {/* modalbonus */}
      <Modal
        open={openModalBonusAdd}
        modalName="tambahbonus"
        data={detailRiwayatGaji}
        setClose={setOpenModalBonusAdd}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
      />
      <Modal
        open={openModalBonusEdit}
        modalName="tambahbonus"
        data={detailBonus}
        setClose={setOpenModalBonusEdit}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
        usersCode={detailRiwayatGaji?.users_code}
        edit
      />

      {/* {/* modaldenda */}
      <Modal
        open={openModalDendaAdd}
        modalName="tambahdenda"
        data={detailRiwayatGaji}
        setClose={setOpenModalDendaAdd}
        gajiCode={detailRiwayatGaji?.gaji_code}
        getData={getData}
      />
      <Modal
        open={openModalDendaEdit}
        modalName="tambahdenda"
        data={detailDenda}
        setClose={setOpenModalDendaEdit}
        getData={getData}
        gajiCode={detailRiwayatGaji?.gaji_code}
        usersCode={detailRiwayatGaji?.users_code}
        edit
      />
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailRiwayatGaji: state.gaji.detailRiwayatGaji,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    delTunjangan: (params) => dispatch(delTunjangan(params)),
    delReimburse: (params) => dispatch(delReimburse(params)),
    delLembur: (params) => dispatch(delLembur(params)),
    delDenda: (params) => dispatch(delDenda(params)),
    delBonus: (params) => dispatch(delBonus(params)),

    cetakRiwayatGaji: (code) => dispatch(cetakRiwayatGaji(code)),
    getDetailRiwayatGaji: (code) => dispatch(getDetailRiwayatGaji(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailRiwayatGaji);

import { Button, ButtonGroup, Grid, Icon } from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMOM, delMOM, downloadMOM } from "app/redux/actions/MOMActions";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
     editDenda,
  getDetailDenda,
} from "app/redux/actions/gaji/DendaAction";
import { formatRupiah } from "app/utils/globalFunction";

const DetailDenda = ({
  getDetailDenda,
  detailDenda
}) => {


  const { id } = useParams();
  const [foundData, setFoundData] = useState({
    name: "",
    tanggal: "",
    keterangan: "",
    nama_denda: "",
    nominal: "",
  });
  const getData = () => {
    getDetailDenda(id);
  };

  useEffect(() => {
    getData();

  }, []);


  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFoundData({
      name: detailDenda?.name,
      tanggal: detailDenda?.tanggal,
      keterangan: detailDenda?.keterangan,
      nama_denda: detailDenda?.nama_denda,
      nominal: detailDenda?.nominal,


    });
  }, [detailDenda]);

  console.log(detailDenda)




  return (
    <div className="m-sm-30">
      <h1 className="mb-20px">Detail Denda</h1>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div className="simple-card-content">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Karyawan</h5>
                <div>{foundData.name}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Tanggal</h5>
                <div>{foundData.tanggal}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Jenis Denda</h5>
                <div>{foundData.nama_denda}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Nominal</h5>
                <div>{formatRupiah(foundData.nominal)}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Keterangan</h5>
                <div>{foundData.keterangan}</div>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    detailDenda: state.gaji.detailDenda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailDenda: (code) => dispatch(getDetailDenda(code)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DetailDenda);

import { Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { SimpleCard } from "matx";

import { connect } from "react-redux";
import Swal from "sweetalert2";

import { ValidatorForm } from "react-material-ui-form-validator";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  editAturTitikAbsensi,
  getDetailAturTitikAbsensi,
} from "app/redux/actions/AturanAction";
import { useHistory, useParams } from "react-router-dom";

const EditTitikAbsensi = ({
  detailAturan,
  editAturTitikAbsensi,
  getDetailAturTitikAbsensi,
}) => {
  const [formData, setFormData] = useState({
    nama_kantor: "",
    alamat: "",
    latitude: "",
    longitude: "",
    radius: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const { id } = useParams();

  const formRef = useRef(null);

  const [submit, setSubmit] = useState(false);

  const getData = () => {
    getDetailAturTitikAbsensi(id);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama_kantor: detailAturan.nama_kantor,
      alamat: detailAturan.alamat,
      latitude: detailAturan.latitude, // replace with appropriate value
      longitude: detailAturan.longitude,
      radius: detailAturan.radius,
    });
  }, [detailAturan]);

  const history = useHistory();

  const handleSubmit = () => {
    editAturTitikAbsensi({
      titik_absensi_code: id,
      nama_kantor: formData.nama_kantor,
      alamat: formData.alamat,
      latitude: formData.latitude,
      longitude: formData.longitude,
      radius: formData.radius,
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Titik Absensi berhasil disimpan",
          "success"
        );
        history.push("/TitikAbsensi");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="   my-2 mt-8 mb-3"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h4 className="mb-5">Edit Titik Absensi</h4>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard loading={false} currency="" saldo="">
        <Grid container spacing={3} className=" mt-3">
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Nama Kantor</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="nama_kantor"
              onChange={handleChange}
              placeholder="Nama Kantor"
              value={formData.nama_kantor}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Alamat</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="alamat"
              onChange={handleChange}
              placeholder="Alamat"
              value={formData.alamat}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Latitude</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="latitude"
              onChange={handleChange}
              placeholder="Latitude"
              value={formData.latitude}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Longitude</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="longitude"
              onChange={handleChange}
              placeholder="Longitude"
              value={formData.longitude}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Radius (meter)</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="radius"
              onChange={handleChange}
              placeholder="Radius"
              value={formData.radius}
            />
          </Grid>
        </Grid>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAturan: state.aturan.detailAturan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailAturTitikAbsensi: (code) =>
      dispatch(getDetailAturTitikAbsensi(code)),
    editAturTitikAbsensi: (params) => dispatch(editAturTitikAbsensi(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTitikAbsensi);

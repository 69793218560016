import { API, setAuthToken } from "app/config/api";
import axios from "axios";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION";

export const getNotification = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`/user/notifikasi`).catch((err) => {
      return Promise.reject(err);
    });
    if (res?.data?.code === 0) {
      dispatch({
        type: GET_NOTIFICATION,
        payload: res?.data?.data?.data,
      });
    } else {
      dispatch({
        type: GET_NOTIFICATION,
        payload: [],
      });
    }
  };
};

export const readAllNotifications = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.patch(`/user/notifikasi`).catch((err) => {
      return Promise.reject(err);
    });
    if (res?.data?.code === 0) {
      dispatch({
        type: DELETE_ALL_NOTIFICATION,
        payload: [],
      });
    } else {
      dispatch({
        type: DELETE_ALL_NOTIFICATION,
        payload: [],
      });
    }
  };
};

export const readNotification = (id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.patch(`/user/notifikasi/${id}`).catch((err) => {
      return Promise.reject(err);
    });
    if (res?.data?.code === 0) {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: [],
      });
    } else {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: [],
      });
    }
  };
};

// Actions Lama

export const deleteNotification = (id) => (dispatch) => {
  axios.post("/api/notification/delete", { id }).then((res) => {
    dispatch({
      type: DELETE_NOTIFICATION,
      payload: res.data,
    });
  });
};

export const deleteAllNotification = () => (dispatch) => {
  axios.post("/api/notification/delete-all").then((res) => {
    dispatch({
      type: DELETE_ALL_NOTIFICATION,
      payload: res.data,
    });
  });
};

export const createNotification = (notification) => (dispatch) => {
  axios.post("/api/notification/add", { notification }).then((res) => {
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: res.data,
    });
  });
};

import UnitKerja from './UnitKerja';
import AddUnitKerja from './AddUnitKerja';
import EditUnitKerja from './EditUnitKerja';

const UnitKerjaRoutes = [
  {
    path: '/lainnya/unit_kerja',
    component: UnitKerja,
    exact: true,
  },
  {
    path: '/lainnya/unit_kerja/add',
    component: AddUnitKerja,
    exact: true,
  },
  {
    path: '/lainnya/unit_kerja/edit/:id',
    component: EditUnitKerja,
    exact: true,
  },
];

export default UnitKerjaRoutes;

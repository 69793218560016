const setParams = (state, setState) => {
  let params;
  if (state.pilih_periode == "bulan") {
    setState((prev) => ({
      ...prev,
      pilih_waktu_list: [
        {
          text: "Januari",
          value: "1",
        },
        {
          text: "Februari",
          value: "2",
        },
        {
          text: "Maret",
          value: "3",
        },
        {
          text: "April",
          value: "4",
        },
        {
          text: "Mei",
          value: "5",
        },
        {
          text: "Juni",
          value: "6",
        },
        {
          text: "Juli",
          value: "7",
        },
        {
          text: "Agustus",
          value: "8",
        },
        {
          text: "September",
          value: "9",
        },
        {
          text: "Oktober",
          value: "10",
        },
        {
          text: "November",
          value: "11",
        },
        {
          text: "Desember",
          value: "12",
        },
      ],
    }));
    params = `?periode=${state.pilih_periode}&${state.pilih_periode}=${state.pilih_waktu}`;
  } else {
    let year = new Date().getUTCFullYear();
    let yearList = [];

    for (let i = 0; i < 10; i++) {
      yearList.push(year - i);
    }

    yearList = yearList.map((year) => ({
      text: year,
      value: year,
    }));

    setState((prev) => ({
      ...prev,
      pilih_waktu_list: yearList,
    }));
    params = `?periode=${state.pilih_periode}&${state.pilih_periode}=${state.pilih_waktu}`;
  }

  return params;
};

const filterDataGraph = (
  state,
  dataStatistikIzinCuti,
  dataStatistikKeterlambatan
) => {
  let dataGraphIzinCuti;
  let dataGraphKeterlambatan;

  if (state.pilih_periode == "tahun") {
    const dataBulan = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const bulanSekarang = new Date().getMonth() + 1;
    const tahunSekarang = new Date().getFullYear() === +state.pilih_waktu;
    const label = dataBulan.slice(
      0,
      tahunSekarang ? bulanSekarang : dataBulan.length
    );

    dataGraphIzinCuti = {
      label,
      data: dataStatistikIzinCuti.slice(
        0,
        tahunSekarang ? bulanSekarang : dataBulan.length
      ),
    };
    dataGraphKeterlambatan = {
      label,
      data: dataStatistikKeterlambatan.slice(
        0,
        tahunSekarang ? bulanSekarang : dataBulan.length
      ),
    };
  } else {
    const getDaysInMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    };

    const currentYear = new Date().getFullYear();

    const totalDay = getDaysInMonth(currentYear, state.pilih_waktu);

    let arrDate = [];

    for (let i = 1; i <= totalDay; i++) {
      if (
        i > new Date().getDate() &&
        new Date().getMonth() + 1 === +state.pilih_waktu
      ) {
        break;
      }
      arrDate.push(i);
    }
    dataGraphIzinCuti = {
      label: arrDate,
      data: dataStatistikIzinCuti,
    };
    dataGraphKeterlambatan = {
      label: arrDate,
      data: dataStatistikKeterlambatan,
    };
  }

  return {
    dataGraphIzinCuti,
    dataGraphKeterlambatan,
  };
};

export { setParams, filterDataGraph };

import Aturan from "./Aturan";
import KebijakanPrivasi from "./KebijakanPrivasi";

const KebijakanRoutes = [
  {
    path: "/kebijakan-privasi",
    component: KebijakanPrivasi,
    exact: true,
  },
  {
    path: "/aturan",
    component: Aturan,
    exact: true,
  },
];

export default KebijakanRoutes;

import {
  GET_DATA_DASHBOARD,
  GET_PERMINTAAN_IZIN_DASHBOARD,
  GET_STATISTIK_IZIN_CUTI,
  GET_STATISTIK_KETERLAMBATAN,
} from '../actions/DashboardActions';

const initialState = {
  dataDashboardTop: [],
  dataDashboardPermintaanIzin: [],
  dataStatistikIzinCuti: [],
  dataStatistikKeterlambatan: [],
};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_DASHBOARD: {
      return {
        ...state,
        dataDashboardTop: action.payload,
      };
    }
    case GET_PERMINTAAN_IZIN_DASHBOARD: {
      return {
        ...state,
        dataDashboardPermintaanIzin: action.payload,
      };
    }
    case GET_STATISTIK_IZIN_CUTI: {
      return {
        ...state,
        dataStatistikIzinCuti: action.payload,
      };
    }
    case GET_STATISTIK_KETERLAMBATAN: {
      return {
        ...state,
        dataStatistikKeterlambatan: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducer;

import { API, setAuthToken } from "app/config/api";

export const GET_DATA_DENDA = "GET_DATA_DENDA";
export const GET_DETAIL_DENDA = "GET_DETAIL_DENDA";
export const DEL_DENDA = "DEL_DENDA";

export const getDenda = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/denda${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_DENDA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_DENDA,
          payload: [],
        });
      });
  };
};

export const getDetailDenda = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/denda/detail/${code}`).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_DENDA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_DENDA,
        payload: [],
      });
    }
  };
};

export const delDenda = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/denda/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_DENDA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const DEL_GAJI_DENDA = "DEL_GAJI_DENDA";

export const delGajiDenda = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/gaji/denda/gaji_denda/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_GAJI_DENDA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addDenda = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/denda/add", params).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const addGajiDenda = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/denda/gaji_denda/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editDenda = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/denda/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

import Izin from './Izin';
import Cuti from './Cuti';
import RekapCutiKaryawan from './RekapCutiKaryawan';
import RekapIzinKaryawan from './RekapIzinKaryawan';

const IzinCutiRoutes = [
  {
    path: '/izin',
    component: Izin,
    exact: true,
  },
  {
    path: '/cuti',
    component: Cuti,
    exact: true,
  },
  {
    path: '/cuti/rekap',
    component: RekapCutiKaryawan,
    exact: true,
  },
  {
    path: '/izin/rekap',
    component: RekapIzinKaryawan,
    exact: true,
  },
];

export default IzinCutiRoutes;

import { Button, ButtonGroup, Grid, Icon } from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMOM, delMOM, downloadMOM } from "app/redux/actions/MOMActions";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  editTunjangan,
  getDetailTunjangan,
} from "app/redux/actions/gaji/TunjanganAction";
import { formatRupiah } from "app/utils/globalFunction";

const DetailTunjangan = ({ getDetailTunjangan, detailTunjangan }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (code) => {
    window.location.href = `/mom/edit/${code}`;
  };

  const handleAdd = () => {
    const { history } = props;
    history.push("/mom/tambah");
  };

  const { id } = useParams();
  const [foundData, setFoundData] = useState();
  const getData = () => {
    getDetailTunjangan(id);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFoundData({
      name: detailTunjangan?.name,
      tanggal: detailTunjangan?.tanggal,
      keterangan: detailTunjangan?.keterangan,
      nominal: detailTunjangan?.nominal,
      jenis: detailTunjangan?.jenis,
      tipe: detailTunjangan?.tipe,
      unit_kerja: detailTunjangan?.unit_kerja,
    });
  }, [detailTunjangan]);

  return foundData ? (
    <div className="m-sm-30">
      <h1 className="mb-20px">Detail Tunjangan</h1>
      <SimpleCard loading={false} currency="" saldo="">
        <div className="simple-card-content">
          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Karyawan</h5>
                <div>{foundData.name}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Unit Kerja</h5>
                <div>{foundData.unit_kerja}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Jenis</h5>
                <div>{foundData.jenis}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tipe</h5>
                <div>{foundData.tipe}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Nominal</h5>
                <div>{formatRupiah(foundData.nominal)}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tanggal</h5>
                <div>{foundData.tanggal}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Keterangan</h5>
                <div>{foundData.keterangan}</div>
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </SimpleCard>
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailTunjangan: state.gaji.detailTunjangan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailTunjangan: (code) => dispatch(getDetailTunjangan(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailTunjangan);

import { API, setAuthToken } from 'app/config/api';

export const GET_DATA_OFFICE = "GET_DATA_OFFICE";


export const getOffice = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/lainnya/office`)
      .then((res) => {
        dispatch({
          type: GET_DATA_OFFICE,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_OFFICE,
          payload: [],
        });
      });
  };
};


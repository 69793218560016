import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

export default function MenuMagangKeterangan({
  item,
  path,
  handleDelete,
  fourthItem = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDetail = (data) => {
    console.log(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteData = () => {
    handleDelete();
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="karyawan-aksi-dot elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className="ptkp-menu-option mb-3" onClick={handleClose}>
          <span className="">
            <VisibilityOutlinedIcon />
          </span>
          <span className="karyawan-aksi-icon pl-3 ">Lihat PDF</span>
        </MenuItem>
        {fourthItem ? (
          <MenuItem className="ptkp-menu-option mb-3" onClick={handleClose}>
            <span className="">
              <VisibilityOutlinedIcon />
            </span>
            <span className="karyawan-aksi-icon pl-3 ">Kirim Email</span>
          </MenuItem>
        ) : (
          ""
        )}
        <Link
          to={{
            pathname: path,
            state: { item },
          }}
        >
          <MenuItem className="ptkp-menu-option mb-3" onClick={handleClose}>
            <span className="color-green">
              <CreateOutlinedIcon />
            </span>
            <span className="karyawan-aksi-icon pl-3 color-green">
              Edit Data
            </span>
          </MenuItem>
        </Link>

        <MenuItem className="ptkp-menu-option" onClick={handleDeleteData}>
          <span className="color-red" onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon />
          </span>
          <span className="karyawan-aksi-icon pl-3 color-red">Hapus Data</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

import { useState } from "react";
import { Dialog, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import AbsensiSelect from "../AbsensiSelect";

export default function ModalFilterAbsensiKaryawan({
  state,
  setState,
  getData,
}) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const useStyles = makeStyles({
    dialog: {
      width: "460px",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  console.log(state);

  return (
    <>
      <Button
        variant="outlined"
        className="mr-3"
        style={{
          width: "200px",
          height: "38px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        onClick={() => setOpen(true)}
      >
        Filter Absensi Karyawan
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: "hidden !important", margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-bold text-black mb-6">
            Filter Absensi Karyawan
          </h1>
          <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
            Nama Karyawan
          </p>
          <TextField
            name="search_nama"
            className="w-full"
            label="Nama Karyawan"
            variant="outlined"
            value={state.search_nama}
            onChange={handleChange}
            size="small"
            autoFocus
          />
          <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
            Tipe Karyawan
          </p>
          <AbsensiSelect
            dataSelect={[
              {
                value: "fulltime",
                text: "Fulltime",
              },
              {
                value: "intern",
                text: "Intern",
              },
              {
                value: "",
                text: "Lihat Semua",
              },
            ]}
            state={state}
            setState={setState}
            name="search_type"
            style="w-full"
          />
          <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
            Status Absensi
          </p>
          <AbsensiSelect
            dataSelect={[
              {
                value: "izin",
                text: "Izin",
              },
              {
                value: "cuti",
                text: "Cuti",
              },
              {
                value: "terlambat",
                text: "Terlambat",
              },
              {
                value: "hadir",
                text: "Hadir",
              },
              {
                value: "tidak hadir",
                text: "Tidak Hadir",
              },
              {
                value: "",
                text: "Lihat Semua",
              },
            ]}
            state={state}
            setState={setState}
            name="search_status"
            style="w-full"
          />
          <div className="d-flex" style={{ marginTop: "35px" }}>
            <Button
              variant="outlined"
              className="w-full text-primary bg-transparent elevation-z0 mr-3 px-4 py-2"
              onClick={() => setOpen(false)}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              className="w-full bg-primary text-white elevation-z0 ml-3 px-4 py-2"
              onClick={() => {
                setOpen(false);
                getData();
              }}
            >
              Cari
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import Dashboard from './Dashboard';

const DashboardRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
  },
];

export default DashboardRoutes;

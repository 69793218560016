import { useState, useEffect, useLayoutEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { SelectText, SearchKaryawan } from "app/components";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import TableKontrak from "./component/TableKontrak";
import Swal from "sweetalert2";

import {
  getKontrakKerja,
  delKontrakKerja,
  editKontrakKerjaStatus,
} from "app/redux/actions/administrasi/KerjaAction";
import { connect } from "react-redux";

const Kontrak = ({
  dataAdministrasi,
  getKontrakKerja,
  delKontrakKerja,
  editKontrakKerjaStatus,
}) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    let params = `?search=${search}&status=${status}`;

    getKontrakKerja(params);
  };

  useEffect(() => {
    getData();
  }, [status]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delKontrakKerja(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const statusSearch = ["diproses", "ditolak", "diterima"];

  const handleStatusEdit = (id, status) => {
    editKontrakKerjaStatus({
      kontrak_kerja_code: id,
      status: status,
    })
      .then(() => {
        Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
        getData();
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Kontrak Kerja Karyawan
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/administrasi/kontrak/add">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="d-flex items-center py-4 "
          style={{ justifyContent: "flex-end", gap: "20px" }}
        >
          <SearchKaryawan
            otherClasses='karyawan-top-search'
            handleChange={(e) => setSearch(e.target.value)}
            value={search}
            handleKeyDown={submitSearch}
          />

          <Grid item xs={3} sm={2} className="d-flex align-items-end">
            <SelectText
              dataSelect={statusSearch}
              label="Status"
              handleChange={handleStatus}
              search
              value={status}
            />
          </Grid>
        </div>
        <TableKontrak
          magang={false}
          data={dataAdministrasi}
          handleDelete={handleDelete}
          handleStatusEdit={handleStatusEdit}
          getData={getData}
        />
      </SimpleCard>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dataAdministrasi: state.administrasi.dataAdministrasi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKontrakKerja: (params) => dispatch(getKontrakKerja(params)),
    delKontrakKerja: (code) => dispatch(delKontrakKerja(code)),
    editKontrakKerjaStatus: (params) =>
      dispatch(editKontrakKerjaStatus(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kontrak);

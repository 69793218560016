import AturLembur from "./AturLembur";
import TitikAbsensi from "./TitikAbsensi";
import AturHariLibur from "./AturHariLibur";
import EditAturHariLibur from "./EditAturHariLibur";
import TambahAturHariLibur from "./TambahAturHariLibur";
import AturAbsensi from "./AturAbsensi";
import TambahAturAbsensi from "./TambahAturAbsensi";
import TambahTitikAbsensi from "./TambahTitikAbsensi";
import EditAturAbsensi from "./EditAturAbsensi";
import EditTitikAbsensi from "./EditTitikAbsensi";

const AturanRoutes = [
  {
    path: "/AturLembur",
    component: AturLembur,
    exact: true,
  },
  {
    path: "/TitikAbsensi",
    component: TitikAbsensi,
    exact: true,
  },
  {
    path: "/TitikAbsensi/Tambah",
    component: TambahTitikAbsensi,
    exact: true,
  },
  {
    path: "/TitikAbsensi/Edit/:id",
    component: EditTitikAbsensi,
    exact: false,
  },

  {
    path: "/AturHariLibur",
    component: AturHariLibur,
    exact: true,
  },
  {
    path: "/AturHariLibur/Edit/:id",
    component: EditAturHariLibur,
    exact: true,
  },

  {
    path: "/AturHariLibur/Tambah",
    component: TambahAturHariLibur,
    exact: true,
  },
  {
    path: "/AturAbsensi",
    component: AturAbsensi,
    exact: true,
  },
  {
    path: "/AturAbsensi/Tambah",
    component: TambahAturAbsensi,
    exact: true,
  },
  {
    path: "/AturAbsensi/Edit/:id",
    component: EditAturAbsensi,
    exact: false,
  },
  // {
  //   path: "/Gaji/Detail/:id",
  //   component: DetailGaji,
  //   exact: false,
  // },
  // {
  //   path: "/RiwayatGaji",
  //   component: RiwayatGaji,
  //   exact: true,
  // },
  // {
  //   path: "/RiwayatGaji/Detail/:id",
  //   component: DetailRiwayatGaji,
  //   exact: false,
  // },
  // {
  //   path: "/Bonus",
  //   component: Bonus,
  //   exact: true,
  // },
  // {
  //   path: "/Bonus/Tambah",
  //   component: TambahBonus,
  //   exact: true,
  // },
  // {
  //   path: "/Bonus/Detail/:id",
  //   component: DetailBonus,
  //   exact: false,
  // },
  // {
  //   path: "/Bonus/Edit/:id",
  //   component: EditBonus,
  //   exact: false,
  // },
  // {
  //   path: "/Tunjangan",
  //   component: Tunjangan,
  //   exact: true,
  // },
  // {
  //   path: "/Tunjangan/Tambah",
  //   component: TambahTunjangan,
  //   exact: true,
  // },
  // {
  //   path: "/Tunjangan/Detail/:id",
  //   component: DetailTunjangan,
  //   exact: false,
  // },
  // {
  //   path: "/Tunjangan/Edit/:id",
  //   component: EditTunjangan,
  //   exact: false,
  // },
  // {
  //   path: "/Lembur",
  //   component: Lembur,
  //   exact: true,
  // },

  // {
  //   path: "/Denda",
  //   component: Denda,
  //   exact: true,
  // },
  // {
  //   path: "/Denda/Tambah",
  //   component: TambahDenda,
  //   exact: true,
  // },
  // {
  //   path: "/Denda/Detail/:id",
  //   component: DetailDenda,
  //   exact: false,
  // },
  // {
  //   path: "/Denda/Edit/:id",
  //   component: EditDenda,
  //   exact: false,
  // },
  // {
  //   path: "/Potongan",
  //   component: Potongan,
  //   exact: true,
  // },
  // {
  //   path: "/Potongan/Detail/:id",
  //   component: DetailPotongan,
  //   exact: false,
  // },
  // {
  //   path: "/Reimburse",
  //   component: Reimburse,
  //   exact: true,
  // },
];

export default AturanRoutes;

import React, { useState, useLayoutEffect } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import RenderTablePajak from '../../components/table/RenderTablePajak';
import { getListPajak } from 'app/redux/actions/PajakAction';

const Pajak = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [state, setState] = useState({
    data_pajak: [],
  });

  const getData = () => {
    getListPajak().then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        data_pajak: data,
      }));
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: '#0A0A0A' }}
          >
            Ketentuan Pajak
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/pajak/ketentuan/add">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="w-full overflow-auto bg-white mt-3">
          <Table className="buku-kas-table">
            <TableHead>
              <TableRow>
                <TableCell
                  className="font-medium text-15 pl-5"
                  colSpan={1}
                  style={{ color: '#0a0a0a' }}
                >
                  No
                </TableCell>
                <TableCell
                  className="font-medium text-15 pl-5"
                  colSpan={4}
                  style={{ color: '#0a0a0a' }}
                >
                  Rentang 1
                </TableCell>
                <TableCell
                  className="font-medium text-15 pl-5"
                  colSpan={4}
                  style={{ color: '#0a0a0a' }}
                >
                  Rentang 2
                </TableCell>
                <TableCell
                  align="center"
                  className="font-medium text-15 "
                  colSpan={4}
                  style={{ color: '#0a0a0a' }}
                >
                  Persentase
                </TableCell>
                <TableCell
                  align="center"
                  className="font-medium text-15 "
                  colSpan={2}
                  style={{ color: '#0a0a0a' }}
                >
                  Aksi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RenderTablePajak
                data={state.data_pajak}
                page={page}
                rowsPerPage={rowsPerPage}
                getData={getData}
              />
            </TableBody>
          </Table>

          <TablePagination
            className="px-16 my-7"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={state.data_pajak?.length ? state.data_pajak?.length : 0}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={'From'}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next page',
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          />
        </div>
      </SimpleCard>
    </div>
  );
};

export default Pajak;

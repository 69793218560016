import { useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Grid, InputAdornment } from '@material-ui/core';
import { SimpleCard } from 'matx';
import Swal from 'sweetalert2';
import { editPtkp } from 'app/redux/actions/PajakAction';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getListPtkp } from '../../redux/actions/PajakAction';

const EditPtkp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    ptkp_code: '',
    status: '',
    nominal: '',
    scaleY: '0.85',
  });

  useLayoutEffect(() => {
    getListPtkp().then((res) => {
      let data = res.data?.data.filter((item) => item.ptkp_code == id);
      data = data[0];
      console.log(data);
      setState((prev) => ({
        ...prev,
        ...data,
      }));
    });
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    console.log(state);
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
       await dispatch(
        editPtkp({
          ptkp_code: state.ptkp_code,
          status: state.status,
          nominal: state.nominal,
        })
      );
      setTimeout(() => {
        history.push('/pajak/ptkp');
        Swal.fire('Success!', 'Data PTKP berhasil disimpan', 'success');
      }, 2000);
    } catch (e) {
      Swal.fire('Oopss!', 'Data PTKP gagal disimpan', 'error');
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #e6e9ed',
      },
    },
    input: {
      transform: 'scaleY(0.88)',
      marginBlock: 'auto',
    },
  }));
  const classes = useStyles();
  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25" style={{ color: '#0A0A0A' }}>
            Edit PTKP
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="mt-9 mb-8"
            >
              <Grid
                container
                className="mt-3"
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-medium text-14"
                    style={{ color: '#0a0a0a' }}
                  >
                    Status
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    value={state.status}
                    name="status"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="Status"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-medium text-14"
                    style={{ color: '#0a0a0a' }}
                  >
                    PTKP
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    value={state.nominal}
                    name="nominal"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="PTKP"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="ptkp-text-bold">Rp</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default EditPtkp;

import { Icon, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const SearchKaryawan = ({
    otherClasses,
    style,
    label = 'Cari Nama Karyawan',
    handleChange,
    value,
    handleKeyDown,
    name = 'search'
}) => {
  const classes = useStyles();

  return (
    <TextField
      size="small"
      style={style}
      variant="outlined"
      className={`karyawan-top-search ${otherClasses}`}
      placeholder={label}
      onChange={handleChange}
      value={value}
      onKeyDown={handleKeyDown}
      name={name}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={classes.icon}>search</Icon>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchKaryawan;

import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import {
  getAturTitikAbsensi,
  delAturTitikAbsensi,
} from "app/redux/actions/AturanAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import { MatxMenu } from "matx";
import Aksi from "../../../assets/component/Aksi.svg";
import { RenderTable } from "app/components";
import ic_searchfilter from "../../../assets/component/ic_searchfilter.svg";
import RenderTableTitikAbsensi from "../../components/table/RenderTableTitikAbsensi";

const TitikAbsensi = ({
  dataAturan,
  getAturTitikAbsensi,
  delAturTitikAbsensi,
}) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });
  const [search, setSearch] = useState("");

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getData = () => {
    let params = `?search=${search}`;

    getAturTitikAbsensi(params);
  };

  useEffect(() => {
    getData();
  }, [status]);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  const handleCloseEdit = () => {
    setState(
      {
        ...state,
        printPopUp: false,
      },
      () => {
        getData();
      }
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delAturTitikAbsensi(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEdit = (code) => {
    window.location.href = `/mom/edit/${code}`;
  };

  const handleEditData = () => {
    setState({
      editAbsensi: true,
    });
  };

  const handleDeleteData = () => {
    setState({
      deleteAbsensi: true,
    });
  };

  const handleDetail = (item) => {
    setState({
      nameClick: item,
    });
  };

  const handleAdd = () => {
    const { history } = props;
    history.push("/mom/tambah");
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-8">
        <h4
          className="font-semibold text-25 my-auto"
          style={{ color: "#0A0A0A" }}
        >
          Titik Absensi
        </h4>
        <div className="tambah-button">
          <Link to={`/TitikAbsensi/Tambah`}>
            <Button>Tambah</Button>
          </Link>
        </div>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center mt-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      style={{ paddingRight: "5px" }}
                      src={ic_searchfilter}
                    ></img>
                  </InputAdornment>
                ),
                style: { fontSize: 13 },
                placeholder: "Cari Nama Kantor",
              }}
            />
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={6}>Nama Kantor</TableCell>

                  <TableCell align="center" colSpan={8}>
                    Alamat
                  </TableCell>

                  <TableCell align="center" colSpan={2}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTableTitikAbsensi
                  state={state}
                  data={dataAturan}
                  tableName="titikabsensi"
                  handleDelete={handleDelete}
                  handleDetail={handleDetail}
                  handleEditData={handleEditData}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataAturan?.length ? dataAturan?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAturan: state.aturan.dataAturan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAturTitikAbsensi: (params) => dispatch(getAturTitikAbsensi(params)),
    delAturTitikAbsensi: (code) => dispatch(delAturTitikAbsensi(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TitikAbsensi);

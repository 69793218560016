import { GET_ATURAN } from "app/redux/actions/kebijakan/AturanActions";
import { GET_KEBIJAKAN_PRIVASI } from "app/redux/actions/kebijakan/KebijakanPrivasiActions";

const initialState = {
  dataAturan: [],
  dataKebijakan: [],
};

const KebijakanAturanReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ATURAN: {
      return {
        ...state,
        dataAturan: action?.payload,
      };
    }
    case GET_KEBIJAKAN_PRIVASI: {
      return {
        ...state,
        dataKebijakan: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default KebijakanAturanReducer;

import { GET_JENIS_TUNJANGAN } from "app/redux/actions/lainnya/JenisTunjanganAction";
import { GET_DATA_UNIT_KERJA } from "app/redux/actions/UnitKerjaAction";
import { GET_SIGNER } from "app/redux/actions/SignerActions";
import { GET_DATA_OFFICE } from "app/redux/actions/OfficeActions";
import { GET_DETAIL_ATUR_PASAL } from "app/redux/actions/lainnya/AturPasalAction";
import { GET_MITRA } from "../actions/lainnya/MitraAction";

const initialState = {
  jenisTunjangan: [],
  dataUnitKerja: [],
  dataSigner: [],
  dataOffice: [],
  detailAturPasal: [],
  dataMitra: [],
};

const LainnyaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JENIS_TUNJANGAN: {
      return {
        ...state,
        jenisTunjangan: action.payload,
      };
    }
    case GET_DATA_UNIT_KERJA: {
      return {
        ...state,
        dataUnitKerja: action.payload,
      };
    }
    case GET_SIGNER: {
      return {
        ...state,
        dataSigner: action.payload,
      };
    }
    case GET_MITRA: {
      return {
        ...state,
        dataMitra: action.payload,
      };
    }

    case GET_DATA_OFFICE: {
      return {
        ...state,
        dataOffice: action.payload,
      };
    }

    case GET_DETAIL_ATUR_PASAL: {
      return {
        ...state,
        detailAturPasal: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default LainnyaReducer;

import { TableCell, TableRow, Button } from "@material-ui/core";
import MenuPtkp from "../menu/MenuPtkp";
import SimpleMenu from "app/components/menu/Menu";

const RenderTableTitikAbsensi = ({
  state,
  data,
  page,
  rowsPerPage,
  getData,
}) => {
  const handleNumbering = () => {
    if (rowsPerPage === 5) {
      return page * 5;
    } else if (rowsPerPage === 10) {
      return page * 10;
    } else if (rowsPerPage === 25) {
      return page * 25;
    }
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell colSpan={1}>{index + 1}</TableCell>
          <TableCell colSpan={6}>{item.nama_kantor}</TableCell>
          <TableCell align="center" colSpan={8}>
            {item.alamat}
          </TableCell>

          <TableCell align="center" colSpan={2}>
            <Button className="karyawan-aksi-dot elevation-z0">
              <SimpleMenu
                item={item}
                linkEdit={`/TitikAbsensi/Edit/${item.titik_absensi_code}`}
                noDetail={true}
                handleDelete={() => handleDelete(item.titik_absensi_code)}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell
        className="font-medium text-12 line-height-28 text-body"
        colSpan={17}
        align="center"
      >
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTableTitikAbsensi;

import { TableCell, TableRow, Button } from "@material-ui/core";
import SimpleMenu from "app/components/menu/Menu";

const RenderTableAturHariLibur = ({
  data,
  page,
  rowsPerPage,
  getData,
  state,
  handleDelete
}) => {
  const handleNumbering = () => {
    if (rowsPerPage === 5) {
      return page * 5;
    } else if (rowsPerPage === 10) {
      return page * 10;
    } else if (rowsPerPage === 25) {
      return page * 25;
    }
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell colSpan={1}>{index + 1}</TableCell>
          <TableCell colSpan={2}>{item.nama}</TableCell>
          <TableCell align="center" colSpan={3}>
            {item.tanggal_mulai}
          </TableCell>
          <TableCell align="center" colSpan={3}>
            {item.tanggal_akhir}
          </TableCell>
          <TableCell align="center" colSpan={3}>
            <Button className="karyawan-aksi-dot elevation-z0">
              <SimpleMenu
                item={item}
                linkEdit={`/AturHariLibur/Edit/${item.atur_hari_libur_code}`}
                noDetail={true}
                handleDelete={() => handleDelete(item.atur_hari_libur_code)}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell
        className="font-medium text-12 line-height-28 text-body"
        colSpan={12}
        align="center"
      >
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTableAturHariLibur;

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SimpleCard } from "matx";
import { useHistory } from "react-router-dom";

import { ValidatorForm } from "react-material-ui-form-validator";
import { ButtonUploadThumbnail } from "app/components";
import { useParams } from "react-router-dom";
import {
  getDetailAturHariLibur,
  editAturHariLibur,
} from "app/redux/actions/AturanAction";
import { DatePickerComponent } from "app/components";

const EditAturHariLibur = ({
  getDetailAturHariLibur,
  detailAturan,
  editAturHariLibur,
}) => {
  const { id } = useParams();

  const [nama, setNama] = useState();

  const currentDateFormat = (e) => {
    let dateFormat = JSON.stringify(e);
    dateFormat = dateFormat.slice(1, 11);
  };
  const [tanggalMulai, setTanggalMulai] = useState(currentDateFormat(new Date()));
  const [tanggalAkhir, setTanggalAkhir] = useState(currentDateFormat(new Date()));
  const [submit, setSubmit] = useState(false);

  const formRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "nama") {
      setNama(value);
    } else if (name === "tanggal_mulai") {
      setTanggalMulai(new Date(value));
    } else if (name === "tanggal_akhir") {
      setTanggalAkhir(new Date(value));
    }
  };
  const handleDateMulaiChange = (date) => {
    setTanggalMulai(date);
  };

  const handleDateAkhirChange = (date) => {
    setTanggalAkhir(date);
  };

  const getData = () => {
    getDetailAturHariLibur(id);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setTimeout(() => {}, 1000);
    setNama(detailAturan.nama);
    setTanggalMulai(detailAturan.tanggal_mulai);
    setTanggalAkhir(detailAturan.tanggal_akhir);
  }, [detailAturan]);

  const history = useHistory();

  const handleSubmit = () => {
    editAturHariLibur({
      atur_hari_libur_code: id,
      nama: nama,
      tanggal_mulai: tanggalMulai,
      tanggal_akhir: tanggalAkhir,
    })
      .then(() => {
        Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
        history.push("/AturHariLibur");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div>
      <div className="p-5">
        <Grid
          container
          spacing={3}
          className="my-2 mx-auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm>
            <h4 className="mb-5">Edit Hari Libur</h4>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            className="d-flex mr-4"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              className="px-15 py-3"
              onClick={handleSubmit}
            >
              <span className="">Simpan</span>
            </Button>
          </Grid>
        </Grid>

        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <ValidatorForm
              ref={formRef}
              onSubmit={handleSubmit}
              className="pi-4"
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama Hari Libur</h5>
                  <TextField
                    className="text-field-modifier-long"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={nama}
                    name="nama"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Tanggal Mulai</h5>
                  <DatePickerComponent
                    isDateFormat
                    date={tanggalMulai}
                    handleDate={handleDateMulaiChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Tanggal Akhir</h5>
                  <DatePickerComponent
                    isDateFormat
                    date={tanggalAkhir}
                    handleDate={handleDateAkhirChange}
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
          </Fragment>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAturan: state.aturan.detailAturan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailAturHariLibur: (code) => dispatch(getDetailAturHariLibur(code)),
    editAturHariLibur: (params) => dispatch(editAturHariLibur(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAturHariLibur);

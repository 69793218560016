import { Button, Grid, TextField, InputAdornment } from "@material-ui/core";
import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SimpleCard } from "matx";

import { SelectText } from "app/components";
import DatePickerComponent from "../../../../components/select/DatePickerComponent";

import { useParams, useHistory } from "react-router-dom";
import { editDenda, getDetailDenda } from "app/redux/actions/gaji/DendaAction";

const EditDenda = ({ editDenda, getDetailDenda, detailDenda }) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    nama_karyawan: "",
    tanggal: new Date().toISOString().slice(0, 10),
    keterangan: "",
    jenis_denda: "",
    nominal: "",
  });

  const getData = () => {
    getDetailDenda(id);
  };

  const handleChangeJenisDenda = (e) => {
    const { value } = e.target;
    console.log(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      jenis_denda: value.jenis_denda_code,
      nominal: value.nominal,
    }));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama_karyawan: detailDenda?.users_code,
      tanggal: detailDenda?.tanggal,
      keterangan: detailDenda?.keterangan,
      jenis_denda: detailDenda?.jenis_denda,
      nominal: detailDenda?.nominal,
    });
  }, [detailDenda]);

  console.log(detailDenda);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const history = useHistory();
  console.log(formData);
  const handleSubmit = () => {
    editDenda({
      denda_code: id,
      users_code: formData.nama_karyawan,
      tanggal: formData.tanggal,
      keterangan: formData.keterangan,
      jenis_denda: formData.jenis_denda,
      nominal: formData.nominal,
    })
      .then(() => {
        Swal.fire("Success!", "Data Denda berhasil disimpan", "success");
        history.push("/Denda");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleChangeStatus = (e) => {
    const { checked } = e.target;

    const value = checked ? "masih bekerja" : "tidak bekerja";

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: value,
    }));
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-2 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="">
            <strong className="font-semibold text-25">Edit Denda</strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-3"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 mb-2  "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div className="my-8 pb-8">
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            className="px-8"
          >
            <Grid item xs={12} sm={6}>
              <p className="font-semibold text-15">Nama Karyawan</p>

              <SelectText
                karyawan
                label="Nama Karyawan"
                value={formData.nama_karyawan}
                handleChange={handleChange("nama_karyawan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-semibold text-15">Tanggal</p>
              <DatePickerComponent
                date={formData.tanggal}
                handleDate={handleDateChange}
                name="tanggal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-semibold text-15">Jenis Denda</p>
              <SelectText
                jenisDenda
                label="Jenis Denda"
                value={formData.jenis_denda}
                handleChange={handleChange("jenis_denda")}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <p className="font-semibold text-15">Nominal</p>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.nominal}
                onChange={handleChange("nominal")}
                name="nominal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <p className="font-semibold text-15">Keterangan</p>
              <TextField
                className="text-field-modifier-multiline"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.keterangan}
                onChange={handleChange("keterangan")}
                name="keterangan"
                multiline
                rows={4}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailDenda: state.gaji.detailDenda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editDenda: (params) => dispatch(editDenda(params)),
    getDetailDenda: (code) => dispatch(getDetailDenda(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDenda);

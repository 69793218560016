import 'cropperjs/dist/cropper.css';

import { Icon } from '@material-ui/core';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';

const UploadKebijakan = ({
  uploadFile,
  maxSize,
  label,
  required,
  note,
  CardStyle,
  reset,
}) => {
  const [FileName, setFileName] = useState(null);
  let fileInput = useRef();

  const getExtension = (filename) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  const checkFile = (filename) => {
    return getExtension(filename).toLowerCase() === 'pdf';
  };

  const kbConverter = (size) => {
    return size * 1024;
  };

  const handleFileChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    if (checkFile(e.target.files[0].name)) {
      let file = files[0];
      if (maxSize && kbConverter(maxSize) < file.size) {
        fileInput.current.value = '';
        setFileName('');
        uploadFile(null);
        Swal.fire({
          title: 'Oopss!',
          text: 'Ukuran file terlalu besar',
          imageUrl: '/assets/images/icon/ic_error.svg',
          imageWidth: 92,
          imageHeight: 92,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#0083E2',
        });
        return;
      }
      setFileName(file.name);
      uploadFile(file);
    } else {
      Swal.fire({
        title: 'Oopss!',
        text: 'Format file tidak didukung',
        imageUrl: '/assets/images/icon/ic_error.svg',
        imageWidth: 92,
        imageHeight: 92,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#0083E2',
      });
    }
  };

  React.useEffect(() => {
    if (reset) {
      setFileName(null);
      fileInput.current.value = '';
    }
  }, [reset]);

  return (
    <>
      {label && (
        <h6 className="mb-2">
          {label}
          {required ? <span className="text-danger"> *</span> : null}
        </h6>
      )}
      <Card
        style={CardStyle}
        className="card-input-image position-relative shadow-none mt-4"
      >
        <div
          className="w-full h-full p-5 position-absolute d-flex align-items-center justify-content-center flex-column"
        >
          <Icon style={{ fontSize: '45px' }} color="primary">text_snippet</Icon>
          <h4 className={'m-0 text-center mt-5'}>
            {FileName ? FileName : 'Taruh file disini atau'}
          </h4>
          <h4 className="m-0 text-center mt-2 text-primary cursor-pointer">
            <u>Klik Disini</u>
          </h4>
        </div>
        <input
          type="file"
          name="foto"
          className="cursor-pointer"
          onChange={handleFileChange}
          ref={fileInput}
          accept='application/pdf'
          id="input-file"
          required={required}
        />
      </Card>
      {note && (
        <ul className="pl-3 mt-4 mb-0">
          {note?.map((item, i) => (
            <li key={i + 1} className="text-muted">
              {item}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default UploadKebijakan;

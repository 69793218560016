import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Button, Grid } from "@material-ui/core";

import {
  getDetailKontrakKerja,
  editKontrakKerja,
} from "app/redux/actions/administrasi/KerjaAction";

import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";
import TextEditorCard from "./component/TextEditorCard";
import AddIcon from "@material-ui/icons/Add";

const EditKontrak = ({
  getDetailKontrakKerja,
  detailAdministrasi,
  editKontrakKerja,
}) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    kop_surat: "",
    nama_karyawan: "",
    nama_signer: "",
    status: "",
  });

  const [inputList, setInputList] = useState([
    {
      urutan: 1,
      judul: "",
      isi: "",
    },
    {
      urutan: 2,
      judul: "",
      isi: "",
    },
    {
      urutan: 3,
      judul: "",
      isi: "",
    },
  ]);

  const getData = () => {
    getDetailKontrakKerja(id);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      kop_surat: detailAdministrasi?.kop_surat_code,
      nama_karyawan: detailAdministrasi?.karyawan_code,
      nama_signer: detailAdministrasi?.signer_code,
      status: detailAdministrasi?.status,
    });
    setInputList(detailAdministrasi?.pasal);
  }, [detailAdministrasi]);

  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  console.log(formData);

  const history = useHistory();

  const handleSubmit = () => {
    editKontrakKerja({
      kontrak_kerja_code: id,
      kop_surat_code: formData.kop_surat,
      karyawan_code: formData.nama_karyawan,
      signer_code: formData.nama_signer,
      status: formData.status,
      pasal: inputList,
    })
      .then(() => {
        Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
        history.push("/administrasi/kontrak");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleInputChange = (urutan, field) => (e) => {
    setInputList((prevInputList) => {
      const updatedInputList = prevInputList.map((item) => {
        if (item.urutan === urutan && field !== "judul") {
          return {
            ...item,
            [field]: e,
          };
        }
        return item;
      });
      const itemExists = updatedInputList.some(
        (item) => item.urutan === urutan
      );
      return itemExists ? updatedInputList : prevInputList;
    });
  };

  const handleJudulChange = (urutan) => (e) => {
    const { name, value } = e.target;
    setInputList((prevInputList) => {
      const updatedInputList = prevInputList.map((item) => {
        if (item.urutan === urutan) {
          return {
            ...item,
            judul: value,
          };
        }
        return item;
      });
      const itemExists = updatedInputList.some(
        (item) => item.urutan === urutan
      );
      return itemExists ? updatedInputList : prevInputList;
    });
  };

  const handleAddInput = () => {
    const lastItem = inputList.length;
    setInputList([
      ...inputList,
      {
        urutan: lastItem + 1,
        judul: "",
        isi: "",
      },
    ]);
  };

  const handleRemoveInput = (urutan) => {
    const updatedList = inputList
      .filter((item) => item.urutan !== urutan)
      .map((item) => {
        if (item.urutan > urutan) {
          return {
            ...item,
            urutan: item.urutan - 1,
          };
        }
        return item;
      });
    setInputList(updatedList);
  };

  const pasalArray = (inputList) => {
    return (
      <>
        {inputList?.map((item) => (
          <TextEditorCard
            key={item.urutan}
            title={`Pasal ${item.urutan}`}
            state={item.isi}
            handleRemove={() => handleRemoveInput(item.urutan)}
            handleContentChange={handleInputChange(item.urutan, "isi")}
            handleJudulChange={handleJudulChange(item.urutan)}
            judul={item.judul}
          />
        ))}
      </>
    );
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">Edit Kontrak</strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <Button
              variant="outlined"
              color="primary"
              className="batal-lembur-button  px-12 py-3 "
              onClick={handleAddInput}
            >
              <AddIcon color="primary" />
              Tambah
            </Button>
            <Button
              variant="contained"
              color="primary"
              className=" px-15 py-3 "
              onClick={handleSubmit}
            >
              <span>Simpan</span>
            </Button>
          </div>
        </Grid>
      </Grid>

      <SimpleCard title="" className="my-8">
        <div className="my-8 pb-2">
          <Grid container justifyContent="center" spacing={3} className="px-8">
            <Grid item xs={12}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Kop Surat</strong>
              </p>
              <Grid item xs={12}>
                <SelectText
                  kopSurat
                  label="Kop Surat"
                  value={formData.kop_surat}
                  handleChange={handleChange("kop_surat")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Nama Karyawan</strong>
              </p>
              <SelectText
                karyawan
                label="Nama Karyawan"
                value={formData.nama_karyawan}
                handleChange={handleChange("nama_karyawan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Nama Signer</strong>
              </p>
              <SelectText
                signer
                label="Nama Signer"
                value={formData.nama_signer}
                handleChange={handleChange("nama_signer")}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>

      {pasalArray(inputList)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAdministrasi: state.administrasi.detailAdministrasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailKontrakKerja: (code) => dispatch(getDetailKontrakKerja(code)),

    editKontrakKerja: (params) => dispatch(editKontrakKerja(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKontrak);

import Reimburse from './Reimburse';
import AddReimburse from './AddReimburse';
import EditReimburse from './EditReimburse';

const ReimburseRoutes = [
  {
    path: '/lainnya/reimburse',
    component: Reimburse,
    exact: true,
  },
  {
    path: '/lainnya/reimburse/add',
    component: AddReimburse,
    exact: true,
  },
  {
    path: '/lainnya/reimburse/edit/:id',
    component: EditReimburse,
    exact: true,
  },
];

export default ReimburseRoutes;

import { API, setAuthToken } from "app/config/api";

export const GET_DATA_TUNJANGAN = "GET_DATA_TUNJANGAN";
export const GET_DETAIL_TUNJANGAN = "GET_DETAIL_TUNJANGAN";
export const DEL_TUNJANGAN = "DEL_TUNJANGAN";

export const getTunjangan = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/tunjangan${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_TUNJANGAN,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_TUNJANGAN,
          payload: [],
        });
      });
  };
};

export const getDetailTunjangan = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/tunjangan/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_TUNJANGAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_TUNJANGAN,
        payload: [],
      });
    }
  };
};

export const delTunjangan = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/tunjangan/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_TUNJANGAN,
        payload: code,
      });
    }
    return res.data;
  };
};

export const DEL_GAJI_TUNJANGAN = "DEL_GAJI_TUNJANGAN";

export const delGajiTunjangan = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/gaji/tunjangan/gaji_tunjangan/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_GAJI_TUNJANGAN,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addTunjangan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/tunjangan/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const addGajiTunjangan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/gaji/tunjangan/gaji_tunjangan/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editTunjangan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/tunjangan/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

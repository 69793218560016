import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Button, Grid, TextField } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import KaryawanSelect from "app/views/karyawan/component/KaryawanSelect";
import {
  getDetailAturPasal,
  editAturPasal,
} from "app/redux/actions/lainnya/AturPasalAction";

import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";
import TextEditorCard from "./component/TextEditorCard";

const EditAturPasal = ({
  getDetailAturPasal,
  detailAturPasal,
  editAturPasal,
}) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    judul_pasal: "",
  });

  const [inputList, setInputList] = useState([
    {
      urutan: 1,
      judul: "",
      isi: "",
    },
    {
      urutan: 2,
      judul: "",
      isi: "",
    },
    {
      urutan: 3,
      judul: "",
      isi: "",
    },
  ]);

  const getData = () => {
    getDetailAturPasal(id);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      judul_pasal: detailAturPasal?.judul_pasal,
    });
    setInputList(detailAturPasal?.detail);
  }, [detailAturPasal]);

  // const handleChange = (id) => (e) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [id]: e.target.value,
  //   }));
  // };

  const handleChange = ({ target: { value, name } }) => {
    setFormData((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const history = useHistory();

  const handleSubmit = () => {
    editAturPasal({
      atur_pasal_code: id,
      judul_pasal: formData.judul_pasal,
      pasal: inputList,
    })
      .then(() => {
        Swal.fire("Success!", "Data atur pasal berhasil disimpan", "success");
        history.push("/lainnya/atur_pasal");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleInputChange = (urutan, field) => (e) => {
    setInputList((prevInputList) => {
      const updatedInputList = prevInputList.map((item) => {
        if (item.urutan === urutan && field !== "judul") {
          return {
            ...item,
            [field]: e,
          };
        }
        return item;
      });
      const itemExists = updatedInputList.some(
        (item) => item.urutan === urutan
      );
      return itemExists ? updatedInputList : prevInputList;
    });
  };

  const handleJudulChange = (urutan) => (e) => {
    const { name, value } = e.target;
    setInputList((prevInputList) => {
      const updatedInputList = prevInputList.map((item) => {
        if (item.urutan === urutan) {
          return {
            ...item,
            judul: value,
          };
        }
        return item;
      });
      const itemExists = updatedInputList.some(
        (item) => item.urutan === urutan
      );
      return itemExists ? updatedInputList : prevInputList;
    });
  };

  const handleAddInput = () => {
    const lastItem = inputList.length;
    setInputList([
      ...inputList,
      {
        urutan: lastItem + 1,
        judul: "",
        isi: "",
      },
    ]);
  };

  const handleRemoveInput = (urutan) => {
    const updatedList = inputList
      .filter((item) => item.urutan !== urutan)
      .map((item) => {
        if (item.urutan > urutan) {
          return {
            ...item,
            urutan: item.urutan - 1,
          };
        }
        return item;
      });
    setInputList(updatedList);
  };

  const pasalArray = (inputList) => {
    return (
      <>
        {inputList?.map((item) => (
          <TextEditorCard
            key={item.urutan}
            title={`Pasal ${item.urutan}`}
            state={item.isi}
            handleRemove={() => handleRemoveInput(item.urutan)}
            handleContentChange={handleInputChange(item.urutan, "isi")}
            handleJudulChange={handleJudulChange(item.urutan)}
            judul={item.judul}
          />
        ))}
      </>
    );
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 text-semiblack">Edit Pasal</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4 justify-end"
          container
          spacing={2}
        >
          <Button
            variant="outlined"
            color="primary"
            className="py-3 w-170 mr-5"
            onClick={handleAddInput}
          >
            <AddIcon />
            <span className="ml-1">Tambah Pasal</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="py-3 w-150"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>

      {/* <div className="karyawan-top-container my-8 py-8 px-3">
        <p className=" karyawan-title-top karyawan-my-auto">Atur Pasal</p>
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            className="karyawan-btn-top "
            onClick={handleAddInput}
          >
            <span className="karyawan-btn-span">Tambah Pasal</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="karyawan-btn-top "
            onClick={handleSubmit}
          >
            <span className="karyawan-btn-span">Simpan</span>
          </Button>
        </div>
      </div> */}

      <SimpleCard title="" className="my-8">
        <div className="my-8 pb-2">
          <Grid container justifyContent="center" spacing={3} className="px-8">
            <Grid item xs={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Judul Pasal
              </h1>
              <KaryawanSelect
                menuItemFontSize="text-16"
                width="100%"
                scaleY="1.25"
                dataSelect={[
                  {
                    value: "Kontrak Kerja",
                    text: "Kontrak Kerja",
                  },
                  {
                    value: "Perjanjian Kerjasama",
                    text: "Perjanjian Kerjasama",
                  },
                ]}
                size="small"
                label="Judul Pasal"
                name="judul_pasal"
                state={formData}
                setState={setFormData}
                style="w-full"
                styleMenu={{
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              />
              {/* <TextField
                size="small"
                variant="outlined"
                className="w-full"
                placeholder="Judul Pasal"
                onChange={handleChange}
                name="judul_pasal"
                value={formData?.judul_pasal}
              /> */}
            </Grid>
          </Grid>
        </div>
      </SimpleCard>

      {pasalArray(inputList)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAturPasal: state.lainnya.detailAturPasal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailAturPasal: (code) => dispatch(getDetailAturPasal(code)),

    editAturPasal: (params) => dispatch(editAturPasal(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAturPasal);

import { API, setAuthToken } from "app/config/api";

export const GET_DATA_REIMBURSE = "GET_DATA_REIMBURSE";
export const GET_DATA_REIMBURSE_USER = "GET_DATA_REIMBURSE_USER";
export const GET_DETAIL_REIMBURSE = "GET_DETAIL_REIMBURSE";
export const DEL_REIMBURSE = "DEL_REIMBURSE";

export const getReimburse = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/reimburse${params}`)

      .then((res) => {
        dispatch({
          type: GET_DATA_REIMBURSE,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_REIMBURSE,
          payload: [],
        });
      });
  };
};

export const getReimburseUser = (path, params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/reimburse/${path}${params}`)

      .then((res) => {
        dispatch({
          type: GET_DATA_REIMBURSE_USER,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_REIMBURSE_USER,
          payload: [],
        });
      });
  };
};

export const getDetailReimburse = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/reimburse/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_REIMBURSE,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_REIMBURSE,
        payload: [],
      });
    }
  };
};

export const delReimburse = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/reimburse/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_REIMBURSE,
        payload: code,
      });
    }
    return res.data;
  };
};

export const DEL_GAJI_REIMBURSE = "DEL_GAJI_REIMBURSE";

export const delGajiReimburse = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/gaji/gaji_reimburse/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_GAJI_REIMBURSE,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addReimburse = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/reimburse/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editReimburse = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/reimburse/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const editStatusReimburse = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/gaji/reimburse/updateStatus`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const addGajiReimburse = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/gaji/reimburse/gaji_reimburse/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

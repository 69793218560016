import { API, setAuthToken } from "app/config/api";
export const GET_FAQ = "GET_FAQ";

export const getFaq = () => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/akun/faq`)
      .then((res) => {
        dispatch({
          type: GET_FAQ,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_FAQ,
          payload: [],
        });
      });
  };
};

export const addFaq = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/akun/faq", body);
};

/*
body: {
    "faq" : [
        {
            "pertanyaan" : "pertanyaan 1",
            "jawaban" : "jawaban 1"
        },
        {
            "pertanyaan" : "pertanyaan 2",
            "jawaban" : "jawaban 2"
        },
        {
            "pertanyaan" : "pertanyaan 3",
            "jawaban" : "jawaban 3"
        }
    ]
}
*/

import { API, setAuthToken } from 'app/config/api';

export const GET_DATA_REKAP_CUTI = 'GET_DATA_REKAP_CUTI';
export const GET_DATA_PENGAJUAN_IZIN = 'GET_DATA_PENGAJUAN_IZIN';
export const GET_DATA_REKAP_IZIN = 'GET_DATA_REKAP_IZIN';
export const GET_DATA_RIWAYAT_IZIN = 'GET_DATA_RIWAYAT_IZIN';

export const getPengajuanCuti = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/cuti/pengajuan${params}`);
};

export const getRiwayatCuti = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/cuti/riwayatCuti${params}`);
};

export const getDetailCuti = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/cuti/detail/${params}`);
};

export const getDetailIzin = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/izin/detail/${params}`);
};

export const getRekapCutiKaryawan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/cuti/rekapCutiKaryawan${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_REKAP_CUTI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_REKAP_CUTI,
        payload: [],
      });
    }
  };
};

export const updateStatusCuti = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/cuti/updateStatus`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const getDataPengajuanIzinKaryawan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/izin/pengajuan${params}`).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_PENGAJUAN_IZIN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_PENGAJUAN_IZIN,
        payload: [],
      });
    }
  };
};

export const getRiwayatIzinKaryawan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/izin/riwayatIzin${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_RIWAYAT_IZIN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_RIWAYAT_IZIN,
        payload: [],
      });
    }
  };
};

export const updateStatusIzin = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/izin/updateStatus`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const getRekapIzinKaryawan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/izin/rekapIzinKaryawan${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_REKAP_IZIN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_REKAP_IZIN,
        payload: [],
      });
    }
  };
};

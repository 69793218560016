import { useState } from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from "@material-ui/core";

import MenuMagangKeterangan from "./MenuMagangKeterangan";

const RenderTable = ({ data, state, handleDelete }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const handleModal = (item) => {};

  return data?.length > 0 ? (
    data
      ?.slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )

      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell className="" align="center" colSpan={1}>
            <span className="karyawan-tabel-body-txt">
              {index + 1 + handleNumbering()}
            </span>
          </TableCell>
          <TableCell className="pl-3" colSpan={4}>
            <span className="karyawan-tabel-body-txt">
              {item.nama_karyawan}
            </span>
          </TableCell>
          <TableCell className="" align="center" colSpan={3}>
            <span className="karyawan-tabel-body-txt">{item.nama_signer}</span>
          </TableCell>
          <TableCell className="" align="center" colSpan={4}>
            <span className="karyawan-tabel-body-txt">
              {item.tanggal_dibuat}
            </span>
          </TableCell>
          <TableCell className="" align="center" colSpan={2}>
            <Button
              className="karyawan-aksi-dot elevation-z0"
              onClick={() => {
                handleModal(item);
              }}
            >
              <MenuMagangKeterangan
                item={item}
                handleDelete={() => handleDelete(item.pernyataan_magang_code)}
                path={`/administrasi/magang/pernyataan/edit/${item.pernyataan_magang_code}`}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={14} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const TableMagangPernyataan = ({ data, handleDelete }) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} align="center">
              <span className="karyawan-tabel-header-txt">No</span>
            </TableCell>
            <TableCell colSpan={4} className="pl-3">
              <span className="karyawan-tabel-header-txt">
                Nama Karyawan Magang
              </span>
            </TableCell>
            <TableCell colSpan={3} className="" align="center">
              <span className="karyawan-tabel-header-txt">Nama Signer</span>
            </TableCell>
            <TableCell colSpan={4} className="" align="center">
              <span className="karyawan-tabel-header-txt">Tanggal Dibuat</span>
            </TableCell>
            <TableCell colSpan={2} className="" align="center">
              <span className="karyawan-tabel-header-txt">Aksi</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable data={data} state={state} handleDelete={handleDelete} />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={"From"}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default TableMagangPernyataan;

import Tunjangan from './Tunjangan';
import AddTunjangan from './AddTunjangan';
import EditTunjangan from './EditTunjangan';

const TunjanganRoutes = [
  {
    path: '/lainnya/tunjangan',
    component: Tunjangan,
    exact: true,
  },
  {
    path: '/lainnya/tunjangan/add',
    component: AddTunjangan,
    exact: true,
  },
  {
    path: '/lainnya/tunjangan/edit/:id',
    component: EditTunjangan,
    exact: true,
  },
];

export default TunjanganRoutes;

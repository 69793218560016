import { API, setAuthToken } from 'app/config/api';

export const getListPtkp = (params = '') => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/pajak/ptkp${params}`);
};

export const getListPajak = () => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get('/user/pajak/ketentuan');
};

export const editPtkp = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/pajak/ptkp/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const editPajak = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/pajak/ketentuan/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const addPajak = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/pajak/ketentuan/add`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const addPtkp = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/pajak/ptkp/add`, params).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delPtkp = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(`/user/pajak/ptkp/delete/${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const delPajak = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(
      `/user/pajak/ketentuan/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

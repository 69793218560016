import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,

  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';



import DatePickerComponent from "../../components/select/DatePickerComponent";
import ic_remove from "assets/component/ic_remove.svg";
import ic_add from "assets/component/ic_add.svg";

import { editHandover, getDetailHandover } from "app/redux/actions/administrasi/HandoverAction";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";

const EditHandover = ({
  editHandover,
  getDetailHandover,
  detailAdministrasi

}) => {

  const [formData, setFormData] = useState({
    handover_code: "",
    alasan_keluar: "",
    tanggal_masuk: new Date().toISOString().slice(0, 10),
    tanggal_keluar: new Date().toISOString().slice(0, 10),
    penerima: "",
    mengetahui_atasan: "",
  });

  const { id } = useParams();



  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

  };

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };
  const history = useHistory();


  const handleSubmit = () => {
    editHandover({
      handover_code: formData.handover_code,
      alasan_keluar: formData.alasan_keluar,
      tanggal_masuk: formData.tanggal_masuk,
      tanggal_keluar: formData.tanggal_keluar,
      penerima_handover_code: formData.penerima,
      mengetahui_atasan_code: formData.mengetahui_atasan,
      detail: inputList,
    }).then(() => {
      Swal.fire("Success!", "Data Handover berhasil disimpan", "success");
      history.push("/administrasi/handover");
    })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };


  const [submit, setSubmit] = useState(false);

  const [inputList, setInputList] = useState([
    {
      yang_diserahterimakan: "",
      pic_keterangan: "",
      yang_diserahterimakan_jabatan: "",
    },
    {
      yang_diserahterimakan: "",
      pic_keterangan: "",
      yang_diserahterimakan_jabatan: "",
    },
  ]);

  const getData = () => {
    getDetailHandover(id);
  };

  useEffect(() => {
    getData();

  }, []);

  useEffect(() => {
    setTimeout(() => { }, 1000);
    setFormData({
      handover_code: id,
      alasan_keluar: detailAdministrasi?.alasan_keluar,
      tanggal_masuk: detailAdministrasi?.tanggal_masuk,
      tanggal_keluar: detailAdministrasi?.tanggal_keluar,
      penerima: detailAdministrasi?.penerima_handover_code,
      mengetahui_atasan: detailAdministrasi?.mengetahui_atasan_code,
    });
    setInputList(detailAdministrasi?.detail);
  }, [detailAdministrasi]);

  const handleInputChange = (index, field) => (e) => {
    const updatedList = [...inputList];

    updatedList[index][field] = e.target.value;

    setInputList(updatedList);
  };

  const handleAddInput = () => {
    setInputList([
      ...inputList,
      {
        yang_diserahterimakan: "",
        pic_keterangan: "",
        yang_diserahterimakan_jabatan: "",
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const updatedList = [...inputList];
    updatedList.splice(index, 1)
    setInputList(updatedList);
  };



  const renderInput = () => {
    const isLastInput = (index) => inputList.length - 1 !== index;

    return (
      <>
        {inputList?.map((item, index) => (
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item sm={3} xs={12}>
              <h5>Yang Diserahterimakan *</h5>
              <TextField
                className="text-field-modifier-medium"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,

                }}
                onChange={handleInputChange(index, 'yang_diserahterimakan')}
                value={item.yang_diserahterimakan}
                fullWidth
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <h5>PIC / Keterangan *</h5>
              <TextField
                className="text-field-modifier-medium"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,

                }}
                onChange={handleInputChange(index, "pic_keterangan")}
                value={item.pic_keterangan}
                fullWidth
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <h5>Yang diserahterimakan / Jabatan *</h5>
              <TextField
                className="text-field-modifier-medium"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,

                }}
                onChange={handleInputChange(index, "yang_diserahterimakan_jabatan")}
                fullWidth
                value={item.yang_diserahterimakan_jabatan}
              />
            </Grid>

            {isLastInput(index) ? (
              <Grid item sm={1} xs={12}>
                <div className="delete-button">
                  <Button onClick={() => handleRemoveInput(index)}>
                    <img src={ic_remove} alt="" />
                  </Button>
                </div>
              </Grid>
            ) : (
              <Grid item sm={1} xs={12}>
                <div className="add-button">
                  <Button onClick={() => handleAddInput()}>
                    <img src={ic_add} alt="" />
                  </Button>
                </div>

              </Grid>
            )}
          </Grid>
        ))}

      </>
    );
  };



  return (
    <div className="m-sm-30">
      <div className="karyawan-top-container mb-20px">
        <p className=" karyawan-title-top karyawan-my-auto">
          Tambah Form Handover
        </p>
        <Button
          variant="contained"
          color="primary"
          className="karyawan-btn-top "
          onClick={handleSubmit}

        >
          <span className="karyawan-btn-span">Simpan</span>
        </Button>
      </div>
      <SimpleCard title="">
        <div className="karyawan-add-ultra-container">
          <Grid
            container
            justifyContent="center"
            spacing={3}
            className="mt-8 px-8"
          >
            <Grid item xs={12} sm={6}>
              <p className="">Tanggal Mulai</p>
              <DatePickerComponent
                date={formData.tanggal_masuk}
                handleDate={handleDateChange}
                name="tanggal_masuk"
              />              </Grid>
            <Grid item xs={12} sm={6}>
              <p className="">Tanggal Selesai</p>
              <DatePickerComponent
                date={formData.tanggal_keluar}
                handleDate={handleDateChange}
                name="tanggal_keluar"
              />              </Grid>
            <Grid item xs={24} sm={12}>
              <Grid item xs={12} sm={6} >

              </Grid>
              <Grid item xs={12} sm={6} >
                <p className="">Alasan Keluar</p>
                {/* <SelectText karyawan label="Nama Signer" /> */}
                <TextField
                  className="text-field-modifier-long"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  id="mui-theme-provider-outlined-input"
                  value={formData.alasan_keluar}
                  onChange={handleChange('alasan_keluar')}
                  placeholder="Alasan Keluar"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="">Penerima Handover</p>
              <SelectText karyawan label="Penerima Handover" handleChange={handleChange('penerima')} value={formData.penerima} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="">Mengetahui Atasan</p>
              <SelectText karyawan label="Mengetahui Atasan" handleChange={handleChange('mengetahui_atasan')} value={formData.mengetahui_atasan} />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>

      <div className="mb-20px" />

      <SimpleCard title="">
        <div className="karyawan-add-ultra-container">
          {renderInput()}

        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAdministrasi: state.administrasi.detailAdministrasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editHandover: (params) => dispatch(editHandover(params)),
    getDetailHandover: (code) => dispatch(getDetailHandover(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHandover);

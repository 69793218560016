import { makeStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Chip,
  Icon,
} from "@material-ui/core";
import { formatRupiah } from "app/utils/globalFunction";

import Aksieye from "assets/component/Aksieye.svg";
import BadgeSelect from "app/components/select/BadgeSelect";
import { useDispatch } from "react-redux";
import { editStatusReimburse } from "app/redux/actions/gaji/ReimburseAction";

const TableReimburseUser = ({ state, data, setData, aksiClick, handleTolakModal, getData }) => {
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  const handleStatusChange = async (event, item) => {
    const selectedStatus = event.target.value;
    if (selectedStatus !== 'ditolak') {
      await dispatch(editStatusReimburse({
        reimburse_code: item.reimburse_code,
        status: selectedStatus,
      }));
    } else {
      handleTolakModal();
      setData(prev => ({ ...prev, ...item, status: selectedStatus }));
    }
    getData();
  }

  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  return (
    <TableBody>
      {data?.length > 0 ? (
        data
          .slice(
            state.page * state.rowsPerPage,
            state.page * state.rowsPerPage + state.rowsPerPage
          )
          .map((item, index) => (
            <TableRow hover key={index}>
              <TableCell colSpan={1}>{index + 1 + handleNumbering()}</TableCell>
              <TableCell colSpan={3}>{item?.name}</TableCell>
              <TableCell align="center" colSpan={3}>
                {item?.jenis_reimburse}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {formatRupiah(item?.nominal)}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {item?.tanggal}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <BadgeSelect
                  item={item}
                  handleChange={(event) => handleStatusChange(event, item)}
                  menus={[
                    {
                      label: 'Diterima',
                      value: 'diterima',
                      icon: <Icon>{item.status.toLowerCase() === 'diterima' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                      color: '#72BE42',
                      backgroundColor: '#F3FFEC'
                    },
                    {
                      label: 'Ditolak',
                      value: 'ditolak',
                      icon: <Icon>{item.status.toLowerCase() === 'ditolak' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                      color: '#FF5C58',
                      backgroundColor: '#FFEBEA'
                    },
                    {
                      label: 'Diproses',
                      value: 'diproses',
                      icon: <Icon>{item.status.toLowerCase() === 'diproses' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                      color: '#FBC02A',
                      backgroundColor: '#FFF8E7'
                    },
                  ]}
                  badgeBackgroundColor={item.status.toLowerCase() === 'diterima' ? '#72BE42' : item.status.toLowerCase() === 'ditolak' ? '#FF5C58' : '#FBC02A'}
                  iconBackgroundColor={item.status.toLowerCase() === 'diterima' ? '#68B03A' : item.status.toLowerCase() === 'ditolak' ? '#F94A46' : '#F6B40E'}
                  renderValue={(selected) => (selected === 'diterima' ? 'Diterima' : selected === 'ditolak' ? 'Ditolak' : 'Diproses')}
                  value={item.status.toLowerCase()}
                />
              </TableCell>
              <TableCell align="center" colSpan={1}>
                <Button onClick={() => aksiClick(item?.reimburse_code)}>
                  <div className="dot-container">
                    <img src={Aksieye} alt="eye" />
                  </div>
                </Button>
              </TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow hover>
          <TableCell colSpan={17} align="center">
            Data kosong
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableReimburseUser;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Card, Link } from "@material-ui/core";
import Swal from "sweetalert2";
import { RichTextEditor } from "matx";

import {
  getKebijakan,
  saveKebijakan,
  uploadKebijakan,
} from "app/redux/actions/kebijakan/KebijakanPrivasiActions";
import UploadKebijakan from "./components/UploadKebijakan";

const KebijakanPrivasi = () => {
  const dispatch = useDispatch();
  const iframeRef = React.useRef(null);
  const { dataKebijakan } = useSelector((state) => state.kebijakanAturan);
  const [kebijakan, setKebijakan] = React.useState("");
  const [reset, setReset] = React.useState(false);
  const [kebijakanFile, setkebijakanFile] = React.useState(null);
  const [previewURL, setPreviewURL] = React.useState("");
  const [fileExists, setFileExists] = React.useState(false);
  const kebijakanFilePath = `${process.env.REACT_APP_BACKEND_API_URL}storage/aturankebijakan/kebijakan_privasi.pdf`;

  // console.log(dataKebijakan);

  const getData = () => {
    dispatch(getKebijakan());
  };

  React.useLayoutEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (dataKebijakan !== []) {
      setKebijakan(dataKebijakan);
    }
  }, [dataKebijakan]);

  React.useEffect(() => {
    const checkKebijakanFile = async () => {
      const response = await fetch(kebijakanFilePath);
      setFileExists(response.ok);
    }
    checkKebijakanFile();
  }, [fileExists]);

  const handleChange = (e) => {
    setKebijakan(e);
  };

  const handleSubmit = () => {
    try {
      if (!kebijakanFile) {
        Swal.fire('Oopss!', "Tolong upload file terlebih dahulu", "error");
        return;
      }

      const formData = new FormData();
      formData.append('file', kebijakanFile);

      uploadKebijakan(formData).then((res) => {
        Swal.fire("Success!", "Data Aturan berhasil disimpan", "success");
        getData();
        setReset(true);
        setkebijakanFile(null);
        setPreviewURL("");
      });
    } catch (e) {
      Swal.fire("Oopss!", "Data Aturan gagal disimpan", "error");
    }
  };

  React.useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleUpload = (file) => {
    setkebijakanFile(file);

    const previewURL = URL.createObjectURL(file);
    setPreviewURL(previewURL);
  }

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="fw-600 text-25 text-black">Kebijakan Privasi</h1>
        </Grid>
        <Grid item xs={12} sm className="d-flex mr-4 justify-end">
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3 text-white fw-500 border-radius-4"
            onClick={handleSubmit}
          >
            <span className="">Save</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <Card className="py-8 bg-white">
          <div className="mx-8 px-10 mb-8 bg-white">
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12}>
                {/* <RichTextEditor
                  content={kebijakan || ""}
                  placeholder=""
                  handleContentChange={handleChange}
                /> */}
                <UploadKebijakan uploadFile={handleUpload} reset={reset} />
              </Grid>
            </Grid>
          </div>

          {previewURL ? (
            <div className="mx-8 px-8">
              <p className="fw-600 text-20 text-black">Preview</p>
              <iframe src={previewURL} width="100%" height={400} style={{ borderRadius: '8px' }}></iframe>
            </div>
          ) : null}

          {fileExists ? (
            <div className="mx-8 px-8">
              <Grid
                container
                spacing={3}
                className="my-8 mx-auto"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm>
                  <p className="fw-600 text-20 text-black">File</p>
                </Grid>
                <Grid item xs={12} sm className="d-flex mr-4 justify-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="batal-lembur-button  px-8 py-3  mr-2"
                    onClick={() => window.open(kebijakanFilePath, '_blank')}
                  >
                    <span className="">Open</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="mx-8 px-8">
              <p className="fw-600 text-20 text-black">File tidak ditemukan. Silakan upload terlebih dahulu.</p>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default KebijakanPrivasi;

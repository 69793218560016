import React, { useState } from "react";
import { SimpleCard } from "matx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import RenderTableDashboard from "app/components/table/RenderTableDashboard";

const DashboardTabelIzinTerbaru = ({ data, getData }) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <SimpleCard>
      <div className="mx-3">
        <h1 className="font-semibold mt-4 mb-10 text-semiblack">
          Permintaan Izin Terbaru
        </h1>
        <div className="w-full overflow-auto bg-white">
          <Table className="buku-kas-table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={1} align="center">
                  <span className="text-15">No</span>
                </TableCell>
                <TableCell colSpan={3} className="pl-3">
                  <span className="text-15">Nama</span>
                </TableCell>
                <TableCell colSpan={3} className="" align="center">
                  <span className="text-15">Unit Kerja</span>
                </TableCell>
                <TableCell colSpan={4} className="" align="center">
                  <span className="text-15">Tanggal</span>
                </TableCell>
                <TableCell colSpan={3} className="" align="center">
                  <span className="text-15">Status</span>
                </TableCell>
                <TableCell colSpan={2} className="" align="center">
                  <span className="text-15">Aksi</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RenderTableDashboard
                data={data}
                getData={getData}
                state={state}
                setState={setState}
              />
            </TableBody>
          </Table>

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length ? data?.length : 0}
            rowsPerPage={state.rowsPerPage}
            labelRowsPerPage={"From"}
            page={state.page}
            backIconButtonProps={{
              "aria-label": "Previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next page",
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onPageChange={handleChangePage}
            onRowsPerPageChange={setRowsPerPage}
          />
        </div>
      </div>
    </SimpleCard>
  );
};

export default DashboardTabelIzinTerbaru;

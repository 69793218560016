import { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { delKaryawan } from 'app/redux/actions/KaryawanActions';

export default function MenuKaryawan({ item, getData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    getData();
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Hapus',
      text: 'Apakah kamu yakin',
      showCancelButton: true,
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal',
      icon: 'warning',
    }).then((res) => {
      if (res.isConfirmed) {
        delKaryawan(id)
          .then((res) => {
            if (res.data.code == 0) {
              console.log(res);
              Swal.fire({
                title: 'Berhasil',
                text: 'Data berhasil dihapus',
                timer: 2000,
                icon: 'success',
              });
            }
            handleClose();
          })
          .catch((err) => {
            console.log('err', err);
            Swal.fire({
              title: 'gagal',
              text: 'Data Gagal dihapus',
              timer: 2000,
              icon: 'error',
            });
            handleClose();
          });
      }
    });
  };

  return (
    <div className="ml-2">
      <MoreHorizIcon onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to={{
            pathname: `/karyawan/detail/${item.users_code}`,
          }}
        >
          <MenuItem className=" mb-3" onClick={handleClose}>
            <span className="">
              <VisibilityOutlinedIcon />
            </span>
            <span className="pl-3 ">Lihat Detail</span>
          </MenuItem>
        </Link>
        <Link
          to={{
            pathname: `/karyawan/edit/${item.users_code}`,
          }}
        >
          <MenuItem className=" mb-3" onClick={handleClose}>
            <span className="text-green">
              <CreateOutlinedIcon />
            </span>
            <span className="pl-3 text-green">Edit Data</span>
          </MenuItem>
        </Link>
        <MenuItem className="" onClick={() => handleDelete(item.users_code)}>
          <span className="text-red">
            <DeleteOutlineOutlinedIcon />
          </span>
          <span className="pl-3 text-red">Hapus Data</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DatePickerComponent from "../../../components/select/DatePickerComponent";
import AdministrasiSwitch from "../component/AdministrasiSwitch";

import { addPenerimaanKerja } from "app/redux/actions/administrasi/KerjaAction";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";

const AddPenerimaanKerja = ({ addPenerimaanKerja }) => {
  const [formData, setFormData] = useState({
    kop_surat: "",
    nama_signer: "",
    nama_karyawan: "",
    tanggal_hadir: new Date().toISOString().slice(0, 10),
    maksimal_response: new Date().toISOString().slice(0, 10),
    perusahaan: "",
    unit_kerja: "",
    tipe_kerja: "",
    tipe_karyawan: "",
    gaji_pokok: "",
    tunjangan: "",
    probation: "",
    probation_bulan: "",
    cuti_tahunan: "",
    privilege: ["Transport"],
    benefit_lainnya: ["THR"],
  });
  console.log(formData);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const history = useHistory();

  const handleSubmit = () => {
    addPenerimaanKerja({
      nama_signer: formData.nama_signer,
      nama_karyawan: formData.nama_karyawan,
      kop_surat: formData.kop_surat,
      tanggal_hadir: formData.tanggal_hadir,
      maksimal_response: formData.maksimal_response,
      perusahaan: formData.perusahaan,
      unit_kerja: formData.unit_kerja,
      tipe_kerja: formData.tipe_kerja,
      tipe_karyawan: formData.tipe_karyawan,
      gaji_pokok: formData.gaji_pokok,
      tunjangan: formData.tunjangan,
      probation: formData.probation,
      probation_bulan: formData.probation_bulan,
      privilege: formData.privilege,
      benefit: formData.benefit_lainnya,
      cuti_tahunan: formData.cuti_tahunan,
    })
      .then(() => {
        Swal.fire("Success!", "Data  Penerimaan Kerja disimpan", "success");
        history.push("/administrasi/penerimaan_kerja");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleChangeStatus = (e) => {
    const { checked } = e.target;

    const value = checked ? "masih bekerja" : "tidak bekerja";

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: value,
    }));
  };

  const dataProbation = ["ya", "tidak"];
  const dataTipeKerja = ["online", "onsite", "hybrid"];
  const dataProbationBulan = [1, 2, 3];
  const dataCutiTahunan = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const dataTipeKaryawan = ["tetap", "kontrak"];

  const [chipActive, setChipActive] = useState([
    {
      kode: 0,
      value: "Transport",
      active: true,
    },
    {
      kode: 1,
      value: "Makan",
      active: false,
    },
    {
      kode: 2,
      value: "Kendaraan",
      active: false,
    },
    {
      kode: 3,
      value: "Kontrakan",
      active: false,
    },
    {
      kode: 4,
      value: "Email Perusahaan",
      active: false,
    },
  ]);

  const handleClickWaktuAbsensi = (kode) => {
    const [selectedObject] = chipActive.filter((data) => data.kode === kode);
    const filteredData = chipActive.filter((data) => data.kode !== kode);

    const objectData = { ...selectedObject, active: !selectedObject.active };
    filteredData.push(objectData);
    filteredData.sort((a, b) => a.kode - b.kode);
    setChipActive(filteredData);

    const jadwalKerja = filteredData.filter((jadwal) => jadwal.active);

    let dataJadwalKerja = [];
    jadwalKerja.forEach((data) => {
      dataJadwalKerja.push(data.value);
    });

    setFormData({
      ...formData,
      privilege: dataJadwalKerja,
    });
  };

  const [chipActive2, setChipActive2] = useState([
    {
      kode: 0,
      value: "THR",
      active: true,
    },
    {
      kode: 1,
      value: "Tunjangan Keluarga",
      active: false,
    },
    {
      kode: 2,
      value: "Tunjangan Jabatan",
      active: false,
    },
    {
      kode: 3,
      value: "BPJR Kesehatan",
      active: false,
    },
    {
      kode: 4,
      value: "Bonus Team Achieved",
      active: false,
    },
    {
      kode: 5,
      value: "Bonus Monthly Achieved",
      active: false,
    },
    {
      kode: 6,
      value: "Bonus Over-Achieved",
      active: false,
    },
  ]);

  const handleClickWaktuAbsensi2 = (kode) => {
    const [selectedObject] = chipActive2.filter((data) => data.kode === kode);
    const filteredData = chipActive2.filter((data) => data.kode !== kode);

    const objectData = { ...selectedObject, active: !selectedObject.active };
    filteredData.push(objectData);
    filteredData.sort((a, b) => a.kode - b.kode);
    setChipActive2(filteredData);

    const jadwalKerja = filteredData.filter((jadwal) => jadwal.active);

    let dataJadwalKerja = [];
    jadwalKerja.forEach((data) => {
      dataJadwalKerja.push(data.value);
    });

    setFormData({
      ...formData,
      benefit_lainnya: dataJadwalKerja,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    active: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "11px",
      width: "fit-content",
      height: "28px",
      background: "#72BE42",
      border: "1px solid #72BE42",
      borderRadius: "50px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      cursor: "pointer",
    },
    inactive: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "11px",
      width: "fit-content",
      height: "28px",
      background: "#ffffff",
      border: "1px solid #72BE42",
      borderRadius: "50px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "28px",
      color: "#72BE42",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">
              Tambah Surat Penerimaan Kerja
            </strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div className="my-8 pb-8">
          <Grid container justifyContent="center" spacing={3} className="px-8">
            <Grid item xs={12}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Kop Surat</strong>
              </p>
              <Grid item xs={12}>
                <SelectText
                  kopSurat
                  label="Kop Surat"
                  handleChange={handleChange("kop_surat")}
                  value={formData.kop_surat}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Nama Signer</strong>
              </p>
              <SelectText
                signer
                label="Nama Signer"
                handleChange={handleChange("nama_signer")}
                value={formData.nama_signer}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Nama Karyawan</strong>
              </p>
              <SelectText
                karyawan
                label="Nama Karyawan"
                handleChange={handleChange("nama_karyawan")}
                value={formData.nama_karyawan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Tanggal Hadir</strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal_hadir}
                handleDate={handleDateChange}
                name="tanggal_hadir"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Maksimal Response</strong>
              </p>
              <DatePickerComponent
                date={formData.maksimal_response}
                handleDate={handleDateChange}
                name="maksimal_response"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Perusahaan</strong>
              </p>
              <SelectText
                office
                label="Perusahaan"
                handleChange={handleChange("perusahaan")}
                value={formData.perusahaan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Unit Kerja</strong>
              </p>
              <SelectText
                unitKerja
                label="Unit Kerja"
                handleChange={handleChange("unit_kerja")}
                value={formData.unit_kerja}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Tipe Kerja</strong>
              </p>
              <SelectText
                dataSelect={dataTipeKerja}
                label="Tipe Kerja"
                value={formData.tipe_kerja}
                handleChange={handleChange("tipe_kerja")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Tipe Karyawan</strong>
              </p>
              <SelectText
                dataSelect={dataTipeKaryawan}
                label="Tipe Karyawan"
                value={formData.tipe_karyawan}
                handleChange={handleChange("tipe_karyawan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Gaji Pokok</strong>
              </p>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.gaji_pokok}
                onChange={handleChange("gaji_pokok")}
                name="gaji_pokok"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Tunjangan</strong>
              </p>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.tunjangan}
                onChange={handleChange("tunjangan")}
                name="tunjangan"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Probation</strong>
              </p>
              <SelectText
                dataSelect={dataProbation}
                label="Probation"
                value={formData.probation}
                handleChange={handleChange("probation")}
              />
            </Grid>

            {formData.probation === "ya" ? (
              <Grid item xs={12} sm={6}>
                <p className="font-weight-bold h6 text-dark">
                  <strong>Probation Bulan</strong>
                </p>
                <SelectText
                  dataSelect={dataProbationBulan}
                  label="Probation Bulan"
                  value={formData.probation_bulan}
                  handleChange={handleChange("probation_bulan")}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}></Grid>
            )}

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Cuti Tahunan</strong>
              </p>
              <SelectText
                dataSelect={dataCutiTahunan}
                label="Cuti Tahunan"
                value={formData.cuti_tahunan}
                handleChange={handleChange("cuti_tahunan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Privilege</strong>
              </p>
              <div className={classes.root}>
                {chipActive.map((chip) => (
                  <Chip
                    key={chip.kode}
                    label={chip.value}
                    onClick={() => handleClickWaktuAbsensi(chip.kode)}
                    className={chip.active ? classes.active : classes.inactive}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h6 text-dark">
                <strong>Benefit Lainnya</strong>
              </p>
              <div className={classes.root}>
                {chipActive2.map((chip) => (
                  <Chip
                    key={chip.kode}
                    label={chip.value}
                    onClick={() => handleClickWaktuAbsensi2(chip.kode)}
                    className={chip.active ? classes.active : classes.inactive}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { dataDivisiList: state.karyawan.dataDivisiList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPenerimaanKerja: (params) => dispatch(addPenerimaanKerja(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPenerimaanKerja);

import { API, setAuthToken } from 'app/config/api';

export const GET_KRITERIA_PENILAIAN = 'GET_KRITERIA_PENILAIAN';
export const GET_PENILAIAN = 'GET_PENILAIAN';

export const getKriteriaPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/form-penilaian/keriteria-penilaian${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_KRITERIA_PENILAIAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_KRITERIA_PENILAIAN,
        payload: [],
      });
    }
  };
};

export const getKriteriaPenilaianList = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/form-penilaian/keriteria-penilaian/${params}`);
};

export const addKriteriaPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/form-penilaian/keriteria-penilaian/add`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getDetailKriteriaPenilaian = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/form-penilaian/keriteria-penilaian/detail/${params}`);
};

export const editKriteriaPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/form-penilaian/keriteria-penilaian/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delKriteriaPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(
      `/user/form-penilaian/keriteria-penilaian/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/form-penilaian/penilaian${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_PENILAIAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_PENILAIAN,
        payload: [],
      });
    }
  };
};

export const addPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/form-penilaian/penilaian/add`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const editPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/form-penilaian/penilaian/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delPenilaian = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(
      `/user/form-penilaian/penilaian/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getDetailPenilaian = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/form-penilaian/penilaian/detail/${params}`);
};

import React from "react";
import { TableCell, TableRow, Button, Avatar, Chip, Icon } from "@material-ui/core";
import foto_profile from "assets/page/karyawan/foto_profile.png";
import { makeStyles } from "@material-ui/core/styles";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Aksi from "../../../assets/component/Aksi.svg";
import { MatxMenu } from "matx";
import { Link } from "react-router-dom";
import SimpleMenu from "app/components/menu/Menu";
import { formatRupiah, formatTanggal } from "app/utils/globalFunction";

import Aksieye from "../../../assets/component/Aksieye.svg";
import BadgeSelect from "../select/BadgeSelect";
import { editStatusLembur } from "app/redux/actions/gaji/LemburAction";

const RenderTable = ({
  data,
  state,
  tableName,
  handleDelete,
  handleDetail,
  handleEdit,
  aksiClick,
  clickEdit,
  clickDetail,
  dataKosongSpan = 21,
  getData = null,
}) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const timeWithoutSeconds = (timeString) => {
    if (timeString) {
      return timeString.substring(0, 5);
    } else {
      return "loading...";
    }
  }; // "08:00"

  function formatDate(dateString) {
    const parts = dateString?.split("-");
    return `${parts[0]}/${parts[1]}/${parts[2]}`;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  const handleLemburStatusChange = async (event, item) => {
    const selectedStatus = event.target.value;
    await editStatusLembur({
      lembur_code: item.lembur_code,
      status: selectedStatus,
      waktu_mulai: item.waktu_mulai,
      waktu_akhir: item.waktu_akhir,
      keterangan: item.keterangan,
    });
    getData();
  }


  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) =>
        tableName === "absensilocation" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.created_by_name}</TableCell>
            <TableCell align="center" colSpan={3}>
              mobile developer
            </TableCell>
            <TableCell align="center" colSpan={3}>
              asdasd
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={2}>
              <MatxMenu menuButtonImg={Aksi}>
                <Button
                  onClick={() => handleDetail(item.created_by_name)}
                  variant="normal"
                >
                  Detail
                </Button>

                <Button onClick={() => handleEdit} variant="normal">
                  EditData
                </Button>

                <Button onClick={() => handleDelete} variant="normal">
                  Hapus
                </Button>
              </MatxMenu>
            </TableCell>
          </TableRow>
        ) : tableName === "absen" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.created_by_name}</TableCell>
            <TableCell align="center" colSpan={3}>
              mobile developer
            </TableCell>
            <TableCell align="center" colSpan={3}>
              asdasd
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={2}>
              <MatxMenu menuButtonImg={Aksi}>
                <Button
                  onClick={() => handleDetail(item.created_by_name)}
                  variant="normal"
                >
                  Detail
                </Button>

                <Button onClick={() => handleEdit} variant="normal">
                  EditData
                </Button>

                <Button onClick={() => handleDelete} variant="normal">
                  Hapus
                </Button>
              </MatxMenu>
            </TableCell>
          </TableRow>
        ) : tableName === "gaji" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={1}>
              <div
                className=" z-100 text-14 d-flex items-center"
                style={{ gap: "16px" }}
              >
                {item.foto_profile === "" ? (
                  <Avatar
                    src={foto_profile}
                    variant="square"
                    alt="foto profil"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: "white",
                    }}
                  />
                ) : (
                  <Avatar
                    variant="square"
                    src={item.foto_profile}
                    width={"50px"}
                  />
                )}

                {item?.name}
              </div>
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.unit_kerja}
            </TableCell>

            <TableCell align="center" colSpan={2}>
              {formatRupiah(item?.gaji_pokok)}
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {item?.nomer_rekening}
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Link to={`/Gaji/Detail/${item?.users_gaji_code}`}>
                <div className="dot-container">
                  <img src={Aksieye} alt="eye" />
                </div>
              </Link>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatGaji" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={1}>
              <div
                className=" z-100 text-14 d-flex items-center"
                style={{ gap: "16px" }}
              >
                {item.foto_profile === "" ? (
                  <Avatar
                    src={foto_profile}
                    variant="square"
                    alt="foto profil"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: "white",
                    }}
                  />
                ) : (
                  <Avatar
                    variant="square"
                    src={item.foto_profile}
                    width={"50px"}
                  />
                )}

                {item?.name}
              </div>
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {formatDate(item?.periode_awal)}-{formatDate(item?.periode_akhir)}
            </TableCell>

            <TableCell align="center" colSpan={2}>
              {formatRupiah(item?.total_gaji)}
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {item?.status === "belum lunas" ? (
                <div
                  className="tambah-button"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  onClick={() => handleEdit(item?.gaji_code)}
                >
                  <Button>Bayar Sekarang</Button>
                </div>
              ) : (
                item?.status
              )}
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkDetail={`/RiwayatGaji/Detail/${item.gaji_code}`}
                  noDelete
                  noEdit
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "bonus" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.name}</TableCell>
            <TableCell align="center" colSpan={3}>
              {item.jenis}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {formatRupiah(item.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tanggal}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkDetail={`/Bonus/Detail/${item.bonus_code}`}
                  linkEdit={`/Bonus/Edit/${item.bonus_code}`}
                  handleDelete={() => handleDelete(item.bonus_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatBonus" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>

            <TableCell colSpan={5}>{item?.jenis} </TableCell>
            <TableCell align="center" colSpan={3}>
              {formatRupiah(item?.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.tanggal}
            </TableCell>
            <TableCell colSpan={5}>{item?.keterangan}</TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  // handleDetail={}
                  // linkEdit={`/Bonus/Edit/${item.bonus_code}`}
                  handleDelete={() => handleDelete(item.bonus_code)}
                  handleEdit={() => handleEdit(item.bonus_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "tunjangan" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.name}</TableCell>
            <TableCell align="center" colSpan={4}>
              {item.jenis}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {formatRupiah(item.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item.tanggal}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkDetail={`/Tunjangan/Detail/${item.tunjangan_code}`}
                  linkEdit={`/Tunjangan/Edit/${item.tunjangan_code}`}
                  handleDelete={() => handleDelete(item.tunjangan_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatTunjangan" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={4}>{item?.jenis}</TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.tipe}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {formatRupiah(item?.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.tanggal}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item?.keterangan}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  handleDelete={() => handleDelete(item?.tunjangan_code)}
                  handleEdit={() => handleEdit(item?.tunjangan_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "reimburse" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={3}>{item.nama}</TableCell>
            <TableCell align="center" colSpan={3}>
              {item.jumlah_diproses}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.jumlah_diterima}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {formatRupiah(item.total_reimburse)}
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Button
                onClick={() => aksiClick(`reimburse/${item?.users_code}`)}
              >
                <div className="dot-container">
                  <img src={Aksieye} alt="eye" />
                </div>
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatReimburse" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={5}>{item?.jenis_reimburse}</TableCell>
            <TableCell align="center" colSpan={3}>
              {formatRupiah(item?.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.tanggal}
            </TableCell>
            <TableCell align="center" colSpan={5}>
              {item?.keterangan}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  handleDelete={() => handleDelete(item?.reimburse_code)}
                  handleEdit={() => handleEdit(item?.reimburse_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "pengajuanlembur" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item?.name}</TableCell>
            <TableCell align="center" colSpan={4}>
              {item?.keterangan}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item?.tanggal}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              <BadgeSelect
                item={item}
                handleChange={(event) => handleLemburStatusChange(event, item)}
                menus={[
                  {
                    label: "Diterima",
                    value: "diterima",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "diterima"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#72BE42",
                    backgroundColor: "#F3FFEC",
                  },
                  {
                    label: "Ditolak",
                    value: "ditolak",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "ditolak"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#FF5C58",
                    backgroundColor: "#FFEBEA",
                  },
                  {
                    label: "Diproses",
                    value: "diproses",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "diproses"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#FBC02A",
                    backgroundColor: "#FFF8E7",
                  },
                ]}
                badgeBackgroundColor={
                  item.status.toLowerCase() === "diterima"
                    ? "#72BE42"
                    : item.status.toLowerCase() === "ditolak"
                    ? "#FF5C58"
                    : "#FBC02A"
                }
                iconBackgroundColor={
                  item.status.toLowerCase() === "diterima"
                    ? "#68B03A"
                    : item.status.toLowerCase() === "ditolak"
                    ? "#F94A46"
                    : "#F6B40E"
                }
                renderValue={(selected) =>
                  selected === "diterima"
                    ? "Diterima"
                    : selected === "ditolak"
                    ? "Ditolak"
                    : "Diproses"
                }
                value={item.status.toLowerCase()}
              />
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  handleDetail={() => handleDetail(item?.lembur_code)}
                  // handleEdit={() => handleEdit(item?.lembur_code)}
                  noEdit
                  noDelete={true}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatlembur" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={3}>{item?.name}</TableCell>
            <TableCell align="center" colSpan={4}>
              {formatRupiah(item?.upah_lembur)}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item?.total_jam}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item?.tanggal}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              <BadgeSelect
                item={item}
                handleChange={(event) => handleLemburStatusChange(event, item)}
                menus={[
                  {
                    label: "Diterima",
                    value: "diterima",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "diterima"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#72BE42",
                    backgroundColor: "#F3FFEC",
                  },
                  {
                    label: "Ditolak",
                    value: "ditolak",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "ditolak"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#FF5C58",
                    backgroundColor: "#FFEBEA",
                  },
                  {
                    label: "Diproses",
                    value: "diproses",
                    icon: (
                      <Icon>
                        {item.status.toLowerCase() === "diproses"
                          ? "radio_button_checked"
                          : "radio_button_unchecked"}
                      </Icon>
                    ),
                    color: "#FBC02A",
                    backgroundColor: "#FFF8E7",
                  },
                ]}
                badgeBackgroundColor={
                  item.status.toLowerCase() === "diterima"
                    ? "#72BE42"
                    : item.status.toLowerCase() === "ditolak"
                    ? "#FF5C58"
                    : "#FBC02A"
                }
                iconBackgroundColor={
                  item.status.toLowerCase() === "diterima"
                    ? "#68B03A"
                    : item.status.toLowerCase() === "ditolak"
                    ? "#F94A46"
                    : "#F6B40E"
                }
                renderValue={(selected) =>
                  selected === "diterima"
                    ? "Diterima"
                    : selected === "ditolak"
                    ? "Ditolak"
                    : "Diproses"
                }
                value={item.status.toLowerCase()}
              />
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Button onClick={() => handleDetail(item?.lembur_code)}>
                <div className="dot-container">
                  <img src={Aksieye} alt="eye" />
                </div>
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatLemburGaji" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={4}>{formatRupiah(item?.upah_lembur)}</TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.total_jam}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item?.tanggal}
            </TableCell>
            <TableCell colSpan={6} align="center">
              {item?.keterangan}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  handleDelete={() => handleDelete(item?.lembur_code)}
                  handleEdit={() => handleEdit(item?.lembur_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "denda" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.name}</TableCell>
            <TableCell align="center" colSpan={4}>
              {formatRupiah(item.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkDetail={`/Denda/Detail/${item.denda_code}`}
                  linkEdit={`/Denda/Edit/${item.denda_code}`}
                  handleDelete={() => handleDelete(item.denda_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "riwayatDenda" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={5}>{item.nama_denda}</TableCell>
            <TableCell align="center" colSpan={3}>
              {formatRupiah(item.nominal)}
            </TableCell>
            <TableCell align="center" colSpan={5}>
              {item.tanggal}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.keterangan}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  handleDelete={() => handleDelete(item?.denda_code)}
                  handleEdit={() => handleEdit(item?.denda_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "potongan" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={2}>
              <div
                className=" z-100 text-14 d-flex items-center"
                style={{ gap: "16px" }}
              >
                {item.foto_profile === "" ? (
                  <Avatar
                    src={foto_profile}
                    variant="square"
                    alt="foto profil"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: "white",
                    }}
                  />
                ) : (
                  <Avatar
                    variant="square"
                    src={item.foto_profile}
                    width={"50px"}
                  />
                )}

                {item?.name}
              </div>
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {formatRupiah(item.potongan)}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item.jumlah_izin}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item.jumlah_tidak_hadir}
            </TableCell>

            <TableCell align="center" colSpan={3}>
              <Button>
                <Link to={`/Potongan/Detail/${item.potongan_code}`}>
                  <div className="dot-container">
                    <img src={Aksieye} alt="eye" />
                  </div>
                </Link>
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "aturharilibur" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={2}>{index + 1}</TableCell>
            <TableCell colSpan={3}>{item.nama}</TableCell>
            <TableCell align="center" colSpan={6}>
              {item.tanggal}
            </TableCell>

            <TableCell align="center" colSpan={5}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkEdit={`/AturHariLibur/Edit/${item.atur_hari_libur_code}`}
                  noDetail={true}
                  handleDelete={() => handleDelete(item.atur_hari_libur_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "aturabsensi" ? (
          <>
            <TableRow hover key={index}>
              <TableCell colSpan={1}>{index + 1}</TableCell>
              <TableCell colSpan={2}>{item.nama}</TableCell>
              <TableCell align="center" colSpan={4}>
                {timeWithoutSeconds(item.jam_awal_masuk)} -
                {timeWithoutSeconds(item.jam_akhir_masuk)}
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {timeWithoutSeconds(item.jam_awal_masuk)} -
                {timeWithoutSeconds(item.jam_awal_keluar)}
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {timeWithoutSeconds(item.jam_akhir_masuk)} -
                {timeWithoutSeconds(item.jam_akhir_keluar)}
              </TableCell>

              <TableCell align="center" colSpan={2}>
                <Button className="karyawan-aksi-dot elevation-z0">
                  <SimpleMenu
                    item={item}
                    linkEdit={`/AturAbsensi/Edit/${item.atur_absensi_code}`}
                    noDetail={true}
                    handleDelete={() => handleDelete(item.atur_absensi_code)}
                  />
                </Button>
              </TableCell>
            </TableRow>
          </>
        ) : tableName === "titikabsensi" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={6}>{item.nama_kantor}</TableCell>
            <TableCell align="center" colSpan={8}>
              {item.alamat}
            </TableCell>

            <TableCell align="center" colSpan={2}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkEdit={`/TitikAbsensi/Edit/${item.titik_absensi_code}`}
                  noDetail={true}
                  handleDelete={() => handleDelete(item.titik_absensi_code)}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "kriteriapenilaian" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={3}>{item.created_by_name}</TableCell>
            <TableCell align="center" colSpan={4}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              {item.tgl}
            </TableCell>

            <TableCell align="center" colSpan={4}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkEdit={`/KriteriaPenilaian/Edit/${item.created_by_name}`}
                  noDetail={true}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : tableName === "penilaian" ? (
          <TableRow hover key={index}>
            <TableCell colSpan={1}>{index + 1}</TableCell>
            <TableCell colSpan={3}>{item.created_by_name}</TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {item.tgl}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Button className="karyawan-aksi-dot elevation-z0">
                <SimpleMenu
                  item={item}
                  linkEdit={`/Penilaian/Edit/${item.created_by_name}`}
                  noDetail={true}
                />
              </Button>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow hover key={index}>
            <TableCell className="" align="center" colSpan={1}>
              <span className="karyawan-tabel-body-txt">
                {index + 1 + handleNumbering()}
              </span>
            </TableCell>
            <TableCell className="pl-3" colSpan={3}>
              <span className="karyawan-tabel-body-txt">{item.nama}</span>
            </TableCell>
            <TableCell className="" align="center" colSpan={3}>
              <span className="karyawan-tabel-body-txt">{item.posisi}</span>
            </TableCell>
            <TableCell className="" align="center" colSpan={4}>
              <span className="karyawan-tabel-body-txt">{item.tanggal}</span>
            </TableCell>
            <TableCell className="" align="center" colSpan={3}>
              <span
                className={
                  item.status === "Diproses"
                    ? "dashboard-status-diproses"
                    : item.status === "Diizinkan"
                    ? "dashboard-status-diizinkan"
                    : "dashboard-status-ditolak"
                }
              >
                <span className="">{item.status}</span>
              </span>
            </TableCell>
            <TableCell className="" align="center" colSpan={2}>
              <Button className="karyawan-aksi-dot elevation-z0">
                {/* <SimpleMenu item={item} /> */}
                <VisibilityOutlinedIcon className="dashboard-green-eye" />
              </Button>
            </TableCell>
          </TableRow>
        )
      )
  ) : (
    <TableRow hover>
      <TableCell colSpan={dataKosongSpan} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTable;

import { useState } from 'react';
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  MenuItem,
  IconButton,
} from '@material-ui/core';

import { capitalizeString, showAlert } from 'app/utils/globalFunction';
import { MatxMenu } from 'matx';
import { MoreHoriz } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Swal from 'sweetalert2';
import { delJenisTunjangan } from 'app/redux/actions/lainnya/JenisTunjanganAction';

const RenderTable = ({ data, state, getData }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const handleDelete = (code) => {
    delJenisTunjangan(code).then(() => {
      let timerInterval;
      Swal.fire({
        title: 'Sedang diproses...',
        html: 'tunggu dalam <b></b>.',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          setTimeout(() => {
            clearInterval(timerInterval);
            Swal.fire(
              'Success!',
              'Data Jenis Tunjangan berhasil dihapus',
              'success'
            );
          }, 3000);
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 400);
        },
      });
    });
    getData();
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell className="pl-3" align="center" colSpan={1}>
            <span className="">{index + 1 + handleNumbering()}</span>
          </TableCell>
          <TableCell className="" colSpan={2}>
            <span className="">{item.jenis}</span>
          </TableCell>
          <TableCell className="pl-3" align="center" colSpan={2}>
            <span className="">{capitalizeString(item.tipe)}</span>
          </TableCell>
          <TableCell className="pl-3" align="center" colSpan={1}>
            <MatxMenu
              menuButton={
                <div className="flex items-center">
                  <IconButton size="small">
                    <MoreHoriz />
                  </IconButton>
                </div>
              }
              horizontalPosition="right"
            >
              <Link to={`/lainnya/tunjangan/edit/${item.jenis_tunjangan_code}`}>
                <MenuItem className=" mb-3">
                  <span className="color-green">
                    <CreateOutlinedIcon />
                  </span>
                  <span className="pl-3 color-green">Edit Data</span>
                </MenuItem>
              </Link>
              <MenuItem
                className="text-red"
                onClick={() =>
                  handleDelete(item.jenis_tunjangan_code, item.jenis)
                }
              >
                <span className="color-red">
                  <DeleteOutlineOutlinedIcon />
                </span>
                <span className="pl-3 color-red">Hapus Data</span>
              </MenuItem>
            </MatxMenu>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={6} align="center">
        {' '}
        Data kosong{' '}
      </TableCell>
    </TableRow>
  );
};

const TableTunjangan = ({ dataTunjangan, getData }) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} align="center">
              <span className="">No</span>
            </TableCell>
            <TableCell colSpan={2} className="">
              <span className="">Jenis</span>
            </TableCell>
            <TableCell colSpan={2} className="" align="center">
              <span className="">Tipe</span>
            </TableCell>
            <TableCell colSpan={1} className="" align="center">
              <span className="">Aksi</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable data={dataTunjangan} state={state} getData={getData} />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataTunjangan?.length ? dataTunjangan?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={'From'}
        page={state.page}
        backIconButtonProps={{
          'aria-label': 'Previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next page',
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default TableTunjangan;

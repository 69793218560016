import MenuPenerimaanKerja from "./MenuPenerimaanKerja";
import { useState } from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from "@material-ui/core";
const RenderTable = ({ data, state, getData, handleDelete }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };
  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            style={{ color: "#0A0A0A" }}
            className="text-14 pl-3"
            align="center"
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell
            style={{ color: "#0A0A0A" }}
            className="text-14 "
            colSpan={5}
          >
            {item.nama_karyawan}
          </TableCell>
          <TableCell
            style={{ color: "#0A0A0A" }}
            className="text-14 "
            colSpan={3}
          >
            {item.nama_signer}
          </TableCell>
          <TableCell
            style={{ color: "#0A0A0A" }}
            className="text-14 "
            colSpan={3}
          >
            {item.created_at}
          </TableCell>
          <TableCell
            style={{ color: "#0A0A0A" }}
            className="text-14 pl-3"
            align="center"
            colSpan={2}
          >
            <MenuPenerimaanKerja
              item={item}
              path={`/administrasi/penerimaan_kerja/edit/${item.penerimaan_kerja_code}`}
              handleDelete={() => handleDelete(item.penerimaan_kerja_code)}
            />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={14} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const TablePenerimaanKerja = ({ data, getData, handleDelete }) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={1}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              No
            </TableCell>
            <TableCell
              colSpan={5}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
            >
              Nama Karyawan Kerja
            </TableCell>
            <TableCell
              colSpan={3}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
            >
              Nama Signer
            </TableCell>
            <TableCell
              colSpan={3}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
            >
              Tanggal Dibuat
            </TableCell>
            <TableCell
              colSpan={2}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable
            data={data}
            state={state}
            getData={getData}
            handleDelete={handleDelete}
          />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16 my-7"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={"From"}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default TablePenerimaanKerja;

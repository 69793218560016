import { API, setAuthToken } from "app/config/api";

export const GET_DATA_ATUR_HARI_LIBUR = "GET_DATA_ATUR_HARI_LIBUR";
export const GET_DETAIL_ATUR_HARI_LIBUR = "GET_DETAIL_ATUR_HARI_LIBUR";
export const DEL_ATUR_HARI_LIBUR = "DEL_ATUR_HARI_LIBUR";
export const GET_DATA_ATUR_LEMBUR = "GET_DATA_ATUR_LEMBUR";
export const GET_DATA_ATUR_ABSENSI = "GET_DATA_ATUR_ABSENSI";
export const GET_DETAIL_ATUR_ABSENSI = "GET_DETAIL_ATUR_ABSENSI";
export const DEL_ATUR_ABSENSI = "DEL_ATUR_ABSENSI";
export const GET_DATA_ATUR_TITIK_ABSENSI = "GET_DATA_ATUR_TITIK_ABSENSI";
export const GET_DETAIL_ATUR_TITIK_ABSENSI = "GET_DETAIL_ATUR_TITIK_ABSENSI";
export const DEL_ATUR_TITIK_ABSENSI = "DEL_ATUR_TITIK_ABSENSI";
export const DEL_ATUR_LEMBUR = "DEL_ATUR_LEMBUR";

export const getAturHariLibur = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/aturan/harilibur${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_ATUR_HARI_LIBUR,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_ATUR_HARI_LIBUR,
          payload: [],
        });
      });
  };
};

export const getDetailAturHariLibur = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/aturan/harilibur/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_ATUR_HARI_LIBUR,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_ATUR_HARI_LIBUR,
        payload: [],
      });
    }
  };
};

export const delAturHariLibur = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/aturan/harilibur/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_ATUR_HARI_LIBUR,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addAturHariLibur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/aturan/harilibur/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editAturHariLibur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/aturan/harilibur/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const getAturLembur = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/aturan/lembur`)
      .then((res) => {
        dispatch({
          type: GET_DATA_ATUR_LEMBUR,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_ATUR_LEMBUR,
          payload: [],
        });
      });
  };
};

export const editAturLembur = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/aturan/lembur/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const getAturAbsensi = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/aturan/absensi${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_ATUR_ABSENSI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_ATUR_ABSENSI,
          payload: [],
        });
      });
  };
};

export const getDetailAturAbsensi = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/aturan/absensi/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_ATUR_ABSENSI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_ATUR_ABSENSI,
        payload: [],
      });
    }
  };
};

export const addAturAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/aturan/absensi/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editAturAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/aturan/absensi/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const delAturAbsensi = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/aturan/absensi/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_ATUR_ABSENSI,
        payload: code,
      });
    }
    return res.data;
  };
};

export const getAturTitikAbsensi = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/aturan/titik_absensi${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_ATUR_TITIK_ABSENSI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_ATUR_TITIK_ABSENSI,
          payload: [],
        });
      });
  };
};

export const getDetailAturTitikAbsensi = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/aturan/titik_absensi/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_ATUR_TITIK_ABSENSI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_ATUR_TITIK_ABSENSI,
        payload: [],
      });
    }
  };
};

export const delAturTitikAbsensi = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/aturan/titik_absensi/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_ATUR_TITIK_ABSENSI,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addAturTitikAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/aturan/titik_absensi/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editAturTitikAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/aturan/titik_absensi/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import {
  getPotongan,
  delPotongan,
} from "app/redux/actions/gaji/PotonganAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import { MatxMenu } from "matx";
import Aksi from "../../../assets/component/Aksi.svg";
import { DatePickerComponent, RenderTable, SearchKaryawan } from "app/components";

const Potongan = ({ dataPotongan, getPotongan, delPotongan }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState("");
  const [searchTgl, setSearchTgl] = useState(new Date());

  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    var dateFormat = JSON.stringify(searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    dateFormat = dateFormat.split("-").reverse().join("-");
    dateFormat = `?bulan_tahun=${dateFormat}`;

    let params =
      search === ""
        ? dateFormat
        : `?search=${search}&${dateFormat.slice(1, 20)}`;

    getPotongan(params);
  };

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  const handleDateChange = (date) => {
    setSearchTgl(date);
  };

  useLayoutEffect(() => {
    getData();
  }, [searchTgl]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleCloseEdit = () => {
    setState(
      {
        ...state,
        opneModal: false,
      },
      () => {
        getData();
      }
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delPotongan(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getPotongan();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleEdit = (code) => {
    window.location.href = `/mom/edit/${code}`;
  };

  const handleEditData = () => {
    setState({
      editAbsensi: true,
    });
  };

  const handleDeleteData = () => {
    setState({
      deleteAbsensi: true,
    });
  };

  const handleDetail = (item) => {
    setState({
      nameClick: item,
    });
  };

  console.log(dataPotongan);

  const renderDetail = () => {
    const { nameClick } = state;

    const foundData = dataPotongan.find(
      (item) => item.created_by_name === nameClick
    );

    return (
      <>
        <h1>Detail Gaji</h1>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Karyawan</h5>
                <div>{foundData.created_by_name}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Unit Kerja</h5>
                <div>{foundData.created_by_name}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Titik Absensi</h5>
                <div>{foundData.created_by_name}</div>
              </Grid>
              <Grid container sm={6} xs={12}>
                <Grid item sm={3} xs={6}>
                  <h5>Jam Masuk</h5>
                  <div>{foundData.created_by_name}</div>
                </Grid>
                <Grid item sm={3} xs={6}>
                  <h5>Jam Masuk</h5>
                  <div>{foundData.created_by_name}</div>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        </SimpleCard>
      </>
    );
  };

  const handleAdd = () => {
    const { history } = props;
    history.push("/mom/tambah");
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={1}
        justify="space-between"
        className="my-4  mb-8"
      >
        <h1 className="mt-4 font-semibold text-25 ">Potongan</h1>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <SearchKaryawan
              otherClasses='karyawan-top-search'
              handleChange={(e) => setSearch(e.target.value)}
              value={search}
              handleKeyDown={submitSearch}
            />
            <Grid item className="d-flex align-items-end justify-content-end">
              <DatePickerComponent
                date={searchTgl}
                handleDate={handleDateChange}
                name="tanggal"
                search
                tipe="MMMM yyyy"
                addPrevNext
              />
            </Grid>
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>No</TableCell>
                  <TableCell colSpan={2}>Nama</TableCell>
                  <TableCell align="center" colSpan={4}>
                    Potongan
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Jumlah Izin
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Jumlah Tidak Hadir
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTable
                  state={state}
                  data={dataPotongan}
                  tableName="potongan"
                  aksiClick={handleDetail}
                  dataKosongSpan={19}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataPotongan?.length ? dataPotongan?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>

        {state.tambahAbsensi && <AbsensiForm formName="tambah" />}
        {state.editAbsensi && <AbsensiForm />}
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataPotongan: state.gaji.dataPotongan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPotongan: (params) => dispatch(getPotongan(params)),
    delPotongan: (params) => dispatch(delPotongan(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Potongan);

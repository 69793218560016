// import {
//   Select,
//   FormControl,
//   MenuItem,
//   InputLabel,
//   Icon,
// } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import { getSigner } from "app/redux/actions/SignerActions";
import { FormHelperText } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import { getAllKaryawan } from "app/redux/actions/KaryawanActions";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { getUnitKerja } from "app/redux/actions/UnitKerjaAction";
import { getKopSurat } from "app/redux/actions/administrasi/KopSuratAction";
import { getOffice } from "app/redux/actions/OfficeActions";
import { getListDenda } from "app/redux/actions/lainnya/JenisDendaAction";
import { getListBonus } from "app/redux/actions/lainnya/JenisBonusAction";
import { getJenisTunjangan } from "app/redux/actions/lainnya/JenisTunjanganAction";
import { getListReimburse } from "app/redux/actions/lainnya/JenisReimburseAction";
import { getMitra } from "app/redux/actions/lainnya/MitraAction";

function SelectText({
  dataSelect = [],
  label = "",
  dataKaryawan,
  getAllKaryawan,
  karyawan,
  getUnitKerja,
  dataUnitKerja,
  unitKerja,
  getKopSurat,
  dataAdministrasi,
  kopSurat,
  handleChange,
  signer,
  getSigner,
  dataSigner,
  office,
  getOffice,
  dataOffice,
  value,
  jenisDenda,
  jenisBonus,
  isJenisTunjangan,
  tipeTunjangan,
  getJenisTunjangan,
  jenisTunjangan,
  selectedTipe = "",
  denda,
  jenisReimburse,
  mitra,
  getMitra,
  dataMitra,
  search,
  insertData,
}) {
  const [dataJenisDenda, setDataJenisDenda] = useState([]);
  const [dataJenisBonus, setDataJenisBonus] = useState([]);
  const [dataJenisReimburse, setDataJenisReimburse] = useState([]);
  const [dataJenisTunjangan, setDataJenisTunjangan] = useState([]);

  const dataTipeTunjangan = ["harian", "bulanan", "tahunan"];

  const [labelName, setLabelName] = useState("");

  if (karyawan) {
    const getData = () => {
      getAllKaryawan();
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (unitKerja) {
    const getData = () => {
      getUnitKerja();
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (kopSurat) {
    const getData = () => {
      var params = `?search=`;
      getKopSurat(params);
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (signer) {
    const getData = () => {
      getSigner();
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (mitra) {
    const getData = () => {
      getMitra();
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (office) {
    const getData = () => {
      getOffice();
    };
    useEffect(() => {
      getData();
    }, []);
  }

  if (jenisDenda) {
    const params = ``;
    const getData = () => {
      getListDenda().then((res) => {
        const data = res.data?.data;
        setDataJenisDenda(data);
      });
    };

    useLayoutEffect(() => {
      getData();
    }, []);
  }

  if (jenisBonus) {
    const params = ``;

    const getData = () => {
      getListBonus(params).then((res) => {
        const data = res.data?.data;
        setDataJenisBonus(data);
      });
    };

    useLayoutEffect(() => {
      getData();
    }, []);
  }

  if (jenisReimburse) {
    const params = ``;

    const getData = () => {
      getListReimburse(params).then((res) => {
        const data = res.data?.data;
        setDataJenisReimburse(data);
      });
    };

    useLayoutEffect(() => {
      getData();
    }, []);
  }

  const data = karyawan
    ? dataKaryawan
    : unitKerja
    ? dataUnitKerja
    : kopSurat
    ? dataAdministrasi
    : signer
    ? dataSigner
    : mitra
    ? dataMitra
    : office
    ? dataOffice
    : jenisDenda
    ? dataJenisDenda
    : jenisBonus
    ? dataJenisBonus
    : jenisReimburse
    ? dataJenisReimburse
    : isJenisTunjangan
    ? jenisTunjangan
    : tipeTunjangan
    ? dataTipeTunjangan
    : dataSelect;

  // const getDataTunjangan = (a) => {
  //   let params = `?tipe=${a}`;
  //   if (search) {
  //     params = `?tipe=`;
  //   }
  //   getJenisTunjangan(params);
  // };
  // useEffect(() => {
  //   if (selectedTipe !== "") {
  //     getDataTunjangan(selectedTipe);
  //   } else {
  //     getDataTunjangan();
  //   }
  // }, [selectedTipe]);

  // if (search && isJenisTunjangan) {
  //   useEffect(() => {
  //     getDataTunjangan();
  //   }, []);
  // }

  const getDataTunjangan = (a) => {
    let params = `?tipe=${a}`;
    if (search) {
      params = `?tipe=`;
    }
    getJenisTunjangan(params);
  };

  useEffect(() => {
    if (selectedTipe !== "") {
      if (isJenisTunjangan) {
        // add a check for isJenisTunjangan
        getDataTunjangan(selectedTipe);
      }
    } else {
      if (isJenisTunjangan) {
        // add a check for isJenisTunjangan
        getDataTunjangan();
      }
    }
  }, [selectedTipe, isJenisTunjangan]); // add isJenisTunjangan to the dependencies array

  if (search && isJenisTunjangan) {
    useEffect(() => {
      getDataTunjangan();
    }, []);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        backgroundColor: search ? "#ffffff" : "#fbfbfb ",
        height: search ? "38px" : "46px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: search ? null : "#e6e9ed",
        borderWidth: search ? null : "1px",
      },
      "& .MuiSelect-select": {
        fontWeight: "400",
        fontSize: "14px",
        color: "#0A0A0A",
      },
      "& .MuiInputLabel-outlined": {
        color: "#9196ab",
        fontSize: search ? null : "14px",
        top: search ? "-7px" : "18px",
        left: search ? null : "14px",
        transform: search ? null : "translate(0, 0) scale(1)",
        '&[data-shrink="true"]': {
          top: search ? "null" : "-6px",
          fontSize: search ? null : "12px",
          fontWeight: search ? null : "400",
          color: "#6B7280",
          transform: search ? null : "translate(0, 0) scale(1)",
        },
      },
    },
  }));
  const classes = useStyles();

  const menuList = data?.map((data, index) => {
    const valueList = karyawan
      ? data.users_code
      : unitKerja
      ? data.unit_kerja_code
      : kopSurat
      ? data.kop_surat_code
      : signer
      ? data.signer_code
      : mitra
      ? data.pelanggan_code
      : office
      ? data.office_code
      : jenisDenda
      ? data.jenis_denda_code
      : jenisBonus
      ? data.jenis_bonus_code
      : jenisReimburse
      ? data.jenis_reimburse_code
      : isJenisTunjangan
      ? data.jenis_tunjangan_code
      : tipeTunjangan
      ? data
      : insertData
      ? data.value
      : data;

    const name =
      karyawan || signer
        ? data.name
        : unitKerja
        ? data.unit_kerja
        : kopSurat
        ? data.judul
        : office
        ? data.office_name
        : jenisDenda
        ? data.nama_denda
        : jenisBonus
        ? data.jenis
        : jenisReimburse
        ? data.jenis_reimburse
        : isJenisTunjangan
        ? data.jenis
        : tipeTunjangan
        ? data
        : mitra
        ? data.nama
        : insertData
        ? data.name
        : data;

    return (
      <MenuItem key={index} text={name} value={search ? name : valueList}>
        {name}
      </MenuItem>
    );
  });

  return (
    <div className="w-full">
      <FormControl
        variant="outlined"
        className={classes.root}
        InputLabelProps={{ classes: { root: classes.inputLabel } }}
      >
        <InputLabel shrink={false}>{value ? null : label}</InputLabel>

        <Select
          value={value}
          onChange={handleChange}
          className=" w-full"
          IconComponent={KeyboardArrowDownIcon}
          variant="outlined"
        >
          {search && (
            <MenuItem key="semua" value="">
              Lihat Semua
            </MenuItem>
          )}
          {menuList}
        </Select>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataKaryawan: state.karyawan.allData,
    dataUnitKerja: state.lainnya.dataUnitKerja,
    dataAdministrasi: state.administrasi.dataAdministrasi,
    dataSigner: state.lainnya.dataSigner,
    dataMitra: state.lainnya.dataMitra,

    dataOffice: state.lainnya.dataOffice,
    jenisTunjangan: state.lainnya.jenisTunjangan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllKaryawan: () => dispatch(getAllKaryawan()),
    getUnitKerja: () => dispatch(getUnitKerja()),
    getSigner: () => dispatch(getSigner()),
    getMitra: () => dispatch(getMitra()),

    getOffice: () => dispatch(getOffice()),
    getKopSurat: (params) => dispatch(getKopSurat(params)),
    getJenisTunjangan: (params) => dispatch(getJenisTunjangan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectText);

import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export default function AbsensiSelect({
  dataSelect = [
    {
      value: 0,
      text: "Lihat Semua",
    },
    {
      value: 1,
      text: "Full Time",
    },
    {
      value: 2,
      text: "Internship",
    },
  ],
  state = "",
  setState = "",
  size = "small",
  label = "",
  style = "karyawan-top-select",
  styleMenu = { background: "#ffffff" },
  name = "",
  scaleY = "1",
}) {
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <FormControl size={size} variant="outlined" className={style}>
        <InputLabel htmlFor="outlined-age-native-simple">
          <span
            style={{ color: "#555c64b7" }}
            className="karyawan-top-select-placeholder my-auto bg-white"
          >
            {label}
          </span>
        </InputLabel>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          onChange={handleChange}
          className={style}
          value={state[name]}
          inputProps={{
            name,
          }}
          size={size}
          style={{
            transform: `scaleY(${scaleY})`,
          }}
        >
          {dataSelect.map((data) => (
            <MenuItem
              key={data.value}
              value={data.value}
              text={data.text}
              style={styleMenu}
            >
              {data.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

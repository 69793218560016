import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import TableHandover from "./component/TableHandover";

import Swal from "sweetalert2";

import {
  getHandover,
  delHandover,
} from "app/redux/actions/administrasi/HandoverAction";
import { connect } from "react-redux";

const Handover = ({ dataAdministrasi, getHandover, delHandover }) => {
  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    var params = `?search=${search}`;
    getHandover(params);
  };
  useEffect(() => {
    getData();
  }, []);

  const onEnter = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delHandover(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Form Handover
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/administrasi/Handover/Tambah">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="d-flex items-center py-3 mb-5"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              width: "270px",
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              placeholder="Cari Penerima atau Pemberi"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={onEnter}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>
        <TableHandover data={dataAdministrasi} handleDelete={handleDelete} />
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAdministrasi: state.administrasi.dataAdministrasi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHandover: (params) => dispatch(getHandover(params)),
    delHandover: (code) => dispatch(delHandover(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Handover);

import {
  GET_DATA_ABSEN,
  GET_DATA_LOKASI_ABSENSI,
  GET_DATA_TITIK_ABSENSI,
  GET_DETAIL_LOKASI_ABSENSI,
  GET_RIWAYAT_ABSENSI_KARYAWAN,
  GET_DETAIL_KEHADIRAN,
} from '../actions/AbsenAction';

const initialState = {
  dataAbsen: [],
  dataLokasiAbsensi: [],
  dataDetailLokasiAbsensi: {},
  dataListTitikAbsensi: [],
  dataRiwayatAbsensiKaryawan: {
    data: [],
    name: { name: '' },
  },
  dataDetailKehadiran: {},
};

const AbsenReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_ABSEN: {
      return {
        ...state,
        dataAbsen: action.payload,
      };
    }
    case GET_DATA_LOKASI_ABSENSI: {
      return {
        ...state,
        dataLokasiAbsensi: action.payload,
      };
    }
    case GET_DETAIL_LOKASI_ABSENSI: {
      return {
        ...state,
        dataDetailLokasiAbsensi: action.payload,
      };
    }
    case GET_DATA_TITIK_ABSENSI: {
      return {
        ...state,
        dataListTitikAbsensi: action.payload,
      };
    }
    case GET_RIWAYAT_ABSENSI_KARYAWAN: {
      return {
        ...state,
        dataRiwayatAbsensiKaryawan: action.payload,
      };
    }
    case GET_DETAIL_KEHADIRAN: {
      return {
        ...state,
        dataDetailKehadiran: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default AbsenReducer;

import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { formatTanggal } from 'app/utils/globalFunction';
import {
  getRiwayatGaji,
  cetakRiwayatGaji,
  delRiwayatGaji,
  addRiwayatGajiBayar,
} from 'app/redux/actions/gaji/RiwayatGajiAction';
import AbsensiForm from '../absensi/AbsensiForm';
import { Link, useHistory } from 'react-router-dom';
import { MatxMenu } from 'matx';
import Aksi from '../../../assets/component/Aksi.svg';
import RenderTableRiwayatGaji from './component/RenderTableRiwayatGaji';
import { formatRupiah } from 'app/utils/globalFunction';
import { SearchKaryawan, SelectText, SelectWithTextAndValue } from 'app/components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';

const riwayatGaji = ({
  dataRiwayatGaji,
  getRiwayatGaji,
  cetakRiwayatGaji,

  delRiwayatGaji,
  addRiwayatGajiBayar,
}) => {
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    search: '',
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: '',
    nameClick: '',
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,
    bulan: '',

    dataLanguage: [
      {
        label: 'Indonesia',
        value: 'ind',
      },
      {
        label: 'English',
        value: 'eng',
      },
    ],
    bahasa: '',
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [bulan, setBulan] = useState({ bulan: '' });
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    let params = `?search=${search}&status=${status}&bulan=${bulan.bulan}`;

    getRiwayatGaji(params);
  };

  useEffect(() => {
    getData();
  }, [status, bulan]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleBayar = (id) => {
    Swal.fire({
      title: 'Bayar Sekarang',
      text: 'Apakah kamu yakin ?',
      showCancelButton: true,
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal',
      icon: 'warning',
    }).then((res) => {
      if (res.isConfirmed) {
        addRiwayatGajiBayar({
          gaji_code: [id],
        })
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: 'Berhasil',
                text: 'Success! Gaji berhasil dibayar dan dikirimkan melalui email!',
                timer: 5000,
                icon: 'success',
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: 'gagal',
              text: 'Gagal Bayar, coba beberapa saat lagi',
              timer: 2000,
              icon: 'error',
            });
          });
      }
    });
  };

  // const handleBayar = (id) => {

  //   addRiwayatGajiBayar({
  //     gaji_code: [id],
  //   })
  //     .then((res) => {
  //       Swal.fire("Success!", "Gaji Berhasil Dibayar", "success");
  //       getData();
  //     })
  //     .catch((err) => {
  //       let error = err?.response?.data;
  //       Swal.fire(
  //         Array.isArray(error?.data)
  //           ? error?.data[0]
  //           : "Gagal Bayar, coba beberapa saat lagi",
  //         false
  //       );
  //     });
  // };

  const dataFilter = dataRiwayatGaji.filter(
    (item) => typeof item === 'object' && item !== null
  );

  const TotalGaji = () => {
    let total = 0;
    dataFilter?.map((item) => {
      total += Number(item?.total_gaji);
    });
    return total;
  };

  const pengurangan = () => {
    let total = 0;
    dataFilter?.map((item) => {
      total += Number(item?.pengurangan);
    });
    return total;
  };

  const tambahan = () => {
    let total = 0;
    dataFilter?.map((item) => {
      total += Number(item?.tambahan);
    });
    return total;
  };

  const statusSearch = ['lunas', 'belum lunas'];
  const handleUnduh = (code) => {
    let timerInterval;
    Swal.fire({
      title: 'Proses Cetak Slip',
      html: 'Silahkan tunggu dalam <b></b> milidetik.',
      timer: 15000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('b');
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 1000);

        cetakRiwayatGaji(code)
          .then((res) => {
            console.log(res.data.pdf_path);

            // history.push({
            //   pathname: '/redirect',
            //   state: { url: res.data.pdf_path },
            // });
            window.open(res.data.pdf_path, '_blank');
            Swal.close();
            getData();
          })
          .catch((err) => {
            let error = err?.response?.data;
            Swal.fire(
              Array.isArray(error?.data)
                ? error?.data[0]
                : 'Gagal Cetak, coba beberapa saat lagi',
              false
            );
          });
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">Riwayat Gaji</h1>

        <Button
          variant="outlined"
          color="primary"
          className="batal-lembur-button  px-8 py-3  mr-2"
        >
          {/* <AddIcon color="primary" /> */}
          <DescriptionIcon />
          <span className="ml-2">Report</span>
        </Button>
      </Grid>

      <div style={{ marginBottom: '30px' }}>
        <SimpleCard
          loading={false}
          className="riwayat-gaji-card "
          currency=""
          saldo=""
          heightInput={200}
        >
          <Grid container spacing={4} justify="space-between">
            <div className="half-container px-8 py-3">
              <Grid item sm={12} xs={24} spacing={3}>
                <h5>Total Gaji Karyawan</h5>

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{
                    marginBlock: '5px',
                    marginBottom: '20px',
                  }}
                >
                  <div className="money-icon">
                    <AttachMoneyIcon color="primary" />
                  </div>
                  <h1
                    style={{
                      margin: '0px',
                      marginLeft: '15px',
                    }}
                  >
                    {formatRupiah(TotalGaji())}
                  </h1>
                </Grid>

                <Link className="tambah-button" to="/RiwayatGaji/Bayar">
                  <Button>Bayar Sekarang</Button>
                </Link>
              </Grid>
            </div>
            <div className="half-container-right px-8 py-3">
              <div
                style={{
                  borderLeft: '1px rgba(170, 170, 170, 0.808) solid',
                  position: 'absolute',
                  left: '0',
                  height: '90%',
                }}
              />
              <div className="green-gaji-card">
                <Grid item sm={10} xs={20}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{
                      marginBottom: '35px',
                    }}
                  >
                    <div className="up-arrow-icon">
                      <ArrowUpwardIcon color="primary" />
                    </div>
                    <h2
                      style={{
                        margin: '0px',
                        marginLeft: '5px',
                      }}
                    >
                      Tambahan
                    </h2>
                  </Grid>

                  <h5>Jumlah Tambahan</h5>
                  <h3>{formatRupiah(tambahan())}</h3>
                </Grid>
              </div>
              <div className="green-gaji-card">
                <Grid item sm={10} xs={20}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{
                      marginBottom: '35px',
                    }}
                  >
                    <div className="down-arrow-icon">
                      <ArrowDownwardIcon color="error" />
                    </div>
                    <h2
                      style={{
                        margin: '0px',
                        marginLeft: '5px',
                      }}
                    >
                      Pengurangan
                    </h2>
                  </Grid>

                  <h5>Jumlah Pengurangan</h5>
                  <h3>{formatRupiah(pengurangan())}</h3>
                </Grid>
              </div>
            </div>
          </Grid>
        </SimpleCard>
      </div>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: 'flex-end', gap: '20px' }}
          >
            <SearchKaryawan
              otherClasses='karyawan-top-search'
              handleChange={(e) => setSearch(e.target.value)}
              value={search}
              handleKeyDown={submitSearch}
            />

            <Grid item xs={3} sm={2} className="d-flex align-items-end">
              <SelectText
                search
                dataSelect={statusSearch}
                label="Status"
                handleChange={handleStatus}
                value={status}
              />
            </Grid>
            <Grid item xs={3} sm={2} className="d-flex align-items-end">
              <SelectWithTextAndValue
                dataSelect={[
                  {
                    text: 'Januari',
                    value: 1,
                  },
                  {
                    text: 'Februari',
                    value: 2,
                  },
                  {
                    text: 'Maret',
                    value: 3,
                  },
                  {
                    text: 'April',
                    value: 4,
                  },
                  {
                    text: 'Mei',
                    value: 5,
                  },
                  {
                    text: 'Juni',
                    value: 6,
                  },
                  {
                    text: 'Juli',
                    value: 7,
                  },
                  {
                    text: 'Agustus',
                    value: 8,
                  },
                  {
                    text: 'September',
                    value: 9,
                  },
                  {
                    text: 'Oktober',
                    value: 10,
                  },
                  {
                    text: 'November',
                    value: 11,
                  },
                  {
                    text: 'Desember',
                    value: 12,
                  },
                  {
                    text: 'Lihat Semua',
                    value: '',
                  },
                ]}
                state={bulan}
                setState={setBulan}
                label="Bulan"
                name="bulan"
              />
            </Grid>
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: '1px #e6e5e5 solid',
                marginTop: '20px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={1}>Nama</TableCell>
                  <TableCell align="center" colSpan={3}>
                    Periode
                  </TableCell>

                  <TableCell align="center" colSpan={2}>
                    Total Gaji
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Status
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTableRiwayatGaji
                  state={state}
                  data={dataFilter}
                  tableName="riwayatGaji"
                  handleEdit={handleBayar}
                  handleUnduh={handleUnduh}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFilter?.length ? dataFilter?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={'From'}
              page={state.page}
              backIconButtonProps={{
                'aria-label': 'Previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next page',
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataRiwayatGaji: state.gaji.dataRiwayatGaji,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRiwayatGaji: (params) => dispatch(getRiwayatGaji(params)),
    delRiwayatGaji: (params) => dispatch(delRiwayatGaji(params)),
    addRiwayatGajiBayar: (params) => dispatch(addRiwayatGajiBayar(params)),
    cetakRiwayatGaji: (params) => dispatch(cetakRiwayatGaji(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(riwayatGaji);

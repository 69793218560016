import {
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReimburse } from "app/redux/actions/gaji/ReimburseAction";

import IzinCutiDatePicker from "app/views/izincuti/component/IzinCutiDatePicker";
import { RenderTable, SearchKaryawan } from "app/components";
import { useHistory } from "react-router-dom";

const Reimburse = ({ dataReimburse, getReimburse }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    searchTgl: new Date(),
    aksiClick: false,
  });

  const history = useHistory();

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState("");

  const getData = () => {
    var dateFormat = JSON.stringify(state.searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    dateFormat = dateFormat.split("-").reverse().join("-");

    let params = `?search=${search}&bulan_tahun=${dateFormat}`;
    getReimburse(params);
  };

  useEffect(() => {
    getData();
  }, [state.searchTgl]);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">Reimburse</h1>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <SearchKaryawan
              otherClasses='karyawan-top-search'
              handleChange={(e) => setSearch(e.target.value)}
              value={search}
              handleKeyDown={submitSearch}
            />
            <IzinCutiDatePicker
              state={state}
              setState={setState}
              name="searchTgl"
              tipe="MMMM yyyy"
              addPrevNext
            />
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={3}>Nama</TableCell>
                  <TableCell align="center" colSpan={3}>
                    Jumlah Diproses
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Jumlah Diterima
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Total Reimburse
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTable
                  state={state}
                  data={dataReimburse}
                  tableName="reimburse"
                  aksiClick={(path) => history.push(path)}
                  dataKosongSpan={15}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataReimburse?.length ? dataReimburse?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataReimburse: state.gaji.dataReimburse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReimburse: (params) => dispatch(getReimburse(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reimburse);

import MagangPernyataan from "./MagangPernyataan";
import MagangKeterangan from "./MagangKeterangan";
import MagangPenerimaan from "./MagangPenerimaan";
import EditMagangKeterangan from "./EditMagangKeterangan";
import AddMagangKeterangan from "./add/AddMagangKeterangan";
import AddMagangPernyataan from "./add/AddMagangPernyataan";

// penerimaan kerja
import AddPenerimaanKerja from "./add/AddPenerimaanKerja";
import EditPenerimaanKerja from "./EditPenerimaanKerja";
import KerjaPenerimaan from "./PenerimaanKerja";

import EditMagangPernyataan from "./EditMagangPernyataan";
import EditMagangPenerimaan from "./EditMagangPenerimaan";
import AddMagangPenerimaan from "./add/AddMagangPenerimaan";
import KopSurat from "./KopSurat";
import PerjanjianKerjaSama from "./PerjanjianKerjaSama";
import Handover from "./Handover";
import TambahHandover from "./TambahHandover";
import TambahKopSurat from "./TambahKopSurat";
import TambahPerjanjianKerjaSama from "./TambahPerjanjianKerjaSama";
import EditKopSurat from "./EditKopSurat";
import EditHandover from "./EditHandover";
import EditPerjanjianKerjaSama from "./EditPerjanjianKerjaSama";
import KerjaKeterangan from "./KerjaKeterangan";
import AddKerjaKeterangan from "./add/AddKerjaKeterangan";
import EditKerjaKeterangan from "./EditKerjaKeterangan";
import Kontrak from "./Kontrak";
import AddKontrak from "./add/AddKontrak";
import EditKontrak from "./EditKontrak";

const AdministrasiRoutes = [
  {
    path: "/administrasi/magang/pernyataan",
    component: MagangPernyataan,
    exact: true,
  },
  {
    path: "/administrasi/KopSurat",
    component: KopSurat,
    exact: true,
  },
  {
    path: "/administrasi/KopSurat/Tambah",
    component: TambahKopSurat,
    exact: true,
  },
  {
    path: "/administrasi/KopSurat/edit/:id",
    component: EditKopSurat,
    exact: false,
  },
  {
    path: "/administrasi/Handover",
    component: Handover,
    exact: true,
  },
  {
    path: "/administrasi/Handover/Tambah",
    component: TambahHandover,
    exact: true,
  },
  {
    path: "/administrasi/Handover/edit/:id",
    component: EditHandover,
    exact: false,
  },
  {
    path: "/administrasi/PerjanjianKerjaSama",
    component: PerjanjianKerjaSama,
    exact: true,
  },
  {
    path: "/administrasi/PerjanjianKerjaSama/Tambah",
    component: TambahPerjanjianKerjaSama,
    exact: true,
  },
  {
    path: "/administrasi/PerjanjianKerjaSama/Edit/:id",
    component: EditPerjanjianKerjaSama,
    exact: true,
  },
  {
    path: "/administrasi/magang/pernyataan/edit/:id",
    component: EditMagangPernyataan,
    exact: true,
  },
  {
    path: "/administrasi/magang/pernyataan/add",
    component: AddMagangPernyataan,
    exact: true,
  },
  {
    path: "/administrasi/magang/keterangan",
    component: MagangKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/magang/keterangan/edit/:id",
    component: EditMagangKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/magang/keterangan/add",
    component: AddMagangKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/magang/penerimaan",
    component: MagangPenerimaan,
    exact: true,
  },
  {
    path: "/administrasi/magang/penerimaan/edit/:id",
    component: EditMagangPenerimaan,
    exact: true,
  },
  {
    path: "/administrasi/magang/penerimaan/add",
    component: AddMagangPenerimaan,
    exact: true,
  },
  {
    path: "/administrasi/penerimaan_kerja",
    component: KerjaPenerimaan,
    exact: true,
  },
  {
    path: "/administrasi/penerimaan_kerja/add",
    component: AddPenerimaanKerja,
    exact: true,
  },
  {
    path: "/administrasi/penerimaan_kerja/edit/:id",
    component: EditPenerimaanKerja,
    exact: true,
  },
  {
    path: "/administrasi/kerja/keterangan",
    component: KerjaKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/kerja/keterangan/add",
    component: AddKerjaKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/kerja/keterangan/edit/:id",
    component: EditKerjaKeterangan,
    exact: true,
  },
  {
    path: "/administrasi/kontrak",
    component: Kontrak,
    exact: true,
  },
  {
    path: "/administrasi/kontrak/add",
    component: AddKontrak,
    exact: true,
  },
  {
    path: "/administrasi/kontrak/edit/:id",
    component: EditKontrak,
    exact: true,
  },
];

export default AdministrasiRoutes;

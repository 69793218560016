import { GET_KEBIJAKAN_PRIVASI } from "app/redux/actions/kebijakan/KebijakanPrivasiActions";

const initialState = {
  dataPrivacyPolicy: [],
};

const KebijakanPrivasiReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_KEBIJAKAN_PRIVASI: {
      return {
        ...state,
        dataPrivacyPolicy: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default KebijakanPrivasiReducer;

import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useDispatch } from "react-redux";
import { delAturPasal } from "app/redux/actions/lainnya/AturPasalAction";

export default function MenuAturPasal({ item, path, getData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await dispatch(delAturPasal(item.atur_pasal_code));
      setTimeout(() => {
        getData();
        handleClose();
        Swal.fire("Success!", "Data Atur Pasal berhasil dihapus", "success");
      }, 2000);
    } catch (e) {
      handleClose();
      Swal.fire("Oopss!", `${e}`, "error");
    }
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="karyawan-aksi-dot elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to={{
            pathname: path,
          }}
        >
          <MenuItem className="ptkp-menu-option mb-3" onClick={handleClose}>
            <span className="color-green">
              <CreateOutlinedIcon />
            </span>
            <span className="karyawan-aksi-icon pl-3 color-green">
              Edit Data
            </span>
          </MenuItem>
        </Link>
        <MenuItem className="ptkp-menu-option" onClick={handleDelete}>
          <span className="color-red">
            <DeleteOutlineOutlinedIcon />
          </span>
          <span className="karyawan-aksi-icon pl-3 color-red">Hapus Data</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

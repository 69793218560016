import { API, setAuthToken } from 'app/config/api';

export const GET_JENIS_TUNJANGAN = 'GET_JENIS_TUNJANGAN';

export const getJenisTunjangan = (params) => {
  return (dispatch) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.get(`/user/lainnya/jenis_tunjangan${params}`)
      .then((res) => {
        dispatch({
          type: GET_JENIS_TUNJANGAN,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_JENIS_TUNJANGAN,
          payload: [],
        });
      });
  };
};

export const addJenisTunjangan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/lainnya/jenis_tunjangan/add`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getDetailJenisTunjangan = (id) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get('/user/lainnya/jenis_tunjangan/detail/' + id);
};

// export const editJenisTunjangan = (params) => {
//   const token = localStorage.getItem("jwt_token");
//   setAuthToken(token);
//   return API.post("/user/lainnya/jenis_tunjangan/update", params);
// };

export const editJenisTunjangan = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/lainnya/jenis_tunjangan/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delJenisTunjangan = (code) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.delete('/user/lainnya/jenis_tunjangan/delete/' + code);
};

import Pajak from './Pajak';
import AddPajak from './AddPajak';
import EditPajak from './EditPajak';
import Ptkp from './Ptkp';
import AddPtkp from './AddPtkp';
import EditPtkp from './EditPtkp';

const PajakRoutes = [
  {
    path: '/pajak/ketentuan',
    component: Pajak,
    exact: true,
  },
  {
    path: '/pajak/ketentuan/add',
    component: AddPajak,
    exact: true,
  },
  {
    path: '/pajak/ketentuan/edit/:id',
    component: EditPajak,
    exact: true,
  },
  {
    path: '/pajak/ptkp',
    component: Ptkp,
    exact: true,
  },
  {
    path: '/pajak/ptkp/add',
    component: AddPtkp,
    exact: true,
  },
  {
    path: '/pajak/ptkp/edit/:id',
    component: EditPtkp,
    exact: true,
  },
];

export default PajakRoutes;

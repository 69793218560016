import Gaji from "./Gaji";
import RiwayatGaji from "./RiwayatGaji";
import Bonus from "./Bonus";
import Tunjangan from "./Tunjangan";
import DetailRiwayatGaji from "./dynamicPage/detail/DetailRiwayatGaji";
import Lembur from "./Lembur";
import Denda from "./Denda";
import Potongan from "./Potongan";
import DetailGaji from "./dynamicPage/detail/DetailGaji";
import DetailBonus from "./dynamicPage/detail/DetailBonus";
import DetailTunjangan from "./dynamicPage/detail/DetailTunjangan";
import DetailDenda from "./dynamicPage/detail/DetailDenda";
import DetailPotongan from "./dynamicPage/detail/DetailPotongan";
import TambahBonus from "./dynamicPage/tambah/TambahBonus";
import TambahTunjangan from "./dynamicPage/tambah/TambahTunjangan";
import TambahDenda from "./dynamicPage/tambah/TambahDenda";
import EditTunjangan from "./dynamicPage/edit/EditTunjangan";
import EditBonus from "./dynamicPage/edit/EditBonus";
import EditDenda from "./dynamicPage/edit/EditDenda";
import Reimburse from "./Reimburse";
import BayarRiwayatGaji from "./BayarRiwayatGaji";
import ReimburseUser from "./dynamicPage/detail/ReimburseUser";
const GajiRoutes = [
  {
    path: "/Gaji",
    component: Gaji,
    exact: true,
  },
  {
    path: "/Gaji/Detail/:id",
    component: DetailGaji,
    exact: false,
  },
  {
    path: "/RiwayatGaji",
    component: RiwayatGaji,
    exact: true,
  },
  {
    path: "/RiwayatGaji/Bayar",
    component: BayarRiwayatGaji,
    exact: true,
  },

  {
    path: "/RiwayatGaji/Detail/:id",
    component: DetailRiwayatGaji,
    exact: false,
  },
  {
    path: "/Bonus",
    component: Bonus,
    exact: true,
  },
  {
    path: "/Bonus/Tambah",
    component: TambahBonus,
    exact: true,
  },
  {
    path: "/Bonus/Detail/:id",
    component: DetailBonus,
    exact: false,
  },
  {
    path: "/Bonus/Edit/:id",
    component: EditBonus,
    exact: false,
  },
  {
    path: "/Tunjangan",
    component: Tunjangan,
    exact: true,
  },
  {
    path: "/Tunjangan/Tambah",
    component: TambahTunjangan,
    exact: true,
  },
  {
    path: "/Tunjangan/Detail/:id",
    component: DetailTunjangan,
    exact: false,
  },
  {
    path: "/Tunjangan/Edit/:id",
    component: EditTunjangan,
    exact: false,
  },
  {
    path: "/Lembur",
    component: Lembur,
    exact: true,
  },

  {
    path: "/Denda",
    component: Denda,
    exact: true,
  },
  {
    path: "/Denda/Tambah",
    component: TambahDenda,
    exact: true,
  },
  {
    path: "/Denda/Detail/:id",
    component: DetailDenda,
    exact: false,
  },
  {
    path: "/Denda/Edit/:id",
    component: EditDenda,
    exact: false,
  },
  {
    path: "/Potongan",
    component: Potongan,
    exact: true,
  },
  {
    path: "/Potongan/Detail/:id",
    component: DetailPotongan,
    exact: false,
  },
  {
    path: "/Reimburse",
    component: Reimburse,
    exact: true,
  },
  {
    path: "/reimburse/:id",
    component: ReimburseUser,
    exact: true,
  },
];

export default GajiRoutes;

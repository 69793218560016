import axios from 'axios';

// Set config defaults when creating the instance
export const URL = process.env.REACT_APP_BACKEND_API_URL;
export const API = axios.create({
  baseURL: `${URL}api/v1/`,
  // baseURL: `http://127.0.0.1:8000/api/v1/`,
});

export const setAuthToken = (token) => {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // API.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
  API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  API.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};

export const API_OFFICE = axios.create({
  baseURL: `https://api.office.alan.co.id/api/`,
});
export const setAuthTokenOffice = (token) => {
  API_OFFICE.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // API_OFFICE.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
  API_OFFICE.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  API_OFFICE.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};

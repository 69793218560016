import Bonus from './Bonus';
import AddBonus from './AddBonus';
import EditBonus from './EditBonus';

const BonusRoutes = [
  {
    path: '/lainnya/bonus',
    component: Bonus,
    exact: true,
  },
  {
    path: '/lainnya/bonus/add',
    component: AddBonus,
    exact: true,
  },
  {
    path: '/lainnya/bonus/edit/:id',
    component: EditBonus,
    exact: true,
  },
];

export default BonusRoutes;

import {
  GET_DATA_DENDA,
  GET_DETAIL_DENDA,
  DEL_DENDA,
  DEL_GAJI_DENDA,
} from "../actions/gaji/DendaAction";

import {
  GET_DATA_TUNJANGAN,
  GET_DETAIL_TUNJANGAN,
  DEL_TUNJANGAN,
  DEL_GAJI_TUNJANGAN,
} from "../actions/gaji/TunjanganAction";

import {
  GET_DATA_REIMBURSE,
  GET_DATA_REIMBURSE_USER,
  GET_DETAIL_REIMBURSE,
  DEL_REIMBURSE,
  DEL_GAJI_REIMBURSE,
} from "../actions/gaji/ReimburseAction";

import {
  GET_DATA_LEMBUR,
  GET_DATA_RIWAYAT_LEMBUR,
  GET_DETAIL_LEMBUR,
  DEL_LEMBUR,
  DEL_GAJI_LEMBUR,
} from "../actions/gaji/LemburAction";

import {
  GET_DATA_POTONGAN,
  GET_DETAIL_POTONGAN,
  DEL_POTONGAN,
} from "../actions/gaji/PotonganAction";

import {
  GET_DATA_BONUS,
  GET_DETAIL_BONUS,
  DEL_BONUS,
  DEL_GAJI_BONUS,
} from "../actions/gaji/BonusAction";

import {
  GET_DATA_INFORMASI_GAJI,
  GET_DETAIL_INFORMASI_GAJI,
  DEL_INFORMASI_GAJI,
} from "../actions/gaji/InformasiGajiAction";

import {
  GET_DATA_RIWAYAT_GAJI,
  GET_DETAIL_RIWAYAT_GAJI,
  DEL_RIWAYAT_GAJI,
  ADD_BAYAR_GAJI_ARRAY,
} from "../actions/gaji/RiwayatGajiAction";

const initialState = {
  dataRiwayatGaji: [],
  detailRiwayatGaji: {},
  dataDenda: [],
  detailDenda: {},
  dataTunjangan: [],
  detailTunjangan: {},
  dataReimburse: [],
  dataReimburseUser: [],
  detailReimburse: {},
  dataLembur: [],
  dataRiwayatLembur: [],
  detailLembur: {},
  dataPotongan: [],
  detailPotongan: {},
  dataBonus: [],
  detailBonus: {},
  dataInformasiGaji: [],
  detailInformasiGaji: {},
  bayarGajiArray: [],
};

const GajiReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_BAYAR_GAJI_ARRAY: {
      return {
        ...state,
        bayarGajiArray: action.payload,
      };
    }
    case DEL_RIWAYAT_GAJI: {
      let penilaian = state.data;
      return {
        ...state,
        dataRiwayatGaji: penilaian,
      };
    }
    case GET_DATA_RIWAYAT_GAJI: {
      return {
        ...state,
        dataRiwayatGaji: action.payload,
      };
    }
    case GET_DETAIL_RIWAYAT_GAJI: {
      return {
        ...state,
        detailRiwayatGaji: action.payload,
      };
    }

    case DEL_DENDA: {
      let penilaian = state.data;
      return {
        ...state,
        dataDenda: penilaian,
      };
    }

    case DEL_GAJI_DENDA: {
      let penilaian = state.data;
      return {
        ...state,
        dataDenda: penilaian,
      };
    }
    case GET_DATA_DENDA: {
      return {
        ...state,
        dataDenda: action.payload,
      };
    }
    case GET_DETAIL_DENDA: {
      return {
        ...state,
        detailDenda: action.payload,
      };
    }
    case DEL_INFORMASI_GAJI: {
      let penilaian = state.data;
      return {
        ...state,
        dataInformasiGaji: penilaian,
      };
    }
    case GET_DATA_INFORMASI_GAJI: {
      return {
        ...state,
        dataInformasiGaji: action.payload,
      };
    }
    case GET_DETAIL_INFORMASI_GAJI: {
      return {
        ...state,
        detailInformasiGaji: action.payload,
      };
    }

    case DEL_TUNJANGAN: {
      let penilaian = state.data;
      return {
        ...state,
        dataTunjangan: penilaian,
      };
    }

    case DEL_GAJI_TUNJANGAN: {
      let penilaian = state.data;
      return {
        ...state,
        dataTunjangan: penilaian,
      };
    }

    case GET_DATA_TUNJANGAN: {
      return {
        ...state,
        dataTunjangan: action.payload,
      };
    }

    case GET_DATA_RIWAYAT_LEMBUR: {
      return {
        ...state,
        dataRiwayatLembur: action.payload,
      };
    }
    case GET_DETAIL_TUNJANGAN: {
      return {
        ...state,
        detailTunjangan: action.payload,
      };
    }

    case DEL_REIMBURSE: {
      let penilaian = state.data;
      return {
        ...state,
        dataReimburse: penilaian,
      };
    }
    case GET_DATA_REIMBURSE: {
      return {
        ...state,
        dataReimburse: action.payload,
      };
    }
    case GET_DATA_REIMBURSE_USER: {
      return {
        ...state,
        dataReimburseUser: action.payload,
      };
    }
    case GET_DETAIL_REIMBURSE: {
      return {
        ...state,
        detailReimburse: action.payload,
      };
    }
    case DEL_LEMBUR: {
      let penilaian = state.data;
      return {
        ...state,
        dataLembur: penilaian,
      };
    }
    case DEL_GAJI_LEMBUR: {
      let penilaian = state.data;
      return {
        ...state,
        dataLembur: penilaian,
      };
    }
    case GET_DATA_LEMBUR: {
      return {
        ...state,
        dataLembur: action.payload,
      };
    }
    case GET_DETAIL_LEMBUR: {
      return {
        ...state,
        detailLembur: action.payload,
      };
    }

    case DEL_POTONGAN: {
      let penilaian = state.data;
      return {
        ...state,
        dataPotongan: penilaian,
      };
    }
    case GET_DATA_POTONGAN: {
      return {
        ...state,
        dataPotongan: action.payload,
      };
    }
    case GET_DETAIL_POTONGAN: {
      return {
        ...state,
        detailPotongan: action.payload,
      };
    }
    case DEL_BONUS: {
      let penilaian = state.data;
      return {
        ...state,
        dataBonus: penilaian,
      };
    }

    case DEL_GAJI_BONUS: {
      let penilaian = state.data;
      return {
        ...state,
        dataBonus: penilaian,
      };
    }

    case GET_DATA_BONUS: {
      return {
        ...state,
        dataBonus: action.payload,
      };
    }
    case GET_DETAIL_BONUS: {
      return {
        ...state,
        detailBonus: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default GajiReducer;

import { API, setAuthToken } from "app/config/api";

export const GET_KEBIJAKAN_PRIVASI = "GET_KEIJAKAN_PRIVASI";

export const getKebijakan = () => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get("/user/akun/aturan_kebijakan")
      .then((res) => {
        dispatch({
          type: GET_KEBIJAKAN_PRIVASI,
          payload: res.data.data.kebijakan.content || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_KEBIJAKAN_PRIVASI,
          payload: [],
        });
      });
  };
};

export const saveKebijakan = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/akun/kebijakan", body);
};

export const uploadKebijakan = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/akun/kebijakan/upload", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import TableKopSurat from "./component/TableKopSurat";

import Swal from "sweetalert2";

import {
  getKopSurat,
  delKopSurat,
} from "app/redux/actions/administrasi/KopSuratAction";
import { connect } from "react-redux";

const KopSurat = ({ dataAdministrasi, getKopSurat, delKopSurat }) => {
  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    var params = `?search=${search}`;

    getKopSurat(params);
  };
  useEffect(() => {
    getData();
  }, []);
  const onEnter = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delKopSurat(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Kop Surat
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/administrasi/KopSurat/Tambah">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="karyawan-add-ultra-container mt-2">
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12} sm={3} className="d-flex align-items-end">
              <TextField
                size="small"
                variant="outlined"
                className="karyawan-top-search"
                placeholder="Cari Judul"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                name="search"
                onKeyDown={onEnter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableKopSurat data={dataAdministrasi} handleDelete={handleDelete} />
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAdministrasi: state.administrasi.dataAdministrasi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKopSurat: (params) => dispatch(getKopSurat(params)),
    delKopSurat: (code) => dispatch(delKopSurat(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KopSurat);

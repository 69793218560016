import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#BBBBBB',
    backgroundColor: 'white',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function IzinCutiTabs({ style, state, setState, izin = true }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="">
      <AppBar
        className="d-flex items-center m-0"
        style={{ boxShadow: 'none', background: 'white' }}
        position="static"
        color="default"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
          className="d-flex items-center m-0"
          style={{ boxShadow: 'none', background: 'white' }}
        >
          <StyledTab
            className="m-0"
            label="Pengajuan"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                pengajuan: true,
                search: '',
              }))
            }
            {...a11yProps(0)}
          />
          <StyledTab
            className="m-0"
            label={`${izin ? 'Riwayat Izin' : 'Riwayat Cuti'}`}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                pengajuan: false,
                search: '',
              }))
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
    </div>
  );
}

import AturPasal from "./AturPasal";
import AddAturPasal from "./AddAturPasal";
import EditAturPasal from "./EditAturPasal";

const AturPasalRoutes = [
    {
        path: "/lainnya/atur_pasal",
        component: AturPasal,
        exact: true,
    },
    
    {
        path: "/lainnya/atur_pasal/add",
        component: AddAturPasal,
        exact: true,
    },
    {
        path: "/lainnya/atur_pasal/edit/:id",
        component: EditAturPasal,
        exact: true,
    },
];

export default AturPasalRoutes;

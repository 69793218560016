import React, { useState, useEffect, useLayoutEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Swal from "sweetalert2";

import RenderTableBayarRiwayatGaji from "./component/TableBayarGaji";
import { getListBayarRiwayatGaji } from "app/redux/actions/PajakAction";
import {
  getBayarRiwayatGaji,
  addRiwayatGajiBayar,
} from "app/redux/actions/gaji/RiwayatGajiAction";
import { connect } from "react-redux";

const BayarRiwayatGaji = ({ bayarGajiArray, addRiwayatGajiBayar }) => {
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [state, setState] = useState({
    data_bayar_gaji: [],
  });

  console.log("bayarGajiCode", bayarGajiArray);

  const [bayar, setBayar] = useState(false);

  const getData = () => {
    getBayarRiwayatGaji().then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        data_bayar_gaji: data,
      }));
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleBayar = () => {
    addRiwayatGajiBayar({
      gaji_code: bayarGajiArray,
    })
      .then((res) => {
        Swal.fire("Success!", "Gaji Berhasil Dibayar", "success");
        setBayar(!bayar);
        getData();
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal Bayar, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Bayar Gaji
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="karyawan-btn-top px-8 py-3"
            onClick={handleBayar}
          >
            <span className="karyawan-btn-span">Bayar Sekarang</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <RenderTableBayarRiwayatGaji
          data={state.data_bayar_gaji}
          page={page}
          rowsPerPage={rowsPerPage}
          getData={getData}
          bayar={bayar}
        />
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bayarGajiArray: state.gaji.bayarGajiArray,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addRiwayatGajiBayar: (params) => dispatch(addRiwayatGajiBayar(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BayarRiwayatGaji);

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  MenuItem,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { addKaryawan } from "app/redux/actions/KaryawanActions";
import { getDivisiList } from "app/redux/actions/WebSettingActions";
import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import { ButtonUploadThumbnail } from "app/components";

class AbsensiForm extends Component {
  constructor() {
    super();
    this.state = {
      submit: false,
      newKategori: "",
      kategori: [],
      error: false,
      error_text: "",
      type: {
        label: "Karyawan",
        value: 1,
      },
      typeSelected: 1,
      dataType: [
        {
          label: "Karyawan",
          value: 1,
        },
        {
          label: "Magang",
          value: 2,
        },
      ],
      v_tanggal_lahir: new Date(),
      tanggal_lahir: new Date().toISOString().split("T")[0],
      atasan_langsung: "",
      hak_cuti: 0,
      jenis_kelamin: 1,
      divisi_code: "",
    };
  }

  componentDidMount() {
    // const { getDivisiList } = this.props;
    // getDivisiList();
  }

  handleChange = (event) => {
    console.log(event.target);
    this.setState({
      [event.target.name]: event.target.value,
      // posisi: event.target.options[event.target.selectedIndex].text,
    });
  };

  handleChangeType = (val) => {
    this.setState({
      type: val.value,
      typeSelected: val.value,
    });
  };

  handleDateChange = (date) => {
    var event = new Date(date);

    let dateFormat = JSON.stringify(event);
    dateFormat = dateFormat.slice(1, 11);
    this.setState({
      tanggal_lahir: dateFormat,
      v_tanggal_lahir: event,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        submit: true,
      },
      this.sendSubmit
    );
  };

  sendSubmit = () => {
    const {
      nama,
      alamat,
      tempat_lahir,
      tanggal_lahir,
      email,
      nik,
      type,
      telp,
      asal_kampus,
      status,
      typeSelected,
      foto_profile,
      foto_ktp,
      foto_npwp,
      foto_bpjs,
      no_bpjs,
      no_npwp,
      gaji_pokok,
      tunjangan,
      posisi,
      divisi_code,
      atasan_langsung,
      hak_cuti,
      jenis_kelamin,
    } = this.state;
    const { addKaryawan, handleClose } = this.props;
    const params = new FormData();
    params.append("nama", nama);
    params.append("email", email);
    params.append("alamat", alamat);
    params.append("tempat_lahir", tempat_lahir);
    params.append("tanggal_lahir", tanggal_lahir);
    params.append("nik", nik);
    params.append("type", typeSelected);
    params.append("telp", telp);
    params.append("asal_kampus", asal_kampus);
    params.append("foto_ktp", foto_ktp);
    params.append("foto_profile", foto_profile);
    params.append("foto_npwp", foto_npwp);
    params.append("foto_bpjs", foto_bpjs);
    params.append("status", status);
    params.append("no_bpjs", no_bpjs);
    params.append("no_npwp", no_npwp);
    params.append("gaji_pokok", gaji_pokok);
    params.append("tunjangan", tunjangan);
    params.append("posisi", posisi);
    params.append("divisi_code", divisi_code);
    params.append("atasan_langsung", atasan_langsung);
    params.append("hak_cuti", hak_cuti);
    params.append("jenis_kelamin", jenis_kelamin);
    addKaryawan(params)
      .then((res) => {
        if (res.code === 0) {
          Swal.fire({
            title: "Berhasil",
            text: "Data berhasil disimpan",
            timer: 2000,
            icon: "success",
          }).then(() => {
            handleClose();
            this.setState({
              submit: false,
              nama: "",
              email: "",
              alamat: "",
              tempat_lahir: "",
              tanggal_lahir: new Date(),
              nik: "",
              telp: "",
              asal_kampus: "",
              status: "",
              type: 1,
              atasan_langsung: "",
            });
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal disimpan",
          timer: 2000,
          icon: "error",
        }).then(() =>
          this.setState({
            submit: false,
          })
        );
      });
  };

  handleUploadProfile = (file, path) => {
    this.setState({
      foto_profile: file.result,
      foto_profile_preview: path,
    });
  };

  handleUploadKTP = (file, path) => {
    this.setState({
      foto_ktp: file.result,
      foto_ktp_preview: path,
    });
  };

  handleUploadNPWP = (file, path) => {
    this.setState({
      foto_npwp: file.result,
      foto_npwp_preview: path,
    });
  };

  handleUploadBPJS = (file, path) => {
    this.setState({
      foto_bpjs: file.result,
      foto_bpjs_preview: path,
    });
  };

  handleChangeAtasan = (val) => {
    this.setState({
      atasan_langsung: val.value,
    });
  };

  render() {
    let {
      submit,
      nama,
      alamat,
      tempat_lahir,
      v_tanggal_lahir,
      email,
      nik,
      dataType,
      type,
      telp,
      asal_kampus,
      status,
      typeSelected,
      no_bpjs,
      no_npwp,
      gaji_pokok,
      tunjangan,
      foto_profile_preview,
      foto_ktp_preview,
      posisi,
      atasan_langsung,
      hak_cuti,
      jenis_kelamin,
      foto_npwp_preview,
      foto_bpjs_preview,
      divisi_code,
    } = this.state;
    let { formName } = this.props;
    return (
      <div>
        <div className="p-5">
          {formName === "tambah" ? (
            <h4 className="mb-5">Tambah Karyawan</h4>
          ) : (
            <h4 className="mb-5">Edit Karyawan</h4>
          )}
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Karyawan</h5>
                <SelectValidator
                  variant="outlined"
                  className="basic-multi-select mb-4 w-full"
                  label="Karyawan"
                  onChange={this.handleChange}
                  name="atasan_langsung"
                  value={atasan_langsung}
                >
                  <MenuItem value="" disabled>
                    <em>Karyawan</em>
                  </MenuItem>
                </SelectValidator>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Unit Kerja</h5>
                <SelectValidator
                  variant="outlined"
                  className="basic-multi-select mb-4 w-full"
                  label="Unit Kerja"
                  onChange={this.handleChange}
                  name="atasan_langsung"
                  value={atasan_langsung}
                >
                  <MenuItem value="" disabled>
                    <em>Unit Kerja</em>
                  </MenuItem>
                </SelectValidator>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Titik Absensi</h5>
                <SelectValidator
                  variant="outlined"
                  className="basic-multi-select mb-4 w-full"
                  label="Titik Absensi"
                  onChange={this.handleChange}
                  name="atasan_langsung"
                  value={atasan_langsung}
                >
                  <MenuItem value="" disabled>
                    <em>Unit Kerja</em>
                  </MenuItem>
                </SelectValidator>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tanggal</h5>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item sm={12} xs={12}>
                    <KeyboardDatePicker
                      className="w-full"
                      variant="inline"
                      format="dd/MMMM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={v_tanggal_lahir}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      size="small"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <div className="w-full text-right">
              <ButtonGroup className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={submit}
                  type="submit"
                >
                  {submit ? (
                    <CircularProgress size={15} color="#fff" />
                  ) : (
                    "Simpan"
                  )}
                </Button>
                <Button variant="contained" color="default">
                  Batal
                </Button>
              </ButtonGroup>
            </div>
            <Grid item sm={6} xs={12}>
              <h5>Waktu Absensi</h5>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="waktu-absensi-button-active">Masuk</div>
                <div className="waktu-absensi-button">Keluar</div>
                <div className="waktu-absensi-button">Masuk Lembur</div>
                <div className="waktu-absensi-button">Keluar Lembur</div>
              </div>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataDivisiList: state.karyawan.dataDivisiList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addKaryawan: (params) => dispatch(addKaryawan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbsensiForm);

import { useState, useEffect } from "react";
import { green } from "@material-ui/core/colors";

import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Checkbox,
  Grid,
  Avatar,
} from "@material-ui/core";
import foto_profile from "assets/page/karyawan/foto_profile.png";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addBayarGajiArray } from "app/redux/actions/gaji/RiwayatGajiAction";
import { formatRupiah } from "app/utils/globalFunction";
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TableBayarGaji = ({
  search,
  data,
  getData,
  onBayarClick,
  addBayarGajiArray,
  bayarGajiArray,
  bayar,
}) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  const [pilihSemua, setPilihSemua] = useState(2);
  const handlePilihSemua = (event) => {
    if (pilihSemua !== 1) {
      setPilihSemua(1);
    } else {
      setPilihSemua(2);
    }
  };

  useEffect(() => {
    setPilihSemua(2);
  }, [state.page]);

  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const [gajiCode, setGajiCode] = useState([]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const newGajiCode = [...gajiCode];
    const index = newGajiCode.indexOf(name);
    if (index === -1) {
      newGajiCode.push(name);
      event.target.checked = true;
    } else {
      newGajiCode.splice(index, 1);
      event.target.checked = false;
    }
    setGajiCode(newGajiCode);
  };

  const allGajiCodes = data
    .slice(
      state.page * state.rowsPerPage,
      state.page * state.rowsPerPage + state.rowsPerPage
    )
    .map((item) => item.gaji_code);

  useEffect(() => {
    if (pilihSemua === 1) {
      setGajiCode(allGajiCodes);
    } else if (pilihSemua === 2) {
      setGajiCode([]);
    }
  }, [pilihSemua]);

  useEffect(() => {
    addBayarGajiArray(gajiCode);
    if (gajiCode.length === state.rowsPerPage) {
      setPilihSemua(1);
    } else if (gajiCode.length < state.rowsPerPage) {
      setPilihSemua(3);
    }
  }, [gajiCode]);

  console.log("gajiCode", gajiCode);

  useEffect(() => {
    setGajiCode([]);
    setPilihSemua(2);
  }, [bayar]);

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableCell
            colSpan={15}
            className="font-medium text-primary text-15 "
            style={{ color: "#0a0a0a" }}
            align="right"
            variant="body"
          >
            <div className=" align-middle">
              <GreenCheckbox
                checked={pilihSemua === 1}
                onChange={handlePilihSemua}
              />
              Pilih Semua
            </div>
          </TableCell>
          <TableRow>
            <TableCell
              colSpan={1}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              No
            </TableCell>
            <TableCell
              colSpan={4}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
            >
              Nama
            </TableCell>
            <TableCell
              colSpan={5}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              Periode
            </TableCell>
            <TableCell
              colSpan={3}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              Nominal
            </TableCell>
            <TableCell
              colSpan={2}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {data?.length > 0 ? (
              data
                .slice(
                  state.page * state.rowsPerPage,
                  state.page * state.rowsPerPage + state.rowsPerPage
                )
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      className="text-14 pl-3"
                      align="center"
                      style={{ color: "#0A0A0A" }}
                      colSpan={1}
                    >
                      {index + 1 + handleNumbering()}
                    </TableCell>
                    <TableCell
                      className="text-14 "
                      style={{ color: "#0A0A0A" }}
                      colSpan={4}
                    >
                      <div
                        className=" z-100 text-14 d-flex items-center"
                        style={{ gap: "16px" }}
                      >
                        {item.foto_profile === "" ? (
                          <Avatar
                            src={foto_profile}
                            variant="square"
                            alt="foto profil"
                            style={{
                              width: "50px",
                              height: "50px",
                              background: "white",
                            }}
                          />
                        ) : (
                          <Avatar
                            variant="square"
                            src={item.foto_profile}
                            width={"50px"}
                          />
                        )}

                        {item?.name}
                      </div>
                    </TableCell>
                    <TableCell
                      className="text-14 "
                      style={{ color: "#0A0A0A" }}
                      colSpan={5}
                      align="center"
                    >
                      {item.periode_awal}
                    </TableCell>
                    <TableCell
                      className="text-14 "
                      style={{ color: "#0A0A0A" }}
                      colSpan={3}
                      align="center"
                    >
                      {formatRupiah(item.total_gaji)}
                    </TableCell>
                    <TableCell className="pl-3" align="center" colSpan={2}>
                      <GreenCheckbox
                        checked={gajiCode.includes(item.gaji_code)}
                        onChange={handleChange}
                        name={item.gaji_code}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={14} align="center">
                  Data kosong
                </TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>

      <TablePagination
        className="px-16 my-7"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={"From"}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    bayarGajiArray: state.gaji.bayarGajiArray,
  };
};

export default connect(mapStateToProps, { addBayarGajiArray })(TableBayarGaji);

// import { useState, useEffect } from "react";
// import { green } from "@material-ui/core/colors";

// import {
//   TablePagination,
//   Table,
//   TableCell,
//   TableRow,
//   TableBody,
//   TableHead,
//   Button,
//   Checkbox,
//   Grid,
// } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";

// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     "&$checked": {
//       color: green[600],
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

// const RenderTable = ({
//   data,
//   state,
//   search,
//   getData,
//   pilihSemua,
//   onBayarClick,
// }) => {
//   const handleNumbering = () => {
//     if (state.rowsPerPage === 5) {
//       return state.page * 5;
//     } else if (state.rowsPerPage === 10) {
//       return state.page * 10;
//     } else if (state.rowsPerPage === 25) {
//       return state.page * 25;
//     }
//   };

//   const [gajiCode, setGajiCode] = useState([]);

//   const handleChange = (event) => {
//     const { name, checked } = event.target;
//     const newGajiCode = [...gajiCode];
//     const index = newGajiCode.indexOf(name);
//     if (index === -1) {
//       newGajiCode.push(name);
//       event.target.checked = true;
//     } else {
//       newGajiCode.splice(index, 1);
//       event.target.checked = false;
//     }
//     setGajiCode(newGajiCode);
//   };

//   const allGajiCodes = data
//     .slice(
//       state.page * state.rowsPerPage,
//       state.page * state.rowsPerPage + state.rowsPerPage
//     )
//     .map((item) => item.gaji_code);

//   useEffect(() => {
//     if (pilihSemua) {
//       setGajiCode(allGajiCodes);
//     } else {
//       setGajiCode([]);
//     }
//   }, [pilihSemua]);

//   return (
//     <>
//       {data?.length > 0 ? (
//         data
//           .slice(
//             state.page * state.rowsPerPage,
//             state.page * state.rowsPerPage + state.rowsPerPage
//           )
//           .map((item, index) => (
//             <TableRow hover key={index}>
//               <TableCell
//                 className="text-14 pl-3"
//                 align="center"
//                 style={{ color: "#0A0A0A" }}
//                 colSpan={1}
//               >
//                 {index + 1 + handleNumbering()}
//               </TableCell>
//               <TableCell
//                 className="text-14 "
//                 style={{ color: "#0A0A0A" }}
//                 colSpan={4}
//               >
//                 {item.name}
//               </TableCell>
//               <TableCell
//                 className="text-14 "
//                 style={{ color: "#0A0A0A" }}
//                 colSpan={5}
//                 align="center"
//               >
//                 {item.periode_awal}
//               </TableCell>
//               <TableCell
//                 className="text-14 "
//                 style={{ color: "#0A0A0A" }}
//                 colSpan={3}
//                 align="center"
//               >
//                 {item.total_gaji}
//               </TableCell>
//               <TableCell className="pl-3" align="center" colSpan={2}>
//                 <GreenCheckbox
//                   checked={gajiCode.includes(item.gaji_code)}
//                   onChange={handleChange}
//                   name={item.gaji_code}
//                 />
//               </TableCell>
//             </TableRow>
//           ))
//       ) : (
//         <TableRow hover>
//           <TableCell colSpan={14} align="center">
//             Data kosong
//           </TableCell>
//         </TableRow>
//       )}
//     </>
//   );
// };

// const TableBayarGaji = ({ search, data, getData, onBayarClick }) => {
//   const [state, setState] = useState({
//     page: 0,
//     rowsPerPage: 5,
//   });

//   const setPage = (page) => {
//     setState({
//       ...state,
//       page,
//     });
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const setRowsPerPage = (event) => {
//     setState({
//       ...state,
//       rowsPerPage: event.target.value,
//     });
//   };

//   const [pilihSemua, setPilihSemua] = useState(false);
//   const handlePilihSemua = (event) => {
//     setPilihSemua(event.target.checked);
//   };

//   useEffect(() => {
//     setPilihSemua(false);
//   }, [state.page]);

//   return (
//     <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
//       <Table className="buku-kas-table">
//         <TableHead>
//           <TableCell
//             colSpan={15}
//             className="font-medium text-primary text-15 "
//             style={{ color: "#0a0a0a" }}
//             align="right"
//             variant="body"
//           >
//             <div className=" align-middle">
//               <GreenCheckbox checked={pilihSemua} onChange={handlePilihSemua} />
//               Pilih Semua
//             </div>
//           </TableCell>
//           <TableRow>
//             <TableCell
//               colSpan={1}
//               className="font-medium text-15"
//               style={{ color: "#0a0a0a" }}
//               align="center"
//             >
//               No
//             </TableCell>
//             <TableCell
//               colSpan={4}
//               className="font-medium text-15"
//               style={{ color: "#0a0a0a" }}
//             >
//               Nama
//             </TableCell>
//             <TableCell
//               colSpan={5}
//               className="font-medium text-15"
//               style={{ color: "#0a0a0a" }}
//               align="center"
//             >
//               Periode
//             </TableCell>
//             <TableCell
//               colSpan={3}
//               className="font-medium text-15"
//               style={{ color: "#0a0a0a" }}
//               align="center"
//             >
//               Nominal
//             </TableCell>
//             <TableCell
//               colSpan={2}
//               className="font-medium text-15"
//               style={{ color: "#0a0a0a" }}
//               align="center"
//             >
//               Aksi
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <RenderTable
//             data={data}
//             state={state}
//             getData={getData}
//             search={search}
//             pilihSemua={pilihSemua}
//             onBayarClick={onBayarClick}
//           />
//         </TableBody>
//       </Table>

//       <TablePagination
//         className="px-16 my-7"
//         rowsPerPageOptions={[5, 10, 25]}
//         component="div"
//         count={data?.length ? data?.length : 0}
//         rowsPerPage={state.rowsPerPage}
//         labelRowsPerPage={"From"}
//         page={state.page}
//         backIconButtonProps={{
//           "aria-label": "Previous page",
//         }}
//         nextIconButtonProps={{
//           "aria-label": "Next page",
//         }}
//         backIconButtonText="Previous page"
//         nextIconButtonText="Next page"
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={setRowsPerPage}
//       />
//     </div>
//   );
// };

// export default TableBayarGaji;

import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Button, Grid } from "@material-ui/core";
import Swal from "sweetalert2";

import AddIcon from "@material-ui/icons/Add";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import TableMagangKeterangan from "./component/TableMagangKeterangan";
import SelectMagang from "./component/SelectMagang";

import {
  getPernyataanMagang,
  delPernyataanMagang,
  getDetailPernyataanMagang,
  editPernyataanMagang,
} from "app/redux/actions/administrasi/MagangAction";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";

const currentDateFormat = (e) => {
  let dateFormat = JSON.stringify(e);
  dateFormat = dateFormat.slice(1, 11);
};

const EditMagangPernyataan = ({
  getDetailPernyataanMagang,

  detailAdministrasi,
  editPernyataanMagang,
}) => {
  const { id } = useParams();

  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    nama_signer: "kaihan",
    nama_karyawan: "rairim",
    kop_surat: "A",
    tanggal_mulai: new Date(),
    tanggal_selesai: new Date(),
  });

  const getData = () => {
    getDetailPernyataanMagang(id);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: e.target.value,
    }));
  };

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama_signer: detailAdministrasi.nama_signer_code,
      nama_karyawan: detailAdministrasi.nama_karyawan_code,
      kop_surat: detailAdministrasi.kop_surat_code,
      tanggal_mulai: detailAdministrasi.tanggal_mulai,
      tanggal_selesai: detailAdministrasi.tanggal_selesai,
    });
  }, [detailAdministrasi]);

  const history = useHistory();

  const handleSubmit = () => {
    editPernyataanMagang({
      pernyataan_magang_code: id,
      nama_signer: formData.nama_signer,
      nama_karyawan: formData.nama_karyawan,
      kop_surat: formData.kop_surat,
      tanggal_mulai: formData.tanggal_mulai,
      tanggal_selesai: formData.tanggal_selesai,
    })
      .then(() => {
        Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
        history.push("/administrasi/magang/pernyataan");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-2 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="">
            <strong className="font-semibold text-25">
              Edit Surat Pernyataan Magang
            </strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-3"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 mb-2  "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className=" mb-10">
          <Grid
            container
            justifyContent="center"
            spacing={3}
            className="mt-4 px-8"
          >
            <Grid item xs={12}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Kop Surat</strong>
              </div>
              <Grid item xs={12}>
                <SelectText
                  kopSurat
                  label="Kop Surat"
                  value={formData.kop_surat}
                  handleChange={handleChange("kop_surat")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Nama Signer</strong>
              </div>
              <SelectText
                signer
                label="Nama Signer"
                value={formData.nama_signer}
                handleChange={handleChange("nama_signer")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Nama Karyawan</strong>
              </div>
              <SelectText
                karyawan
                label="Nama Karyawan"
                value={formData.nama_karyawan}
                handleChange={handleChange("nama_karyawan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Tanggal Mulai</strong>
              </div>
              <DatePickerComponent
                isDateFormat
                date={formData.tanggal_mulai}
                handleDate={handleDateChange}
                name="tanggal_mulai"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">
                  Tanggal Selesai
                </strong>
              </div>
              <DatePickerComponent
                isDateFormat
                date={formData.tanggal_selesai}
                handleDate={handleDateChange}
                name="tanggal_selesai"
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAdministrasi: state.administrasi.detailAdministrasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailPernyataanMagang: (code) =>
      dispatch(getDetailPernyataanMagang(code)),
    editPernyataanMagang: (params) => dispatch(editPernyataanMagang(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMagangPernyataan);

import { TableCell, TableRow, Button } from '@material-ui/core';
import MenuPtkp from '../menu/MenuPtkp';

const RenderTablePtkp = ({ data, page, rowsPerPage, getData }) => {
  const handleNumbering = () => {
    if (rowsPerPage === 5) {
      return page * 5;
    } else if (rowsPerPage === 10) {
      return page * 10;
    } else if (rowsPerPage === 25) {
      return page * 25;
    }
  };

  return data?.length > 0 ? (
    data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell className="text-semiblack text-14 pl-5" colSpan={1}>
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell className="text-semiblack text-14 pl-5" colSpan={4}>
            {item.status}
          </TableCell>
          <TableCell className="text-semiblack text-14 pl-5" colSpan={4}>
            {item.nominal === null
              ? ''
              : item.nominal == undefined
              ? ''
              : `Rp ${item.nominal
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}`}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 "
            align="center"
            colSpan={2}
          >
            <Button className="karyawan-aksi-dot elevation-z0">
              <MenuPtkp
                item={item}
                getData={getData}
                editPath={`/pajak/ptkp/edit/${item.ptkp_code}`}
                isPajak={false}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell
        className="font-medium text-12 line-height-28 text-body"
        colSpan={11}
        align="center"
      >
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTablePtkp;

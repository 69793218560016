import { useState } from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Icon,
  Dialog,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatTgl } from "app/utils/globalFunction";
import Modal from "./Modal";
import BadgeSelect from "app/components/select/BadgeSelect";
import { updateStatusCuti, updateStatusIzin } from "app/redux/actions/IzinCutiAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const RenderTable = ({ state, setState, statePage, izin, getData, dispatch }) => {
  const handleNumbering = () => {
    if (statePage.rowsPerPage === 5) {
      return statePage.page * 5;
    } else if (statePage.rowsPerPage === 10) {
      return statePage.page * 10;
    } else if (statePage.rowsPerPage === 25) {
      return statePage.page * 25;
    }
  };

  const handleStatusChange = async (event, item) => {
    const selectedStatus = event.target.value;
    if (izin) {
      if (selectedStatus !== 'ditolak') {
        await dispatch(updateStatusIzin({
          izin_code: item.izin_code,
          status: selectedStatus,
          tanggal_mulai: item.tanggal_mulai,
          tanggal_akhir: item.tanggal_akhir,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          selectedItem: { ...item },
          openDitolak: true,
        }));
      }
    } else {
      if (selectedStatus !== 'ditolak') {
        await dispatch(updateStatusCuti({
          cuti_code: item.cuti_code,
          status: selectedStatus,
          tanggal_mulai: item.tanggal_mulai,
          tanggal_akhir: item.tanggal_akhir,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          selectedItem: { ...item },
          openDitolak: true,
        }));
      }
    }
    getData();
  }

  return state.data?.length > 0 ? (
    state.data
      .slice(
        statePage.page * statePage.rowsPerPage,
        statePage.page * statePage.rowsPerPage + statePage.rowsPerPage
      )
      .filter((data) =>
        state.pengajuan
          ? data.status.toLowerCase() === "diproses"
          : data.status.toLowerCase() !== "diproses"
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            className="text-semiblack text-14"
            align="center"
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell
            className="text-14 pl-3 text-semiblack"
            colSpan={izin ? 6 : 4}
          >
            {item?.name}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={izin ? 4 : 3}
          >
            {item?.unit_kerja}
          </TableCell>
          {izin ? (
            <TableCell
              className="text-semiblack text-14 pl-3"
              align="center"
              colSpan={izin ? 4 : 3}
            >
              {item?.jenis_izin}
            </TableCell>
          ) : (
            <TableCell
              className="text-semiblack text-14 pl-3"
              align="center"
              colSpan={1}
            ></TableCell>
          )}
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={izin ? 8 : 5}
          >
            {formatTgl(item?.tanggal_mulai) === formatTgl(item?.tanggal_akhir)
              ? formatTgl(item?.tanggal_mulai)
              : formatTgl(item?.tanggal_mulai) +
                " - " +
                formatTgl(item?.tanggal_akhir)}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 pl-2"
            align="center"
            colSpan={3}
          >
            <BadgeSelect
              item={item}
              handleChange={(event) => handleStatusChange(event, item)}
              menus={[
                {
                  label: 'Diizinkan',
                  value: 'diizinkan',
                  icon: <Icon>{item.status.toLowerCase() === 'diizinkan' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                  color: '#72BE42',
                  backgroundColor: '#F3FFEC'
                },
                {
                  label: 'Ditolak',
                  value: 'ditolak',
                  icon: <Icon>{item.status.toLowerCase() === 'ditolak' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                  color: '#FF5C58',
                  backgroundColor: '#FFEBEA'
                },
                {
                  label: 'Diproses',
                  value: 'diproses',
                  icon: <Icon>{item.status.toLowerCase() === 'diproses' ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>,
                  color: '#FBC02A',
                  backgroundColor: '#FFF8E7'
                },
              ]}
              badgeBackgroundColor={item.status.toLowerCase() === 'diizinkan' ? '#72BE42' : item.status.toLowerCase() === 'ditolak' ? '#FF5C58' : '#FBC02A'}
              iconBackgroundColor={item.status.toLowerCase() === 'diizinkan' ? '#68B03A' : item.status.toLowerCase() === 'ditolak' ? '#F94A46' : '#F6B40E'}
              renderValue={(selected) => (selected === 'diizinkan' ? 'Diizinkan' : selected === 'ditolak' ? 'Ditolak' : 'Diproses')}
              value={item.status.toLowerCase()}
            />
          </TableCell>
          <TableCell className="text-14 pl-2" align="center" colSpan={2}>
            <Modal item={item} getData={getData} izin={izin} />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={izin ? 28 : 19} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const IzinPengajuan = ({ state, setState, izin = true, getData }) => {
  const dispatch = useDispatch();

  const [statePage, setStatePage] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setStatePage({
      ...statePage,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setStatePage({
      ...statePage,
      rowsPerPage: event.target.value,
    });
  };

  const handleCloseDitolak = () => {
    setState((prev) => ({
      ...prev,
      openDitolak: false,
    }));
  }

  const handleAlasan = (event) => {
    const alasan = event.target.value;
    setState((prev) => ({
     ...prev,
      selectedItem: { ...prev.selectedItem, alasan },
    }));
  }

  const handleKirimPenolakan = async () => {
    const item = state?.selectedItem;
    if (izin) {
      try {
        await dispatch(
          updateStatusIzin({
            izin_code: item?.izin_code,
            status: "Ditolak",
            alasan: item?.alasan,
            tanggal_mulai: item?.tanggal_mulai,
            tanggal_akhir: item?.tanggal_akhir,
          })
        );

        setTimeout(() => {
          handleCloseDitolak();
          Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
        }, 1000);
      } catch (e) {
        handleCloseDitolak();
        Swal.fire("Oopss!", `${e}`, "error");
      }
    } else {
      try {
        await dispatch(
          updateStatusCuti({
            cuti_code: item?.cuti_code,
            status: "Ditolak",
            alasan: item?.alasan,
            tanggal_mulai: item?.tanggal_mulai,
            tanggal_akhir: item?.tanggal_akhir,
          })
        );

        setTimeout(() => {
          handleCloseDitolak();
          Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
        }, 1000);
      } catch (e) {
        handleCloseDitolak();
        Swal.fire("Oopss!", `${e}`, "error");
      }
    }
    getData();
  };

  const useStyles = makeStyles({
    dialog: {
      width: "430px",
      height: "fit-content",
      scrollbarColor: "transparent",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} className="text-15" align="center">
              No
            </TableCell>
            <TableCell colSpan={izin ? 6 : 4} className="text-15 pl-3">
              Nama
            </TableCell>
            <TableCell
              colSpan={izin ? 4 : 3}
              className="text-15"
              align="center"
            >
              Unit Kerja
            </TableCell>
            {izin ? (
              <TableCell
                colSpan={izin ? 4 : 3}
                className="text-15"
                align="center"
              >
                Jenis Izin
              </TableCell>
            ) : (
              <TableCell
                colSpan={1}
                className="text-15"
                align="center"
              ></TableCell>
            )}
            <TableCell
              colSpan={izin ? 8 : 5}
              className="text-15"
              align="center"
            >
              Rentang Waktu
            </TableCell>
            <TableCell colSpan={3} className="text-15" align="center">
              Status
            </TableCell>
            <TableCell colSpan={2} className="text-15" align="center">
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable
            getData={getData}
            state={state}
            setState={setState}
            izin={izin}
            statePage={statePage}
            dispatch={dispatch}
          />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16 my-7"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={state.data?.length ? state.data?.length : 0}
        rowsPerPage={statePage.rowsPerPage}
        labelRowsPerPage={"From"}
        page={statePage.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />

      <Dialog
        open={state?.openDitolak}
        onClose={handleCloseDitolak}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: "hidden !important", margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-semibold text-black mb-5">
            Masukkan Alasan Ditolak
          </h1>
          <Grid container>
            <Grid item xs={12}>
              <p
                className="text-16 font-medium text-black"
                style={{ marginBottom: "-3px" }}
              >
                Alasan Ditolak
              </p>
              <textarea
                required={true}
                name="alasan"
                rows="3"
                cols="50"
                className="mt-3 w-full px-6 py-4 border-radius-5"
                placeholder="Alasan"
                style={{
                  border: "2px solid #e6e9ed",
                  fontSize: "1.5em",
                  fontFamily: "inherit",
                }}
                onChange={handleAlasan}
              />
            </Grid>

            <Grid item xs={6} className="mt-4 pr-3">
              <Button
                variant="outlined"
                className="w-full text-primary elevation-z0 p-4"
                onClick={handleCloseDitolak}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6} className="mt-4 pl-3">
              <Button
                variant="contained"
                className="w-full text-white bg-primary elevation-z0 p-4"
                onClick={handleKirimPenolakan}
              >
                Kirim
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default IzinPengajuan;

import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import SelectMagang from "./component/SelectMagang";

import {
  getPenerimaanMagang,
  delPenerimaanMagang,
  getDetailPenerimaanMagang,
  editPenerimaanMagang,
} from "app/redux/actions/administrasi/MagangAction";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SelectText } from "app/components";

const EditMagangPenerimaan = ({
  getDetailPenerimaanMagang,
  detailAdministrasi,
  editPenerimaanMagang,
}) => {
  const { id } = useParams();

  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    nama_signer: "",
    nama_karyawan: "",
    kop_surat: "",
    tanggal_mulai: new Date(),
    tanggal_selesai: new Date(),
    pembimbing: "",
    perusahaan: "",
    tipe: "",
    benefit: "",
    insentif: "",
    unit_kerja: "",
  });

  const getData = () => {
    getDetailPenerimaanMagang(id);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama_signer: detailAdministrasi?.nama_signer,
      nama_karyawan: detailAdministrasi?.nama_karyawan_code,
      kop_surat: detailAdministrasi?.kop_surat_code,
      tanggal_mulai: detailAdministrasi?.tanggal_mulai,
      tanggal_selesai: detailAdministrasi?.tanggal_selesai,
      perusahaan: detailAdministrasi?.perusahaan_code,
      unit_kerja: detailAdministrasi?.unit_kerja_code,
      pembimbing: detailAdministrasi?.pembimbing_code,
      tipe: detailAdministrasi?.tipe,
      benefit: detailAdministrasi?.benefit,
      insentif: detailAdministrasi?.insentif,
    });
  }, [detailAdministrasi]);

  const history = useHistory();

  const handleSubmit = () => {
    editPenerimaanMagang({
      penerimaan_magang_code: id,
      nama_signer: formData.nama_signer,
      nama_karyawan: formData.nama_karyawan,
      kop_surat: formData.kop_surat,
      tanggal_mulai: formData.tanggal_mulai,
      tanggal_selesai: formData.tanggal_selesai,
      pembimbing: formData.pembimbing,
      perusahaan: formData.perusahaan,
      tipe: formData.tipe,
      benefit: formData.benefit,
      insentif: formData.insentif,
      unit_kerja: formData.unit_kerja,
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Penerimaan Magan berhasil disimpan",
          "success"
        );
        history.push("/administrasi/magang/penerimaan");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const TipeData = ["hybrid", "online", "onsite"];

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-2 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="">
            <strong className="font-semibold text-25">
              Tambah Surat Penerimaan Magang
            </strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-3"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 mb-2  "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className=" mb-10">
          <Grid
            container
            justifyContent="center"
            spacing={3}
            className="mt-4 px-8"
          >
            <Grid item xs={12}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Kop Surat</strong>
              </div>
              <Grid item xs={12}>
                <SelectText
                  kopSurat
                  label="Kop Surat"
                  value={formData.kop_surat}
                  handleChange={handleChange("kop_surat")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Nama Signer</strong>
              </div>
              <SelectText
                signer
                label="Nama Signer"
                value={formData.nama_signer}
                handleChange={handleChange("nama_signer")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Nama Karyawan</strong>
              </div>
              <SelectText
                karyawan
                label="Nama Karyawan"
                value={formData.nama_karyawan}
                handleChange={handleChange("nama_karyawan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Tanggal Mulai</strong>
              </div>
              <DatePickerComponent
                isDateFormat
                date={formData.tanggal_mulai}
                handleDate={handleDateChange}
                name="tanggal_mulai"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">
                  Tanggal Selesai
                </strong>
              </div>
              <DatePickerComponent
                isDateFormat
                date={formData.tanggal_selesai}
                handleDate={handleDateChange}
                name="tanggal_selesai"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Perusahaan</strong>
              </div>
              <SelectText
                office
                label="Perusahaan"
                value={formData.perusahaan}
                handleChange={handleChange("perusahaan")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">
                  Nama Pembimbing
                </strong>
              </div>
              <SelectText
                karyawan
                label="Nama Pembimbing"
                value={formData.pembimbing}
                handleChange={handleChange("pembimbing")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Tipe</strong>
              </div>
              <SelectText
                dataSelect={TipeData}
                label="Tipe"
                value={formData.tipe}
                handleChange={handleChange("tipe")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Unit Kerja</strong>
              </div>
              <SelectText
                unitKerja
                label="Unit Kerja"
                value={formData.unit_kerja}
                handleChange={handleChange("unit_kerja")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Benefit</strong>
              </div>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.benefit}
                onChange={handleChange("benefit")}
                name="nominal"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Insentif</strong>
              </div>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.insentif}
                onChange={handleChange("insentif")}
                name="nominal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAdministrasi: state.administrasi.detailAdministrasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailPenerimaanMagang: (code) =>
      dispatch(getDetailPenerimaanMagang(code)),
    editPenerimaanMagang: (params) => dispatch(editPenerimaanMagang(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMagangPenerimaan);

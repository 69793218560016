import { API, setAuthToken } from 'app/config/api';

export const getListBonus = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get('/user/lainnya/jenis_bonus' + params);
};

export const addJenisBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(`/user/lainnya/jenis_bonus/add`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const delJenisBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.delete(
      `/user/lainnya/jenis_bonus/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getDetailJenisBonus = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get(`/user/lainnya/jenis_bonus/detail/${params}`);
};

export const editJenisBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/lainnya/jenis_bonus/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Chip } from '@material-ui/core';
import { SimpleCard } from 'matx';
import Swal from 'sweetalert2';

import { getAllKaryawan } from 'app/redux/actions/KaryawanActions';
import {
  addLokasiAbsensi,
  getTitikAbsensi,
} from 'app/redux/actions/AbsenAction';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import AbsensiDatePicker from './component/AbsensiDatePicker';
import AbsensiSelect from './component/AbsensiSelect';

const AddAbsensiLocation = ({
  dataKaryawan,
  getAllKaryawan,
  dataListTitikAbsensi,
  getTitikAbsensi,
  addLokasiAbsensi,
}) => {
  const [state, setState] = useState({
    users_code: '',
    tanggal_absensi: new Date(),
    titik_absensi_list: [],
    titik_absensi: '',
    waktu_absensi: ['Masuk'],
    data_karyawan: [],
  });

  const getData = () => {
    getAllKaryawan();
    getTitikAbsensi();
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const setDataKaryawan = dataKaryawan.map((data) => ({
      value: data.users_code,
      text: data.name,
    }));

    const setDataTitikAbsensi = dataListTitikAbsensi.map((data) => ({
      value: data.titik_absensi_code,
      text: data.nama_kantor,
    }));

    setState({
      ...state,
      data_karyawan: setDataKaryawan,
      titik_absensi_list: setDataTitikAbsensi,
    });
  }, [dataKaryawan, dataListTitikAbsensi]);

  const [chipActive, setChipActive] = useState([
    {
      kode: 0,
      value: 'Masuk',
      active: true,
    },
    {
      kode: 1,
      value: 'Pulang',
      active: false,
    },
    {
      kode: 2,
      value: 'Masuk Lembur',
      active: false,
    },
    {
      kode: 3,
      value: 'Pulang Lembur',
      active: false,
    },
  ]);

  const handleClickWaktuAbsensi = (kode) => {
    const [selectedObject] = chipActive.filter((data) => data.kode === kode);
    const filteredData = chipActive.filter((data) => data.kode !== kode);

    const objectData = { ...selectedObject, active: !selectedObject.active };
    filteredData.push(objectData);
    filteredData.sort((a, b) => a.kode - b.kode);
    setChipActive(filteredData);

    const jadwalKerja = filteredData.filter((jadwal) => jadwal.active);

    let dataJadwalKerja = [];
    jadwalKerja.forEach((data) => {
      dataJadwalKerja.push(data.value);
    });

    setState({
      ...state,
      waktu_absensi: dataJadwalKerja,
    });
  };

  const history = useHistory();

  const handleSubmit = async () => {
    let dateFormat = JSON.stringify(state.tanggal_absensi);
    dateFormat = dateFormat.slice(1, 11);
    try {
      await addLokasiAbsensi({
        users_code: state.users_code,
        tanggal_absensi: dateFormat,
        titik_absensi: state.titik_absensi,
        waktu_absensi: state.waktu_absensi,
      });

      Swal.fire('Success!', 'Data Lokasi Absensi berhasil disimpan', 'success');
      history.push('/lokasiabsensi');
    } catch (e) {
      Swal.fire('Oopss!', 'Data Lokasi Absensi gagal disimpan', 'error');
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    active: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '11px',
      width: 'fit-content',
      height: '28px',
      background: '#72BE42',
      border: '1px solid #72BE42',
      borderRadius: '50px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '28px',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    inactive: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '11px',
      width: 'fit-content',
      height: '28px',
      background: '#ffffff',
      border: '1px solid #72BE42',
      borderRadius: '50px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '28px',
      color: '#72BE42',
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 text-semiblack">
            Tambah Lokasi Absensi
          </h1>
        </Grid>
        <Grid item xs={12} sm className="d-flex mr-4 justify-end">
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard>
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-15">
                  Karyawan <span className="text-red">*</span>
                </h1>
                <AbsensiSelect
                  dataSelect={state.data_karyawan}
                  size="medium"
                  name="users_code"
                  state={state}
                  setState={setState}
                  label="Karyawan"
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                    paddingBlock: '10px',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-15">
                  Titik Absensi <span className="text-red">*</span>
                </h1>
                <AbsensiSelect
                  dataSelect={state.titik_absensi_list}
                  size="medium"
                  name="titik_absensi"
                  state={state}
                  setState={setState}
                  label="Titik Absensi"
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                    paddingBlock: '10px',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-15">
                  Tanggal Absensi <span className="text-red">*</span>
                </h1>
                <AbsensiDatePicker
                  state={state}
                  setState={setState}
                  name="tanggal_absensi"
                  size="medium"
                  format="long"
                  paddingBlock="0px"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-15">
                  Waktu Absensi <span className="text-red">*</span>
                </h1>
                <div className={classes.root}>
                  {chipActive.map((chip) => (
                    <Chip
                      key={chip.kode}
                      label={chip.value}
                      onClick={() => handleClickWaktuAbsensi(chip.kode)}
                      className={
                        chip.active ? classes.active : classes.inactive
                      }
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataKaryawan: state.karyawan.allData,
    dataListTitikAbsensi: state.absen.dataListTitikAbsensi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllKaryawan: () => dispatch(getAllKaryawan()),
    getTitikAbsensi: () => dispatch(getTitikAbsensi()),
    addLokasiAbsensi: (params) => dispatch(addLokasiAbsensi(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAbsensiLocation);

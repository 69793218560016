import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.white,
    "&$checked": {
      transform: "translateX(12px)",
      color: "#fcfcfc",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#72BE42",
        borderColor: "#72BE42",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch);

export default function AdministrasiSwitch({
  masihBekerja,
  setMasihBekerja,
  changeMasihBekerja,
}) {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <AntSwitch checked={masihBekerja} onChange={changeMasihBekerja} />
        </Grid>
        <Grid item>Masih Bekerja</Grid>
      </Grid>
    </Typography>
  );
}

import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";

import DatePickerComponent from "../../../components/select/DatePickerComponent";
import AdministrasiSwitch from "../component/AdministrasiSwitch";

import { addKeteranganKerja } from "app/redux/actions/administrasi/KerjaAction";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { SelectText } from "app/components";

const AddKerjaKeterangan = ({ addKeteranganKerja }) => {
  const [formData, setFormData] = useState({
    nama_signer: "",
    nama_karyawan: "",
    kop_surat: "",
    tanggal_mulai: new Date().toISOString().slice(0, 10),
    tanggal_selesai: new Date().toISOString().slice(0, 10),
    perusahaan: "",
    unit_kerja: "",
    status: "tidak bekerja",
  });

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const history = useHistory();

  const handleSubmit = () => {
    addKeteranganKerja({
      nama_signer: formData.nama_signer,
      nama_karyawan: formData.nama_karyawan,
      kop_surat: formData.kop_surat,
      tanggal_mulai: formData.tanggal_mulai,
      tanggal_selesai: formData.tanggal_selesai,
      perusahaan: formData.perusahaan,
      unit_kerja: formData.unit_kerja,
      status: formData.status,
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Keterangan Kerja berhasil disimpan",
          "success"
        );
        history.push("/administrasi/kerja/keterangan");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleChangeStatus = (e) => {
    const { checked } = e.target;

    const value = checked ? "masih bekerja" : "tidak bekerja";

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: value,
    }));
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">
              Tambah Surat Keterangan Kerja
            </strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="my-8 pb-8 font-semibold text-15">
          <Grid container justifyContent="center" spacing={3} className="px-8">
            <Grid item xs={12}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Kop Surat
                </strong>
              </p>
              <Grid item xs={12}>
                <SelectText
                  kopSurat
                  label="Kop Surat"
                  handleChange={handleChange("kop_surat")}
                  value={formData.kop_surat}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Nama Signer
                </strong>
              </p>
              <SelectText
                signer
                label="Nama Signer"
                handleChange={handleChange("nama_signer")}
                value={formData.nama_signer}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Nama Karyawan
                </strong>
              </p>
              <SelectText
                karyawan
                label="Nama Karyawan"
                handleChange={handleChange("nama_karyawan")}
                value={formData.nama_karyawan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Tanggal Mulai
                </strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal_mulai}
                handleDate={handleDateChange}
                name="tanggal_mulai"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Tanggal Selesai
                </strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal_selesai}
                handleDate={handleDateChange}
                name="tanggal_selesa"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Perusahaan
                </strong>
              </p>
              <SelectText
                office
                label="Perusahaan"
                handleChange={handleChange("perusahaan")}
                value={formData.perusahaan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15 h4 text-dark">
                  Unit Kerja
                </strong>
              </p>
              <SelectText
                unitKerja
                label="Unit Kerja"
                handleChange={handleChange("unit_kerja")}
                value={formData.unit_kerja}
              />
            </Grid>

            <Grid item xs={12}>
              <AdministrasiSwitch changeMasihBekerja={handleChangeStatus} />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { dataDivisiList: state.karyawan.dataDivisiList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addKeteranganKerja: (params) => dispatch(addKeteranganKerja(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddKerjaKeterangan);

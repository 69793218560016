import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import {
  getAturHariLibur,
  delAturHariLibur,
} from "app/redux/actions/AturanAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import RenderTableAturHariLibur from "../../components/table/RenderTableAturHariLibur";
import ic_searchfilter from "../../../assets/component/ic_searchfilter.svg";
import DatePickerComponent from "../../components/select/DatePickerComponent";
import { SelectText } from "app/components";

function createYearArray() {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let i = currentYear - 3; i <= currentYear + 3; i++) {
    yearArray.push(i.toString());
  }

  return yearArray;
}

const yearArray = createYearArray();
const currentYear = new Date().getFullYear();

const AturHariLibur = ({ dataAturan, getAturHariLibur, delAturHariLibur }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };
  const [search, setSearch] = useState("");
  const [searchTgl, setSearchTgl] = useState(currentYear);

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const getData = () => {
    // var dateFormat = JSON.stringify(searchTgl);
    // dateFormat = dateFormat.slice(1, 8);
    // dateFormat = dateFormat.split("-").reverse().join("-");
    let dateFormat = `?tahun=${searchTgl}`;

    let params =
      search === ""
        ? dateFormat
        : `?search=${search}&${dateFormat.slice(1, 20)}`;

    getAturHariLibur(params);
  };

  const handleDateChange = (date) => {
    const { value } = date.target;
    console.log(value);

    setSearchTgl(value);
  };

  useEffect(() => {
    getData();
  }, [searchTgl]);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delAturHariLibur(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  console.log(dataAturan);

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4">
        <h1>Atur Hari Libur</h1>
        <div className="tambah-button">
          <Link to={`/AturHariLibur/Tambah`}>
            <Button>Tambah</Button>
          </Link>
        </div>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center mt-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      style={{ paddingRight: "5px" }}
                      src={ic_searchfilter}
                    ></img>
                  </InputAdornment>
                ),
                style: { fontSize: 13 },
                placeholder: "Cari Nama Hari Libur",
              }}
            />
            <Grid item xs={3} sm={2} className="d-flex align-items-end">
              <SelectText
                dataSelect={yearArray}
                label="Tahun"
                value={searchTgl}
                handleChange={handleDateChange}
                search
              />
            </Grid>
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={2}>Nama</TableCell>

                  <TableCell align="center" colSpan={3}>
                    Tanggal Mulai
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Tanggal Akhir
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTableAturHariLibur
                  state={state}
                  data={dataAturan}
                  tableName="aturharilibur"
                  handleDelete={handleDelete}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataAturan?.length ? dataAturan?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAturan: state.aturan.dataAturan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAturHariLibur: (params) => dispatch(getAturHariLibur(params)),
    delAturHariLibur: (code) => dispatch(delAturHariLibur(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AturHariLibur);

import { Button, Avatar } from '@material-ui/core';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';

import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import ModalCrop from 'app/components/modals/ModalCrop';

const FotoProfileHandler = ({
  uploadFoto,
  maxSize,
  preview,
  aspectRatio,
  formatIcon,
  CardStyle,
  setState = { setState },
}) => {
  const [FileName, setFileName] = useState(null);
  const [FilePath, setFilePath] = useState(null);
  let fileInput = useRef();

  const getExtension = (filename) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  const checkImage = (filename) => {
    var ext = getExtension(filename);
    if (formatIcon) {
      switch (ext.toLowerCase()) {
        case 'svg':
        case 'webp':
          return true;
        default:
          return false;
      }
    } else {
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'png':
        case 'webp':
        case 'jpeg':
          return true;
        default:
          return false;
      }
    }
  };

  const kbConverter = (size) => {
    return size * 1024;
  };

  const handleImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    if (checkImage(e.target.files[0].name)) {
      let file = files[0];
      let reader = new FileReader();
      if (maxSize && kbConverter(maxSize) < file.size) {
        fileInput.current.value = '';
        setFileName('');
        uploadFoto('', '');
        Swal.fire({
          title: 'Oopss!',
          text: 'Ukuran file terlalu besar',
          imageUrl: '/assets/images/icon/ic_error.svg',
          imageWidth: 92,
          imageHeight: 92,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#0083E2',
        });
        return;
      }
      reader.readAsDataURL(file);
      const initFile = URL.createObjectURL(file);
      setFileName(file.name);
      reader.onloadend = () => {
        uploadFoto(reader.result, initFile);
      };
    } else {
      Swal.fire({
        title: 'Oopss!',
        text: 'Format file tidak didukung',
        imageUrl: '/assets/images/icon/ic_error.svg',
        imageWidth: 92,
        imageHeight: 92,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#0083E2',
      });
    }
  };

  const handleClickInput = (e) => {
    fileInput.current.click();
  };

  const handleDeleteImage = (e) => {
    setState((prev) => ({
      ...prev,
      foto_profile: '',
      img_profile_preview: '',
    }));
  };

  return (
    <div className="d-flex items-center">
      <Card style={CardStyle} className="position-relative shadow-none">
        {preview && (
          <div className="h-full w-full position-absolute">
            <Avatar
              src={preview}
              alt="preview foto"
              variant="rounded"
              className=""
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '100%',
              }}
            />
          </div>
        )}
        <input
          type="file"
          name="foto_profile"
          className="cursor-pointer"
          onChange={handleImageChange}
          ref={fileInput}
          style={{ display: 'none' }}
          accept={
            formatIcon
              ? 'image/svg+xml, image/webp'
              : 'image/webp, image/jpg, image/jpeg, image/png'
          }
          id="input-file"
        />
      </Card>
      {FilePath?.length > 0 ? (
        <ModalCrop
          handleClose={() => {
            setFilePath('');
            fileInput.current.value = '';
          }}
          open={true}
          filePath={FilePath}
          handleSave={(data) => {
            uploadFoto(data, data);
            setFilePath(null);
          }}
          handleUpload={() => fileInput.current?.click()}
          aspectRatio={aspectRatio || 1 / 1}
        />
      ) : null}
      <Button
        onClick={handleClickInput}
        variant="outlined"
        color="primary"
        className="p-5 ml-8 rounded-lg h4 font-weight-bolder"
        style={{
          height: '45px',
          border: '1px solid  #72be42',
          color: '#72be42',
        }}
      >
        <CreateIcon /> <span className="">Ubah</span>
      </Button>
      <Button
        onClick={handleDeleteImage}
        variant="outlined"
        color="primary"
        className="p-5 ml-5 rounded-lg h4 font-weight-bolder"
        style={{
          height: '45px',
          border: '1px solid  #FF5C58',
          color: '#FF5C58',
        }}
      >
        <DeleteOutlineIcon /> <span className="">Hapus</span>
      </Button>
    </div>
  );
};

export default FotoProfileHandler;

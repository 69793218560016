import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { dateFormatter } from 'globalize';
import { Box, Icon } from '@material-ui/core';
import { isNowOrLater, updateDate } from 'utils';

function AbsensiDatePicker({
  state,
  setState,
  name,
  tipe = 'dd/MM/yyyy',
  format = 'short',
  slice = 11,
  size = 'small',
  paddingBlock = '',
  scaleY = '1',
  addPrevNext = false,
  prevNextIndicator = 'bulan',
}) {
  const DatePicker = withStyles({
    root: {
      borderRadius: '5px',
      background: '#fbfbfb',
      width: format === 'long' ? '100%' : '210px',
      height: '46px',
      paddingBlock: paddingBlock,

      '& .MuiInputBase-root': {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
  })((props) => <KeyboardDatePicker {...props} />);
  const handleTanggal = (e) => {
    const event = new Date(e);

    let dateFormat = JSON.stringify(event);

    dateFormat = dateFormat.slice(1, slice);
    setState({
      ...state,
      [name]: dateFormat,
    });
  };

  return (
    <Box display="flex" alignItems="center">
      {addPrevNext && <Icon
        className="prev-next"
        onClick={
          () => {
            const newDate = updateDate(state.searchTgl, prevNextIndicator, 'kurang');
            setState((prevState) => ({
              ...prevState,
              searchTgl: newDate,
            }));
          }
        }
        style={{ cursor: "pointer", marginTop: "-10px" }}
      >chevron_left</Icon>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format={tipe}
          control={<KeyboardDatePicker />}
          value={state[name]}
          inputAdornmentProps={{
            position: 'end',
            backgroundColor: 'red',
          }}
          onChange={handleTanggal}
          size={size}
          style={{
            transform: `scaleY(${scaleY})`,
          }}
        />
      </MuiPickersUtilsProvider>
      {addPrevNext && <Icon
        className={`prev-next ${isNowOrLater(state.searchTgl, prevNextIndicator) ? 'prev-next-disabled' : ''}`}
        onClick={
          !isNowOrLater(state.searchTgl, prevNextIndicator) ?
            () => {
              const newDate = updateDate(state.searchTgl, prevNextIndicator, 'tambah');
              setState((prevState) => ({
                ...prevState,
                searchTgl: newDate,
              }));
            } :
            () => {}
        }
        style={{ cursor: "pointer", marginTop: "-10px" }}
      >chevron_right</Icon>}
    </Box>
  );
}

export default AbsensiDatePicker;

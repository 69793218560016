import { Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { SimpleCard } from "matx";

import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SelectText } from "app/components";

import { ValidatorForm } from "react-material-ui-form-validator";
import { getAturLembur } from "app/redux/actions/AturanAction";
import { editAturLembur } from "app/redux/actions/AturanAction";
import { set } from "lodash";

const AturLembur = ({ getAturLembur, dataAturan, editAturLembur }) => {
  const [state, setState] = useState({
    rumus_1: "",
    rumus_2: "",
    dari: "",
  });

  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = () => {
    getAturLembur();
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setState({
      waktu_jam: dataAturan.waktu_jam,
      upah_lembur: dataAturan.upah_lembur,
    });
  }, [dataAturan]);

  const handleSubmit = () => {
    editAturLembur({
      rumus_1: state.rumus_1,
      rumus_2: state.rumus_2,
      dari: state.dari,
      hari_kerja: hariKerjaArray,
      hari_libur: hariLiburArray,
    })
      .then(() => {
        Swal.fire("Success!", "Data Atur Lembur berhasil disimpan", "success");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi"
        );
      });
  };

  const hariKerjaValue = [1, 1, 2, 2, 2];

  const hariLiburValue = [1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2];

  const [hariLiburArray, setHariLiburArray] = useState([]);
  const [hariKerjaArray, setHariKerjaArray] = useState([]);

  useEffect(() => {
    setState({
      rumus_1: dataAturan.atur_lembur?.rumus_1,
      rumus_2: dataAturan.atur_lembur?.rumus_2,
      dari: dataAturan.atur_lembur?.dari,
    });
  }, [dataAturan.atur_lembur]);
  console.log(state);

  useEffect(() => {
    const populateArrays = () => {
      const hariLiburArray1 = [];
      const hariKerjaArray1 = [];

      dataAturan.atur_lembur_detail?.forEach((obj) => {
        if (obj.tipe === "hari libur") {
          hariLiburArray1.push(obj);
        } else if (obj.tipe === "hari kerja") {
          hariKerjaArray1.push(obj);
        }
      });

      setHariLiburArray(hariLiburArray1.map((obj) => obj.kelipatan));
      setHariKerjaArray(hariKerjaArray1.map((obj) => obj.kelipatan));
    };

    if (dataAturan.atur_lembur_detail) {
      populateArrays();
    }
  }, [dataAturan.atur_lembur_detail]);

  console.log(hariLiburArray, hariKerjaArray);

  const handleChangeArray = (index, tipe, e) => {
    const newValue = e.target.value;
    let updatedArray;

    if (tipe === "hari libur") {
      updatedArray = hariLiburArray.map((value, i) =>
        i === index ? newValue : value
      );
      setHariLiburArray(updatedArray);
    } else if (tipe === "hari kerja") {
      updatedArray = hariKerjaArray.map((value, i) =>
        i === index ? newValue : value
      );
      setHariKerjaArray(updatedArray);
    }
  };

  const renderInput = (data, tipe) => {
    if (data && data.length > 0) {
      return data.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <div className="render-input-text w-full">Jam ke {index + 1}</div>

          <Grid item sm={8} xs={16}>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              id="mui-theme-provider-outlined-input"
              value={item}
              onChange={(e) => handleChangeArray(index, tipe, e)}
              name="kelipatan"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
          <div className="atur-lembur-border">x</div>
          <Grid item sm={3} xs={6}>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              id="mui-theme-provider-outlined-input"
              disabled
              name="upah_lembur"
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="Upah Lembur"
            />
          </Grid>
        </Grid>
      ));
    } else {
      return null;
    }
  };

  const statusSearch = [
    {
      value: "gaji pokok",
      name: "Gaji Pokok",
    },
    {
      value: "tunjangan tidak tetap",
      name: "Tunjangan Tidak Tetap",
    },
    {
      value: "thp",
      name: "THP",
    },
  ];

  const [status, setStatus] = useState("Gaji Pokok");

  const handleStatus = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      dari: value,
    }));
  };

  return (
    <div>
      <div className="p-5">
        <Grid
          container
          spacing={3}
          className="my-2 mx-auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm>
            <h4 className="mb-5">Atur Lembur</h4>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            className="d-flex mr-4"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              className="px-15 py-3"
              onClick={handleSubmit}
            >
              <span className="">Simpan</span>
            </Button>
          </Grid>
        </Grid>
        <div className="mb-4 ">
          <SimpleCard loading={false} currency="" saldo="" heightInput={130}>
            <Fragment>
              <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                className="pi-4"
              >
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={24}>
                    <h5>Waktu (Jam)</h5>
                  </Grid>
                  <Grid item sm={2} xs={4}>
                    <TextField
                      className="text-field-modifier-long"
                      fullWidth
                      variant="standard"
                      id="mui-theme-provider-outlined-input"
                      value={state.rumus_1}
                      onChange={(e) => handleChange(e)}
                      name="rumus_1"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                  <div className="atur-lembur-border">/</div>
                  <Grid item sm={2} xs={4}>
                    <TextField
                      className="text-field-modifier-long"
                      fullWidth
                      variant="standard"
                      id="mui-theme-provider-outlined-input"
                      value={state.rumus_2}
                      onChange={(e) => handleChange(e)}
                      name="rumus_2"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                  <div className="atur-lembur-border">x</div>
                  <Grid item sm={7} xs={14}>
                    <SelectText
                      insertData
                      dataSelect={statusSearch}
                      label="Status"
                      handleChange={handleStatus}
                      value={state.dari}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Fragment>
          </SimpleCard>
        </div>

        <div className="mb-4 ">
          <SimpleCard loading={false} currency="" saldo="">
            <Fragment>
              <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                className="pi-4 mb-8"
              >
                <Grid item sm={12} xs={24}>
                  <h5>Hari Kerja</h5>
                </Grid>
                {renderInput(hariKerjaArray, "hari kerja")}
              </ValidatorForm>
            </Fragment>
          </SimpleCard>
        </div>

        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <ValidatorForm
              ref={formRef}
              onSubmit={handleSubmit}
              className="pi-4 mb-8"
            >
              <Grid item sm={12} xs={24}>
                <h5>Hari Libur</h5>
              </Grid>
              {renderInput(hariLiburArray, "hari libur")}
            </ValidatorForm>
          </Fragment>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAturan: state.aturan.dataAturan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAturLembur: () => dispatch(getAturLembur()),
    editAturLembur: (params) => dispatch(editAturLembur(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AturLembur);

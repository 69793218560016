import { GET_LIST_UNIT_KERJA } from '../actions/UnitKerjaAction';

const initialState = {
  dataListUnitKerja: [],
};

const UnitKerjaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_UNIT_KERJA: {
      return {
        ...state,
        dataListUnitKerja: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default UnitKerjaReducer;

import React, { useState, useEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import UploadImage from "app/components/button/UploadImage";

import {
  getDetailKopSurat,
  editKopSurat,
} from "app/redux/actions/administrasi/KopSuratAction";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const EditKopSurat = ({
  getDetailKopSurat,
  detailAdministrasi,
  editKopSurat,
}) => {
  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [judul, setJudul] = useState("");

  const [paramsFooter, setParamsFooter] = useState({
    img: "",
    img_preview: "",
  });

  const handleChangeFooter = (file, path) => {
    setParamsFooter((pref) => ({
      ...pref,
      img: file,
      img_preview: path,
    }));
  };

  console.log(paramsFooter);

  const [paramsHeader, setParamsHeader] = useState({
    img: "",
    img_preview: "",
  });

  const handleChangeHeader = (file, path) => {
    setParamsHeader((pref) => ({
      ...pref,
      img: file,
      img_preview: path,
    }));
  };

  const getData = () => {
    getDetailKopSurat(id);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setParamsFooter({
      img: detailAdministrasi.footer,
      img_preview: detailAdministrasi.footer,
    });
    setParamsHeader({
      img: detailAdministrasi.header,
      img_preview: detailAdministrasi.header,
    });
    setJudul(detailAdministrasi.judul);
  }, [detailAdministrasi]);

  const history = useHistory();

  const handleSubmit = () => {
    editKopSurat({
      kop_surat_code: id,
      header: paramsHeader.img,
      footer: paramsFooter.img,
      judul: judul,
    })
      .then(() => {
        Swal.fire("Success!", "Data Hari Libur berhasil disimpan", "success");
        history.push("/administrasi/KopSurat");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">Edit Kop Surat</strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="karyawan-add-ultra-container">
          <Grid
            container
            justifyContent="center"
            spacing={3}
            className="mt-8 px-8"
          >
            <Grid item sm={6} xs={12}>
              <h5>Bobot Nilai (%)</h5>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                id="mui-theme-provider-outlined-input"
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
                placeholder="Status"
                fullWidth
              />

              <Grid item sm={12} xs={24}>
                <h4>Foto Bukti</h4>
                <UploadImage
                  uploadFoto={handleChangeHeader}
                  preview={paramsHeader?.img_preview}
                  aspectRatio={400 / 200}
                  CardStyle={{ width: "100%", marginBottom: "10px" }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Foto Bukti</h4>
                <UploadImage
                  uploadFoto={handleChangeFooter}
                  preview={paramsFooter?.img_preview}
                  aspectRatio={400 / 200}
                  CardStyle={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} className="preview-kop-surat">
              <h4>Preview</h4>

              <img src={paramsHeader?.img_preview} alt="" />
              <div className="preview-kop-surat-container">
                <div className="preview-kop-surat-block" />
                <div className="preview-kop-surat-block" />
                <div className="preview-kop-surat-block" />
                <div className="preview-kop-surat-block" />
                <div className="preview-kop-surat-block-mini" />
              </div>
              <img src={paramsFooter?.img_preview} alt="" />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAdministrasi: state.administrasi.detailAdministrasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailKopSurat: (code) => dispatch(getDetailKopSurat(code)),
    editKopSurat: (params) => dispatch(editKopSurat(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKopSurat);

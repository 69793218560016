import { combineReducers } from "redux";

import LayoutReducer from "./LayoutReducer";
import LoginReducer from "./LoginReducer";
import NotificationReducer from "./NotificationReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import UserReducer from "./UserReducer";
import KaryawanReducer from "./KaryawanReducer";
import MOMReducer from "./MOMReducer";
import CheckTokenReducer from "./CheckTokenReducer";
import AbsenReducer from "./AbsenReducer";
import LainnyaReducer from "./LainnyaReducer";
import AturanReducer from "./AturanReducer";
import IzinCutiReducer from "./IzinCutiReducer";
import AdministrasiReducer from "./AdministrasiReducer";
import PenilaianReducer from "./PenilaianReducer";
import DashboardReducer from "./DashboardReducer";
import GajiReducer from "./GajiReducer";
import UnitKerjaReducer from "./UnitKerjaReducer";
import SuketReducer from "./SuketReducer";
import PeringatanReducer from "./PeringatanReducer";
import PenilaianReducer_sistem_lama from "./PenilaianReducer_sistem_lama";
import KaryawanReducer_sl from "./KaryawanReducer_sistem_lama";
import SuperReducer from "./adminLama/SuperReducer";
import PenerimaanReducer from "./adminLama/PenerimaanReducer";
import WebSettingReducer from "./adminLama/WebSettingReducer";
import SignerReducer from "./adminLama/SignerReducer";
import KaryawanLamaReducer from "./adminLama/KaryawanReducer";
import FaqReducer from "./FaqReducer";
import KebijakanPrivasiReducer from "./kebijakan/KebijakanPrivasiReducer";
import KebijakanAturanReducer from "./kebijakan/KebijakanAturanReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notifications: NotificationReducer,
  karyawan: KaryawanReducer,
  karyawan_sl: KaryawanReducer_sl,
  checkToken: CheckTokenReducer,
  mom: MOMReducer,
  izincuti: IzinCutiReducer,
  aturan: AturanReducer,
  absen: AbsenReducer,
  penilaian: PenilaianReducer,
  penilaian_sl: PenilaianReducer_sistem_lama,
  lainnya: LainnyaReducer,
  unitkerja: UnitKerjaReducer,
  administrasi: AdministrasiReducer,
  dashboard: DashboardReducer,
  gaji: GajiReducer,
  signer: SignerReducer,
  suket: SuketReducer,
  peringatan: PeringatanReducer,
  super: SuperReducer,
  setting: WebSettingReducer,
  penerimaan: PenerimaanReducer,
  suket: SuketReducer,
  signer: SignerReducer,
  karyawanLama: KaryawanLamaReducer,
  faq: FaqReducer,
  kebijakan: KebijakanPrivasiReducer,
  kebijakanAturan: KebijakanAturanReducer,
});

export default RootReducer;

import { RichTextEditor } from "matx";
import { TextField, Grid, Button } from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const TextEditorCard = ({
  title,
  state,
  handleContentChange,
  handleRemove,
  handleJudulChange,
  judul,
}) => {
  return (
    <>
      <div className="mb-20px" />

      <SimpleCard title="">
        <div className="karyawan-add-ultra-container">
          <Grid container spacing={2} className=" px-8">
            <Grid item xs={12} sm={6}>
              <div className="font-semibold text-20  mt-6 ">{title}</div>
            </Grid>

            <Grid container xs={12} sm={6} row justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                className="karyawan-btn-top mt-6 max-height-40px "
                onClick={handleRemove}
              >
                <DeleteOutlineIcon />
                <span className="karyawan-btn-span">Hapus Pasal</span>
              </Button>
            </Grid>
            <Grid item xs={24} sm={12}>
              <p className="font-semibold text-15">Judul</p>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                id="mui-theme-provider-outlined-input"
                value={judul}
                onChange={handleJudulChange}
                placeholder="Judul"
                fullWidth
              />
            </Grid>

            <Grid item xs={24} sm={12}>
              <RichTextEditor
                content={state}
                handleContentChange={handleContentChange}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </>
  );
};

export default TextEditorCard;

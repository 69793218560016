import { useState, useLayoutEffect, useEffect } from 'react';
import { SimpleCard } from 'matx';
import { Grid } from '@material-ui/core';

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import CardChartTotalPenghargaan from 'app/components/charts/CardChartTotalPenghargaan';
import DashboardSelect from './component/DashboardSelect';

import { connect } from 'react-redux';
import {
  getDataDashboardTop,
  getStatistikIzinCuti,
  getDataDashboardPermintaanIzin,
  getStatistikKeterlambatan,
} from 'app/redux/actions/DashboardActions';
import DashboardCardCounter from './component/DashboardCardCounter';
import DashboardTabelIzinTerbaru from './component/DashboardTabelIzinTerbaru';
import { filterDataGraph, setParams } from './DashboardFunction';

const Dashboard = ({
  dataDashboardTop,
  getDataDashboardTop,
  dataDashboardPermintaanIzin,
  getDataDashboardPermintaanIzin,
  dataStatistikIzinCuti,
  getStatistikIzinCuti,
  dataStatistikKeterlambatan,
  getStatistikKeterlambatan,
}) => {
  const [state, setState] = useState({
    pilih_waktu_list: [],
    pilih_waktu: new Date().getUTCFullYear(),
    pilih_periode: 'tahun',
    jumlah_karyawan: 0,
    jumlah_unit_kerja: 0,
    jumlah_izin_cuti: 0,
    jumlah_telat: 0,
    jumlah_cuti: 0,
    data_statistik_izin_cuti: { label: [], data: [] },
    data_statistik_keterlambatan: { label: [], data: [] },
  });

  const getData = () => {
    const params = setParams(state, setState);
    getDataDashboardTop(params);
    getDataDashboardPermintaanIzin(params);
    getStatistikIzinCuti(params);
    getStatistikKeterlambatan(params);
  };

  useLayoutEffect(() => {
    getData();
  }, [state.pilih_waktu, state.pilih_periode]);

  useEffect(() => {
    const { dataGraphIzinCuti, dataGraphKeterlambatan } = filterDataGraph(state, dataStatistikIzinCuti, dataStatistikKeterlambatan);

    setState((prev) => ({
      ...prev,
      jumlah_karyawan: dataDashboardTop.total_karyawan,
      jumlah_unit_kerja: dataDashboardTop.jumlah_unit_kerja,
      jumlah_izin_cuti: dataDashboardTop.izinCuti,
      jumlah_telat: dataDashboardTop.telat,
      data_statistik_izin_cuti: dataGraphIzinCuti,
      data_statistik_keterlambatan: dataGraphKeterlambatan,
    }));
  }, [
    dataDashboardTop,
    dataDashboardPermintaanIzin,
    dataStatistikIzinCuti,
    dataStatistikKeterlambatan,
  ]);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 my-auto text-semiblack">
            Dashboard
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex items-center mr-4 justify-end gap-10"
        >
          <DashboardSelect
            dataSelect={[
              {
                text: 'Tahun',
                value: 'tahun',
              },
              {
                text: 'Bulan',
                value: 'bulan',
              },
            ]}
            label="Pilih Periode"
            state={state}
            setState={setState}
            name="pilih_periode"
          />
          <DashboardSelect
            dataSelect={state.pilih_waktu_list}
            label={
              state.pilih_periode == 'tahun' ? 'Pilih Tahun' : 'Pilih Bulan'
            }
            state={state}
            setState={setState}
            name="pilih_waktu"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        className="d-flex align-items-center mt-3 mb-8"
      >
        <DashboardCardCounter
          title='Total Karyawan'
          count={state.jumlah_karyawan}
          Icon={PeopleAltOutlinedIcon}
        />
        <DashboardCardCounter
          title='Jumlah Unit Kerja'
          count={state.jumlah_unit_kerja}
          Icon={WorkOutlineOutlinedIcon}
        />
        <DashboardCardCounter
          title='Izin & Cuti'
          count={state.jumlah_izin_cuti}
          Icon={ExitToAppOutlinedIcon}
        />
        <DashboardCardCounter
          title='Telat'
          count={state.jumlah_telat}
          Icon={ScheduleOutlinedIcon}
        />
      </Grid>
      <div className="mt-16">
        <DashboardTabelIzinTerbaru
          data={dataDashboardPermintaanIzin}
          getData={getData}
        />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          className="mt-16"
        >
          <Grid item xs={12} sm={6}>
            <SimpleCard>
              <CardChartTotalPenghargaan
                label="Jumlah Izin & Cuti"
                chart={state.data_statistik_izin_cuti}
                loading={false}
                title="Statistik Izin & Cuti"
              />
            </SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleCard>
              <CardChartTotalPenghargaan
                label="Jumlah Terlambat"
                chart={state.data_statistik_keterlambatan}
                loading={false}
                title="Statistik Keterlambatan"
              />
            </SimpleCard>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDashboardTop: state.dashboard.dataDashboardTop,
    dataDashboardPermintaanIzin: state.dashboard.dataDashboardPermintaanIzin,
    dataStatistikIzinCuti: state.dashboard.dataStatistikIzinCuti,
    dataStatistikKeterlambatan: state.dashboard.dataStatistikKeterlambatan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataDashboardTop: (params) => dispatch(getDataDashboardTop(params)),
    getDataDashboardPermintaanIzin: (params) =>
      dispatch(getDataDashboardPermintaanIzin(params)),
    getStatistikIzinCuti: (params) => dispatch(getStatistikIzinCuti(params)),
    getStatistikKeterlambatan: (params) =>
      dispatch(getStatistikKeterlambatan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

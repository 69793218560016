import { API, setAuthToken } from "app/config/api";

export const GET_DATA_PENERIMAAN_KERJA = "GET_DATA_PENERIMAAN_KERJA";
export const GET_DETAIL_PENERIMAAN_KERJA = "GET_DETAIL_PENERIMAAN_KERJA";
export const DEL_PENERIMAAN_KERJA = "DEL_PENERIMAAN_KERJA";
export const GET_DATA_KETERANGAN_KERJA = "GET_DATA_KETERANGAN_KERJA";
export const GET_DETAIL_KETERANGAN_KERJA = "GET_DETAIL_KETERANGAN_KERJA";
export const DEL_KETERANGAN_KERJA = "DEL_KETERANGAN_KERJA";
export const GET_DATA_KONTRAK_KERJA = "GET_DATA_KONTRAK_KERJA";
export const GET_DETAIL_KONTRAK_KERJA = "GET_DETAIL_KONTRAK_KERJA";
export const DEL_KONTRAK_KERJA = "DEL_KONTRAK_KERJA";

export const getPenerimaanKerja = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/penerimaan_kerja${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_PENERIMAAN_KERJA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_PENERIMAAN_KERJA,
          payload: [],
        });
      });
  };
};

export const getDetailPenerimaanKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/penerimaan_kerja/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_PENERIMAAN_KERJA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_PENERIMAAN_KERJA,
        payload: [],
      });
    }
  };
};

export const delPenerimaanKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/penerimaan_kerja/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_PENERIMAAN_KERJA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addPenerimaanKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/penerimaan_kerja/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editPenerimaanKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/penerimaan_kerja/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getKeteranganKerja = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/keterangan_kerja${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_KETERANGAN_KERJA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_KETERANGAN_KERJA,
          payload: [],
        });
      });
  };
};

export const getDetailKeteranganKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/keterangan_kerja/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_KETERANGAN_KERJA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_KETERANGAN_KERJA,
        payload: [],
      });
    }
  };
};

export const addKeteranganKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/keterangan_kerja/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editKeteranganKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/keterangan_kerja/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delKeteranganKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/keterangan_kerja/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_KETERANGAN_KERJA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const getKontrakKerja = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/administrasi/kontrak_kerja${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_KONTRAK_KERJA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_KONTRAK_KERJA,
          payload: [],
        });
      });
  };
};

export const getDetailKontrakKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(
      `user/administrasi/kontrak_kerja/detail/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_KONTRAK_KERJA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_KONTRAK_KERJA,
        payload: [],
      });
    }
  };
};

export const delKontrakKerja = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/administrasi/kontrak_kerja/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_KONTRAK_KERJA,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addKontrakKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/administrasi/kontrak_kerja/add",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editKontrakKerja = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/kontrak_kerja/update`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const editKontrakKerjaStatus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      `user/administrasi/kontrak_kerja/update/status`,
      params
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

import { Button, ButtonGroup, Grid, Icon } from '@material-ui/core';
import { SimpleCard } from 'matx';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  editInformasiGaji,
  getDetailInformasiGaji,
} from 'app/redux/actions/gaji/InformasiGajiAction';
import Swal from 'sweetalert2';
import { Link, useParams, useHistory } from 'react-router-dom';
import { formatRupiah } from 'app/utils/globalFunction';

const DetailGaji = ({ getDetailInformasiGaji, detailInformasiGaji }) => {
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    search: '',
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: '',
    nameClick: '',
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: 'Indonesia',
        value: 'ind',
      },
      {
        label: 'English',
        value: 'eng',
      },
    ],
    bahasa: '',
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (code) => {
    window.location.href = `/mom/edit/${code}`;
  };

  const handleAdd = () => {
    const { history } = props;
    history.push('/mom/tambah');
  };

  const { id } = useParams();
  const [foundData, setFoundData] = useState();
  const getData = () => {
    getDetailInformasiGaji(id);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);

    setFoundData(detailInformasiGaji);
  }, [detailInformasiGaji]);

  console.log(foundData);

  return foundData ? (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="mb-20px">Detail Gaji</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="outlined"
            color="primary"
            className="px-15 py-3"
            onClick={() => history.push('/Gaji')}
          >
            <span className="">Kembali</span>
          </Button>
        </Grid>
      </Grid>
      <div style={{ marginBottom: '25px' }}>
        <SimpleCard loading={false} currency="" saldo="">
          <div className="simple-card-content">
            <h1 className="mb-20px">Info Karyawan</h1>

            <Fragment>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama</h5>
                  <div>{foundData?.name}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Unit Kerja</h5>
                  <div>{foundData?.unit_kerja}</div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Gaji Pokok</h5>
                  <div>{formatRupiah(foundData?.gaji_pokok)}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Tunjangan</h5>
                  <div>{formatRupiah(foundData?.tunjangan)}</div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Total Gaji</h5>
                  <div>{formatRupiah(foundData?.gaji_pokok + foundData?.tunjangan)}</div>
                </Grid>
              </Grid>
            </Fragment>
          </div>
        </SimpleCard>
      </div>
      <SimpleCard loading={false} currency="" saldo="">
        <div className="simple-card-content">
          <h1 className="mb-20px">Informasi Pembayaran</h1>

          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Bank</h5>
                <div>{foundData?.nama_bank}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>NO.Rekening</h5>
                <div>{foundData?.nomer_rekening}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Pemilik Akun</h5>
                <div>{foundData?.pemilik_akun}</div>
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </SimpleCard>
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailInformasiGaji: state.gaji.detailInformasiGaji,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailInformasiGaji: (code) => dispatch(getDetailInformasiGaji(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailGaji);

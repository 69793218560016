import { useState } from "react";
import { Dialog, Avatar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function ModalImg({ src }) {
  const [state, setState] = useState({
    created_at: "",
    openDialog: false,
    openDitolak: false,
    keterangan: "",
  });

  const useStyles = makeStyles({
    dialog: {
      width: "430px",
      height: "fit-content",
      scrollbarColor: "transparent",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      openDialog: true,
    }));
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      openDialog: false,
    }));
  };

  return (
    <div className="m-0 p-0">
      <Avatar
        className="w-full"
        onClick={handleClickOpen}
        src={src}
        style={{ height: "170px", cursor: "pointer" }}
        variant="rounded"
      />

      <Dialog
        open={state.openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <div
          className="my-8 px-5"
          style={{ overflow: "hidden !important", margin: 0 }}
        >
          <h1 className="text-18 font-semibold text-black mb-3">Detail Foto</h1>
          <Avatar
            sx={{ minWidth: "100%", minHeight: "100%" }}
            className="w-full h-full"
            style={{ height: "100%", width: "100%" }}
            src={src}
            variant="rounded"
          />
          <Button
            variant="outlined"
            color="warning"
            className="w-full elevation-z0 p-2 mt-4"
            onClick={handleClose}
          >
            Tutup
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

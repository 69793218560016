import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { green } from '@material-ui/core/colors';
import {
  Button,
  TextField,
  Radio,
  Grid,
  Chip,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import Swal from 'sweetalert2';
import {
  addKaryawan,
  getAllKaryawanSelect,
} from 'app/redux/actions/KaryawanActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UploadImage from 'app/components/button/UploadImage';
import DatePicker from './component/DatePicker';
import KaryawanSelect from './component/KaryawanSelect';
import {
  getAturWaktuAbsensi,
  getTitikAbsensi,
} from 'app/redux/actions/AbsenAction';
import { getListPtkp } from 'app/redux/actions/PajakAction';
import { getUnitKerjaList } from 'app/redux/actions/UnitKerjaAction';

const AddKaryawan = ({
  getUnitKerjaList,
  dataListUnitKerja,
  getTitikAbsensi,
  dataListTitikAbsensi,
  addKaryawan,
}) => {
  const [state, setState] = useState({
    foto_profile: '',
    img_profile_preview: '',
    foto_ktp: '',
    img_ktp_preview: '',
    foto_npwp: '',
    img_npwp_preview: '',
    foto_bpjs: '',
    img_bpjs_preview: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    unit_kerja: '',
    pilih_atasan: '',
    jenis_perjanjian: '',
    status: '',
    jenis_penghasilan: '',
    ptkp: '',
    tanggal_gajian: '',
    waktu_kerja: '',
    titik_absensi: '',
    nama_bank: '',
    tipe_karyawan: 'fulltime',
    showPassword: false,
    showKonfirmasiPassword: false,
    data_users: [],
    data_unit_kerja: [],
    data_titik_absensi: [],
    data_waktu_kerja: [],
    data_list_ptkp: [],
  });

  const inputRef = useRef({
    name: '',
    email: '',
    telepon: '',
    alamat: '',
    NIK: '',
    no_bpjs: '',
    no_npwp: '',
    hak_cuti_tahunan: '',
    gaji_pokok: '',
    tunjangan: '',
    jadwal_kerja: ['Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at"],
    nomer_rekening: '',
    pemilik_akun: '',
    password: '',
    konfirmasi_password: '',
    scaleY: '0.85',
  });

  useLayoutEffect(() => {
    getUnitKerjaList();
    getTitikAbsensi();

    getAllKaryawanSelect().then((res) => {
      let data_users = res.data?.data.map((item) => ({
        value: item.users_code,
        text: item.name,
      }));
      setState((prev) => ({
        ...prev,
        data_users,
      }));
    });

    getAturWaktuAbsensi().then((res) => {
      let data = res.data?.data.map((item) => ({
        value: item.atur_absensi_code,
        text: item.nama,
      }));
      setState((prev) => ({
        ...prev,
        data_waktu_kerja: data,
      }));
    });

    getListPtkp('').then((res) => {
      let data = res.data?.data.map((item) => ({
        value: item.ptkp_code,
        text: item.status,
      }));
      setState((prev) => ({
        ...prev,
        data_list_ptkp: data,
      }));
    });
  }, []);

  useEffect(() => {
    if (state.data_unit_kerja.length == 0) {
      const data_unit_kerja = dataListUnitKerja.map((unitkerja) => ({
        value: unitkerja.unit_kerja_code,
        text: unitkerja.unit_kerja,
      }));
      setState((prev) => ({
        ...prev,
        data_unit_kerja,
      }));
    }
    if (state.data_titik_absensi.length == 0) {
      const data_titik_absensi = dataListTitikAbsensi.map((titikabsensi) => ({
        value: titikabsensi.titik_absensi_code,
        text: titikabsensi.nama_kantor,
      }));
      setState((prev) => ({
        ...prev,
        data_titik_absensi,
      }));
    }
  }, [dataListUnitKerja, dataListTitikAbsensi]);

  const handleChange = (e) => {
    inputRef.current = {
      ...inputRef.current,
      [e.target.name]: e.target.value,
    };
  };

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const handleChangeProfile = (file, path) => {
    setState({
      ...state,
      foto_profile: file,
      img_profile_preview: path,
    });
  };

  const handleChangeKtp = (file, path) => {
    setState({
      ...state,
      foto_ktp: file,
      img_ktp_preview: path,
    });
  };

  const handleChangeNpwp = (file, path) => {
    setState({
      ...state,
      foto_npwp: file,
      img_npwp_preview: path,
    });
  };

  const handleChangeBpjs = (file, path) => {
    setState({
      ...state,
      foto_bpjs: file,
      img_bpjs_preview: path,
    });
  };

  const [chipActive, setChipActive] = useState([
    {
      kode: 0,
      hari: 'Senin',
      active: true,
    },
    {
      kode: 1,
      hari: 'Selasa',
      active: true,
    },
    {
      kode: 2,
      hari: 'Rabu',
      active: true,
    },
    {
      kode: 3,
      hari: 'Kamis',
      active: true,
    },
    {
      kode: 4,
      hari: "Jum'at",
      active: true,
    },
    {
      kode: 5,
      hari: 'Sabtu',
      active: false,
    },
    {
      kode: 6,
      hari: 'Minggu',
      active: false,
    },
  ]);

  const handleClickHari = (kode) => {
    const [selectedObject] = chipActive.filter((data) => data.kode === kode);
    const filteredData = chipActive.filter((data) => data.kode !== kode);

    const objectData = { ...selectedObject, active: !selectedObject.active };
    filteredData.push(objectData);
    filteredData.sort((a, b) => a.kode - b.kode);
    setChipActive(filteredData);

    const jadwalKerja = filteredData.filter((jadwal) => jadwal.active);
    let dataJadwalKerja = [];
    jadwalKerja.forEach((data) => {
      dataJadwalKerja.push(data.hari);
    });

    inputRef.current = {
      ...inputRef.current,
      jadwal_kerja: dataJadwalKerja,
    };
  };

  const history = useHistory();

  const handleSubmit = async () => {
    console.log(inputRef.current);
    console.log(state);
    if (inputRef.current.password === inputRef.current.konfirmasi_password) {
      if (
        state.tipe_karyawan === 'fulltime' &&
        state.foto_profile !== '' &&
        state.foto_ktp !== '' &&
        state.foto_npwp !== '' &&
        inputRef.current.name !== '' &&
        inputRef.current.name !== undefined &&
        inputRef.current.email !== '' &&
        inputRef.current.email !== undefined &&
        inputRef.current.telepon !== '' &&
        inputRef.current.telepon !== undefined &&
        state.tanggal_lahir !== '' &&
        inputRef.current.alamat !== '' &&
        inputRef.current.alamat !== undefined &&
        inputRef.current.NIK !== '' &&
        inputRef.current.NIK !== undefined &&
        inputRef.current.no_npwp !== '' &&
        inputRef.current.no_npwp !== undefined &&
        state.jenis_kelamin !== '' &&
        state.unit_kerja !== '' &&
        state.pilih_atasan !== '' &&
        inputRef.current.hak_cuti_tahunan !== '' &&
        inputRef.current.hak_cuti_tahunan !== undefined &&
        state.jenis_perjanjian !== '' &&
        state.status !== '' &&
        inputRef.current.gaji_pokok !== '' &&
        inputRef.current.gaji_pokok !== undefined &&
        inputRef.current.tunjangan !== '' &&
        inputRef.current.tunjangan !== undefined &&
        state.jenis_penghasilan !== '' &&
        state.ptkp !== '' &&
        state.tanggal_gajian !== '' &&
        state.waktu_kerja !== '' &&
        state.titik_absensi !== '' &&
        state.nama_bank !== '' &&
        inputRef.current.nomer_rekening !== '' &&
        inputRef.current.pemilik_akun !== '' &&
        inputRef.current.password !== ''
      ) {
        try {
           await addKaryawan({
            foto_profile: state.foto_profile,
            foto_ktp: state.foto_ktp,
            foto_npwp: state.foto_npwp,
            foto_bpjs: state.foto_bpjs,
            name: inputRef.current.name,
            email: inputRef.current.email,
            telepon: inputRef.current.telepon,
            tanggal_lahir: state.tanggal_lahir,
            alamat: inputRef.current.alamat,
            NIK: inputRef.current.NIK,
            no_npwp: inputRef.current.no_npwp,
            no_bpjs: inputRef.current.no_bpjs,
            jenis_kelamin: state.jenis_kelamin,
            unit_kerja: state.unit_kerja,
            pilih_atasan: state.pilih_atasan,
            hak_cuti_tahunan: inputRef.current.hak_cuti_tahunan,
            jenis_perjanjian: state.jenis_perjanjian,
            status: state.status,
            gaji_pokok: inputRef.current.gaji_pokok,
            tunjangan: inputRef.current.tunjangan,
            jenis_penghasilan: state.jenis_penghasilan,
            ptkp: state.ptkp,
            tanggal_gajian: state.tanggal_gajian,
            waktu_kerja: state.waktu_kerja,
            jadwal_kerja: inputRef.current.jadwal_kerja || [
              'Senin',
              'Selasa',
              'Rabu',
              'Kamis',
              "Jum'at",
            ],
            titik_absensi: state.titik_absensi,
            nama_bank: state.nama_bank,
            nomer_rekening: inputRef.current.nomer_rekening,
            pemilik_akun: inputRef.current.pemilik_akun,
            password: inputRef.current.password,
            tipe_karyawan: state.tipe_karyawan,
            role: 'User 1',
          });

          let timerInterval;
          Swal.fire({
            title: 'Sedang diproses...',
            html: 'tunggu dalam waktu <b></b> detik.',
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              setTimeout(() => {
                clearInterval(timerInterval);
                history.push('/karyawan');
                Swal.fire(
                  'Success!',
                  'Data karyawan berhasil disimpan',
                  'success'
                );
              }, 4000);
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 1000);
            },
          });
        } catch (e) {
          Swal.fire('Oopss!', 'Data karyawan gagal disimpan', 'error');
        }
      } else if (
        state.tipe_karyawan === 'internship' &&
        state.foto_profile !== '' &&
        state.foto_ktp !== '' &&
        inputRef.current.name !== '' &&
        inputRef.current.name !== undefined &&
        inputRef.current.email !== '' &&
        inputRef.current.email !== undefined &&
        inputRef.current.telepon !== '' &&
        inputRef.current.telepon !== undefined &&
        state.tanggal_lahir !== '' &&
        inputRef.current.alamat !== '' &&
        inputRef.current.alamat !== undefined &&
        inputRef.current.NIK !== '' &&
        inputRef.current.NIK !== undefined &&
        state.jenis_kelamin !== '' &&
        state.unit_kerja !== '' &&
        state.pilih_atasan !== '' &&
        state.status !== '' &&
        inputRef.current.gaji_pokok !== '' &&
        inputRef.current.gaji_pokok !== undefined &&
        state.tanggal_gajian !== '' &&
        state.waktu_kerja !== '' &&
        state.titik_absensi !== '' &&
        state.nama_bank !== '' &&
        inputRef.current.nomer_rekening !== '' &&
        inputRef.current.pemilik_akun !== '' &&
        inputRef.current.password !== ''
      ) {
        try {
          await addKaryawan({
            foto_profile: state.foto_profile,
            foto_ktp: state.foto_ktp,
            name: inputRef.current.name,
            email: inputRef.current.email,
            telepon: inputRef.current.telepon,
            tanggal_lahir: state.tanggal_lahir,
            alamat: inputRef.current.alamat,
            NIK: inputRef.current.NIK,
            jenis_kelamin: state.jenis_kelamin,
            unit_kerja: state.unit_kerja,
            pilih_atasan: state.pilih_atasan,
            status: state.status,
            gaji_pokok: inputRef.current.gaji_pokok,
            tanggal_gajian: state.tanggal_gajian,
            waktu_kerja: state.waktu_kerja,
            jadwal_kerja: inputRef.current.jadwal_kerja,
            titik_absensi: state.titik_absensi,
            nama_bank: state.nama_bank,
            nomer_rekening: inputRef.current.nomer_rekening,
            pemilik_akun: inputRef.current.pemilik_akun,
            password: inputRef.current.password,
            tipe_karyawan: state.tipe_karyawan,
            role: 'User 1',
          });

          let timerInterval;
          Swal.fire({
            title: 'Sedang diproses...',
            html: 'tunggu dalam waktu <b></b> detik.',
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              setTimeout(() => {
                clearInterval(timerInterval);
                // history.push('/karyawan');
                Swal.fire(
                  'Success!',
                  'Data karyawan berhasil disimpan',
                  'success'
                );
              }, 4000);
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 1000);
            },
          });
        } catch (e) {
          Swal.fire('Oopss!', 'Data karyawan gagal disimpan', 'error');
        }
      } else {
        Swal.fire('Oopss!', 'Data isian karyawan tidak lengkap', 'error');
      }
    } else {
      Swal.fire(
        'Oopss!',
        'Password dan konfirmasi password tidak sama',
        'error'
      );
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    active: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '11px',
      width: '80px',
      height: '28px',
      background: '#72BE42',
      border: '1px solid #72BE42',
      borderRadius: '50px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '28px',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    inactive: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '11px',
      width: '80px',
      height: '28px',
      background: '#ffffff',
      border: '1px solid #72BE42',
      borderRadius: '50px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '28px',
      color: '#72BE42',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #e6e9ed',
      },
    },
    input: {
      transform: 'scaleY(0.88)',
      marginBlock: 'auto',
    },
  }));

  const classes = useStyles();
  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-semiblack text-25">
            Tambah Karyawan
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="mt-9 mb-8"
            >
              <Grid
                item
                xs={12}
                sm
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Info Umum
                </h1>
              </Grid>
              <Grid
                item
                xs={12}
                sm
                className="d-flex align-items-center"
                style={{ justifyContent: 'flex-end' }}
              >
                <GreenRadio
                  ref={inputRef}
                  value="fulltime"
                  control={<Radio />}
                  label="Full Time"
                  checked={state.tipe_karyawan === 'fulltime' ? true : false}
                  onClick={() =>
                    setState({
                      ...state,
                      tipe_karyawan: 'fulltime',
                    })
                  }
                />
                <span className="mr-6">Full Time</span>
                <GreenRadio
                  ref={inputRef}
                  value="internship"
                  control={<Radio />}
                  label="internship"
                  checked={state.tipe_karyawan === 'fulltime' ? false : true}
                  onClick={() =>
                    setState({
                      ...state,
                      tipe_karyawan: 'internship',
                    })
                  }
                />
                <span>Internship</span>
              </Grid>
            </Grid>
            <Grid
              className=""
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} className="mb-4">
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Foto Profile <span className="text-red">*</span>
                </h1>
                <UploadImage
                  name="foto_profile"
                  uploadFoto={handleChangeProfile}
                  preview={state.img_profile_preview}
                  aspectRatio={300 / 454}
                  CardStyle={{
                    width: '100%',
                    height: '300px',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-4">
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Foto KTP <span className="text-red">*</span>
                </h1>
                <UploadImage
                  name="foto_ktp"
                  uploadFoto={handleChangeKtp}
                  preview={state.img_ktp_preview}
                  aspectRatio={300 / 454}
                  CardStyle={{
                    width: '100%',
                    height: '300px',
                  }}
                />
              </Grid>
            </Grid>
            {state.tipe_karyawan === 'fulltime' && (
              <Grid
                container
                spacing={5}
                className=""
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} className="mb-4">
                  <h1 className="text-semiblack mb-6 font-medium text-14">
                    Foto NPWP <span className="text-red">*</span>
                  </h1>
                  <UploadImage
                    name="foto_npwp"
                    uploadFoto={handleChangeNpwp}
                    preview={state.img_npwp_preview}
                    aspectRatio={300 / 454}
                    CardStyle={{
                      width: '100%',
                      height: '300px',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-4">
                  <h1 className="text-semiblack mb-6 font-medium text-14">
                    Foto BPJS
                  </h1>
                  <UploadImage
                    name="foto_bpjs"
                    uploadFoto={handleChangeBpjs}
                    preview={state.img_bpjs_preview}
                    aspectRatio={300 / 454}
                    CardStyle={{
                      width: '100%',
                      height: '300px',
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Nama <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{
                    className: classes.input,
                  }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  name="name"
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Nama"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Email <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{
                    className: classes.input,
                  }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  name="email"
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Email"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  No. Telp <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{
                    className: classes.input,
                  }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  name="telepon"
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="No. Telp"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Tanggal Lahir <span className="text-red">*</span>
                </h1>
                <DatePicker
                  required={true}
                  state={state}
                  setState={setState}
                  name="tanggal_lahir"
                  scaleY="0.85"
                />
              </Grid>
              <Grid item xs={12}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Alamat <span className="text-red">*</span>
                </h1>
                <textarea
                  name="alamat"
                  rows="4"
                  cols="50"
                  className="mt-3 w-full px-6 py-4 border-radius-5"
                  placeholder="Alamat"
                  style={{
                    border: '2px solid #e6e9ed',
                    fontSize: '1.5em',
                    fontFamily: 'inherit',
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  NIK <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{
                    className: classes.input,
                  }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  name="NIK"
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="NIK"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack mb-5 font-medium text-14">
                    No. BPJS
                  </h1>
                  <TextField
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    ref={inputRef}
                    name="no_bpjs"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="No. BPJS"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
              )}

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack mb-5 font-medium text-14">
                    No. NPWP <span className="text-red">*</span>
                  </h1>
                  <TextField
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    ref={inputRef}
                    name="no_npwp"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="No. NPWP"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Jenis Kelamin <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={[
                    {
                      value: 'perempuan',
                      text: 'Perempuan',
                    },
                    {
                      value: 'laki-laki',
                      text: 'Laki-laki',
                    },
                  ]}
                  size="small"
                  label="Jenis Kelamin"
                  name="jenis_kelamin"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Unit Kerja <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={state.data_unit_kerja}
                  size="small"
                  label="Unit Kerja"
                  name="unit_kerja"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Pilih Atasan <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={state.data_users}
                  size="small"
                  label="Pilih Atasan"
                  name="pilih_atasan"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack mb-5 font-medium text-14">
                    Hak Cuti Tahunan (Hari) <span className="text-red">*</span>
                  </h1>
                  <TextField
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    ref={inputRef}
                    name="hak_cuti_tahunan"
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="Hak Cuti Tahunan (Hari)"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
              )}

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack font-medium text-14 mb-5">
                    Jenis Perjanjian <span className="text-red">*</span>
                  </h1>
                  <KaryawanSelect
                    menuItemFontSize="text-16"
                    width="100%"
                    scaleY="1.25"
                    dataSelect={[
                      {
                        value: 'magang',
                        text: 'Magang',
                      },
                      {
                        value: 'pkwt',
                        text: 'PKWT',
                      },
                      {
                        value: 'pkwtt',
                        text: 'PKWTT',
                      },
                    ]}
                    size="small"
                    label="Jenis Perjanjian"
                    name="jenis_perjanjian"
                    state={state}
                    setState={setState}
                    style="w-full"
                    styleMenu={{
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                  />
                </Grid>
              )}

              <Grid
                container
                className="mt-3 mb-8 px-8"
                spacing={5}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack font-medium text-14 mb-5">
                    Status <span className="text-red">*</span>
                  </h1>
                  <KaryawanSelect
                    menuItemFontSize="text-16"
                    width="100%"
                    scaleY="1.25"
                    dataSelect={[
                      {
                        value: 'aktif',
                        text: 'Aktif',
                      },
                      {
                        value: 'tidak aktif',
                        text: 'Tidak Aktif',
                      },
                    ]}
                    size="small"
                    label="Status"
                    name="status"
                    state={state}
                    setState={setState}
                    style="w-full"
                    styleMenu={{
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
      <div className="my-10">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="my-4"
            >
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Gaji & Jadwal
                </h1>
              </Grid>
              <Grid item xs={12} sm />
            </Grid>
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Gaji Pokok <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{ className: classes.input }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  name="gaji_pokok"
                  onChange={handleChange}
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Gaji Pokok"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="">Rp</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack mb-5 font-medium text-14">
                    Tunjangan Tetap<span className="text-red">*</span>
                  </h1>
                  <TextField
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{
                      transform: 'scaleY(1.25)',
                    }}
                    ref={inputRef}
                    className={`${classes.outlined} border-radius-5 w-full`}
                    placeholder="Tunjangan Tetap"
                    name="tunjangan"
                    onChange={handleChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="">Rp</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack font-medium text-14 mb-5">
                    Jenis Penghasilan <span className="text-red">*</span>
                  </h1>
                  <KaryawanSelect
                    menuItemFontSize="text-16"
                    width="100%"
                    scaleY="1.25"
                    dataSelect={state.data_list_ptkp}
                    size="small"
                    label="Jenis Penghasilan"
                    name="jenis_penghasilan"
                    state={state}
                    setState={setState}
                    style="w-full"
                    styleMenu={{
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                  />
                </Grid>
              )}

              {state.tipe_karyawan === 'fulltime' && (
                <Grid item xs={12} sm={6}>
                  <h1 className="text-semiblack font-medium text-14 mb-5">
                    PTKP <span className="text-red">*</span>
                  </h1>
                  <KaryawanSelect
                    menuItemFontSize="text-16"
                    width="100%"
                    scaleY="1.25"
                    dataSelect={state.data_list_ptkp}
                    size="small"
                    label="PTKP"
                    name="ptkp"
                    state={state}
                    setState={setState}
                    style="w-full"
                    styleMenu={{
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Tanggal Gajian <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={[
                    {
                      value: 'Awal Bulan ( Hari Pertama )',
                      text: 'Awal Bulan ( Hari Pertama )',
                    },
                    {
                      value: 'Akhir Bulan',
                      text: 'Akhir Bulan',
                    },
                  ]}
                  size="small"
                  label="Tanggal Gajian"
                  name="tanggal_gajian"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Waktu Kerja <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={state.data_waktu_kerja}
                  size="small"
                  label="Waktu Kerja"
                  name="waktu_kerja"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>

              {state.tipe_karyawan === 'fulltime' ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <h1 className="text-semiblack font-medium text-14 mb-5">
                      Jadwal Kerja <span className="text-red">*</span>
                    </h1>
                    <div className={classes.root}>
                      {chipActive.map((chip) => (
                        <Chip
                          key={chip.kode}
                          label={chip.hari}
                          onClick={() => handleClickHari(chip.kode)}
                          className={
                            chip.active ? classes.active : classes.inactive
                          }
                        />
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h1 className="text-semiblack font-medium text-14 mb-5">
                      Titik Absensi <span className="text-red">*</span>
                    </h1>
                    <KaryawanSelect
                      menuItemFontSize="text-16"
                      width="100%"
                      scaleY="1.25"
                      dataSelect={state.data_titik_absensi}
                      size="small"
                      label="Titik Absensi"
                      name="titik_absensi"
                      state={state}
                      setState={setState}
                      style="w-full"
                      styleMenu={{
                        fontSize: '15px',
                        fontWeight: '400',
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <h1 className="text-semiblack font-medium text-14 mb-5">
                      Titik Absensi <span className="text-red">*</span>
                    </h1>
                    <KaryawanSelect
                      menuItemFontSize="text-16"
                      width="100%"
                      scaleY="1.25"
                      dataSelect={state.data_titik_absensi}
                      size="small"
                      label="Titik Absensi"
                      name="titik_absensi"
                      state={state}
                      setState={setState}
                      style="w-full"
                      styleMenu={{
                        fontSize: '15px',
                        fontWeight: '400',
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    className="px-8 mt-1"
                    spacing={5}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={6}>
                      <h1 className="text-semiblack font-medium text-14 mb-5">
                        Jadwal Kerja <span className="text-red">*</span>
                      </h1>
                      <div className={classes.root}>
                        {chipActive.map((chip) => (
                          <Chip
                            key={chip.kode}
                            label={chip.hari}
                            onClick={() => handleClickHari(chip.kode)}
                            className={
                              chip.active ? classes.active : classes.inactive
                            }
                          />
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </SimpleCard>
      </div>
      <div className="my-10">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="my-4"
            >
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Informasi Pembayaran
                </h1>
              </Grid>
              <Grid item xs={12} sm />
            </Grid>
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack font-medium text-14 mb-5">
                  Nama Bank <span className="text-red">*</span>
                </h1>
                <KaryawanSelect
                  menuItemFontSize="text-16"
                  width="100%"
                  scaleY="1.25"
                  dataSelect={[
                    {
                      value: 'Mandiri',
                      text: 'Mandiri',
                    },
                    {
                      value: 'BRI',
                      text: 'BRI',
                    },
                    {
                      value: 'BCA',
                      text: 'BCA',
                    },
                    {
                      value: 'BNI',
                      text: 'BNI',
                    },
                    {
                      value: 'BTN',
                      text: 'BTN',
                    },
                    {
                      value: 'BSI',
                      text: 'BSI',
                    }
                  ]}
                  size="small"
                  label="Nama Bank"
                  name="nama_bank"
                  state={state}
                  setState={setState}
                  style="w-full"
                  styleMenu={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  No. Rekening <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{ className: classes.input }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="No. Rekening"
                  variant="outlined"
                  name="nomer_rekening"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Pemilik Akun <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{ className: classes.input }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Pemilik Akun"
                  variant="outlined"
                  name="pemilik_akun"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
      <div className="my-10">
        <SimpleCard className="">
          <div className="mx-8 px-10 mt-5 mb-8">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              className="my-4"
            >
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex justify-content-end align-items-center"
              >
                <h1
                  className="font-semibold text-20"
                  style={{
                    color: '#0a0a0a',
                  }}
                >
                  Informasi Password
                </h1>
              </Grid>
              <Grid item xs={12} sm />
            </Grid>
            <Grid
              container
              className="mt-3"
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Password <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{ className: classes.input }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  type={state.showPassword ? 'text' : 'password'}
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Password"
                  variant="outlined"
                  name="password"
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setState({
                              ...state,
                              showPassword: !state.showPassword,
                            })
                          }
                          edge="end"
                        >
                          {state.showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-semiblack mb-5 font-medium text-14">
                  Konfirmasi Password <span className="text-red">*</span>
                </h1>
                <TextField
                  size="small"
                  inputProps={{ className: classes.input }}
                  style={{
                    transform: 'scaleY(1.25)',
                  }}
                  ref={inputRef}
                  type={state.showKonfirmasiPassword ? 'text' : 'password'}
                  className={`${classes.outlined} border-radius-5 w-full`}
                  placeholder="Konfirmasi Password"
                  variant="outlined"
                  name="konfirmasi_password"
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setState({
                              ...state,
                              showKonfirmasiPassword:
                                !state.showKonfirmasiPassword,
                            })
                          }
                          edge="end"
                        >
                          {state.showKonfirmasiPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataListUnitKerja: state.unitkerja.dataListUnitKerja,
    dataListTitikAbsensi: state.absen.dataListTitikAbsensi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUnitKerjaList: () => dispatch(getUnitKerjaList()),
    getTitikAbsensi: () => dispatch(getTitikAbsensi()),
    addKaryawan: (body) => dispatch(addKaryawan(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddKaryawan);

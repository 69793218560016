import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import AddIcon from "@material-ui/icons/Add";

import {
  getLembur,
  delLembur,
  getRiwayatLembur,
  editStatusLembur,
} from "app/redux/actions/gaji/LemburAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import { MatxMenu } from "matx";
import Aksi from "../../../assets/component/Aksi.svg";
import { DatePickerComponent, Modal, RenderTable, SearchKaryawan } from "app/components";

const Lembur = ({
  dataLembur,
  getLembur,
  delLembur,
  editStatusLembur,
  dataRiwayatLembur,
  getRiwayatLembur,
}) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,
    aksiClick: false,

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const [openModalPengajuan, setOpenModalPengajuan] = useState(false);
  const [openModalRiwayat, setOpenModalRiwayat] = useState(false);
  const [openModalTambah, setOpenModalTambah] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const [riwayatLembur, setRiwayatLembur] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTgl, setSearchTgl] = useState(new Date());
  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const getData = () => {
    var dateFormat = JSON.stringify(searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    dateFormat = dateFormat.split("-").reverse().join("-");
    dateFormat = `?bulan_tahun=${dateFormat}`;

    let params =
      search === ""
        ? dateFormat
        : `?search=${search}&${dateFormat.slice(1, 20)}`;

    getLembur(params);
    getRiwayatLembur(params);
  };

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  const handleDateChange = (date) => {
    setSearchTgl(date);
  };

  useLayoutEffect(() => {
    getData();
  }, [searchTgl]);
  useLayoutEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delLembur(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getLembur();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const [detailLembur, setDetailLembur] = useState();

  const handleEdit = (code) => {
    const DetailData = dataLembur.find((item) => item.lembur_code === code);

    setDetailLembur(DetailData);

    setOpenModalEdit(true);
  };

  const handleDetail = (code) => {
    const DetailData = dataLembur.find((item) => item.lembur_code === code);

    setDetailLembur(DetailData);

    setOpenModalPengajuan(true);
  };

  const handleDetailRiwayat = (code) => {
    const DetailData = dataRiwayatLembur.find(
      (item) => item.lembur_code === code
    );

    setDetailLembur(DetailData);

    setOpenModalRiwayat(true);
  };

  const handleCloseRiwayat = () => {
    setOpenModalRiwayat(false);
    getData();
  };

  const handleClosePengajuan = () => {
    setOpenModalPengajuan(false);
    getData();
  };

  const handleTerima = (id) => {
    editStatusLembur({
      lembur_code: id,
      status: "diterima",
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Status Lembur berhasil disimpan",
          "success"
        );
        getData();
        setOpenModalPengajuan(false);
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const [openModalTolak, setOpenModalTolak] = useState(false);

  const handleTolakModal = () => {
    setOpenModalTolak(true);
  };

  const handleTolak = (id, alasan) => {
    editStatusLembur({
      lembur_code: id,
      status: "ditolak",
      alasan: "alasan",
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Status Lembur berhasil disimpan",
          "success"
        );
        getData();
        setOpenModalPengajuan(false);
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">Lembur</h1>
        <div className="tambah-button">
          <Button onClick={() => setOpenModalTambah(true)}>
            <AddIcon />
            <div style={{ marginLeft: "5px" }}>Tambah</div>
          </Button>
        </div>
      </Grid>

      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <Grid container spacing={2} justify="space-between">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className="flex items-center " style={{ gap: "24px" }}>
                <div
                  className={
                    !riwayatLembur ? "lembur-button-active" : "lembur-button"
                  }
                  onClick={() => {
                    setRiwayatLembur(false);
                  }}
                >
                  Pengajuan
                </div>
                <div
                  className={
                    riwayatLembur ? "lembur-button-active" : "lembur-button"
                  }
                  onClick={() => {
                    setRiwayatLembur(true);
                  }}
                >
                  Riwayat Lembur
                </div>
              </div>
            </Grid>
            <div
              className="d-flex items-center py-4 "
              style={{ justifyContent: "flex-end", gap: "20px" }}
            >
              <SearchKaryawan
                otherClasses='karyawan-top-search'
                handleChange={(e) => setSearch(e.target.value)}
                value={search}
                handleKeyDown={submitSearch}
              />
              <Grid item className="d-flex align-items-end">
                <DatePickerComponent
                  date={searchTgl}
                  handleDate={handleDateChange}
                  name="tanggal"
                  search
                  tipe="MMMM yyyy"
                  addPrevNext
                />
              </Grid>
            </div>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              {!riwayatLembur ? (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>No</TableCell>
                      <TableCell colSpan={2}>Nama</TableCell>
                      <TableCell align="center" colSpan={4}>
                        Keterangan Lembur
                      </TableCell>

                      <TableCell align="center" colSpan={4}>
                        Tanggal
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Status
                      </TableCell>
                      <TableCell align="center" colSpan={3}>
                        Aksi
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <RenderTable
                      state={state}
                      data={dataLembur}
                      tableName="pengajuanlembur"
                      handleDetail={handleDetail}
                      handleEdit={handleEdit}
                      dataKosongSpan={19}
                      getData={getData}
                    />
                  </TableBody>
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>No</TableCell>
                      <TableCell colSpan={3}>Nama</TableCell>
                      <TableCell align="center" colSpan={4}>
                        Upah Lembur
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Total Jam
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Tanggal
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Status
                      </TableCell>
                      <TableCell align="center" colSpan={3}>
                        Aksi
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <RenderTable
                      state={state}
                      data={dataRiwayatLembur}
                      tableName="riwayatlembur"
                      aksiClick={() => setOpenModalRiwayat(true)}
                      handleDetail={handleDetailRiwayat}
                      getData={getData}
                    />
                  </TableBody>
                </>
              )}
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataLembur?.length ? dataLembur?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
      <Modal
        open={openModalRiwayat}
        setClose={setOpenModalRiwayat}
        modalName="detailLemburRiwayat"
        data={detailLembur}
        getData={getData}
        edit
      />
      <Modal
        open={openModalPengajuan}
        setClose={setOpenModalPengajuan}
        modalName="detailLemburPengajuan"
        data={detailLembur}
        getData={getData}
        edit
        pengajuan
        handleSimpan={handleTerima}
        handleTolakModal={handleTolakModal}
      />
      <Modal
        open={openModalTambah}
        setClose={setOpenModalTambah}
        modalName="tambahlembur"
        getData={getData}
      />
      <Modal
        open={openModalTolak}
        setClose={setOpenModalTolak}
        modalName="tolaklembur"
        getData={getData}
        handleTolak={handleTolak}
        data={detailLembur}
      />
      <Modal
        open={openModalEdit}
        setClose={setOpenModalEdit}
        modalName="tambahlembur"
        getData={getData}
        data={detailLembur}
        edit
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataLembur: state.gaji.dataLembur,
    dataRiwayatLembur: state.gaji.dataRiwayatLembur,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLembur: (params) => dispatch(getLembur(params)),
    delLembur: (params) => dispatch(delLembur(params)),
    getRiwayatLembur: (params) => dispatch(getRiwayatLembur(params)),
    editStatusLembur: (params) => dispatch(editStatusLembur(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Lembur);

import React from "react";
import { Redirect } from "react-router-dom";

import DashboardRoutes from "./views/dashboard/DashboardRoutes";
import KaryawanRoutes from "./views/karyawan/KaryawanRoutes";
import MOMRoutes from "./views/mom/MOMRoutes";
import AbsensiRoutes from "./views/absensi/AbsensiRoutes";
import GajiRoutes from "./views/gaji/GajiRoutes";
import PajakRoutes from "./views/pajak/PajakRoutes";
import IzinCutiRoutes from "./views/izincuti/IzinCutiRoutes";
import AdministrasiRoutes from "./views/administrasi/AdministrasiRoutes";
import AturanRoutes from "./views/aturan/AturanRoutes";
import LainnyaRoutes from "./views/lainnya/LainnyaRoutes";
// import FormPenilaianRoutes from "./views/formpenilaian/FormPenilaianRoutes";
import ProfileRoutes from "./views/profile/ProfileRoutes";
import SertifikatRoutes from "./views/sertifikat/SertifikatRoutes";
import SuratPeringatanRoutes from "./views/surat_peringatan/SuratPeringatanRoutes";
import RedirectLink from "./views/Redirect";
import SuratPenerimaanRoutes from "./views/adminLama/surat_penerimaan/SuratPenerimaanRoutes";
import SuketMagangRoutes from "./views/adminLama/suket_magang/SuketMagangRoutes";
import SuketKerjaRoutes from "./views/adminLama/suket_kerja/SuketKerjaRoutes";
import SuperMagangRoutes from "./views/adminLama/super_magang/SuperMagangRoutes";
import PenilaianRoutes from "./views/penilaian_sistem_lama/PenilaianRoutes_sistem_lama";
import KebijakanRoutes from "./views/kebijakan/KebijakanRoutes";
import FaqRoutes from "./views/faq/FaqRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const RedirectLinkComponent = [
  {
    path: "/redirect",
    exact: true,
    component: RedirectLink,
  },
];

const routes = [
  ...DashboardRoutes,
  ...SertifikatRoutes,
  ...SuratPeringatanRoutes,
  ...KaryawanRoutes,
  ...MOMRoutes,
  ...AbsensiRoutes,
  ...GajiRoutes,
  ...PajakRoutes,
  ...IzinCutiRoutes,
  ...AdministrasiRoutes,
  ...LainnyaRoutes,
  ...AturanRoutes,
  // ...FormPenilaianRoutes,
  ...PenilaianRoutes,
  ...ProfileRoutes,
  ...SuratPenerimaanRoutes,
  ...SuketMagangRoutes,
  ...SuketKerjaRoutes,
  ...SuperMagangRoutes,
  ...KebijakanRoutes,
  ...FaqRoutes,
  ...RedirectLinkComponent,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;

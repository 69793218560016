import { useState, useLayoutEffect, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { SimpleCard } from "matx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDetailKehadiran } from "app/redux/actions/AbsenAction";

const DetailAbsensiKaryawan = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dataDetailKehadiran = useSelector(
    (state) => state.absen.dataDetailKehadiran
  );

  const [state, setState] = useState({
    name: "",
    unit_kerja: "",
    tanggal: new Date().toString(),
    jam_masuk: "00:00:00",
    jam_pulang: "00:00:00",
    longtitude: "",
    longitude: "",
    latitude: "",
  });

  useLayoutEffect(() => {
    dispatch(getDetailKehadiran(id));
  }, []);

  useEffect(() => {
    if (dataDetailKehadiran !== {}) {
      setState((prev) => ({
        ...prev,
        ...dataDetailKehadiran,
        // latitude:
        //   dataDetailKehadiran.latitude === ""
        //     ? -6.363996159874421
        //     : Number(dataDetailKehadiran.latitude),
        // longtitude:
        //   dataDetailKehadiran.longtitude === ""
        //     ? 106.84959639987117
        //     : Number(dataDetailKehadiran.longtitude),
        // longitude:
        //   dataDetailKehadiran.longitude === ""
        //     ? 106.84959639987117
        //     : Number(dataDetailKehadiran.longitude),
      }));
    }
  }, [dataDetailKehadiran]);

  const handleTanggal = (tanggal) => {
    var newTgl = tanggal.slice(0, 11).split("-").reverse();
    if (newTgl[1] == "01") newTgl[1] = "Januari";
    if (newTgl[1] == "02") newTgl[1] = "Februari";
    if (newTgl[1] == "03") newTgl[1] = "Maret";
    if (newTgl[1] == "04") newTgl[1] = "April";
    if (newTgl[1] == "05") newTgl[1] = "Mei";
    if (newTgl[1] == "06") newTgl[1] = "Juni";
    if (newTgl[1] == "07") newTgl[1] = "Juli";
    if (newTgl[1] == "08") newTgl[1] = "Agustus";
    if (newTgl[1] == "09") newTgl[1] = "September";
    if (newTgl[1] == "10") newTgl[1] = "Oktober";
    if (newTgl[1] == "11") newTgl[1] = "November";
    if (newTgl[1] == "12") newTgl[1] = "Desember";

    return newTgl.join(" ");
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 text-semiblack">
            Detail Kehadiran
          </h1>
        </Grid>
      </Grid>
      <div className="my-8">
        <SimpleCard className="">
          <div className="mx-12 px-10 mt-5 mb-8">
            <Grid
              container
              className="mt-3"
              spacing={4}
              justifyContent="flex-start"
              alignItems="baseline"
            >
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Nama Karyawan
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.name}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Unit Kerja
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {state.unit_kerja}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Tanggal
                </h1>
                <p className="text-16 font-medium text-semiblack">
                  {handleTanggal(state.tanggal)}
                </p>
              </Grid>
              <Grid item xs={12} sm={6} container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                    Jam Masuk
                  </h1>
                  <p className="text-16 font-medium text-semiblack">
                    {state.jam_masuk.slice(0, 5)}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                    Jam Pulang
                  </h1>
                  <p className="text-16 font-medium text-semiblack">
                    {state.jam_pulang !== ""
                      ? state.jam_pulang.slice(0, 5)
                      : "-"}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Bukti Masuk
                </h1>
                {state.absen_with == "" && (
                  <p className="text-16 font-medium text-semiblack">-</p>
                )}
                {state.absen_with == "location" && (
                  <p className="text-16 font-medium text-semiblack">
                    <iframe
                      key={state.users_code + Math.random() * 1000}
                      width="450"
                      height="250"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                      src={
                        "https://maps.google.com/maps?q=" +
                        state.latitude +
                        ", " +
                        (state.longitude || state.longtitude) +
                        "&z=15&output=embed"
                      }
                    ></iframe>
                  </p>
                )}

                {state.absen_with == "selfie" && (
                  <img src={state.foto_bukti} alt="Foto Bukti" />
                )}

                {state.absen_with == "qr_code" && (
                  <p className="text-16 font-medium text-semiblack">
                    Scan QR CODE
                  </p>
                )}
              </Grid>
              <Grid item xs={6}>
                <h1 className="text-muted text-14 font-medium mb-3 font-500 h4">
                  Bukti Keluar
                </h1>
                {state.absen_with_pulang == "" && (
                  <p className="text-16 font-medium text-semiblack">-</p>
                )}
                {state.absen_with_pulang == "location" && (
                  <p className="text-16 font-medium text-semiblack">
                    <iframe
                      key={state.users_code + Math.random() * 1000}
                      width="450"
                      height="250"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                      src={
                        "https://maps.google.com/maps?q=" +
                        state.latitude_pulang +
                        ", " +
                        (state.longitude_pulang || state.longtitude_pulang) +
                        "&z=15&output=embed"
                      }
                    ></iframe>
                  </p>
                )}

                {state.absen_with_pulang == "selfie" && (
                  <img src={state.foto_bukti_pulang} alt="Foto Bukti" />
                )}

                {state.absen_with_pulang == "qr_code" && (
                  <p className="text-16 font-medium text-semiblack">
                    Scan QR CODE
                  </p>
                )}
              </Grid>
            </Grid>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default DetailAbsensiKaryawan;

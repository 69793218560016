import { TableCell, TableRow, Button, Icon, Dialog, Grid, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "app/views/izincuti/component/Modal";
import BadgeSelect from "../select/BadgeSelect";
import { useDispatch } from "react-redux";
import { updateStatusIzin } from "app/redux/actions/IzinCutiAction";
import Swal from "sweetalert2";

const RenderTableDashboard = ({ data, state, setState, getData }) => {
  const dispatch = useDispatch();

  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const handleTanggal = (str) => {
    var tgl = str.split("-");
    tgl = tgl.reverse();
    if (tgl[1] == "01") tgl[1] = "Januari";
    if (tgl[1] == "02") tgl[1] = "Februari";
    if (tgl[1] == "03") tgl[1] = "Maret";
    if (tgl[1] == "04") tgl[1] = "April";
    if (tgl[1] == "05") tgl[1] = "Mei";
    if (tgl[1] == "06") tgl[1] = "Juni";
    if (tgl[1] == "07") tgl[1] = "Juli";
    if (tgl[1] == "08") tgl[1] = "Agustus";
    if (tgl[1] == "09") tgl[1] = "September";
    if (tgl[1] == "10") tgl[1] = "Oktober";
    if (tgl[1] == "11") tgl[1] = "November";
    if (tgl[1] == "12") tgl[1] = "Desember";
    tgl = tgl.join(" ");

    return tgl;
  };

  const handleStatusChange = async (event, item) => {
    const selectedStatus = event.target.value;
    if (selectedStatus !== "ditolak") {
      await dispatch(
        updateStatusIzin({
          izin_code: item.izin_code,
          status: selectedStatus,
          tanggal_mulai: item.tanggal_mulai,
          tanggal_akhir: item.tanggal_akhir,
        })
      );
    } else {
      setState((prev) => ({
        ...prev,
        selectedItem: { ...item },
        openDitolak: true,
      }));
    }
    getData();
  };

  const handleCloseDitolak = () => {
    setState((prev) => ({
      ...prev,
      openDitolak: false,
    }));
  };

  const handleAlasan = (event) => {
    const alasan = event.target.value;
    setState((prev) => ({
      ...prev,
      selectedItem: { ...prev.selectedItem, alasan },
    }));
  };

  const handleKirimPenolakan = async () => {
    const item = state?.selectedItem;
    try {
      await dispatch(
        updateStatusIzin({
          izin_code: item?.izin_code,
          status: "Ditolak",
          alasan: item?.alasan,
          tanggal_mulai: item?.tanggal_mulai,
          tanggal_akhir: item?.tanggal_akhir,
        })
      );
      setTimeout(() => {
        handleCloseDitolak();
        Swal.fire("Success!", "Data Cuti Berhasil Disimpan", "success");
      }, 1000);
    } catch (e) {
      handleCloseDitolak();
      Swal.fire("Oopss!", `${e}`, "error");
    }
    getData();
  };

  const useStyles = makeStyles({
    dialog: {
      width: "430px",
      height: "fit-content",
      scrollbarColor: "transparent",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  return (
    <>
      {data?.length > 0 ? (
        data
          .slice(
            state.page * state.rowsPerPage,
            state.page * state.rowsPerPage + state.rowsPerPage
          )
          .map((item, index) => (
            <TableRow hover key={index}>
              <TableCell
                className="color-semiblack text-14"
                align="center"
                colSpan={1}
              >
                {index + 1 + handleNumbering()}
              </TableCell>
              <TableCell className="color-semiblack text-14 pl-3" colSpan={3}>
                {item.name}
              </TableCell>
              <TableCell
                className="color-semiblack text-14 pl-2"
                align="center"
                colSpan={3}
              >
                {item.unit_kerja}
              </TableCell>
              <TableCell
                className="color-semiblack text-14"
                align="center"
                colSpan={4}
              >
                {handleTanggal(item.tanggal_mulai)} -{" "}
                {handleTanggal(item.tanggal_akhir)}
              </TableCell>
              <TableCell
                className="color-semiblack text-14 pl-2"
                align="center"
                colSpan={3}
              >
                <BadgeSelect
                  item={item}
                  handleChange={(event) => handleStatusChange(event, item)}
                  menus={[
                    {
                      label: "Diizinkan",
                      value: "diizinkan",
                      icon: (
                        <Icon>
                          {item.status.toLowerCase() === "diizinkan"
                            ? "radio_button_checked"
                            : "radio_button_unchecked"}
                        </Icon>
                      ),
                      color: "#72BE42",
                      backgroundColor: "#F3FFEC",
                    },
                    {
                      label: "Ditolak",
                      value: "ditolak",
                      icon: (
                        <Icon>
                          {item.status.toLowerCase() === "ditolak"
                            ? "radio_button_checked"
                            : "radio_button_unchecked"}
                        </Icon>
                      ),
                      color: "#FF5C58",
                      backgroundColor: "#FFEBEA",
                    },
                    {
                      label: "Diproses",
                      value: "diproses",
                      icon: (
                        <Icon>
                          {item.status.toLowerCase() === "diproses"
                            ? "radio_button_checked"
                            : "radio_button_unchecked"}
                        </Icon>
                      ),
                      color: "#FBC02A",
                      backgroundColor: "#FFF8E7",
                    },
                  ]}
                  badgeBackgroundColor={
                    item.status.toLowerCase() === "diizinkan"
                      ? "#72BE42"
                      : item.status.toLowerCase() === "ditolak"
                      ? "#FF5C58"
                      : "#FBC02A"
                  }
                  iconBackgroundColor={
                    item.status.toLowerCase() === "diizinkan"
                      ? "#68B03A"
                      : item.status.toLowerCase() === "ditolak"
                      ? "#F94A46"
                      : "#F6B40E"
                  }
                  renderValue={(selected) =>
                    selected === "diizinkan"
                      ? "Diizinkan"
                      : selected === "ditolak"
                      ? "Ditolak"
                      : "Diproses"
                  }
                  value={item.status.toLowerCase()}
                />
              </TableCell>
              <TableCell
                className="color-semiblack text-14 pl-1"
                align="center"
                colSpan={2}
              >
                <Modal
                  item={item}
                  getData={getData}
                  izin={true}
                  handleTanggal={handleTanggal}
                />
              </TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow hover>
          <TableCell colSpan={16} align="center">
            Data kosong
          </TableCell>
        </TableRow>
      )}

      <Dialog
        open={state?.openDitolak}
        onClose={handleCloseDitolak}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: "hidden !important", margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-semibold text-black mb-5">
            Masukkan Alasan Ditolak
          </h1>
          <Grid container>
            <Grid item xs={12}>
              <p
                className="text-16 font-medium text-black"
                style={{ marginBottom: "-3px" }}
              >
                Alasan Ditolak
              </p>
              <textarea
                required={true}
                name="alasan"
                rows="3"
                cols="50"
                className="mt-3 w-full px-6 py-4 border-radius-5"
                placeholder="Alasan"
                style={{
                  border: "2px solid #e6e9ed",
                  fontSize: "1.5em",
                  fontFamily: "inherit",
                }}
                onChange={handleAlasan}
              />
            </Grid>

            <Grid item xs={6} className="mt-4 pr-3">
              <Button
                variant="outlined"
                className="w-full text-primary elevation-z0 p-4"
                onClick={handleCloseDitolak}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6} className="mt-4 pl-3">
              <Button
                variant="contained"
                className="w-full text-white bg-primary elevation-z0 p-4"
                onClick={handleKirimPenolakan}
              >
                Kirim
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default RenderTableDashboard;

import { API, setAuthToken } from "app/config/api";

export const GET_DATA_RIWAYAT_GAJI = "GET_DATA_RIWAYAT_GAJI";
export const GET_DETAIL_RIWAYAT_GAJI = "GET_DETAIL_RIWAYAT_GAJI";
export const DEL_RIWAYAT_GAJI = "DEL_RIWAYAT_GAJI";

export const ADD_BAYAR_GAJI_ARRAY = "ADD_BAYAR_GAJI_ARRAY";

export const getRiwayatGaji = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/riwayat-gaji${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_RIWAYAT_GAJI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_RIWAYAT_GAJI,
          payload: [],
        });
      });
  };
};

export const addBayarGajiArray = (array) => {
  return {
    type: "ADD_BAYAR_GAJI_ARRAY",
    payload: array,
  };
};

export const getBayarRiwayatGaji = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get(`user/gaji/riwayat-gaji/bayar`);
};

export const getDetailRiwayatGaji = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/riwayat-gaji/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_RIWAYAT_GAJI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_RIWAYAT_GAJI,
        payload: [],
      });
    }
  };
};

export const delRiwayatGaji = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/riwayat-gaji/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_RIWAYAT_GAJI,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addRiwayatGaji = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/riwayat-gaji/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

// export const cetakRiwayatGaji = (code, send_email) => {
//   return async (dispatch) => {
//     const token = await localStorage.getItem("jwt_token");
//     setAuthToken(token);
//     API.defaults.headers.common["Content-Type"] = "multipart/form-data";
//     const res = await API.post(
//       `/gaji/riwayat_gaji/${code}/${send_email}/cetak`
//     ).catch((err) => {
//       return Promise.reject(err);
//     });

//     return res.data;
//   };
// };

export const addRiwayatGajiBayar = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(
      "user/gaji/riwayat-gaji/bayarSekarang",
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const cetakRiwayatGaji = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/riwayat_gaji/${code}/1/cetak`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};
export const editRiwayatGaji = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/riwayat-gaji/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

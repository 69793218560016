import Faq from "./Faq";

const FaqRoutes = [
  {
    path: "/faq",
    component: Faq,
    exact: true,
  },
];

export default FaqRoutes;

import {
  Button,
  Dialog,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UploadImage from "app/components/button/UploadImage";
import Swal from "sweetalert2";
import Aksi from "../../../assets/component/Aksi.svg";
import DatePickerComponent from "../select/DatePickerComponent";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useState, useEffect } from "react";
import ModalImg from "app/components/Modal/ModalImg";
import DateFnsUtils from "@date-io/date-fns";
import { SelectText } from "app/components";
import { connect, useDispatch } from "react-redux";

import { addGajiTunjangan } from "app/redux/actions/gaji/TunjanganAction";
import {
  editReimburse,
  addGajiReimburse,
} from "app/redux/actions/gaji/ReimburseAction";

import { editBonus, addGajiBonus } from "app/redux/actions/gaji/BonusAction";

import { editDenda, addGajiDenda } from "app/redux/actions/gaji/DendaAction";

import {
  editLembur,
  addGajiLembur,
  addLembur,
  editGajiLembur,
} from "app/redux/actions/gaji/LemburAction";

import { editTunjangan } from "app/redux/actions/gaji/TunjanganAction";
import { editStatusLembur } from "app/redux/actions/gaji/LemburAction";
import { formatRupiah, formatTanggal } from "app/utils/globalFunction";

const Modal = ({
  open,
  data,
  modalName,
  pageName,
  handleTerima,
  date,
  setClose,

  usersCode,
  addGajiTunjangan,
  addLembur,

  getData,
  edit,
  editTunjangan,
  editReimburse,
  addGajiReimburse,
  handleTolakModal,
  handleTolak,
  editBonus,
  addGajiBonus,
  editDenda,
  addGajiDenda,
  editLembur,
  addGajiLembur,
  gajiCode,
  pengajuan,
  editGajiLembur,
}) => {
  const [state, setState] = useState({
    newKategori: "",
    kategori: [],
    error: false,
    error_text: "",
    upah_lembur: 0,
    selectedTime: "08:00",
    type: {
      label: "Karyawan",
      value: 1,
    },
    typeSelected: 1,
    dataType: [
      {
        label: "Karyawan",
        value: 1,
      },
      {
        label: "Magang",
        value: 2,
      },
    ],
    time: new Date(),
    v_tanggal_lahir: new Date(),
    tanggal_lahir: new Date().toISOString().split("T")[0],
    atasan_langsung: "",
    hak_cuti: 0,
    jenis_kelamin: 1,
    divisi_code: "",
  });
  const [params, setParams] = useState({
    img: "",
    img_preview: "",
  });

  const handleClose = () => {
    setClose(false);
    getData();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  function formatTime(inputStr) {
    if (inputStr === undefined || inputStr === null) return "";

    return inputStr.slice(0, 5);
  }

  const [submit, setSubmit] = useState(false);

  const [formData, setFormData] = useState({
    tanggal: new Date().toISOString().slice(0, 10),
    keterangan: "",
    jenis: "",
    tipe: "",
    nominal: "",
    total_jam: "",
    upah_lembur: "",
    upah_per_jam: "",
    time: new Date(),
    waktu_mulai: new Date(),
    waktu_akhir: new Date(),
    foto_bukti: "",
    foto_bukti_preview: "",
    nama_karyawan: "",
    name: "",
    status: "",
    unit_kerja: "",
  });

  // detail lembur
  const [status, setStatus] = useState("diproses");

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    if (edit) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tanggal: data?.tanggal,
        keterangan: data?.keterangan,
        jenis:
          modalName === "tambahtunjangan"
            ? data?.jenis_tunjangan_code
            : modalName === "tambahdenda"
            ? data?.jenis_denda
            : modalName === "tambahbonus"
            ? data?.jenis_bonus_code
            : data?.jenis,
        tipe: data?.tipe,
        nominal: data?.nominal,
        total_jam: data?.total_jam,
        upah_lembur: data?.upah_lembur,
        upah_per_jam: data?.upah_per_jam,
        foto_bukti_preview: data?.foto_bukti_preview,
        foto_bukti: data?.foto_bukti,
        waktu_mulai: formatTime(data?.waktu_mulai),
        waktu_akhir: formatTime(data?.waktu_akhir),
        nama_karyawan: data?.users_code,
        status: data?.status,
        unit_kerja: data?.unit_kerja,
        name: data?.name,
        lembur_code: data?.lembur_code,
      }));
      console.log(formData.name);
    }
  }, [data]);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // status lembur
  // const handleSubmitStatusLembur = () => {

  // }

  const handleSubmit = (apiFunction) => {
    apiFunction({
      lembur_code: data?.lembur_code,
      bonus_code: data?.bonus_code,
      gaji_code: gajiCode,
      tunjangan_code: data?.tunjangan_code,
      reimburse_code: data?.reimburse_code,
      denda_code: data?.denda_code,
      tanggal: formData.tanggal,
      keterangan: formData.keterangan,
      jenis: formData.jenis,
      jenis_denda: formData.jenis,
      nominal: formData.nominal,
      tipe: formData.tipe,
      users_code:
        modalName === "tambahlembur" ? formData.nama_karyawan : usersCode,

      total_jam: formData.total_jam,
      upah_lembur: formData.upah_lembur,
      upah_per_jam: formData.upah_per_jam,

      foto_bukti: formData.foto_bukti,
      waktu_mulai: formData.waktu_mulai,
      waktu_akhir: formData.waktu_akhir,
    })
      .then(() => {
        Swal.fire("Success!", "Data Tunjangan berhasil disimpan", "success");

        handleClose();
        setFormData({
          tanggal: new Date().toISOString().slice(0, 10),
          keterangan: "",
          jenis: "a",
          tipe: "a",
          nominal: "",
          total_jam: "",
          upah_lembur: "",
          upah_per_jam: "",
          time: new Date(),
        });
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleChangeFoto = (file, path) => {
    setFormData((pref) => ({
      ...pref,
      foto_bukti: file,
      foto_bukti_preview: path,
    }));
  };

  const handleChangeAtasan = (val) => {
    setState({
      atasan_langsung: val.value,
    });
  };

  const statusSearch = ["diproses", "ditolak", "diterima"];
  const dispatch = useDispatch();

  // Lembur Pengajuan Simpan
  const handleSimpan = (id) => {
    editStatusLembur({
      lembur_code: formData?.lembur_code,
      status: status,
      keterangan: formData?.keterangan,
      waktu_mulai: formData?.waktu_mulai,
      waktu_akhir: formData?.waktu_akhir,
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Status Lembur berhasil disimpan",
          "success"
        );
        getData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      fullWidth={false}
      style={{ minHeight: "fit-content !important" }}
    >
      {modalName === "tambahlembur" ? (
        <div className="p-5">
          <h1 className="mb-5  font-semibold text-20">
            {edit ? "Edit" : "Tambah"} Lembur
          </h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h4>Karyawan</h4>
                <SelectText
                  value={formData.nama_karyawan}
                  karyawan
                  label="Nama Karyawan"
                  handleChange={handleChange("nama_karyawan")}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h4>Waktu Mulai</h4>
                <TextField
                  name="jam_masuk"
                  type="time"
                  value={formData.waktu_mulai}
                  onChange={handleChange("waktu_mulai")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h4>Waktu Akhir</h4>
                <TextField
                  name="jam_masuk"
                  type="time"
                  value={formData.waktu_akhir}
                  onChange={handleChange("waktu_akhir")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h4>Upah Lembur</h4>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.upah_lembur}
                  onChange={handleChange("upah_lembur")}
                  name="upah_lembur"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h4>Upah Per Jam</h4>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.upah_per_jam}
                  onChange={handleChange("upah_per_jam")}
                  name="upah_per_Jam"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24} className="h-full">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={2}
                  placeholder="Keterangan"
                  className="text-field-modifier-multiline"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Foto Bukti</h4>

                <UploadImage
                  uploadFoto={handleChangeFoto}
                  preview={formData?.foto_bukti_preview}
                  CardStyle={{
                    width: "100%",
                    height: "200px !important",
                  }}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  disabled={submit}
                  type="submit"
                  onClick={
                    edit
                      ? () => handleSubmit(editLembur)
                      : () => handleSubmit(addLembur)
                  }
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "detailLemburRiwayat" ? (
        <div className="p-5 simple-card-content">
          <h1 className="mb-5">Detail Lembur</h1>
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="modal-content">
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama Pegawai</h5>
                  <div>{formData?.name}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Tanggal</h5>

                  <div>{formData.tanggal}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Waktu Mulai Lembur</h5>
                  <div>{formData.waktu_mulai}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Waktu Akhir Lembur</h5>
                  <div>{formData.waktu_akhir}</div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Upah Lembur</h5>
                  <div>{formData.upah_lembur}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Total Jam</h5>
                  <div>{formData.total_jam}</div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Unit Kerja</h5>
                  <div>{formData.unit_kerja}</div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <h5>Status</h5>
                  <div>{formData.status}</div>
                </Grid>

                <Grid item sm={12} xs={24} className="mb-20px">
                  <h5>Keterangan</h5>
                  <div>{formData.keterangan}</div>
                </Grid>
              </Grid>

              {formData.foto_bukti && (
                <Grid item sm={12} xs={24}>
                  <h5>Bukti</h5>

                  <a href={formData?.foto_bukti}>Lihat Bukti</a>
                </Grid>
              )}

              <div className="atur_kehadiran_button_container">
                {pengajuan ? (
                  <>
                    {/* <div className="tolak-lembur-button w-full">
                      <Button
                        onClick={() => handleTolakModal()}
                        variant="contained"
                        color="default"
                      >
                        Tolak
                      </Button>
                    </div> */}
                    <div className="batal-lembur-button w-full">
                      <Button
                        onClick={() => {
                          handleSimpan(formData?.lembur_code);
                          handleClose();
                        }}
                        variant="contained"
                        color="default"
                      >
                        Simpan
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="batal-lembur-button w-full">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="default"
                    >
                      Tutup
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ValidatorForm>
        </div>
      ) : // detail lembur
      modalName === "detailLemburPengajuan" ? (
        <div className="p-5 simple-card-content">
          <h1 className="mb-5">Detail Lembur</h1>
          {/* <ValidatorForm onSubmit={handleSubmit}> */}
          <div className="modal-content">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Pegawai</h5>
                <div>{formData.name}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tanggal</h5>

                <div>{formData.tanggal}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Upah Lembur</h5>
                <div>{formData.upah_lembur}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Total Jam</h5>
                <div>{formData.total_jam}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Unit Kerja</h5>
                <div>{formData.unit_kerja}</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Status</h5>
                <SelectText
                  dataSelect={statusSearch}
                  handleChange={handleStatus}
                  value={status}
                  // search
                  label="Status"
                />
              </Grid>

              {status == "diterima" && (
                <>
                  <Grid item sm={6} xs={12}>
                    <h5>Waktu Mulai Lembur</h5>
                    <TextField
                      name="jam_masuk"
                      type="time"
                      value={formData?.waktu_mulai}
                      onChange={handleChange("waktu_mulai")}
                      variant="standard"
                      className="w-full text-field-modifier-time "
                      size="small"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    {/* <div>{formData?.waktu_mulai}</div> */}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <h5>Waktu Akhir Lembur</h5>
                    <TextField
                      name="jam_masuk"
                      type="time"
                      value={formData.waktu_akhir}
                      onChange={handleChange("waktu_akhir")}
                      variant="standard"
                      className="w-full text-field-modifier-time "
                      size="small"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    {/* <div>{formData?.waktu_akhir}</div> */}
                  </Grid>

                  <Grid item sm={12} xs={24} className="mb-20px">
                    <h5>Keterangan</h5>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={2}
                      placeholder="Keterangan"
                      className="text-field-modifier-multiline"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={formData?.keterangan}
                      onChange={handleChange("keterangan")}
                    />
                    {/* <div>{formData.keterangan}</div> */}
                  </Grid>
                </>
              )}

              {status == "ditolak" && (
                <Grid item sm={12} xs={24} className="mb-20px">
                  <h5>Keterangan</h5>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={2}
                    placeholder="Keterangan"
                    className="text-field-modifier-multiline"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={formData?.keterangan}
                    onChange={handleChange("keterangan")}
                  />
                  {/* <div>{formData.keterangan}</div> */}
                </Grid>
              )}
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="batal-lembur-button w-full">
                <Button
                  onClick={() => handleSimpan(formData?.lembur_code)}
                  variant="contained"
                  color="default"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
          {/* </ValidatorForm> */}
        </div>
      ) : modalName === "detailReimburse" ? (
        <div className="p-5 simple-card-content">
          <h1 className="mb-5">Detail Reimburse</h1>
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="modal-content">
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama Pegawai</h5>
                  <div>{data.name}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Jenis Reimburse</h5>
                  <div>{data.jenis_reimburse}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Unit Kerja</h5>
                  <div>{data.unit_kerja}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Status</h5>
                  {data?.status?.toLowerCase() === "diproses" ? (
                    <Chip
                      className={classes.yellow}
                      color="primary"
                      label="Diproses"
                    />
                  ) : data?.status?.toLowerCase() === "ditolak" ? (
                    <Chip
                      className={classes.red}
                      color="primary"
                      colorPrimary=""
                      label="Ditolak"
                    />
                  ) : (
                    <Chip
                      className={classes.root}
                      color="primary"
                      label="Diterima"
                    />
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Dibuat Tanggal</h5>
                  <div>{formatTanggal(data.tanggal)}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Nominal</h5>
                  <div>{formatRupiah(data.nominal)}</div>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={24}
                  className={
                    data?.status?.toLowerCase() !== "ditolak" && "mb-20px"
                  }
                >
                  <h5>Keterangan</h5>
                  <div>{data.keterangan}</div>
                </Grid>
                {data?.status?.toLowerCase() === "ditolak" && (
                  <Grid item sm={12} className="mb-20px">
                    <h5>Alasan Ditolak</h5>
                    <div>{data.alasan_ditolak}</div>
                  </Grid>
                )}
              </Grid>
              <Grid item sm={12} className="mb-0">
                <h5>Bukti</h5>
                {/* <div
                  style={{
                    backgroundImage: `url(${data.bukti})`,
                    // backgroundImage: `url(${formData.foto_bukti_preview})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "100%",
                  }}
                  className="w-full"
                /> */}
                {data?.bukti === "" || data?.bukti == undefined ? (
                  <Avatar
                    className="w-full"
                    style={{ height: "170px" }}
                    variant="rounded"
                  >
                    Bukti tidak ditemukan
                  </Avatar>
                ) : (
                  // <Avatar
                  //   className="w-full"
                  //   src={`${data?.bukti}`}
                  //   style={{ height: "170px" }}
                  //   variant="rounded"
                  // />
                  <ModalImg src={data?.bukti} />
                )}
              </Grid>
              <div className="atur_kehadiran_button_container mt-5">
                {data?.status?.toLowerCase() === "diproses" ? (
                  <>
                    <div className="tolak-lembur-button w-full">
                      <Button
                        onClick={() => handleTolakModal()}
                        variant="contained"
                        color="default"
                      >
                        Tolak
                      </Button>
                    </div>
                    <div className="batal-lembur-button w-full">
                      <Button
                        onClick={() => {
                          handleTerima(data?.reimburse_code);
                          handleClose();
                        }}
                        variant="contained"
                        color="default"
                      >
                        Terima
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="tolak-lembur-button w-full">
                      <Button
                        onClick={() => handleClose()}
                        variant="contained"
                        color="default"
                      >
                        Tutup
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ValidatorForm>
        </div>
      ) : modalName === "tambahtunjangan" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Tunjangan</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Tipe</h4>
                <SelectText
                  tipeTunjangan
                  label="Tipe"
                  handleChange={handleChange("tipe")}
                  value={formData?.tipe}
                />
              </Grid>
              <Grid item sm={12} xs={24}>
                <h4>Jenis</h4>
                <SelectText
                  isJenisTunjangan
                  label="Jenis"
                  handleChange={handleChange("jenis")}
                  value={formData?.jenis}
                  selectedTipe={formData?.tipe}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h5>Nominal</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.nominal}
                  onChange={handleChange("nominal")}
                  name="nominal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editTunjangan)
                      : () => handleSubmit(addGajiTunjangan)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tolaklembur" || modalName === "tolakreimburse" ? (
        <div className="p-5">
          <h1 className="mb-5">Alasan Ditolak</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Alasan Ditolak</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Alasan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={() => {
                    handleTolak(
                      modalName === "tolakreimburse"
                        ? data?.reimburse_code
                        : data?.lembur_code,
                      formData?.keterangan
                    );
                    handleClose();
                  }}
                  type="submit"
                >
                  Kirim
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tambahtunjangan" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Tunjangan</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Tipe</h4>
                <SelectText
                  tipeTunjangan
                  label="Tipe"
                  handleChange={handleChange("tipe")}
                  value={formData?.tipe}
                />
              </Grid>
              <Grid item sm={12} xs={24}>
                <h4>Jenis</h4>
                <SelectText
                  isJenisTunjangan
                  label="Jenis"
                  handleChange={handleChange("jenis")}
                  value={formData?.jenis}
                  selectedTipe={formData?.tipe}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h5>Nominal</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.nominal}
                  onChange={handleChange("nominal")}
                  name="nominal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editTunjangan)
                      : () => handleSubmit(addGajiTunjangan)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tambahlemburriwayat" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Lembur</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h4>Waktu Mulai</h4>
                <TextField
                  name="jam_masuk"
                  type="time"
                  value={formData.waktu_mulai}
                  onChange={handleChange("waktu_mulai")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h4>Waktu Akhir</h4>
                <TextField
                  name="jam_masuk"
                  type="time"
                  value={formData.waktu_akhir}
                  onChange={handleChange("waktu_akhir")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Upah Lembur</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.upah_lembur}
                  onChange={handleChange("upah_lembur")}
                  name="upah_lembur"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Upah Per Jam</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.upah_per_jam}
                  onChange={handleChange("upah_per_jam")}
                  name="upah_per_Jam"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>
              {/* <Grid item sm={12} xs={24}>
                <h5>Total Jam</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.total_jam}
                  onChange={handleChange("total_jam")}
                  name="total_jam"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid> */}

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editGajiLembur)
                      : () => handleSubmit(addGajiLembur)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tambahreimburse" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Reimburse</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Jenis</h4>
                <SelectText
                  jenisReimburse
                  label="Jenis"
                  handleChange={handleChange("jenis")}
                  value={formData?.jenis}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h5>Nominal</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.nominal}
                  onChange={handleChange("nominal")}
                  name="nominal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editReimburse)
                      : () => handleSubmit(addGajiReimburse)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tambahdenda" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Denda</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Jenis</h4>
                <SelectText
                  jenisDenda
                  label="Jenis"
                  handleChange={handleChange("jenis")}
                  value={formData?.jenis}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h5>Nominal</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.nominal}
                  onChange={handleChange("nominal")}
                  name="nominal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editDenda)
                      : () => handleSubmit(addGajiDenda)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : modalName === "tambahbonus" ? (
        <div className="p-5">
          <h1 className="mb-5">{edit ? "Edit" : "Tambah"} Bonus</h1>
          <div className="modal-content form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={24}>
                <h4>Jenis</h4>
                <SelectText
                  jenisBonus
                  label="Jenis"
                  handleChange={handleChange("jenis")}
                  value={formData?.jenis}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h5>Nominal</h5>
                <TextField
                  className="text-field-modifier-long"
                  fullWidth
                  variant="standard"
                  id="mui-theme-provider-outlined-input"
                  value={formData?.nominal}
                  onChange={handleChange("nominal")}
                  name="nominal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="ptkp-text-bold">Rp</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={24}>
                <h4>Tanggal</h4>
                <DatePickerComponent
                  date={formData?.tanggal}
                  handleDate={(date) => handleDateChange(date, "tanggal")}
                  name="tanggal"
                />
              </Grid>

              <Grid item sm={12} xs={24} className="mb-4">
                <h4>Keterangan</h4>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Keterangan"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="text-field-modifier-multiline"
                  value={formData?.keterangan}
                  onChange={handleChange("keterangan")}
                />
              </Grid>
            </Grid>

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  variant="normal"
                  onClick={
                    edit
                      ? () => handleSubmit(editBonus)
                      : () => handleSubmit(addGajiBonus)
                  }
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-5">
          <h1 className="mb-5">Atur Kehadiran</h1>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid item sm={6} xs={12}>
              <h4>Nama Karyawan</h4>
              <p>deni juli</p>
            </Grid>
            <Grid item sm={6} xs={12}>
              <h4>Kehadiran</h4>
              <p>hadir</p>
            </Grid>
            {modalName === "tidakhadir" ? (
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h4>Catatan</h4>
                  <input type="text" />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h4>Jam Masuk</h4>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item sm={12} xs={12}>
                      <KeyboardTimePicker
                        className="w-full"
                        variant="inline"
                        format="hh:mm"
                        margin="normal"
                        id="date-picker-inline"
                        value={state.v_tanggal_lahir}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        size="small"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h4>Jam Pulang</h4>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item sm={12} xs={12}>
                      <KeyboardTimePicker
                        keyboardIcon={<img src={Aksi} />}
                        className="w-full"
                        variant="inline"
                        format="hh:mm"
                        margin="normal"
                        id="time-picker"
                        value={state.v_tanggal_lahir}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        size="small"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            )}

            <div className="atur_kehadiran_button_container">
              <div className="simpan-button">
                <Button variant="normal" disabled={submit} type="submit">
                  Simpan
                </Button>
              </div>
              <div className="Hapus-button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                >
                  Batal
                </Button>
              </div>
            </div>
          </ValidatorForm>
        </div>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return { dataKaryawan: state.karyawan.allData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGajiTunjangan: (params) => dispatch(addGajiTunjangan(params)),
    addGajiReimburse: (params) => dispatch(addGajiReimburse(params)),
    addGajiLembur: (params) => dispatch(addGajiLembur(params)),
    addGajiDenda: (params) => dispatch(addGajiDenda(params)),
    addGajiBonus: (params) => dispatch(addGajiBonus(params)),
    editLembur: (params) => dispatch(editLembur(params)),
    addLembur: (params) => dispatch(addLembur(params)),
    editGajiLembur: (params) => dispatch(editGajiLembur(params)),

    editTunjangan: (params) => dispatch(editTunjangan(params)),
    editReimburse: (params) => dispatch(editReimburse(params)),
    editDenda: (params) => dispatch(editDenda(params)),
    editBonus: (params) => dispatch(editBonus(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

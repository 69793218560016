import { useState } from 'react';
import MenuBonus from './MenuBonus';
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from '@material-ui/core';

const RenderTable = ({ data, state, getData }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };
  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell className="text-semiblack text-14 " colSpan={9}>
            {item.jenis}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={2}
          >
            <MenuBonus
              item={item}
              path={`/lainnya/bonus/edit/${item.jenis_bonus_code}`}
              getData={getData}
            />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={12} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const TableBonus = ({ data, getData }) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={1}
              className="text-semiblack font-medium text-15"
              align="center"
            >
              No
            </TableCell>
            <TableCell
              colSpan={9}
              className="text-semiblack font-medium text-15"
            >
              Jenis Bonus
            </TableCell>
            <TableCell
              colSpan={2}
              className="text-semiblack font-medium text-15"
              align="center"
            >
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable data={data} state={state} getData={getData} />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16 my-7"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={'From'}
        page={state.page}
        backIconButtonProps={{
          'aria-label': 'Previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next page',
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default TableBonus;

import { API, setAuthToken } from "app/config/api";

export const GET_DATA_BONUS = "GET_DATA_BONUS";
export const GET_DETAIL_BONUS = "GET_DETAIL_BONUS";
export const DEL_BONUS = "DEL_BONUS";

export const getBonus = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/bonus${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_BONUS,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_BONUS,
          payload: [],
        });
      });
  };
};

export const getDetailBonus = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/bonus/detail/${code}`).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_BONUS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_BONUS,
        payload: [],
      });
    }
  };
};

export const delBonus = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/bonus/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_BONUS,
        payload: code,
      });
    }
    return res.data;
  };
};

export const DEL_GAJI_BONUS = "DEL_GAJI_BONUS";

export const delGajiBonus = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/gaji_bonus/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_GAJI_BONUS,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/bonus/add", params).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const addGajiBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/bonus/gaji_bonus/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editBonus = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/bonus/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

import { useLayoutEffect, useEffect, useState } from 'react';
import { SimpleCard } from 'matx';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from '@material-ui/core';

import TableTunjangan from './component/TableTunjangan';
import { connect } from 'react-redux';
import { getJenisTunjangan } from 'app/redux/actions/lainnya/JenisTunjanganAction';
import AddIcon from '@material-ui/icons/Add';

const Tunjangan = ({ jenisTunjangan, getJenisTunjangan }) => {
  const [search, setSearch] = useState('');

  const getData = () => {
    var params = `?search=${search}`;
    getJenisTunjangan(params);
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: '#0A0A0A' }}
          >
            Jenis Tunjangan
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: 'flex-end' }}
        >
          <Link to="/lainnya/tunjangan/add">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="karyawan-add-ultra-container mt-2">
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            className="mb-3"
          >
            <Grid item xs={12} sm={3} className="d-flex align-items-end">
              <TextField
                size="small"
                variant="outlined"
                className="karyawan-top-search"
                placeholder="Cari Jenis"
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    getData();
                  }
                }}
                value={search}
                name="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableTunjangan
            search={search}
            dataTunjangan={jenisTunjangan}
            getData={getData}
          />
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jenisTunjangan: state.lainnya.jenisTunjangan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJenisTunjangan: (params) => dispatch(getJenisTunjangan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tunjangan);

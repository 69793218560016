import { API, setAuthToken } from "app/config/api";

export const GET_DATA_INFORMASI_GAJI = "GET_DATA_INFORMASI_GAJI";
export const GET_DETAIL_INFORMASI_GAJI = "GET_DETAIL_INFORMASI_GAJI";
export const DEL_INFORMASI_GAJI = "DEL_INFORMASI_GAJI";

export const getInformasiGaji = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`user/gaji/informasi_gaji${params}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_INFORMASI_GAJI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DATA_INFORMASI_GAJI,
          payload: [],
        });
      });
  };
};

export const getDetailInformasiGaji = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`user/gaji/informasi_gaji/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_INFORMASI_GAJI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_INFORMASI_GAJI,
        payload: [],
      });
    }
  };
};

export const delInformasiGaji = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(
      `user/gaji/informasi_gaji/delete/${code}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: DEL_INFORMASI_GAJI,
        payload: code,
      });
    }
    return res.data;
  };
};

export const addInformasiGaji = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("user/gaji/informasi_gaji/add", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );

    return res.data;
  };
};

export const editInformasiGaji = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post(`user/gaji/informasi_gaji/update`, params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

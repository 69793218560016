import React, { useState } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import { Button, TextField, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { SelectText } from "app/components";

import { TimePickerComponent } from "app/components";
import TableMagangKeterangan from "./component/TableMagangKeterangan";
import SelectMagang from "./component/SelectMagang";
import ic_remove from "assets/component/ic_remove.svg";
import ic_add from "assets/component/ic_add.svg";
import { connect } from "react-redux";
import DatePickerComponent from "../../components/select/DatePickerComponent";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { addHandover } from "app/redux/actions/administrasi/HandoverAction";

const TambahHandover = ({ addHandover }) => {
  const [search, setSearch] = useState("");
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    alasan_keluar: "",
    tanggal_masuk: new Date().toISOString().slice(0, 10),
    tanggal_keluar: new Date().toISOString().slice(0, 10),
    penerima: "",
    mengetahui_atasan: "",
    alasan: "",
  });

  const formattedTime = (e) => {
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return e.toLocaleTimeString("en-US", options);
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  console.log(formData);
  const history = useHistory();

  const handleSubmit = () => {
    addHandover({
      alasan_keluar: formData.alasan_keluar,
      tanggal_masuk: formData.tanggal_masuk,
      tanggal_keluar: formData.tanggal_keluar,
      penerima_handover_code: formData.penerima,
      mengetahui_atasan_code: formData.mengetahui_atasan,
      detail: inputList,
      alasan: formData.alasan,
    })
      .then(() => {
        Swal.fire("Success!", "Data Handover berhasil disimpan", "success");
        history.push("/administrasi/Handover");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const [submit, setSubmit] = useState(false);

  const [inputList, setInputList] = useState([
    {
      yang_diserahterimakan: "",
      pic_keterangan: "",
      yang_diserahterimakan_jabatan: "",
    },
    {
      yang_diserahterimakan: "",
      pic_keterangan: "",
      yang_diserahterimakan_jabatan: "",
    },
  ]);

  const handleInputChange = (index, field) => (e) => {
    const updatedList = [...inputList];

    updatedList[index][field] = e.target.value;

    setInputList(updatedList);
  };

  const handleAddInput = () => {
    setInputList([
      ...inputList,
      {
        yang_diserahterimakan: "",
        pic_keterangan: "",
        yang_diserahterimakan_jabatan: "",
      },
    ]);
  };

  const dataAlasanKeluar = ["Habis kontrak", "Lainnya"];

  const handleRemoveInput = (index) => {
    const updatedList = [...inputList];
    updatedList.splice(index, 1);
    setInputList(updatedList);
  };

  const renderInput = () => {
    const isLastInput = (index) => inputList.length - 1 !== index;

    return (
      <>
        {inputList?.map((item, index) => (
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item sm={3} xs={6}>
              <h5 className="font-semibold text-13">Yang Diserahterimakan *</h5>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={handleInputChange(index, "yang_diserahterimakan")}
                value={item.yang_diserahterimakan}
                fullWidth
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <h5 className="font-semibold text-13">PIC / Keterangan *</h5>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={handleInputChange(index, "pic_keterangan")}
                value={item.pic_keterangan}
                fullWidth
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <h5 className="font-semibold text-13">
                Yang diserahterimakan / Jabatan *
              </h5>
              <TextField
                className="text-field-modifier-long"
                variant="standard"
                id={`input-${index}`}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={handleInputChange(
                  index,
                  "yang_diserahterimakan_jabatan"
                )}
                fullWidth
                value={item.yang_diserahterimakan_jabatan}
              />
            </Grid>

            {isLastInput(index) ? (
              <div className="delete-button  mb-4">
                <Button onClick={() => handleRemoveInput(index)}>
                  <img src={ic_remove} alt="" />
                </Button>
              </div>
            ) : (
              <div className="add-button mb-4">
                <Button onClick={() => handleAddInput()} fullWidth>
                  <img src={ic_add} alt="" />
                </Button>
              </div>
            )}
          </Grid>
        ))}
      </>
    );
  };
  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-4 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="mb-4">
            <strong className="font-semibold text-25">Tambah Handover</strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-4"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard title="" className="my-8">
        <div className="my-8 pb-2">
          <Grid container justifyContent="center" spacing={3} className="px-8">
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15">Tanggal Mulai</strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal_masuk}
                handleDate={handleDateChange}
                name="tanggal_masuk"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong className="font-semibold text-15">
                  Tanggal Selesai
                </strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal_keluar}
                handleDate={handleDateChange}
                name="tanggal_keluar"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">Alasan Keluar</strong>
              </div>

              <SelectText
                label="Alasan Keluar"
                handleChange={handleChange("alasan_keluar")}
                dataSelect={dataAlasanKeluar}
                value={formData.alasan_keluar}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {formData.alasan_keluar === "Lainnya" && (
                <>
                  <div className="mb-4">
                    <strong className="font-semibold text-15">Alasan</strong>
                  </div>

                  <TextField
                    className="text-field-modifier-long"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    id="mui-theme-provider-outlined-input"
                    value={formData.alasan}
                    onChange={handleChange("alasan")}
                    placeholder="alasan"
                    fullWidth
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">
                  Penerima Handover
                </strong>
              </div>
              <SelectText
                karyawan
                label="Penerima Handover"
                handleChange={handleChange("penerima")}
                value={formData.penerima}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mb-4">
                <strong className="font-semibold text-15">
                  Mengetahui Atasan
                </strong>
              </div>
              <SelectText
                karyawan
                label="Mengetahui Atasan"
                handleChange={handleChange("mengetahui_atasan")}
                value={formData.mengetahui_atasan}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>

      <div className="mb-20px" />

      <SimpleCard title="">
        <div className="karyawan-add-ultra-container mt-5 mb-5 px-8 ">
          {renderInput()}
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { dataDivisiList: state.karyawan.dataDivisiList };
};

const mapDispatchToProps = (dispatch) => {
  return { addHandover: (params) => dispatch(addHandover(params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(TambahHandover);

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { SimpleCard } from "matx";

import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";

import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";

import {
  editAturAbsensi,
  getDetailAturAbsensi,
} from "app/redux/actions/AturanAction";

import { TimePickerComponent } from "app/components";
import ic_remove from "assets/component/ic_remove.svg";
import ic_add from "assets/component/ic_add.svg";

const EditAturAbsensi = ({
  editAturAbsensi,
  getDetailAturAbsensi,
  detailAturan,
}) => {
  const { id } = useParams();

  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  const [formData, setFormData] = useState({
    nama: "",
    jam_awal_masuk: currentTime,
    jam_akhir_masuk: currentTime,
    jam_awal_keluar: currentTime,
    jam_akhir_keluar: currentTime,
    jam_awal_lembur: currentTime,
    jam_akhir_lembur: currentTime,
  });

  const formattedTime = (e) => {
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return e.toLocaleTimeString("en-US", options);
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    if (id === "nama") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };

  console.log(formData);
  const history = useHistory();

  const handleSubmit = () => {
    editAturAbsensi({
      atur_absensi_code: id,
      nama: formData.nama,
      jam_awal_masuk: formData.jam_awal_masuk,
      jam_akhir_masuk: formData.jam_akhir_masuk,
      jam_awal_keluar: formData.jam_awal_keluar,
      jam_akhir_keluar: formData.jam_akhir_keluar,
      jam_awal_kerja: formData.jam_awal_kerja,
      jam_akhir_kerja: formData.jam_akhir_kerja,
      denda_array: inputList,
    })
      .then(() => {
        Swal.fire("Success!", "Data Absensi berhasil disimpan", "success");
        history.push("/AturAbsensi");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const formRef = useRef(null);

  const [submit, setSubmit] = useState(false);

  const [inputList, setInputList] = useState([
    {
      jam_awal: currentTime,
      jam_akhir: currentTime,
      nominal: "",
    },
    {
      jam_awal: currentTime,
      jam_akhir: currentTime,
      nominal: "",
    },
  ]);

  const handleInputChange = (index, field) => (e) => {
    const updatedList = [...inputList];

    updatedList[index][field] = e.target.value;

    setInputList(updatedList);
  };

  const handleAddInput = () => {
    setInputList([
      ...inputList,
      {
        jam_awal: currentTime,
        jam_akhir: currentTime,
        nominal: "",
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const updatedList = [...inputList];
    updatedList.splice(index, 1);
    setInputList(updatedList);
  };

  console.log(detailAturan);

  const renderInput = () => {
    const isLastInput = (index) => inputList.length - 1 !== index;
    const onlyOne = () => inputList.length == 1;

    return (
      <>
        {inputList?.map((item, index) => (
          <Grid container justifyContent="space-between">
            <Grid item sm={3} xs={12}>
              <h6>Jam Awal</h6>
              <TextField
                name="jam_masuk"
                type="time"
                value={item.jam_awal}
                onChange={handleInputChange(index, "jam_awal")}
                variant="standard"
                className="w-full text-field-modifier-time "
                size="small"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <h6>Jam Akhir</h6>
              <TextField
                name="jam_masuk"
                type="time"
                value={item.jam_akhir}
                onChange={handleInputChange(index, "jam_akhir")}
                variant="standard"
                className="w-full text-field-modifier-time "
                size="small"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <h6>Nominal</h6>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={item.nominal}
                onChange={handleInputChange(index, "nominal")}
                name="nominal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>

            {onlyOne() ? (
              <>
                <Grid item sm={1} xs={12}>
                  <div className="add-button">
                    <Button onClick={() => handleAddInput()}>
                      <img src={ic_add} alt="" />
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <div className="delete-button" style={{ display: "none" }}>
                    <Button onClick={() => handleRemoveInput(index)}>
                      <img src={ic_remove} alt="" />
                    </Button>
                  </div>
                </Grid>
              </>
            ) : isLastInput(index) ? (
              <>
                <Grid item sm={1} xs={12}>
                  <div className="delete-button">
                    <Button onClick={() => handleRemoveInput(index)}>
                      <img src={ic_remove} alt="" />
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <div className="delete-button" style={{ display: "none" }}>
                    <Button onClick={() => handleRemoveInput(index)}>
                      <img src={ic_remove} alt="" />
                    </Button>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={1} xs={12}>
                  <div className="delete-button">
                    <Button onClick={() => handleRemoveInput(index)}>
                      <img src={ic_remove} alt="" />
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <div className="add-button">
                    <Button onClick={() => handleAddInput()}>
                      <img src={ic_add} alt="" />
                    </Button>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </>
    );
  };

  const getData = () => {
    getDetailAturAbsensi(id);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama: detailAturan?.nama,
      jam_awal_masuk: detailAturan?.jam_awal_masuk,
      jam_akhir_masuk: detailAturan?.jam_akhir_masuk,
      jam_awal_keluar: detailAturan?.jam_awal_keluar,
      jam_akhir_keluar: detailAturan?.jam_akhir_keluar,
      jam_awal_kerja: detailAturan?.jam_awal_kerja,
      jam_akhir_kerja: detailAturan?.jam_akhir_kerja,
    });
    setInputList(detailAturan?.denda);
  }, [detailAturan]);

  console.log("inputList", inputList);

  return (
    <div>
      <div className="p-5">
        <Grid
          container
          spacing={3}
          className="my-4 mx-auto "
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm>
            <div className="mb-4">
              <strong className="font-semibold text-25">Edit Absensi</strong>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            className="d-flex mr-4"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              className=" px-15 py-3 "
              onClick={handleSubmit}
            >
              <span>Simpan</span>
            </Button>
          </Grid>
        </Grid>

        <div className="mt-8 mb-6">
          <SimpleCard loading={false} currency="" saldo="" heightInput={150}>
            <Grid item xs={12}>
              <h6 className="mt-5 mb-5">Nama Absensi</h6>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.nama}
                onChange={handleChange("nama")}
                name="nominal"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </SimpleCard>
        </div>
        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            {/* Waktu Jam Kerja */}
            <div className="sub-title-form">Waktu Jam Kerja</div>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h6>Jam Awal</h6>

                <TextField
                  name="jam_akhir"
                  type="time"
                  value={formData.jam_awal_kerja}
                  onChange={handleChange("jam_awal_kerja")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h6>Jam Akhir</h6>

                <TextField
                  name="jam_akhir"
                  type="time"
                  value={formData.jam_akhir_kerja}
                  onChange={handleChange("jam_akhir_kerja")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>
            
            <div className="sub-title-form">Waktu Absen Masuk</div>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h6>Jam Awal</h6>

                <TextField
                  name="jam_masuk"
                  type="time"
                  value={formData.jam_awal_masuk}
                  onChange={handleChange("jam_awal_masuk")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <h6>Jam Akhir</h6>

                <TextField
                  name="jam_akhir"
                  type="time"
                  value={formData.jam_akhir_masuk}
                  onChange={handleChange("jam_akhir_masuk")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className="sub-title-form">Waktu Absen Pulang</div>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h6>Jam Awal</h6>

                <TextField
                  name="jam_akhir"
                  type="time"
                  value={formData.jam_awal_keluar}
                  onChange={handleChange("jam_awal_keluar")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <h6>Jam Akhir</h6>

                <TextField
                  name="jam_akhir"
                  type="time"
                  value={formData.jam_akhir_keluar}
                  onChange={handleChange("jam_akhir_keluar")}
                  variant="standard"
                  className="w-full text-field-modifier-time "
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>
          <div className="mb-10" />
        </SimpleCard>

        <div className="mb-5" />

        <SimpleCard loading={false} currency="" saldo="">
          <Fragment>
            <div className="sub-title-form">Denda Keterlambatan</div>
            {renderInput()}

            <div className="mb-20px" />
          </Fragment>
        </SimpleCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailAturan: state.aturan.detailAturan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAturAbsensi: (params) => dispatch(editAturAbsensi(params)),
    getDetailAturAbsensi: (code) => dispatch(getDetailAturAbsensi(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAturAbsensi);

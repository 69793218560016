import { Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import React, { Fragment, useState, useRef } from "react";
import { SimpleCard } from "matx";

import { connect } from "react-redux";
import Swal from "sweetalert2";

import { ValidatorForm } from "react-material-ui-form-validator";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useHistory } from "react-router-dom";

import { addAturTitikAbsensi } from "app/redux/actions/AturanAction";

const TambahTitikAbsensi = ({ addAturTitikAbsensi }) => {
  const [formData, setFormData] = useState({
    nama_kantor: "",
    alamat: "",
    latitude: "",
    longitude: "",
    radius: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const formRef = useRef(null);

  const [submit, setSubmit] = useState(false);
  const history = useHistory();

  const handleSubmit = async  () => {
    try {
      await addAturTitikAbsensi({
        nama_kantor: formData.nama_kantor,
        alamat: formData.alamat,
        latitude: formData.latitude,
        longitude: formData.longitude,
        radius: formData.radius,
      });
      Swal.fire("Success!", "Data Lokasi Absensi berhasil disimpan", "success");
      history.push("/TitikAbsensi");
    } catch (e) {
      Swal.fire("Oopss!", "Data Lokasi Absensi gagal disimpan", "error");
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="   my-2 mt-8 mb-3"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h4 className="mb-5 font-semibold text-25 ">Tambah Titik Absensi</h4>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3"
            onClick={handleSubmit}
          >
            <span className="">Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard loading={false} currency="" saldo="">
        <Grid container spacing={3} className=" mt-3">
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Nama Kantor</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="nama_kantor"
              onChange={handleChange}
              placeholder="Nama Kantor"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Alamat</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="alamat"
              onChange={handleChange}
              placeholder="Alamat"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Latitude</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="latitude"
              onChange={handleChange}
              placeholder="Latitude"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Longitude</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="longitude"
              onChange={handleChange}
              placeholder="Longitude"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <h5 className="font-semibold text-15">Radius (meter)</h5>
            <TextField
              className="text-field-modifier-long"
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              id="mui-theme-provider-outlined-input"
              name="radius"
              onChange={handleChange}
              placeholder="Radius"
            />
          </Grid>
        </Grid>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDivisiList: state.karyawan.dataDivisiList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAturTitikAbsensi: (params) => dispatch(addAturTitikAbsensi(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TambahTitikAbsensi);

import { Button, Dialog, Icon, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BadgeSelect from "app/components/select/BadgeSelect";
import {
  addKaryawanAbsensi,
  editKaryawanAbsensi,
  manualUpdateDataAbsen,
} from "app/redux/actions/AbsenAction";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const AbsensiBadgeSelect = ({ item, state, setState, getData }) => {
  const menus = [
    {
      label: "Absensi",
      value: "",
      icon: (
        <Icon>
          {item.status.toLowerCase() === ""
            ? "radio_button_checked"
            : "radio_button_unchecked"}
        </Icon>
      ),
      color: "#00ACEE",
      backgroundColor: "#E0F7FF",
    },
    {
      label: "Hadir",
      value: "hadir",
      icon: (
        <Icon>
          {item.status.toLowerCase() === "hadir"
            ? "radio_button_checked"
            : "radio_button_unchecked"}
        </Icon>
      ),
      color: "#72BE42",
      backgroundColor: "#F3FFEC",
    },
    {
      label: "Tidak Hadir",
      value: "tidak hadir",
      icon: (
        <Icon>
          {item.status.toLowerCase() === "tidak hadir"
            ? "radio_button_checked"
            : "radio_button_unchecked"}
        </Icon>
      ),
      color: "#FF5C58",
      backgroundColor: "#FFEBEA",
    },
    {
      label: "Terlambat",
      value: "terlambat",
      icon: (
        <Icon>
          {item.status.toLowerCase() === "terlambat"
            ? "radio_button_checked"
            : "radio_button_unchecked"}
        </Icon>
      ),
      color: "#FBC02A",
      backgroundColor: "#FFF8E7",
    },
    // {
    //   label: "Izin",
    //   value: "izin",
    //   icon: (
    //     <Icon>
    //       {item.status.toLowerCase() === "izin"
    //         ? "radio_button_checked"
    //         : "radio_button_unchecked"}
    //     </Icon>
    //   ),
    //   color: "#2B2B2B",
    //   backgroundColor: "",
    // },
    // {
    //   label: "Cuti",
    //   value: "cuti",
    //   icon: (
    //     <Icon>
    //       {item.status.toLowerCase() === "cuti"
    //         ? "radio_button_checked"
    //         : "radio_button_unchecked"}
    //     </Icon>
    //   ),
    //   color: "#2B2B2B",
    //   backgroundColor: "",
    // },
  ];

//   if (item.status === "") {
//     menus.slice().unshift({
//       label: "Absensi",
//       value: "",
//       icon: (
//         <Icon>
//           {item.status.toLowerCase() === ""
//             ? "radio_button_checked"
//             : "radio_button_unchecked"}
//         </Icon>
//       ),
//       color: "#2B2B2B",
//       backgroundColor: "",
//     });
//   }

  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [hadir, setHadir] = useState(false);
  const [disableJamMasuk, setDisableJamMasuk] = useState(false);
  const [disableJamPulang, setDisableJamPulang] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitHadir, setSubmitHadir] = useState(true);
  const useStyles = makeStyles({
    dialog: {
      width: "460px",
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();
  const handleClickOpen = (submitHadir) => {
    setOpen(true);
    setSubmitHadir(submitHadir);
  };
  const handleClose = () => {
    setOpen(false);
    if (item.hasOwnProperty("id")) {
      getData(item.id);
    } else {
      getData();
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (submitHadir) {
      if (item.jam_masuk === "") {
        try {
          let dateFormat = JSON.stringify(
              item.hasOwnProperty("tanggal") ? item.tanggal : state.searchTgl
          );
          dateFormat = dateFormat.slice(1, 11);

        //   if (item.status !== '') {
            // update ke hadir jika status sudah ada
            await dispatch(manualUpdateDataAbsen({
                absensi_karyawan_code: item.absensi_karyawan_code,
                status: selectedStatus.toLowerCase(),
                jam_masuk: state.jam_masuk,
                users_code: item.users_code == undefined ? item.id : item.users_code,
                tanggal: dateFormat,
            }));
        //   } else {
            //   await dispatch(addKaryawanAbsensi({
            //     users_code:
            //       item.users_code == undefined ? item.id : item.users_code,
            //     jam_masuk: state.jam_masuk,
            //     tanggal: dateFormat,
            //     catatan: "",
            //   }));
        //   }

          setState({
            jam_masuk: "00:00",
            jam_pulang: "00:00",
            catatan: "",
          });

          handleClose();

          Swal.fire("Success!", "Data Absensi berhasil disimpan", "success");
        } catch (e) {
          Swal.fire("Oopss!", `Data Absensi gagal disimpan ${e}`, "error");
        }
      } else if (item.jam_masuk !== "") {
        try {
          let dateFormat = JSON.stringify(
            item.hasOwnProperty("tanggal") ? item.tanggal : state.searchTgl
          );
          dateFormat = dateFormat.slice(1, 11);

          await dispatch(manualUpdateDataAbsen({
            users_code: item.users_code == undefined ? item.id : item.users_code,
            jam_masuk: state.jam_masuk ? state.jam_masuk : item.jam_masuk,
            jam_pulang: state.jam_pulang,
            tanggal: dateFormat,
            status: selectedStatus.toLowerCase(),
            absensi_karyawan_code: item.absensi_karyawan_code,
            catatan: state.catatan == "" ? "tidak ada catatan" : state.catatan,
          }));
        //   await dispatch(editKaryawanAbsensi({
        //     users_code:
        //       item.users_code == undefined ? item.id : item.users_code,
        //     jam_masuk: item.jam_masuk,
        //     jam_pulang: state.jam_pulang,
        //     tanggal: dateFormat,
        //     status: selectedStatus.toLowerCase(),
        //     absensi_karyawan_code: item.absensi_karyawan_code,
        //     catatan: state.catatan == "" ? "tidak ada catatan" : state.catatan,
        //   }));

          setState({
            jam_masuk: "00:00",
            jam_pulang: "00:00",
            catatan: "",
          });

          handleClose();

          Swal.fire("Success!", "Data Absensi berhasil disimpan", "success");
        } catch (e) {
          Swal.fire("Oopss!", `Data Absensi gagal disimpan ${e}`, "error");
        }
      }
    } else {
      try {
        let dateFormat = JSON.stringify(
            item.hasOwnProperty("tanggal") ? item.tanggal : state.searchTgl
        );
        dateFormat = dateFormat.slice(1, 11);

        // if (item.status !== '') {
            // update ke tidak hadir jika status sudah ada
            await dispatch(manualUpdateDataAbsen({
                absensi_karyawan_code: item.absensi_karyawan_code,
                tanggal: dateFormat,
                status: selectedStatus.toLowerCase(),
                catatan: state.catatan,
                users_code: item.users_code == undefined ? item.id : item.users_code,
            }));
        // } else {
        //     await dispatch(addKaryawanAbsensi({
        //         users_code: item.users_code == undefined ? item.id : item.users_code,
        //         tanggal: dateFormat,
        //         catatan: state.catatan,
        //         status: selectedStatus.toLowerCase(),
        //     }));
        // }

        setState({
            jam_masuk: "00:00",
            jam_pulang: "00:00",
            catatan: "",
        });

        handleClose();

        Swal.fire("Success!", "Data Absensi berhasil disimpan", "success");

      } catch (e) {
        Swal.fire("Oopss!", `Data Absensi gagal disimpan ${e}`, "error");
      }
    }
  };

  const handleStatusChange = async (event, item) => {
    const newSelectedStatus = event.target.value;
    setSelectedStatus(newSelectedStatus);

    let dateFormat = JSON.stringify(
        item.hasOwnProperty("tanggal") ? item.tanggal : state.searchTgl
    );
    dateFormat = dateFormat.slice(1, 11);

    switch (newSelectedStatus) {
      case "hadir":
      case "terlambat":
        setHadir(true);
        setDisableJamMasuk(false);
        setDisableJamPulang(true);
        handleClickOpen(true);
        break;
      case "tidak hadir":
        setHadir(false);
        setDisableJamMasuk(false);
        setDisableJamPulang(false);
        handleClickOpen(false);
        break;
      default:
        await dispatch(manualUpdateDataAbsen({
            absensi_karyawan_code: item.absensi_karyawan_code,
            status: newSelectedStatus.toLowerCase(),
            users_code: item.users_code,
            tanggal: dateFormat,
        }));
        handleClose();
        break;
    }
  };

  return (
    <>
      <BadgeSelect
        item={item}
        handleChange={(event) => handleStatusChange(event, item)}
        menus={menus}
        badgeBackgroundColor={
          item.status.toLowerCase() === "hadir"
            ? "#72BE42"
            : item.status.toLowerCase() === "tidak hadir"
            ? "#FF5C58"
            : item.status.toLowerCase() === "terlambat"
            ? "#FBC02A"
            : item.status.toLowerCase() === ""
            ? "#00ACEE"
            : "#2B2B2B"
        }
        iconBackgroundColor={
          item.status.toLowerCase() === "hadir"
            ? "#68B03A"
            : item.status.toLowerCase() === "tidak hadir"
            ? "#F94A46"
            : item.status.toLowerCase() === "terlambat"
            ? "#F6B40E"
            : item.status.toLowerCase() === ""
            ? "#0A9AD1"
            : "#000000"
        }
        renderValue={(selected) =>
          selected === "hadir"
            ? "Hadir"
            : selected === "tidak hadir"
            ? "Tidak Hadir"
            : selected === "terlambat"
            ? "Terlambat"
            : selected === "izin"
            ? "Izin"
            : selected === "cuti"
            ? "Cuti"
            : "Absensi"
        }
        value={item.status.toLowerCase()}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: "hidden !important", margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-bold text-black mb-6">Atur Kehadiran</h1>
          <p
            className="text-14 font-medium"
            style={{ color: "#BBBBBB", marginBottom: "-7px" }}
          >
            Nama Karyawan
          </p>
          <p className="text-16 font-medium text-black mb-6">{item.name}</p>
          <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
            Kehadiran
          </p>
          {hadir ? (
            <div className="d-flex">
              <Button
                variant="contained"
                className="bg-primary text-white elevation-z0 p-4 mr-3"
                style={{ width: "120px" }}
              >
                Hadir
              </Button>
              <Button
                variant="contained"
                className="text-white elevation-z0 p-4 ml-3"
                style={{ background: "#CECECE", width: "120px" }}
              >
                Tidak Hadir
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              <Button
                variant="contained"
                className="text-white elevation-z0 p-4 mr-3"
                style={{ background: "#CECECE", width: "120px" }}
              >
                Hadir
              </Button>
              <Button
                variant="contained"
                className="bg-error text-white elevation-z0 p-4 ml-3"
                style={{ width: "120px" }}
              >
                Tidak Hadir
              </Button>
            </div>
          )}
          {hadir ? (
            <div className="d-flex" style={{ marginTop: "8.5px" }}>
              <div className="mr-3 w-full">
                <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
                  Jam Masuk
                </p>
                <TextField
                  name="jam_masuk"
                  className="w-full"
                  label="Jam Masuk"
                  variant="outlined"
                  type="time"
                  disabled={disableJamMasuk}
                  value={disableJamMasuk ? item.jam_masuk : state.jam_masuk}
                  onChange={handleChange}
                  style={{ scaleY: "0.8" }}
                />
              </div>
              <div className="ml-3 w-full">
                <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
                  Jam Pulang
                </p>
                <TextField
                  name="jam_pulang"
                  className="w-full"
                  label="Jam Pulang"
                  variant="outlined"
                  type="time"
                  disabled={disableJamPulang}
                  value={state.jam_pulang}
                  onChange={handleChange}
                  style={{ scaleY: "0.8" }}
                />
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "25px" }}>
              <p className="text-14 font-medium" style={{ color: "#BBBBBB" }}>
                Catatan
              </p>
              <textarea
                name="catatan"
                rows="4"
                cols="50"
                className="w-full text-14 font-normal rounded-lg px-4 py-3"
                placeholder="Catatan (Tidak Wajib)"
                onChange={handleChange}
                style={{
                  backgroundColor: "#fbfbfb",
                  border: "1px solid #bcbfc2",
                  borderRadius: "5px",
                  resize: "vertical"
                }}
              />
            </div>
          )}
          <div className="d-flex" style={{ marginTop: "35px" }}>
            <Button
              variant="outlined"
              className="w-full text-primary bg-transparent elevation-z0 mr-3 p-4"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              className="w-full bg-primary text-white elevation-z0 ml-3 p-4"
              onClick={() => handleSubmit()}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AbsensiBadgeSelect;

import { Button, Grid, TextField, InputAdornment } from "@material-ui/core";
import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SimpleCard } from "matx";

import { SelectText } from "app/components";
import DatePickerComponent from "../../../../components/select/DatePickerComponent";

import { useParams, useHistory } from "react-router-dom";
import {
  editTunjangan,
  getDetailTunjangan,
} from "app/redux/actions/gaji/TunjanganAction";

const EditTunjangan = ({
  editTunjangan,
  getDetailTunjangan,
  detailTunjangan,
}) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    nama_karyawan: "",
    tanggal: new Date().toISOString().slice(0, 10),
    keterangan: "",
    jenis_tunjangan: "",
    tipe_tunjangan: "",
    nominal: "",
  });

  const getData = () => {
    getDetailTunjangan(id);
  };

  const handleChangeJenisTunjangan = (e) => {
    const { value } = e.target;
    console.log(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      jenis_tunjangan: value.jenis_tunjangan_code,
      nominal: value.nominal,
    }));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFormData({
      nama_karyawan: detailTunjangan?.users_code,
      tanggal: detailTunjangan?.tanggal,
      keterangan: detailTunjangan?.keterangan,
      jenis_tunjangan: detailTunjangan?.jenis,
      nominal: detailTunjangan?.nominal,
      tipe_tunjangan: detailTunjangan?.tipe,
    });
  }, [detailTunjangan]);

  console.log(formData);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleChange = (id) => (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const history = useHistory();

  const handleSubmit = () => {
    editTunjangan({
      tunjangan_code: id,
      users_code: formData.nama_karyawan,
      tanggal: formData.tanggal,
      keterangan: formData.keterangan,
      jenis: formData.jenis_tunjangan,
      nominal: formData.nominal,
      tipe: formData.tipe_tunjangan,
    })
      .then(() => {
        Swal.fire("Success!", "Data Tunjangan berhasil disimpan", "success");
        history.push("/Tunjangan");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const handleChangeStatus = (e) => {
    const { checked } = e.target;

    const value = checked ? "masih bekerja" : "tidak bekerja";

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: value,
    }));
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-2 mx-auto "
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <div className="">
            <strong className="font-semibold text-25">Edit Tunjangan</strong>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-3"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            className=" px-15 py-3 mb-2  "
            onClick={handleSubmit}
          >
            <span>Simpan</span>
          </Button>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div className="my-8 pb-8">
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            className="px-8"
          >
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Nama Karyawan</strong>
              </p>
              <SelectText
                karyawan
                label="Nama Karyawan"
                handleChange={handleChange("nama_karyawan")}
                value={formData.nama_karyawan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Tanggal</strong>
              </p>
              <DatePickerComponent
                date={formData.tanggal}
                handleDate={handleDateChange}
                name="tanggal"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Tipe</strong>
              </p>
              <SelectText
                tipeTunjangan
                label="Tipe Tunjangan"
                handleChange={handleChange("tipe_tunjangan")}
                value={formData.tipe_tunjangan}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className="font-weight-bold h4 text-dark">
                <strong>Jenis</strong>
              </p>
              <SelectText
                isJenisTunjangan
                label="Jenis Tunjangan"
                handleChange={handleChange("jenis_tunjangan")}
                value={formData.jenis_tunjangan}
                selectedTipe={formData.tipe_tunjangan}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <h5>Nominal</h5>
              <TextField
                className="text-field-modifier-long"
                fullWidth
                variant="standard"
                id="mui-theme-provider-outlined-input"
                value={formData.nominal}
                onChange={handleChange("nominal")}
                name="nominal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="ptkp-text-bold">Rp</span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}></Grid>

            <Grid item sm={6} xs={12}>
              <h5>Keterangan</h5>
              <TextField
                className="text-field-modifier-multiline"
                fullWidth
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={formData.keterangan}
                onChange={handleChange("keterangan")}
                name="keterangan"
                multiline
                rows={4}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailTunjangan: state.gaji.detailTunjangan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editTunjangan: (params) => dispatch(editTunjangan(params)),
    getDetailTunjangan: (code) => dispatch(getDetailTunjangan(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTunjangan);
